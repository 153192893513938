<template>
  <v-container>
    Please select a student...
    <v-autocomplete
      class="my-10"
      v-model="studentID"
      :items="students"
      item-text="Name"
      item-value="UniqueID"
      outlined
      dense
      label="Select Student Name"
    ></v-autocomplete>
  </v-container>
</template>

<script>
export default {
  name: "SelectStudent",
  components: {},
  data: () => ({
    students: [],
    studentID: null,
  }),
  mounted() {
    this.getStudents();
  },
  watch: {
    studentID() {
      if (this.studentID != null) {
        this.$store.commit("setStudentID", this.studentID);
      }
    },
  },
  methods: {
    getStudents() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/34",
        params: {
          SubjectID: 1,
        },
        responseType: "json",
      })
        .then((response) => {
          this.students = response.data;
          //console.log(response.data)
          //return response.data
          //this.loaded=true
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    getSchool() {
      return this.$store.getters.getSchool;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
  },
};
</script>
