<template>
  <div>
    <v-card>
      <ampil-card title="Admin Menu"></ampil-card>
      <v-card-title>Getting started with amPIL...</v-card-title>
      <v-card-text>
        Use the menu options to set up amPIL. We would recommend working through the sections sequentially. Please see the documentation below for more details
        <div class="text-h6">Help</div>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              School Structure
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>
                  Begin by setting up amPIL for the structure of your school, adding subjects, classes, schemes of work, assessments and terminal exams such as GCSE and KS2.
                </li>
                <li>
                  When allocating class names, make sure these match your upload file exactly, and if students are setted, list these sequentially.
                </li>
                <li>
                  The topic mappings will allow amPIL to help predict upcoming topics students might struggle with on a scheme of work. 
                </li>
                <li>
                  Best practice is to set up the scheme of work mappings, then once you are ready to set the students an assessment, you can set the assessment mappings one by one.
                </li>
                <li>
                  amPIL will try and help map topics automatically to make this process quicker (currently supported subjects are Maths, English, Science).
                </li>
                <li>
                  Terminal exam data is used to help rank students and allocate grades. If you are a secondary teacher for example, you should upload students KS2 scores as prior attainment.
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Student Details
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>
                  You can upload your students automatically via CSV or manually.
                </li>
                <li>
                  You should provide a DoB for all students as this is used to help verify the student accounts.
                </li>
                <li>
                  Make sure the set names match exactly as they did in the previous step
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
          <v-expansion-panel-header>
            Manage Users
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li>
                  You can add both teacher and student accounts here.
                </li>
                <li>
                  Teacher accounts: All teachers listed in the School Structure step can be given teacher accounts. 
                </li>
                  You can assign them a role of Admin, Leader, or Teacher 
                <li>
                  Admins have access to this menu, and have full privileges. Be careful allocating this role.
                </li>
                <li>
                  Leaders can assign assessment feedback tasks, and have access to whole year statistics. They are also able to view amPIL as any student or teacher in the school.
                </li>
                <li>
                  Teachers can only view data for their own teaching classes, apart from the whole-year markbook. They are also able to view amPIL as any student.
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
          <v-expansion-panel-header>
            Export QLA Data
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              You can export any part of amPIL's data here as CSV
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
//import SelectStudent from "@/components/student/SelectStudent";

export default {
  name: "AdminHome",
  data: () => ({}),
  components: {},
  mounted() {
    this.setScrollBar();
  },
  methods: {
    setScrollBar() {
      this.$store.commit("setScrollBar", {
        slug: "admin_home",
        data: [],
      });
    },
  },
  computed: {
    getScope() {
      return this.$store.getters.getScope;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
    getStudentID() {
      return this.$store.getters.getStudentID;
    },
  },
};
</script>
