<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
        :disabled="testID>-1"
          v-model="selectedAssessment"
          :items="Assessment"
          item-text="TestName"
          item-value="TestID"
          label="Select Assessment..."
          outlined
          @change="getAssessmentTopics(selectedAssessment)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="selectedSubject"
          :items="subjects"
          item-text="SubjectName"
          item-value="SubjectID"
          label="Select Subject..."
          outlined
          @change="getTree(selectedSubject)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="3"
        v-if="(selectedAssessment != null) & (selectedSubject != null)"
      >
        <v-btn color="lime" @click="clearCurrent()"> Clear current </v-btn>
      </v-col>
      <v-col
        cols="3"
        v-if="(selectedAssessment != null) & (selectedSubject != null)"
      >
        <v-btn color="lime" @click="clearAll()"> Clear All </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn v-if="canSubmit" color="lime" @click="Submit()"> Save </v-btn>
        <v-alert v-if="Submitted == true" dense text type="success">
          Data uploaded successfully!
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="(selectedAssessment != null) & (selectedSubject != null)">
      <v-col cols="6">
        <v-card height="800" class="overflow-auto">
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="assessmentTopic in assessmentTopics"
                :key="assessmentTopic.QuestionID"
                color="lime"
                @click="
                  selectAssessmentTopic(
                    assessmentTopic.QuestionID,
                    assessmentTopic.TestTopic
                  )
                "
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="assessmentTopic.TestTopic"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-for="(mapped, id) in mappedTopics[
                      assessmentTopic.QuestionID
                    ]"
                    :key="id"
                    >{{ topics[mapped] }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <div
                  v-if="
                    mappedTopics[assessmentTopic.QuestionID] &&
                    mappedTopics[assessmentTopic.QuestionID].length > 0
                  "
                >
                  <v-badge
                    color="red"
                    :content="mappedTopics[assessmentTopic.QuestionID].length"
                  >
                  </v-badge>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          v-if="selectedAssessmentTopic != null"
          height="400"
          class="overflow-auto"
        >
          <v-card-title> AI Suggestions:</v-card-title>
          <v-slider
            v-model="nlpsug"
            color="lime"
            label="Number of suggestions.."
            min="1"
            :max="nlpArray.length"
            thumb-label="always"
          ></v-slider>

          <v-card-text>
            <div v-for="n in nlpsug" :key="n">
              <div v-if="nlpArray[n - 1]">
                <v-checkbox
                  v-model="mappedTopics[selectedAssessmentTopic]"
                  :value="nlpArray[n - 1].id"
                  :label="n + ') ' + nlpArray[n - 1].name"
                ></v-checkbox>
              </div>

              <v-progress-linear
                v-if="nlpArray[n - 1]"
                v-model="nlpArray[n - 1].value"
                color="lime"
                striped
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}% Match</strong>
                </template>
              </v-progress-linear>
            </div>
          </v-card-text>
        </v-card>
        <v-card height="400" class="overflow-auto">
          <v-card-title>Manual Mappings:</v-card-title>
          <v-treeview
            v-if="selectedAssessmentTopic != null"
            color="lime"
            v-model="mappedTopics[selectedAssessmentTopic]"
            :items="tree"
            selectable
            selection-type="independent"
            @input="selectTopic()"
          ></v-treeview>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import natural from "natural";
import {stemmer} from 'stemmer'
import cosine from "calculate-cosine-similarity";
import keyword_extractor from "keyword-extractor";
export default {
  name: "MappingAssessment",
  components: {},
  data: () => ({
    tree: [],
    topics: {},
    selectedTopic2: [],
    selectedTopic: "",
    subjects: [],
    selectedSubject: null,
    selectedAssessment: null,
    selectedAssessmentTopic: null,
    Assessment: null,
    assessmentTopics: [],
    mappedTopics: [],
    canSubmit: false,
    submitArray: [],
    deleteArray: [],
    existingArray: [],
    nlpArray: [],
    nlpsug: 5,
    flatTree: [],
    nlpSoW: [],
    topicMappings: [],
    Submitted: false,
  }),
  mounted() {
    
    this.getSubjects();
    this.getAssessment();
  },
  props: {
    testID: Number,
  },
  methods: {
    getAssessment() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/20",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Assessment = response.data;
          if (this.testID>-1){
            this.selectedAssessment = this.testID
            this.getAssessmentTopics(this.selectedAssessment)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExistingMaps() {
      this.mappedTopics = [];
      this.existingArray = [];
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/19",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.existingArray = response.data;
          for (let i = 0; i < response.data.length; i++) {
            //this.selectedTopic2.push(response.data[i].TopicID)
            if (response.data[i].QuestionID) {
              this.mappedTopics[response.data[i].QuestionID] = [];
            }
          }
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].QuestionID) {
              if (this.topics[response.data[i].TopicID]) {
                this.mappedTopics[response.data[i].QuestionID].push(
                  response.data[i].TopicID
                );
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTree(sub) {
      //this.mappedTopics = []
      this.selectedAssessmentTopic = null;
      this.Submitted = false;
      this.canSubmit = false;
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/topics/1",
        params: {
          data: sub,
        },
        responseType: "json",
      })
        .then((response) => {
          this.tree = response.data;
          this.getTopics(sub);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubjects() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/topics/3",
        params: {
          data: 1,
        },
        responseType: "json",
      })
        .then((response) => {
          this.subjects = response.data;
          //this.getExistingMaps()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTopics(sub) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/topics/2",
        params: {
          data: sub,
        },
        responseType: "json",
      })
        .then((response) => {
          this.topics = response.data;
          this.getflatTree(sub);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getflatTree(sub) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/topics/4",
        params: {
          data: sub,
        },
        responseType: "json",
      })
        .then((response) => {
          this.flatTree = response.data;
          this.getExistingMaps();
          //console.log(this.flatTree)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAssessmentTopics(assess) {
      this.Submitted = false;
      this.selectedAssessmentTopic = null;
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/21",
        params: {
          TestID: assess,
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.assessmentTopics = response.data;
          } else {
            this.assessmentTopics = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectTopic() {
      this.canSubmit = true;
    },
    selectAssessmentTopic(key, topic) {
      this.Submitted = false;
      if (!this.mappedTopics[key]) {
        this.mappedTopics[key] = [];
      }

      this.selectedAssessmentTopic = key;
      this.nlpArray = this.checkString(topic);
      this.nlpsug = 5;
    },
    clearCurrent() {
      this.Submitted = false;
      this.mappedTopics[this.selectedAssessmentTopic] = [];
      this.canSubmit = true;
      //this.selectedAssessmentTopic = null
    },
    clearAll() {
      this.Submitted = false;
      this.mappedTopics = [];
      this.canSubmit = true;
      //this.selectedAssessmentTopic = null
    },
    Submit() {
      //cycle through existing mappings and find those that dont exist, add to delete array
      //cycle through mappings and add to mapping array
      //in mapping array key is sowid and values are topics
      this.deleteArray = [];
      this.submitArray = [];
      //Deal with existing data...
      for (let k = 0; k < this.existingArray.length; k++) {
        let c = 0;
        for (let i = 0; i < this.mappedTopics.length; i++) {
          if (this.mappedTopics[i]) {
            for (let j = 0; j < this.mappedTopics[i].length; j++) {
              if (
                this.existingArray[k].QuestionID == i &&
                this.existingArray[k].TopicID == this.mappedTopics[i][j]
              ) {
                //console.log('found')
                this.submitArray.push({
                  keyval: {NLPAssessID: this.existingArray[k].NLPAssessID || null},
                  values: {QuestionID: i, TopicID: this.mappedTopics[i][j]},
                });
                c++;
              }
            }
          }
        }
        if (c == 0) {
          this.deleteArray.push({NLPAssessID: this.existingArray[k].NLPAssessID});
        }
        //Add new
      }
      for (let i = 0; i < this.mappedTopics.length; i++) {
        if (this.mappedTopics[i]) {
          for (let j = 0; j < this.mappedTopics[i].length; j++) {
            let c = 0;
            for (let k = 0; k < this.existingArray.length; k++) {
              if ( 
                this.existingArray[k].QuestionID == i &&
                this.existingArray[k].TopicID == this.mappedTopics[i][j]
              ) {
                //console.log('found')
                c++;
              }
            }
            if (c == 0) {
              this.submitArray.push({
                keyval: {NLPAssessID: null},
                values: {QuestionID: i, TopicID: this.mappedTopics[i][j]},
              });
            }
          }
        }
      }
      this.removeEntries();
      //console.log(this.submitArray)
      //console.log(this.deleteArray)
    },
    removeEntries() {
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/1",
        data: {
          table: "NLPAssess",
          values: this.deleteArray
        },
        responseType: "json",
      })
        .then(() => {
          this.updateEntries();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateEntries() {
      this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/admin",
          data: {
            table: "NLPAssess",
            data: this.submitArray
          },
          responseType: "json",
        })
          .then((response) => {
            this.canSubmit = false;
          this.Submitted = true;
          //this.getExistingMaps()
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getSoWMap() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/18",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.nlpSoW = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    stem(input) {
      //  Extract the keywords
      const extraction_result = keyword_extractor.extract(input, {
        language: "english",
        remove_digits: true,
        return_changed_case: true,
        remove_duplicates: true,
      });
      for (let i = 0; i < extraction_result.length; i++) {
        extraction_result[i] = extraction_result[i].replace(/[^a-z0-9]/gi, "");
        extraction_result[i] = stemmer(extraction_result[i]);
        //extraction_result[i] = natural.PorterStemmer.stem(extraction_result[i]);
      }
      return extraction_result;
    },
    similarity(string1, string2) {
      return cosine(string1, string2);
    },
    checkString(string) {
      let check = this.stem(string);
      let topicarray = [];
      let lastKey = Object.keys(this.topics).pop();
      for (let i = 0; i < lastKey; i++) {
        topicarray.push({ id: i, data: this.stem(this.topics[i]) });
      }
      let output = [];
      for (let i = 0; i < topicarray.length; i++) {
        let ranking = this.similarity(topicarray[i].data, check);
        if (ranking > 0) {
          ranking = ranking.toFixed(2) * 100;
          output.push({
            id: i,
            name: this.topics[topicarray[i].id],
            value: ranking,
          });
        }
      }
      let sorted = output.sort((a, b) => (a.value < b.value ? 1 : -1));
      return sorted;
    },
  },
};
</script>
