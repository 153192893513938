<template>
  <v-tabs align-with-title show-arrows>
    <v-tab v-for="item in items" :key="item.ID" @click="select(item.ID)">{{
      item.Name
    }}</v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "ScrollBar",
  components: {},
  data: () => ({
    //Dont forget to change current test on dashboard to first in array
  }),
  props: {
    items: Array,
  },
  methods: {
    select(id) {
      switch (this.getScrollBar.slug) {
        case "assessment":
          this.$store.commit("setSelectedTest", id);
          break;
        case "admin_school":
          this.adminSchool(id);
          break;
        case "admin_students":
          this.adminStudents(id);
          break;
        case "admin_users":
          this.adminUsers(id);
          break;
        case "yeargroups":
          this.yeargroups(id);
          break;
        case "classes":
          this.classes(id);
          break;
        default:
          break;
      }
    },
    adminSchool(id) {
      switch (id) {
        case 1:
          this.$router.push("subjects").catch(() => {});
          break;
        case 2:
          this.$router.push("sow").catch(() => {});
          break;
        case 3:
          this.$router.push("assessments").catch(() => {});
          break;
        case 4:
          this.$router.push("classes").catch(() => {});
          break;
        case 5:
          this.$router.push("mappings").catch(() => {});
          break;
        case 6:
          this.$router.push("terminal").catch(() => {});
          break;
        case 7:
          this.$router.push("teachers").catch(() => {});
          break;
        default:
          break;
      }
    },
    adminStudents(id) {
      switch (id) {
        case 1:
          this.$router.push("add_students").catch(() => {});
          break;
        case 2:
          this.$router.push("upload_students").catch(() => {});
          break;
        default:
          break;
      }
    },
    adminUsers(id) {
      switch (id) {
        case 1:
          this.$router.push("users").catch(() => {});
          break;
        case 2:
          this.$router.push("users_students").catch(() => {});
          break;
        default:
          break;
      }
    },
    yeargroups(id) {
      this.$store.commit("setYeargroup", id);
    },
    classes(id){
      this.$store.commit("setClass", id);
    }
  },
  computed: {
    getScrollBar() {
      return this.$store.getters.getScrollBar;
    },
  },
};
</script>
