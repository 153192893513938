<template>
    <v-container v-if="loaded">
        <ampil-card :title="task.TestName"></ampil-card>
        <v-card-text>
                <v-alert
                text
                dense
                color="teal"
                icon="mdi-list-status"
     
                    >Feedback task completion status:
                    <ul>
                        <li>
                            {{  getStatus.text }}
                        </li>
                    </ul>
                    </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-dialog v-model="uploadDialog" width="500" persistent>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="status<2" color="lime" text v-bind="attrs" v-on="on">Upload Totals </v-btn>
                    </template>
                    <v-card>
                        <ampil-card :title="task.TestName"></ampil-card>
                        <v-card-text>
                        <upload-totals-manual
                            :testName="task.TestName"
                            :test="task.TestID"
                            :total="60"
                            :givenSet="task.SetID"
                        ></upload-totals-manual>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="lime" text @click="saveUpload()">Close</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <feedback-rank-check @submitted="status=3" v-if="status==2" :task="task"></feedback-rank-check>
                <feedback-final-check  v-if="status==4" :task="task"></feedback-final-check>
              </v-card-actions>
              <v-card-actions>
                  
                  <v-btn color="lime" text @click="loadScripts()">Add task to GC</v-btn>
                  <div id="widget-div"></div>
                <v-spacer></v-spacer>
                <submission-tracker :setID="task.SetID" :testID="task.TestID"></submission-tracker>
              </v-card-actions>
    </v-container>
</template>

<script>
            //status 0 totals not uploaded
            
            //status 1 totals uploaded
            //status 2 initial ranking complete
            //status 3 teacher checked
            //status 4 grades allocated
            //status 5 teacher checked
            //status 6 complete
import UploadTotalsManual from "@/components/teacher/UploadTotalsManual.vue";
import SubmissionTracker from "@/components/teacher/SubmissionTracker.vue";
import FeedbackRankCheck from "@/components/teacher/FeedbackRankCheck.vue";
import FeedbackFinalCheck from "@/components/teacher/FeedbackFinalCheck.vue";
window.___gcfg = {
        parsetags: 'explicit'
      };
export default {
  name: "Feedback",
  props: {
    task: Object,

  },
  components: {
    SubmissionTracker,
    UploadTotalsManual,
    FeedbackRankCheck,
    FeedbackFinalCheck,
  },
  created(){
    
  },

  mounted(){
    this.status = this.task.Status
      this.getTotals()
  },
  data() {
    return {
        uploadDialog: false,
        totals: [],
        status: null,
        loaded: false,
    };
  },
  methods: {
    loadScripts(){
      this.$loadScript('https://apis.google.com/js/platform.js')
      .then(() => {
        gapi.sharetoclassroom.render("widget-div",
            {size:32, url: "https://www.ampil.co.uk/student", body: "Click the hyperlink to log your feedback for this assessment. If the link does not work, you can log into amPIL at https://www.ampil.co.uk/student with the password given to you by your teacher.", itemtype: "assignment", title:"Submit assessment feedback "+this.task.TestName} );
      });
    },
    checkTotals(){
        if (this.status<2){
            let tot = this.totals.filter(a=> a.TTotal!=null)
        if (tot.length>0){
            this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/teacher/"+this.task.FeedbackMapID,
        data: {
          table: "FeedbackMaps",
          values: {Status: 1}
        },
        responseType: "json",
      })
        .then((response) => {
            this.status=1
        })
        .catch((error) => {
          console.log(error);
        });
        }
        }
        
    },
    saveUpload(){
        this.uploadDialog=false
        this.getTotals()
    },
    getTotals(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/24",
        params: {
          SetID: this.task.SetID,
          TestID: this.task.TestID,
        },
        responseType: "json",
      })
        .then((response) => {
          this.totals = response.data;
          this.loaded=true
          this.checkTotals()
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    
    getStatus(){
            let st = this.status
            let retval = {}
            if (st==0){
                retval.text = "Step 1) Awaiting Assessment total scores. Click the button below to submit these for your class"
            }
            else if (st==1){
                retval.text = "Step 2) Awaiting subject leader performing initial ranking..."
            }
            else if (st==2){
                if (this.task.TeacherInput==1){
                    retval.text = "Step 3) Initial Ranking complete. Click the button to check your class data"
                }
                else{
                    retval.text = "Step 3) Initial Ranking complete. Awaiting mapping of grades..."
                }
                
            }
            else if (st==3){
                retval.text = "Step 4) Awaiting all staff to check initial ranking, and department lead to assign grades..."
            }
            else if (st==4){
                if (this.task.TeacherInput==1){
                    retval.text = "Step 5) Grades allocated. Click the button to check your class data"
                }
                else{
                    retval.text = "Step 5) Grades allocated. Awaiting final checks..."
                }
            }
            else if (st==5){
                retval.text = "Step 6) Grades allocated and checked by teaching staff. Awaiting final checks..."
            }
            else if (st==6){
                retval.text = "Feedback Task complete! You may now delete this task"
            }
            //status 0 totals not uploaded
            
            //status 1 totals uploaded
            //status 2 initial ranking complete
            //status 3 teacher checked
            //status 4 grades allocated
            //status 5 teacher checked
            //status 6 complete
            retval.status = st
            return  retval
        }
  },
};
</script>
