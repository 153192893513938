<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>Select Group</v-card-title>
          <v-card-text>
            <v-treeview
              @update:active="selectSet"
              activatable
              :items="Tree"
            ></v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title> Manual Student Upload </v-card-title>
          <v-card-text>
            <p>
              Manually edit student data. Click on a field to edit the value.
              Remember the student ID MUST be unique
            </p>
            <v-alert v-if="error.type == 1" dense outlined type="error">
              {{ error.text }}
            </v-alert>
            <v-alert v-if="error.type == 2" dense outlined type="success">
              {{ error.text }}
            </v-alert>
          </v-card-text>
          <v-card-actions v-if="canSubmit && showStudents">
            <v-dialog v-model="newStudentToggle" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="lime" dark v-bind="attrs" v-on="on">
                  Add Student
                </v-btn>
              </template>

              <v-card>
                <v-card-title> Add new student to this group </v-card-title>

                <v-card-text>
                  (Student will not be added unless you click 'Submit Changes')
                  <v-text-field
                    v-model="newStudent.StudentID"
                    counter="20"
                    label="ID"
                    :rules="selectRules"
                    color="lime"
                    @keyup="checkID2(newStudent.StudentID)"
                  >
                  </v-text-field>
                  <v-text-field
                    color="lime"
                    v-model="newStudent.LastFirst"
                    counter="50"
                    :rules="selectRules"
                    label="Name"
                  >
                  </v-text-field>
                  <p>Date of Birth</p>
                  <v-date-picker
                    v-model="newStudent.DoB"
                    :rules="selectRules"
                    color="lime"
                  ></v-date-picker>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-if="canSubmit2">
                  <v-btn color="lime" text @click="newStudentToggle = false">
                    Cancel
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="lime"
                    :disabled="
                      newStudent.LastFirst.length == 0 ||
                      newStudent.DoB.length == 0 ||
                      newStudent.StudentID.length == 0
                    "
                    text
                    @click="addSingleStudent()"
                  >
                    Add
                  </v-btn>
                </v-card-actions>
                <v-alert v-else dense outlined type="error">
                  Student ID must be unique
                </v-alert>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-btn color="lime" dark @click="submitData()">
              Submit Changes
            </v-btn>
          </v-card-actions>
          <div v-else>
            <v-alert v-if="!canSubmit" dense outlined type="error">
              Student ID must be Unique
            </v-alert>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="showStudents">
          <v-data-table
            :headers="headers"
            :items="Students"
            item-key="UniqueID"
            :items-per-page="15"
            class="elevation-1"
          >
            <template v-slot:item.LastFirst="props">
              <v-edit-dialog :return-value.sync="props.item.LastFirst" large>
                {{ props.item.LastFirst }}
                <template v-slot:input>
                  <v-text-field
                    color="lime"
                    v-model="props.item.LastFirst"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.StudentID="props2">
              <v-edit-dialog
                :return-value.sync="props2.item.StudentID"
                large
                @open="setcurrentID(props2.item.StudentID)"
                @save="checkid(props2.item.StudentID)"
              >
                {{ props2.item.StudentID }}
                <template v-slot:input>
                  <v-text-field
                    color="lime"
                    v-model="props2.item.StudentID"
                    label="Edit"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.DoB="props3">
              <v-edit-dialog :return-value.sync="props3.item.DoB" large>
                {{ dateFormat(props3.item.DoB) }}
                <template v-slot:input>
                  <v-date-picker
                    v-model="props3.item.DoB"
                    color="lime"
                  ></v-date-picker>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.SetID="props4">
              <v-edit-dialog
                :return-value.sync="props4.item.SetID"
                large
                color="lime"
              >
                {{ findSet(Sets, props4.item.SetID).SetName }}
                <template v-slot:input>
                  <v-select
                    color="lime"
                    v-model="props4.item.SetID"
                    :items="Sets"
                    item-value="SetId"
                    item-text="SetName"
                    label="Select Set"
                  ></v-select>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small color="red" @click="deleteStudent(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </div>
        <div v-else>
          <p>Select a set to continue...</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AdminAddStudents",
  components: {},
  data: () => ({
    Yeargroups: [],
    Sets: [],
    Tree: [],
    offRollSet: null,
    error: {},
    Students: [],
    allStudents: [],
    showStudents: false,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "StudentID",
      },
      { text: "Name", value: "LastFirst" },
      { text: "Date of Birth", value: "DoB" },
      { text: "Move Set", value: "SetID" },
      { text: "Remove", value: "actions", sortable: false },
    ],
    selected: [],
    currentID: null,
    canSubmit: true,
    canSubmit2: true,
    deleteStudents: [],
    newStudentToggle: false,
    newStudent: {
      StudentID: "",
      DoB: "",
      LastFirst: "",
    },
    setID: null,
    selectRules: [(v) => v.length > 0 || "Required"],
  }),
  mounted() {
    this.setScrollBar();
    this.getOffroll();
    this.getAllStudents();
  },
  props: {},
  computed: {},
  methods: {
    setScrollBar() {
      this.$store.commit("setScrollBar", {
        slug: "admin_students",
        data: [
          { ID: 1, Name: "Manually Allocate Students" },
          { ID: 2, Name: "Bulk Upload" },
        ],
      });
    },
    getYears() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/8",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Yeargroups = response.data;
          this.getSets();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSets() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/16",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response2) => {
          this.Sets = response2.data;
          this.createTree();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createTree() {
      for (let i = 0; i < this.Yeargroups.length; i++) {
        let s = [];
        for (let j = 0; j < this.Sets.length; j++) {
          if (this.Sets[j].YeargroupID == this.Yeargroups[i].YeargroupID) {
            s.push({
              id: "s" + this.Sets[j].SetId,
              name: this.Sets[j].SetName,
            });
          }
        }
        this.Tree.push({
          id: "y" + this.Yeargroups[i].YeargroupID,
          name: "Year " + this.Yeargroups[i].Yeargroup,
          locked: true,
          children: s,
        });
      }
      this.Tree.push({ id: "o" + this.offRollSet[0].SetId, name: "Off-Roll" });
    },
    getOffroll() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/14",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.offRollSet = response.data;
          this.getYears();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectSet(value) {
      let idfull = value[0];
      let id = parseInt(idfull.slice(1));
      this.setID = id;
      let type = idfull.slice(0, 1);
      if (type == "y") {
        this.error = { text: "Select a group", type: 1 };
        this.showStudents = false;
      }
      if (type == "o") {
        this.error = { text: "Off Roll selected", type: 2 };
        this.getNames(this.offRollSet[0].SetId);
        this.showStudents = true;
      }
      if (type == "s") {
        let setDetails = this.findSet(this.Sets, id);
        let yearDetails = this.findYear(
          this.Yeargroups,
          setDetails.YeargroupID
        );
        this.error = {
          text: "Year " + yearDetails.Yeargroup + ": " + setDetails.SetName,
          type: 2,
        };
        this.deleteStudents = [];
        this.getNames(id);
        this.showStudents = true;
      }
    },
    findSet(s, v) {
      const result = s.find(({ SetId }) => SetId === v);
      return result;
    },
    findYear(s, v) {
      const result = s.find(({ YeargroupID }) => YeargroupID === v);
      return result;
    },
    getNames(id) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/17",
        params: {
          SetId: id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Students = response.data;
          this.canSubmit = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllStudents() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/13",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.allStudents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkid(v) {
      //check not current
      if (v != this.currentID) {
        let j = 0;
        //in class array
        for (let i = 0; i < this.Students.length; i++) {
          if (this.Students[i].StudentID.toUpperCase() == v.toUpperCase()) {
            j++;
          }
        }
        //check not in all
        for (let i = 0; i < this.allStudents.length; i++) {
          if (this.allStudents[i].StudentID.toUpperCase() == v.toUpperCase()) {
            j++;
          }
        }
        if (j > 1) {
          console.log("found");
          this.canSubmit = false;
        } else {
          console.log("not found");
          this.canSubmit = true;
        }
      }
    },
    checkID2(id) {
      let j = 0;
      for (let i = 0; i < this.allStudents.length; i++) {
        if (this.allStudents[i].StudentID.toUpperCase() == id.toUpperCase()) {
          j++;
        }
      }
      if (j > 0) {
        this.canSubmit2 = false;
      } else {
        this.canSubmit2 = true;
      }
    },
    setcurrentID(v) {
      this.currentID = v;
    },
    deleteStudent(s) {
      for (let i = 0; i < this.Students.length; i++) {
        if (this.Students[i].UniqueID == s.UniqueID) {
          this.Students.splice(i, 1);
          if (s.UniqueID != null) {
            this.deleteStudents.push({ UniqueID: s.UniqueID });
          }
        }
      }
    },
    dateFormat(d) {
      var parts = d.split("-");
      var year = parseInt(parts[0], 10);
      var month = "" + parseInt(parts[1], 10);
      var day = "" + parseInt(parts[2], 10);
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return day + "/" + month + "/" + year;
    },
    submitData() {
      //Add student box
      //Create submit array
      //Delete students
      //check ID against all
      let submitAll = [];
      for (let i = 0; i < this.Students.length; i++) {
        submitAll.push({
          keyval: { UniqueID: this.Students[i].UniqueID  || null},
          values: {
            LastFirst: this.Students[i].LastFirst,
            StudentID: this.Students[i].StudentID,
            DoB: this.Students[i].DoB,
            SetID: this.Students[i].SetID,
            SubjectID: this.$store.getters.getSubject,
          },
        });
      }
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "StudentNames",
          data: submitAll,
        },
        responseType: "json",
      })
        .then(() => {
          if (this.deleteStudents.length > 0) {
            this.$http({
              method: "delete",
              url: process.env.VUE_APP_BACKEND + "/admin/" + null,
              data: {
                table: "StudentNames",
                values: this.deleteStudents,
              },
              responseType: "json",
            })
              .then(() => {
                this.error.text = this.error.text + " Upload Successful!";
                this.getAllStudents();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.error.text = this.error.text + " Upload Successful!";
            this.getAllStudents();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addSingleStudent() {
      this.newStudentToggle = false;
      this.newStudent.SetID = this.setID;
      this.newStudent.UniqueID = "";
      for (let i = 0; i < this.Sets.length; i++) {
        if (this.Sets[i].SetId == this.setID) {
          this.newStudent.SetName = this.Sets[i].SetName;
        }
      }
      //SetID SetName UniqueID

      this.Students.push(this.newStudent);
      this.newStudent = {
        StudentID: "",
        DoB: "",
        LastFirst: "",
      };
    },
  },
};
</script>
