<template>

      <v-dialog
        v-model="dialog"

      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="typeChange().color"
            :dark="typeChange().dark"
            :outlined="typeChange().outlined"
            :text="typeChange().text"
            v-bind="attrs"
            v-on="on"
          >
            {{ buttonText }}
          </v-btn>
        </template>
  
        <v-card>
          <v-card-title class="text-h6 lime lighten-2">
            {{ title }}
          </v-card-title>
  
          <v-card-text v-if="text">
            {{text}}
          </v-card-text>

          <v-data-table
          v-if="dataTable"
          :headers="dataTable.headers"
          :items="dataTable.values"
          class="elevation-1"
          multi-sort
          :sort-by="sortBy"
          >

          </v-data-table>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="lime"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </template>

<script>
export default {
  name: "DialogModal",
  props: {
    text: String,
    buttonText: String,
    title: String,
    type: String,
    dataTable: Object,
    sortBy: Array,
  },
  mounted() {},
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    typeChange(){
      let retval = {}
      switch(this.type){
        case "error":
          retval = {outlined: true, text: true, color: "red"}
          break;
        case "outline":
          retval = {outlined: true, text: true, color: "lime"}
          break;
        default:
          retval = {dark: true, color: "lime"}
          break;
      }
      return retval
    }
  },
  computed: {},
};
</script>