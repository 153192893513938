<template>
  <v-card
    class="mx-auto my-1"
    max-width="374"
    :elevation="id == this.$store.state.Subject && this.CanSelect ? 24 : 0"
    :dark="id == this.$store.state.Subject && this.CanSelect ? true : false"
  >
    <v-img height="250" :src="Img"></v-img>
    <v-card-title
      >{{ Name }}
      <template v-if="CanSelect">
        <template v-if="id == this.$store.state.Subject">
          (Currently Selected)
        </template>
      </template>
    </v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-actions>
      <v-btn color="lime" text @click="$emit('edit')"> Edit </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="lime" text v-if="CanSelect" @click="Selected()">
        Select
      </v-btn>
      <v-spacer></v-spacer>
      <confirm-action
        btntext="Delete"
        Title="Confirm Delete?"
        Message="Warning: This will delete all stored data for this item. Are you sure you wish to continue?"
        @confirm="$emit('delete')"
      ></confirm-action>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConfirmAction from "@/components/admin/ConfirmAction";
export default {
  name: "CardView",
  components: {
    ConfirmAction,
  },
  props: {
    id: Number,
    Name: String,
    Img: String,
    CanSelect: Boolean,
  },
  methods: {
    Selected() {
      this.$store.commit("setSubject", this.id);
      //this.getSum();
    },
  },
};
</script>
