<template>
    <v-container>
        <v-skeleton-loader
      class="mx-auto"
      type="table"
      v-if="ready==false"
    ></v-skeleton-loader>
        <v-data-table
            v-if="ready"
            :headers="headers"
            :items="markbook.data"
            :items-per-page="-1"
            class="elevation-1"
        >
        <template
        v-slot:item.Grade="{ item, index }"
      >
      <v-chip
        :color="getColor(item.Grade)"
        dark
      >
        {{ item.Grade }}
        <div v-if="(index+1)<markbook.data.length && item.Grade!=markbook.data[index+1].Grade">
            <v-btn color="red" text @click="changeGrade(index,markbook.data[index+1].Grade)">
                <v-chip color="red" small><v-icon>mdi-arrow-down-bold-circle</v-icon></v-chip>
            </v-btn>
        </div>
        <div v-if="index!=0 && item.Grade!=markbook.data[index-1].Grade">
            <v-btn color="lime" text @click="changeGrade(index,markbook.data[index-1].Grade)">
                <v-chip color="lime" small><v-icon>mdi-arrow-up-bold-circle</v-icon></v-chip>
            </v-btn>
        </div>
      </v-chip>
      </template>
      <template
        v-slot:item.Score="{ item }"
      >
      {{ parseInt(item.Score) }}
      </template>
    </v-data-table>
    <v-card-actions>
        <v-alert v-if="submitMsg != ''" dense outlined type="success">
          {{ submitMsg }}
        </v-alert>
        <v-spacer></v-spacer>
        <v-btn color="lime" @click="saveGrades()">Save</v-btn>
    </v-card-actions>
    </v-container>
</template>

<script>
import Markbook from '@/mixins/Markbook'
export default {
  name: "FeedbackGradesMap",
  mixins: [Markbook],
  props: {
    gp: Array,
    yeargroupID: Number,
    taskID: Number,
  },
  mounted() {
    this.getRanking()
    this.checkPAD()
    this.getAvailableTests()
    this.getAllData()
    this.getStudents()

  },
  data() {
    return {
        PAD: {},
        allPAD: [],
        availableTests: [],
        allData: [],
        allStudents: [],
        percentages: [],
        ranking: [],
        markbook: [],
        ready: false,
        submitMsg: '',
        headers: [{text: "Student ID", value: "StudentID"}, {text: "Name", value: "LastFirst"}, {text: "Set", value: "SetName"}, {text: "Grade", value: "Grade"}, {text: "Ranking", value: "Ranking"}, {text: "Score", value: "Score"}]
    };
  },
  methods: {
    saveGrades(){
        let submit = []
        for (let i=0;i<this.markbook.data.length;i++){
            submit.push({keyval: {RankID: this.markbook.data[i].RankID || null}, values: {Grade: this.markbook.data[i].Grade, TFlag: 0}})
        }
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/leader",
        data: {
          table: "ranking",
          data: submit
        },
        responseType: "json",
      })
        .then((response) => {
            //this.submitMsg="Grades submitted successfully"
            this.updateTasks()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateTasks(){
        this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/leader/"+this.taskID,
        data: {
          table: "FeedbackMaps",
          key: "TaskID",
          values: {Status: 4}
        },
        responseType: "json",
      })
        .then((response) => {
            this.submitMsg="Grades submitted successfully"
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeGrade(i,v){
        this.markbook.data[i].Grade = v
    },
    getColor(i){
        let color = this.percentages.find(a=> a.Grade==i)
        if (color!=undefined){
            return color.Color
        }
        else{
            return ""
        }
    },
    randomColor(){
        return "#"+Math.floor(Math.random()*16777215).toString(16);
    },
    assignGrades(){
        //get number of students for each yeargroup
        let tot = this.allStudents.length
        let cnt = 0
        for (let i=0;i<(this.gp.length-1);i++){
            let cnt2 = Math.ceil((this.gp[i].Perc/100*tot))
            cnt = cnt + cnt2
            this.percentages.push({Grade: this.gp[i].Label, Count: cnt, Color: this.randomColor()})
            
        }
        //let remaining = tot-cnt
        this.percentages.push({Grade: this.gp[(this.gp.length-1)].Label, Count: tot, Color: this.randomColor()})
        //assign grades to students
        let j=0
        for (let i=0;i<this.ranking.length;i++){
            
            if (i>=this.percentages[j].Count){
                j++
            }
            this.ranking[i].Grade = this.percentages[j].Grade
        }
        let tests = this.ranking[0].Tests
        tests = JSON.parse(tests)
        this.markbook = this.markbookSort(this.allStudents, tests, this.allData, this.ranking, this.allPAD)
        for (let i=0;i<this.markbook.tests.length;i++){
            this.headers.push({text: this.markbook.tests[i].TestName, value: this.markbook.tests[i].TestID.toString()})
        }
        this.markbook.data.sort((a, b) => b.Score - a.Score);
        this.ready = true
    },
    checkPAD(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/4",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.PAD = response.data
            this.getAllPAD()
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAvailableTests(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/11",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.availableTests = response.data
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllData(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/12",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allData = response.data
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStudents(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/14",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allStudents = response.data
            this.assignGrades()
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRanking(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/16",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.ranking = response.data
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllPAD(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/13",
        params: {
          YeargroupID: this.yeargroupID,
          EndpointID: this.PAD.EndID
        },
        responseType: "json",
      })
        .then((response) => {
            this.allPAD = response.data
            for (let i=0;i<this.allPAD.length;i++){
                //if not a number
                if (isNaN(this.allPAD[i].EndScore) || this.allPAD[i].EndScore==""){
                    if (this.allPAD[i].EndScore.toUpperCase()=="A*"){
                        this.allPAD[i].Std = 56
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="A"){
                        this.allPAD[i].Std = 48
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="B"){
                        this.allPAD[i].Std = 40
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="C"){
                        this.allPAD[i].Std = 32
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="D"){
                        this.allPAD[i].Std = 24
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="E"){
                        this.allPAD[i].Std = 16
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="F"){
                        this.allPAD[i].Std = 8
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="U"){
                        this.allPAD[i].Std = 0
                    }
                    else{
                        this.allPAD[i].Std = 0
                    }
                }
                else{
                    this.allPAD[i].Std = parseInt(this.allPAD[i].EndScore)
                }
            }

          
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  watch: {
    gp: function () {
        this.percentages = []
        this.assignGrades()
    },
  },
};
</script>
