<template>
    <v-container v-if="loaded">
      <v-btn color="black" width="100%" dark @click="$router.push('/teacher')">Back to Dashboard</v-btn>
        <markbook-component :yeargroupID=yeargroupID></markbook-component>
    </v-container>
</template>

<script>
import MarkbookComponent from "@/components/teacher/Markbook";
export default {
  name: "Markbook",
  components: {
    MarkbookComponent
  },
  props: {
    yearID: Number,
  },
  mounted() {
    this.getYear()
  },
  data() {
    return {
      yeargroupID: null,
      loaded: false
    };
  },
  methods: {
    getYear(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/29",
        params: {
          SetId: this.getClass,
        },
        responseType: "json",
      })
        .then((response) => {
          this.yeargroupID = response.data[0].YeargroupID
          this.loaded=true
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {
    getClass(){
      return this.$store.getters.getClass
    },
  },
  watch: {
    getClass: function () {
        this.getYear()
    },
  }
};
</script>
