<template>
    <div>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  //import SelectStudent from "@/components/student/SelectStudent";
  
  export default {
    name: "AdminIndex",
    data: () => ({}),
    components: {},
    mounted() {},
    methods: {},
    computed: {
      getScope() {
        return this.$store.getters.getScope;
      },
      getStudent() {
        return this.$store.getters.getStudentDetails;
      },
      getStudentID() {
        return this.$store.getters.getStudentID;
      },
    },
  };
  </script>