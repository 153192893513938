<template>
  <v-app>
    <v-app-bar
      app
      color="#50900c"
      dark
      clipped-right
      v-if="$store.getters.isAuthenticated"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="amPIL Logo"
          class="shrink mr-2"
          contain
          src="/images/logo2.png"
          transition="scale-transition"
          width="40"
        />
        <v-toolbar-title
          class="pink--text text--lighten-5 font-weight-bold d-none d-sm-flex"
          >amPIL Tracker: {{ school }}</v-toolbar-title
        >
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title class="pink--text text--lighten-5 font-weight-bold"
        >{{ name
        }}<v-btn
          class="pink--text text--lighten-5 font-weight-bold"
          text
          @click="logout()"
          >Logout</v-btn
        ></v-toolbar-title
      >
      <change-scope></change-scope>
      <template v-slot:extension>
        <scroll-bar :items="$store.getters.getScrollBar.data"></scroll-bar>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <main-menu></main-menu>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
    <v-footer padless app color="#50900c" dark>

    <div class="text-center pink--text text--lighten-5 text-caption">
      
      <div><a href="mailto:admin@ampil.co.uk" class="text-center pink--text text--lighten-5 text-caption" target="_blank"><v-icon color="pink lighten-5">mdi-email</v-icon> admin@ampil.co.uk</a><a href="https://www.facebook.com/ampiltracker/" class="text-center pink--text text--lighten-5 text-caption" target="_blank"><v-icon color="pink lighten-5">mdi-facebook</v-icon> Facebook</a><a href="https://twitter.com/AmpilRag" class="text-center pink--text text--lighten-5 text-caption" target="_blank"><v-icon color="pink lighten-5">mdi-twitter</v-icon> Twitter</a></div>
    </div>
    <v-spacer></v-spacer>
    <div class="text-center pink--text text--lighten-5 text-caption">
      {{ new Date().getFullYear() }} - amPIL Tracker, by Dan Wilson
    </div>
    <v-spacer></v-spacer>
    <div class="text-center pink--text text--lighten-5 text-caption">
      <privacy></privacy>
    </div>
  </v-footer>
  <footer>
    <cookie-law theme="dark-lime" :buttonDecline=true storageType="cookies" :cookieOptions="{expires: 30}" buttonDeclineClass="Cookie__button" buttonText="Accept" buttonDeclineText="Decline non-essential">
      <div slot="message">
        This website uses cookies to improve the user experience <privacy></privacy>
      </div>
    </cookie-law>
  </footer>
  <error-handler></error-handler>
  </v-app>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import ChangeScope from "@/components/ChangeScope";
import ScrollBar from "@/components/ScrollBar";
import Privacy from "@/components/Privacy";
import ErrorHandler from "@/components/ErrorHandler";
import CookieLaw from 'vue-cookie-law';
export default {
  name: "App",
  components: {
    MainMenu,
    ChangeScope,
    ScrollBar,
    Privacy,
    CookieLaw,
    ErrorHandler,
  },
  data: () => ({
    //
    Tests: [{ ID: 1, Name: "dddd" }],
    drawer: false,
    cookieOpt: {
      //domain: "ampil.co.uk",
      expires: new Date,
    }
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    scope() {
      return this.$store.getters.getScope;
    },
    name() {
      return this.$store.getters.getName;
    },
    school() {
      return this.$store.getters.getSchool;
    },
  },
  methods: {
    navigate(route) {
      this.$router.push(route).catch((err) => {});
    },
    async logout() {
      let self = this;
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/logout",
        responseType: "json",
      })
        .then(function (response) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          console.log("Logged out: " + response.data.name);
          self.$store.dispatch("logout");
          self.$cookies.set("logged_in", false);
          self.$router.push("login");
        })
        .catch(function (error) {
          self.$router.push("login");
        });
    },
  },
};
</script>

<style>

a {
  text-decoration: none;
}

</style>
