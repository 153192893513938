<template>
    <v-container>
        <v-dialog v-if="loaded" v-model="dialog" persistent>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="lime" text v-bind="attrs" v-on="on">Check allocated grades </v-btn>
                    </template>
                    <v-card>
                        <ampil-card :title="task.TaskName"></ampil-card>
                        <v-card-text>
                            Check the grades that your department lead has allocated. If you do not agree with any of them, they can be flagged and you can leave an optional comment.
                            <v-data-table
                                :headers="headers"
                                :items="markbook.data"
                                :items-per-page="15"
                            >
                            <template v-slot:item.Tflag="{ item }">
                                    <v-checkbox
                                        color="red darken-3"
                                        v-model="item.Tflag"
                                    ></v-checkbox>
                            </template>
                            <template v-slot:item.Comment="{ item }">
                                <v-text-field v-model="item.Comment" :disabled="item.Tflag!=1"
                                ></v-text-field>
                            </template>
                            </v-data-table>
                        </v-card-text>
                    <v-card-actions>
                        <v-btn color="lime" text @click="dialog=false">Close</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="lime" dark @click="save()" >Save</v-btn>
                    </v-card-actions>

                    
                    </v-card>
                </v-dialog>
    </v-container>
</template>

<script>
import Markbook from '@/mixins/Markbook'
export default {
  name: "FeedbackFinalCheck",
  mixins: [Markbook],
  props: {
    task: Object,
  },
  mounted() {
    this.getAllData()
  },
  data() {
    return {
        dialog: false,
        loaded: false,
        allRanking: [],
        allStudents: [],
        allData: [],
        markbook: [],
        headers: [
            {text: "ID", value: "StudentID"},
            {text: "Name", value: "LastFirst"},
            {text: "Set", value: "SetName"},
            {text: "Ranking", value: "Ranking"},
            {text: "Score", value: "Score"},
            {text: "Grade", value: "Grade"},
            {text: "Flag result", value: "Tflag"},
            {text: "Teacher Comment", value: "Comment"}
        ],
    };
  },
  methods: {
    save(){
        let submit = []
        for (let i=0;i<this.markbook.data.length;i++){
            submit.push({keyval: {RankID: this.markbook.data[i].RankID || null}, values: {UniqueID: this.markbook.data[i].UniqueID, YearID: this.markbook.data[i].YearID, SetID: this.markbook.data[i].SetID, Ranking: this.markbook.data[i].Ranking, Score: this.markbook.data[i].Score, Grade: this.markbook.data[i].Grade, Tflag: this.markbook.data[i].Tflag, Comment: this.markbook.data[i].Comment}})
        }
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/teacher",
        data: {
          table: "ranking",
          data: submit
        },
        responseType: "json",
      })
        .then((response) => {
            //this.submitMsg="Grades submitted successfully"
            this.save2()
            
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save2(){
        this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/teacher/"+this.task.FeedbackMapID,
        data: {
          table: "FeedbackMaps",
          key: "FeedbackMapID",
          values: {Status: 5}
        },
        responseType: "json",
      })
        .then((response) => {
          this.dialog=false
          this.$emit('submitted')
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllData(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/25",
        params: {
          SetID: this.task.SetID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allData = response.data
            this.getStudents()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStudents(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/26",
        params: {
            SetID: this.task.SetID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allStudents = response.data
            this.getRanking()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRanking(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/27",
        params: {
            SetID: this.task.SetID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allRanking = response.data
            this.markbook = this.markbookSort(this.allStudents, [this.task.TestID], this.allData, this.allRanking)
            this.markbook.data.sort((a, b) => a.Ranking - b.Ranking)
            this.yearRank = this.markbook.data.map(a=> a.Ranking)
            
            for (let i=0;i<this.markbook.data.length;i++){
                //this.markbook.data[i].YearRank = this.markbook.data[i].Ranking
                //this.markbook.data[i].Ranking = (i+1)
            }
            this.loaded = true
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
};
</script>
