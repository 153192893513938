<template>
    <v-container>
        <v-form v-if="tasksLoaded" v-model="submittable">
                <v-text-field
                    v-model="tasks[0].TaskName"
                    label="Task Name"
                    outlined
                    :rules="rules.name"
                    required
                ></v-text-field>
                <v-dialog v-model="datePicker" width="400">
               
                <template v-slot:activator="{ on, attrs }">
                    
                    <v-btn color="lime" text @click="datePicker = !datePicker">
                        Task Expiry: {{ tasks[0].Expiry }}
                    </v-btn>
                </template>
                <v-date-picker
                    v-model="tasks[0].Expiry"
                    @change="datePicker = !datePicker"
                    color="lime"
                    :min="dateRange().min"
                    :max="dateRange().max"
                >
            </v-date-picker>

            </v-dialog>
            <v-switch
                v-model="toggle"
                color="lime"
                label="Require teacher responses for rankings"
            ></v-switch>
            <div v-for="(set, index) in sets">
                <v-row>
                    <v-col>
                        {{ set.SetName }}
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="selectedTest[set.SetId]"
                            :items="selectTest(set.SetId)"
                            item-text="TestName"
                            item-value="TestID"
                            label="Select Assessment"
                        ></v-select>
                    </v-col>
                </v-row>
            </div>
            <v-btn v-if="submittable" color="lime" dark @click="submit()">
                Continue
            </v-btn>
            </v-form>
    </v-container>
  </template>
  
  <script>

  export default {
    name: "FeedbackSelect",
    props: {
        taskID: Number,
        yeargroupID: Number,
        tasks: Array,
    },
    data: () => ({
        dialog: false,
        datePicker: false,
        submittable: true,
        rules: {
          name: [val => (val || '').length > 0 || 'This field is required'],
          date: [val => (val || '').length > 0 || 'This field is required'],
        },
        sets: [],
        tests: [],
        selectedTest: [],
        tasksLoaded: false,
        toggle: false,
        insertID: null,
    }),
    watch: {

    },
    mounted() {

        this.getTests()
        
        this.getSets()
        
    },
    methods: {
        setToggle(){
            if (this.tasks[0].TeacherInput==1){
                this.toggle=true
            }
            else{
                this.toggle=false
            }
        },
        submit(){
            this.$http({
                method: "post",
                url: process.env.VUE_APP_BACKEND + "/leader",
                data: {
                    table: "FeedbackTasks",
                    data: this.tasksArray,
                },
                responseType: "json",
                })
                .then((response) => {
                    //console.log(response.data)
                    this.insertID = response.data.lastid
                    this.$emit('newTaskID', this.insertID)
                    this.$emit('newTask', {task_id: this.insertID, expiry: this.tasks[0].Expiry, name: this.tasks[0].TaskName})
                    this.submit2()
                })
                .catch((error) => {
                    console.log(error);
                });
            
        },
        submit2(){
            this.$http({
                method: "post",
                url: process.env.VUE_APP_BACKEND + "/leader",
                data: {
                    table: "FeedbackMaps",
                    data: this.testsArray,
                },
                responseType: "json",
                })
                .then((response) => {
                    this.$emit('saved')
                })
                .catch((error) => {
                    console.log(error);
                });
            
        },
        mapTests(){
            for (let i=0;i<this.tasks.length;i++){
                this.selectedTest[this.tasks[i].SetId] = this.tasks[i].TestID
            }
        },
        selectTest(s){
            const result = this.tests.filter(({ SetID }) => SetID === s);
            return result
        },
        getTests(){
            
            this.$http({
                method: "get",
                url: process.env.VUE_APP_BACKEND + "/leader/9",
                params: {
                    SubjectID: this.getSubject,
                },
                responseType: "json",
                })
                .then((response) => {
                    this.tests = response.data
                    this.mapTests()
                    this.setToggle()
                    this.tasksLoaded=true
                })
                .catch((error) => {
                    console.log(error);
            });
        },
        getSets(){
            this.$http({
                method: "get",
                url: process.env.VUE_APP_BACKEND + "/leader/8",
                params: {
                    YeargroupID: this.yeargroupID,
                },
                responseType: "json",
                })
                .then((response) => {
                    this.sets = response.data
                })
                .catch((error) => {
                    console.log(error);
            });
        },
        dateRange(){
            let min = new Date(Date.now())
            let max = new Date((Date.now() + 30 * 24 * 60 * 60 * 1000))
            let def = new Date((Date.now() + 14 * 24 * 60 * 60 * 1000))
            let retval = {}
            retval.min = min.toISOString().slice(0, 10).toString()
            retval.max = max.toISOString().slice(0, 10).toString()
            retval.def = def.toISOString().slice(0, 10).toString()
            return retval
        },
    },
    computed: {
        getYeargroup(){
            return this.$store.getters.getYeargroup
        },
        getSubject(){
            return this.$store.getters.getSubject
        },
        testsArray(){
            let retArray = []
            let match

            let k = Object.keys(this.selectedTest)
                for (let i=0;i<k.length;i++){
                    if (k[i]!="undefined"){
                //if a record is already found
                //match = this.tasks.find(obj => obj.SetID == k[i] && obj.TestID == this.selectedTest[k[i]])
                match = this.tasks.find(obj => obj.SetId == k[i])
                if (this.tasks[0].TaskID!=undefined){
                    this.insertID = this.tasks[0].TaskID
                    
                }
                if (match!=undefined){
                    retArray.push({keyval: {FeedbackMapID: match.FeedbackMapID || null}, values: {TaskID: this.insertID, SetID: k[i], TestID: this.selectedTest[k[i]]}})
                }
                else{
                    retArray.push({keyval: {FeedbackMapID: null}, values: {TaskID: this.insertID, SetID: k[i], TestID: this.selectedTest[k[i]]}})
                }
                    }

                
            }


            return retArray
        },
        tasksArray(){
            let retArray = []
            if (this.tasks[0].taskID==undefined){
                retArray.push({keyval: {taskID: null}, values: {YeargroupID: this.yeargroupID, TaskName: this.tasks[0].TaskName, Expiry: this.tasks[0].Expiry, TeacherInput: this.getToggle}})
            }
            else{
                retArray.push({keyval: {taskID: this.tasks[0].taskID || null}, values: {TaskName: this.tasks[0].TaskName, Expiry: this.tasks[0].Expiry, TeacherInput: this.getToggle}})
            }
            
            return retArray
        },
        getToggle(){
            if (this.toggle==true){
                return 1
            }
            else{
                return 0
            }
        },
        checkChange() {
          return `${this.taskID}|${this.yeargroupID}|${this.tasks}`;
        },
    },
    watch: {
        checkChange: function () {
            this.getTests()
            this.getSets()
        },
    },
  }
  </script>