<template>
  <v-container>
    <search :input="SoWs" searchfield="name" @search="searchArray"></search>
    <v-dialog v-model="Popup" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ PopupTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="isFormValid">
          <v-card-text>
            <v-text-field
              label="Scheme Of Work Name"
              hide-details="auto"
              v-model="SoWName"
              :rules="rules"
              solo
            >
            </v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            Scheme of Work Topics:
            <v-text-field
              v-for="(Topic, index) in Topics"
              :label="String(index + 1)"
              :key="index"
              :id="String(Topic.id)"
              :rules="rules"
              v-model="Topic.name"
            >
              <v-icon
                slot="append"
                color="red"
                @click="removeTopic(index, Topic)"
              >
                mdi-trash-can-outline
              </v-icon>
            </v-text-field>
            <v-text-field
              :label="String(Topics.length + 1)"
              :key="Topics.length"
              v-model="NewTopic"
              @keyup="newRow()"
            >
            </v-text-field>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-btn color="lime" text @click="Popup = !Popup">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="lime" @click="CSVTrigger = true">Upload CSV</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!isFormValid" color="lime" text @click="Submit()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="CSVTrigger" persistent>
      <v-card>
        <v-card-title> File Upload: </v-card-title>
        <v-card-text>
          <v-file-input
            chips
            accept="text/csv"
            show-size
            label="Select CSV File for Import..."
            v-model="fileName"
          ></v-file-input>
          Download template file:
          <a :href="getLink+'sow_upload.csv'"
            >Click Here</a
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="lime" dark @click="CSVTrigger = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="lime"
            dark
            :disabled="fileName == null"
            @click="upload()"
          >
            Upload
          </v-btn>

          <v-alert v-if="errorMsg != ''" dense outlined type="error">
            {{ errorMsg }}
          </v-alert>
          <v-alert v-if="submitMsg != ''" dense outlined type="success">
            {{ submitMsg }}
          </v-alert>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col md="10">
        <v-row>
          <v-col md="5">
            <v-card class="mx-auto my-2" width="374" elevation="1">
              <v-card-actions class="justify-center">
                <v-btn
                  class="mx-auto my-auto"
                  fab
                  dark
                  x-large
                  color="lime"
                  @click="NewSoW()"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-title class="justify-center"
                >Add New Scheme of Work</v-card-title
              >
            </v-card>
          </v-col>

          <v-col md="5" v-for="SoW in SoWs2" :key="SoW.id">
            <card-view
              :Name="SoW.name"
              :id="SoW.id"
              Img="/images/books.jpeg"
              @edit="edit(SoW.id)"
              @delete="del(SoW.id)"
            >
            </card-view>

            <v-dialog v-model="delflag" max-width="290">
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to delete?
                </v-card-title>

                <v-card-text>
                  Deleting this item will also remove all Topic to Scheme of
                  Work mappings that are present.
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="green darken-1" text @click="delflag = false">
                    Cancel
                  </v-btn>

                  <v-btn color="green darken-1" text @click="removeTopic2()">
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardView from "@/components/admin/CardView";
import Search from "@/components/admin/Search";
export default {
  name: "AdminSoW",
  components: {
    CardView,
    Search,
  },
  data: () => ({
    SoWs: [],
    SoWs2: [],
    Popup: false,
    PopupTitle: "",
    SoWName: "",
    SoWID: "",
    submitted: "",
    SubjectID: "",
    Topics: [],
    NewTopic: "",
    rules: [(value) => !!value || "Required."],
    delflag: false,
    TopicsSubmit: [],
    TopicID: [],
    isFormValid: false,
    CSVTrigger: false,
    submitMsg: "",
    errorMsg: "",
    fileName: null,
    uploadData: [],
  }),
  mounted() {
    this.SubjectID = this.$store.getters.getSubject;
    this.getSoW();
  },
  props: {
    CanSelect: Boolean,
  },
  computed: {
    getLink(){
      return process.env.VUE_APP_CSV
    }
  },
  methods: {
    edit(e) {
      this.SoWName = this.SoWs2[this.getName(e)].name;
      this.SoWID = this.SoWs2[this.getName(e)].id;
      this.SchoolID = 1;
      this.PopupTitle = "Edit Scheme of Work";
      this.getTopics(this.SoWID);
      this.Popup = !this.Popup;
    },
    del(e) {
      //console.log(this.getName(e));
      this.deleteSoW(e);
    },
    deleteSoW(e) {
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + e,
        data: {
          table: "SoW",
          //SchoolID: 1,
        },
        responseType: "json",
      })
        .then((response) => {
          this.submitted = response.data;
          this.getSoW();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSoW() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/2",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.SoWs = [];
          for (let i = 0; i < response.data.length; i++) {
            //this.Subjects[i].name = response.data[i].subject_name
            //this.Subjects[i].id = response.data[i].subjectid

            this.SoWs.push({
              name: response.data[i].SoWName,
              id: response.data[i].SoWId,
            });
          }
          this.SoWs2 = this.SoWs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    NewSoW() {
      this.PopupTitle = "Add New Scheme of Work";
      this.SoWName = "";
      this.SoWID = null;
      this.Topics = [];
      //this.getTopics(1)
      this.Popup = !this.Popup;
    },
    getName(e) {
      for (let i = 0; i < this.SoWs2.length; i++) {
        if (e == this.SoWs2[i].id) {
          return i;
        }
      }
    },
    Submit() {
      this.Popup = !this.Popup;

      this.TopicsSubmit = [];
      for (let i = 0; i < this.Topics.length; i++) {
        if (this.Topics[i].id == undefined) {
          this.Topics[i].id = null;
        }
        this.TopicsSubmit.push({
          //keyval: this.Topics[i].id, THIS LINE I HAVE CHANGED!!!!
          keyval: { SoWTopicID: this.Topics[i].id  || null},
          values: { SoWTopic: this.Topics[i].name, SoWID: this.SoWID },
        });
      }
      //console.log(this.TopicsSubmit)
      this.addSoW();
    },
    addSoW() {
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "SoW",
          data: [
            {
              keyval: { SoWId: this.SoWID  || null},
              values: { SubjectID: this.SubjectID, SoWName: this.SoWName },
            },
          ],
        },
        responseType: "json",
      })
        .then((response) => {
          this.submitted = response.data;

          //this.reset();
          if (this.submitted.lastid) {
            this.SoWID = this.submitted.lastid;
            this.TopicsSubmit = [];
            for (let i = 0; i < this.Topics.length; i++) {
              if (this.Topics[i].id == undefined) {
                this.Topics[i].id = null;
              }
              this.TopicsSubmit.push({
                keyval: { SoWTopicID: this.Topics[i].id  || null},
                values: { SoWTopic: this.Topics[i].name, SoWID: this.SoWID },
              });
            }
          }
          this.addTopics();
          this.getSoW();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addTopics() {
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "SoWTopic",
          data: this.TopicsSubmit,
        },
        responseType: "json",
      })
        .then((response) => {
          //this.getSum();
          //console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTopics(e) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/3",
        params: {
          SoWID: e,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Topics = [];
          for (let i = 0; i < response.data.length; i++) {
            //this.Subjects[i].name = response.data[i].subject_name
            //this.Subjects[i].id = response.data[i].subjectid
            this.Topics.push({
              name: response.data[i].SoWTopic,
              id: response.data[i].SoWTopicID,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newRow() {
      this.Topics.push({ name: this.NewTopic });
      this.NewTopic = "";
    },
    removeTopic(i, Topic) {
      if (Topic.id) {
        //count = this.mapExists(Topic.id)
        //this.delflag=true
        this.mapExists(Topic.id, i);
        //console.log(v)
      } else {
        this.Topics.splice(i, 1);
      }
    },
    mapExists(e, i) {
      //let rval = false
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/4",
        params: {
          SoWTopicID: e,
        },
        responseType: "json",
      })
        .then((response) => {
          //count = response.data[0].Cnt
          let cnt = 0;
          for (let p = 0; p < response.data.length; p++) {
            cnt = cnt + response.data[p].Cnt;
          }
          this.TopicID[0] = e;
          this.TopicID[1] = i;
          if (cnt > 0) {
            this.delflag = true;
            //console.log(e)
          } else {
            this.removeTopic2();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeTopic2() {
      this.Topics.splice(this.TopicID[1], 1);
      this.delflag = false;
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + this.TopicID[0],
        data: {
          table: "SoWTopic",
          //SchoolID: 1,
        },
        responseType: "json",
      })
        .then(() => {
          //this.getSub();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchArray(v) {
      this.SoWs2 = v;
    },
    upload() {
      this.uploadData = [];
      let self = this;
      this.$papa.parse(this.fileName, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: "greedy",
        complete: function (results) {
          //console.log(results.data)
          self.checkUpload(results.data);
          let chk = self.checkUpload(results.data);
          //console.log(chk)
          if (chk == true) {
            self.uploadData = results.data;
            self.Topics = [];
            for (let i = 0; i < self.uploadData.length; i++) {
              self.Topics.push({ name: self.uploadData[i].SoW_Topic });
            }
            //console.log(this.Topics);
            self.CSVTrigger = false;
          } else {
            self.fileName = null;
          }
          //use self to access functions
          //remove all entries for current assessment
          //check headers match requested format
          //check question numbers are unique, maxmark is an integer >0
        },
        error: function () {
          this.fileName = null;
          console.log("Parsing errors");
        },
      });
    },
    checkUpload(data) {
      let chk = true;
      if (data[0]["SoW_Topic"]) {
        chk = true;
      } else {
        chk = false;
        this.errorMsg = "The upload file does not meet the required format.";
      }
      //console.log(data)
      return chk;
    },
  },
};
</script>
