<template>
    <v-container>
        <div v-if="loaded">
            <v-form v-model="canSelectGP">
            <v-row>
               
                <v-col>
                    <v-select
                        :items="gradeProfiles.gp"
                        item-value="GPID"
                        item-text="Name"
                        label="Select saved grade profile"
                        solo
                        :rules="rules"
                        v-model="selectedGP"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-btn color="lime" :disabled="canSelectGP==false" text @click="loadGP(selectedGP)">Load Grade Profile</v-btn>
                </v-col>
                <v-col>
                    <v-dialog v-model="dialog">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="lime" :disabled="percentSum" text @click="newGPCheck()" v-bind="attrs" v-on="on">Save Grade Profile</v-btn>
                        </template>

      <v-card>
        <ampil-card title="Enter grade profile name:"></ampil-card>
        <v-form v-model="submitValid">
        <v-card-text>
                <v-text-field
                    :rules="rules"
                    v-model="NewGP.name"
                    label="Grade Profile Name"
                  ></v-text-field>
                  <v-checkbox
                  :disabled="selectedGP2==null"
                    v-model="NewGP.check"
                    label="Save as new"
                    ></v-checkbox>
        </v-card-text>
        </v-form>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="submitValid==false"
            color="lime"
            text
            @click="saveGP()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
                    </v-dialog>
                </v-col>
            
            </v-row>
        </v-form>
            <v-row v-for="(grade, index) in gp" :key="index" :id="grade.GID">
                <v-col>
                    <v-text-field
                    :rules="rules"
                    v-model="grade.Label"
                    label="Grade"
                  ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    v-model="grade.Perc"
                    :rules="numberRules"
                    label="Percentage"
                  ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="New grade"
                  :rules="rules"
                  :key="gp.length"
                  v-model="NewGrade"
                  ref="newGrade"
                  @change="newRow()"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :rules="numberRules"
                  v-model="NewPerc"
                  label="Percentage"
                  @change="newRow()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-dialog v-model="dialog2">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="lime" :disabled="percentSum" v-bind="attrs" v-on="on">Assign grades to students</v-btn>
                        </template>

      <v-card>
        <ampil-card title="Assign Grades:"></ampil-card>
        <v-card-title>Tweak the grade boundaries using the buttons on each boundary</v-card-title>
        <feedback-grades-map :yeargroupID="yeargroupID" :gp="gp" :taskID="taskID"></feedback-grades-map>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="lime"
            text
            @click="dialog2=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
                    </v-dialog>

                </v-col>
            </v-row>

        </div>
    </v-container>
</template>

<script>
import FeedbackGradesMap from "@/components/leader/FeedbackGradesMap.vue";
export default {
  name: "FeedbackGrades",
  components: {
    FeedbackGradesMap
  },
  props: {
    taskID: Number,
    yeargroupID: Number,
  },
  mounted() {
    this.getGrades()
  },
  data() {
    return {
        loaded: false,
        gradeProfiles: [],
        allData: [],
        gp: [],
        NewGrade: "",
        NewPerc: null,
        NewGP: {name: "", check: true},
        canSelectGP: false,
        rules: [(value) => !!value || "Required."],
        numberRules: [
        //v => v.length > 0 || 'This field may not be empty',
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        (v) => v > 0 || "The value must be greater than zero",
        ],
        selectedGP: null,
        selectedGP2: null,
        dialog: false,
        dialog2: false,
        submitValid: false,
    };
  },
  methods: {
    loadGP(s){
        let retval = this.gradeProfiles.grades.filter(a => a.GPID==s)
        this.gp = retval
        this.selectedGP2 = this.selectedGP
    },
    newRow() {
      if (this.NewGrade != "" && this.NewPerc != null) {
        this.gp.push({
          Label: this.NewGrade,
          Perc: this.NewPerc,
        });
        this.NewGrade = "";
        this.NewPerc = null;
        this.$refs.newGrade.focus();
      }
    },
    getGrades(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/15",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.gradeProfiles = response.data
            this.getData()
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getData(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/16",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allData = response.data
            this.loaded=true
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newGPCheck(){
        if (this.selectedGP2!=null){
            this.NewGP.name = this.gradeProfiles.gp.find(a=> a.GPID==this.selectedGP2).Name
            this.NewGP.check = false
            this.NewGP.GPID = this.selectedGP2
            //
        }
    },
    saveGP(){
        let GPID
        if (this.NewGP.check==true){
            GPID = null
        }
        else{
            GPID = this.NewGP.GPID
        }
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/leader",
        data: {
          table: "GradeProfileName",
          data: [
            {
              keyval: { GPID: GPID || null},
              values: {
                Name: this.NewGP.name,
                YearID: this.yeargroupID,
              },
            },
          ],
        },
        responseType: "json",
      })
        .then((response) => {
            this.saveGP2(response.data.lastid)
        })
        .catch((error) => {
          console.log(error);
        });
        
    },
    saveGP2(id){
        let submit = []
        for (let i=0;i<this.gp.length;i++){
            if (this.gp[i].GID!=undefined && this.NewGP.check==false){
                submit.push({keyval: {GID: this.gp[i].GID || null}, values: {GPID: id, Label: this.gp[i].Label, Perc: this.gp[i].Perc}})
            }
            else{
                submit.push({keyval: {GID: null}, values: {GPID: id, Label: this.gp[i].Label, Perc: this.gp[i].Perc}})
            }
        }
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/leader",
        data: {
          table: "GradeProfileScores",
          data: submit
        },
        responseType: "json",
      })
        .then((response) => {
            this.dialog=false
            this.getGrades()
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {
    percentSum(){
        let total = 0
        for (let i=0;i<this.gp.length;i++){
            total+=parseInt(this.gp[i].Perc)
        }
        if (total==100){
            return false
        }
        return true
    },

  },
  watch: {
    dialog2: function(){
      this.$emit('changed')
    }
  },
};
</script>
