<template>
    <div>
        <v-select
                v-model="yearSat"
                :items="getYear"
                item-value="id"
                item-text="year"
                label="Select the year the exam was sat"
            ></v-select>
        <v-card v-if="yearSat!=null">
      <v-card-title> File Upload: </v-card-title>
      <v-card-text>
        
        <v-file-input
          chips
          accept="text/csv"
          show-size
          label="Select CSV File for Import..."
          v-model="fileName"
        ></v-file-input>
        Download template file:
        <a :href="getLink+'terminal_upload.csv'"
          >Click Here</a
        >
      </v-card-text>
      <v-card-actions>
        <v-btn color="lime" dark :disabled="fileName == null" @click="upload()">
          Upload
        </v-btn>
        <v-alert v-if="errorMsg != ''" dense outlined type="error">
          {{ errorMsg }}
        </v-alert>
        <v-alert v-if="submitMsg != ''" dense outlined type="success">
          {{ submitMsg }}
        </v-alert>
        <v-card-actions v-if="toUpload.length>0">
            <dialog-modal type="outline" buttonText="View Upload Data" title="Upload Data:" :dataTable="tableOutput" :sortBy="['SetName','LastFirst']"></dialog-modal>
            <v-spacer></v-spacer>
            <v-btn color="lime" outlined text @click="submit()">Commit to DB</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn v-if="submitValid" color="lime" outlined text @click="sortData()">Confirm Assessments</v-btn>
        </v-card-actions>
      </v-card-actions>
      <v-alert
            v-if="submitted"
            dense
            text
            type="success"
        >
        Data uploaded successfully!
        </v-alert>
      <div v-if="unmatchedStudents.length>0">
            <v-alert
                dense
                outlined
                type="error"
                >
                {{ unmatchedStudents.length }} student(s) from the upload file cannot be found in the database
                <div class="text-right">
                    <dialog-modal type="error" buttonText="Show" title="The following students could not be found:" :text="prepareUnmatched(unmatchedStudents)"></dialog-modal>
                </div>
            </v-alert>
            
        </div>
    </v-card>
    </div>
</template>

<script>
export default {
  name: "PriorCsv",
  props: {
    endpoint: Number,
    year: Number,
  },
  mounted() {
    this.getAllStudents()
    this.getAllEndpoints()
  },
  data() {
    return {
        ready: false,
        fileName: null,
        errorMsg: '',
        submitMsg: '',
        uploadCheck: false,
        uploadData: [],
        matchedStudents: [],
        unmatchedStudents: [],
        allEndpoints: [],
        allStudents: [],
        checkFile: [
        "StudentID",
        "LastFirst",
        "Grade"
      ],
      toUpload: [],
      years: [],
      yearSat: null,
      tableOutput: [],
      submitted: false,
    };
  },
  methods: {
    submit(){
        this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/teacher",
          data: {
            table: "EndpointScores",
            data: this.toUpload
          },
          responseType: "json",
        })
          .then((response) => {
            this.submitted = true
          })
          .catch((error) => {
            console.log(error);
          });
    },
    prepareUnmatched(input){
        let out = ''
        for (let i=0;i<input.length;i++){
            out = out + input[i].LastFirst + "(" + input[i].StudentID + "), "
        }
        return out
    },
    validateStudents(){
        let match
        let set
        //To check for unique sets in array
        Array.prototype.inArray = function(comparer) { 
            for(var i=0; i < this.length; i++) { 
                if(comparer(this[i])) return true; 
            }
            return false; 
        }; 
        Array.prototype.pushIfNotExist = function(element, comparer) { 
            if (!this.inArray(comparer)) {
                this.push(element);
            }
        }; 
        for (let i=0;i<this.uploadData.length;i++){
            match = this.allStudents.find(({ StudentID }) => StudentID === this.uploadData[i].StudentID);
            if (match!=undefined){
                //this.matchedStudents.push(this.uploadData[i])
                this.matchedStudents.push({StudentID: match.StudentID, UniqueID: match.UniqueID, LastFirst: match.LastFirst, SetName: match.SetName, Grade: this.uploadData[i].Grade, DateCompleted: this.idToYear(this.yearSat)})
            }
            else {
                this.unmatchedStudents.push(this.uploadData[i])
            }
        }
        //create upload array and display buttons (check against existing)
        this.toUpload = []
        for (let i=0;i<this.matchedStudents.length;i++){
            match = this.allEndpoints.find(obj => obj.StudentID == this.matchedStudents[i].UniqueID && obj.EndpointID == this.endpoint)
            if (match!=undefined){
                this.toUpload.push({keyval: {EndpointScoresID: match.EndpointScoresID || null}, values: {StudentID: this.matchedStudents[i].UniqueID, EndpointID: this.endpoint, EndScore: this.matchedStudents[i].Grade, DateCompleted: this.idToYear(this.yearSat)}})
            }
            else{
                this.toUpload.push({keyval: {EndpointScoresID: null}, values: {StudentID: this.matchedStudents[i].UniqueID, EndpointID: this.endpoint, EndScore: this.matchedStudents[i].Grade, DateCompleted: this.idToYear(this.yearSat)}})
            }
        }
        let headers = [
            {text: "ID", value: "StudentID", groupable: false},
            {text: "Name", value: "LastFirst", groupable: false},
            {text: "Class", value: "SetName", groupable: false},
            {text: "Grade", value: "Grade"},
            {text: "Year Sat", value: "DateCompleted"},
        ]
        this.tableOutput.headers = headers
        this.tableOutput.values = this.matchedStudents
        console.log(this.toUpload, this.matchedStudents)
        
    },
    checkFileValid(d) {
      let h = Object.keys(d.data[0]);
      let ret = true;
      let pattern = /^null$|^[\d]*$|^[ABCDEFGUabcdefgu]$|^A\*$/;
      let result
      for (let i = 0; i < h.length; i++) {
        if (h[i] != this.checkFile[i]) {
          this.errorMsg =
            "The upload file is invalid. Please check you have uploaded using the correct template.";
          ret = false;
        }
      }
      
      //check all data is integers
      for (let j=0;j<d.data.length;j++){
        result = pattern.test(d.data[j].Grade);
        if (result==false){
            this.errorMsg =
            "The upload file is invalid. The grades submitted should be numeric or A* to G or U.";
          ret = false;
        }
      }
      
      return ret;
    },
    clearAll() {
      this.uploadData = []
      this.matchedStudents = []
      this.unmatchedStudents = []
      this.toUpload = []
    },
    getAllStudents() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/4",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.allStudents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllEndpoints() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/7",
        params: {
          EndpointID: this.endpoint,
        },
        responseType: "json",
      })
        .then((response) => {
          this.allEndpoints = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    upload() {
      let self = this;
      this.$papa.parse(this.fileName, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: "greedy",
        complete: function (results) {
          self.fileName = null;
          if (self.checkFileValid(results)) {
            self.clearAll();
            self.uploadData = results.data;
            //check students exist
            self.validateStudents()
            
            self.errorMsg = "";
            self.uploadCheck = true;
          } else {
            self.uploadCheck = false;
          }
        },
        error: function () {
          this.fileName = null;
          console.log("Parsing errors");
        },
        
      });
    },
    idToYear(i){
        const result = this.getYear.find(({ id }) => id === i);
        return result.year
    },
  },
  computed: {
    getYear(){
        let yr = new Date().getFullYear()
        let years = []
        for (let i=0;i<15;i++){
            years.push({id: i, year: (yr-i)})
        }
        return years
    },
    getLink(){
      return process.env.VUE_APP_CSV
    }
  },
};
</script>