<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          v-model="selectedSoW"
          :items="sow"
          item-text="SoWName"
          item-value="SoWId"
          label="Select SoW..."
          outlined
          @change="getSoWTopics(selectedSoW)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-btn v-if="canSubmit" color="lime" @click="submitMap()">
          Submit
        </v-btn>
        <v-alert v-if="Submitted == true" dense text type="success">
          Data uploaded successfully!
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="selectedSoW != null">
      <v-col cols="6">
        <v-card height="815" class="overflow-auto">
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="sowTopic in sowTopics"
                :key="sowTopic.SoWTopicID"
                color="lime"
                @click="selectSoWTopic(sowTopic.SoWTopicID)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="sowTopic.SoWTopic"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card v-if="showQuestions">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            height="650"
            class="overflow-auto"
            v-model="selectedQuestions"
            :headers="headers"
            :items="questions"
            item-key="QuestionID"
            :search="search"
            group-by="TestName"
            show-group-by
            show-select
          >
            <template
              v-slot:item.data-table-select="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                :readonly="item.disabled"
                :disabled="item.disabled"
                @input="select($event)"
                @click="canSubmit = true"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MappingManual",
  components: {},
  data: () => ({
    nlpAssess: [],
    nlpSoW: [],
    flatTree: [],
    tempMappings: [],
    topicMappings: [],
    sow: [],
    sowTopics: [],
    selectedSoW: null,
    questions: [],
    search: "",
    headers: [
      {
        text: "Assessment",
        align: "start",
        value: "TestName",
      },
      { text: "Question Topic", value: "TestTopic", groupable: false },
      { text: "Select", value: "QuestionID", groupable: false },
    ],
    selectedQuestions: [],
    existingMaps: [],
    selectedTopic: null,
    submitArray: [],
    deleteArray: [],
    canSubmit: false,
    Submitted: false,
    showQuestions: false,
  }),
  async mounted() {
    await this.getSoW();
    await this.getnlp();
    await this.getQuestions();
  },
  props: {},
  methods: {
    getSoW() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/2",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.sow = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSoWTopics(sow) {
      this.canSubmit = false;
      this.Submitted = false;
      this.showQuestions = false;
      //this.selectedSoWTopic = null
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/3",
        params: {
          SoWID: sow,
        },
        responseType: "json",
      })
        .then((response) => {
          this.sowTopics = response.data;
          //this.selectedSoWTopic = this.sowTopics[0].SoWTopicID
          //this.getExistingMaps()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getQuestions() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/20",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.questions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getnlp() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/18",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.nlpSoW = response.data;
          this.$http({
            method: "get",
            url: process.env.VUE_APP_BACKEND + "/admin/19",
            params: {
              SubjectID: this.$store.getters.getSubject,
            },
            responseType: "json",
          })
          .then((response) => {
            this.nlpAssess = response.data;
            this.$http({
              method: "get",
              url: process.env.VUE_APP_BACKEND + "/topics/4",
              params: {
                data: this.$store.getters.getSubject,
              },
              responseType: "json",
            })
            .then((response) => {
              this.flatTree = response.data;
                  //console.log(this.flatTree)
                  //this.checkChild(1,469)
                  //console.log(this.topicMappings)
                  this.topicMaps();
            })
            .catch((error) => {
              console.log(error);
            });
          })
          .catch((error) => {
            console.log(error);
          });
        })
        .catch((error) => {
          console.log(error);
        });

    },
    topicMaps() {
      for (let j = 0; j < this.nlpSoW.length; j++) {
        this.tempMappings = [];
        this.checkChild(this.nlpSoW[j].TopicID, this.nlpSoW[j].SoWTopicID);
        //console.log(this.tempMappings)
        for (let i = 0; i < this.tempMappings.length; i++) {
          for (let k = 0; k < this.nlpAssess.length; k++) {
            if (this.tempMappings[i].TopicID == this.nlpAssess[k].TopicID) {
              this.topicMappings.push({
                SoWTopicID: this.tempMappings[i].SoWTopicID,
                TopicID: this.tempMappings[i].TopicID,
                TopicName: this.tempMappings[i].TopicName,
                QuestionID: this.nlpAssess[k].QuestionID,
              });
            }
          }
        }
      }
      //console.log(this.topicMappings)
    },
    checkChild(v, s) {
      for (let i = 0; i < this.flatTree.length; i++) {
        if (this.flatTree[i].Parent == v) {
          this.tempMappings.push({
            SoWTopicID: s,
            TopicID: this.flatTree[i].TopicID,
            TopicName: this.flatTree[i].TopicName,
          });
          this.checkChild(this.flatTree[i].TopicID, s);
        }
      }
    },
    selectSoWTopic(top) {
      this.showQuestions = true;
      this.canSubmit = true;
      this.Submitted = false;
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/21",
        params: {
          SoWTopicID: top,
        },
        responseType: "json",
      })
        .then((response) => {
          this.selectedTopic = top;
          this.existingMaps = response.data;
          this.selectedQuestions = [];
          for (let j = 0; j < this.questions.length; j++) {
            this.questions[j].disabled = false;
          }
          //console.log(response.data)
          for (let k = 0; k < response.data.length; k++) {
            this.selectedQuestions.push({
              QuestionID: response.data[k].QuestionID,
              SoWMappingID: response.data[k].SoWMappingID,
            });
          }

          for (let i = 0; i < this.topicMappings.length; i++) {
            if (this.topicMappings[i].SoWTopicID == top) {
              this.selectedQuestions.push({
                QuestionID: this.topicMappings[i].QuestionID,
              });
              for (let j = 0; j < this.questions.length; j++) {
                if (
                  this.questions[j].QuestionID ==
                  this.topicMappings[i].QuestionID
                ) {
                  this.questions[j].disabled = true;
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitMap() {
      //console.log(this.questions)
      let deleteArray = [];
      let submitArray = [];
      for (let i = 0; i < this.existingMaps.length; i++) {
        let c = 0;
        for (let j = 0; j < this.selectedQuestions.length; j++) {
          if (
            this.existingMaps[i].QuestionID ==
              this.selectedQuestions[j].QuestionID &&
            this.existingMaps[i].SoWTopicID == this.selectedTopic
          ) {
            //console.log('found '+this.existingMaps[i].SoWMappingID)
            c++;
          }
        }
        if (c == 0) {
          //console.log('not found '+this.existingMaps[i].SoWMappingID)
          deleteArray.push({SoWMappingID: this.existingMaps[i].SoWMappingID});
        }
      }
      for (let i = 0; i < this.selectedQuestions.length; i++) {
        let e = this.findEntry(
          this.questions,
          this.selectedQuestions[i].QuestionID
        );
        let f = this.findEntry(
          this.existingMaps,
          this.selectedQuestions[i].QuestionID
        );
        //console.log(f)
        if (e.disabled == false) {
          if (f) {
            submitArray.push({
              keyval: {SoWMappingID: f.SoWMappingID || null},
              values:
                {QuestionID: this.selectedQuestions[i].QuestionID,
                SoWTopicID: this.selectedTopic}
            });
          } else {
            submitArray.push({
              keyval: {SoWMappingID: null},
              values: 
                {QuestionID: this.selectedQuestions[i].QuestionID,
                SoWTopicID: this.selectedTopic}
            });
          }
        }
      }
      //console.log(deleteArray)
      //does not get mapping if deleted then selected again because keyval still there
      //console.log(submitArray)
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/1",
        data: {
          table: "SoWMapping",
          values: deleteArray
        },
        responseType: "json",
      })
        .then(() => {
          this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/admin",
          data: {
            table: "SoWMapping",
            data: submitArray
          },
          responseType: "json",
        })
          .then((response) => {
            this.canSubmit = false;
              this.Submitted = true;
              this.selectSoWTopic(this.selectedTopic);
          })
          .catch((error) => {
            console.log(error);
          });
        })
        .catch((error) => {
          console.log(error);
        });
      //create delete array by checking any maps not in existingMaps
      //create submit map by cycling through all questions with disabled as false
      //ensure no duplicates?
    },
    findEntry(s, v) {
      const result = s.find(({ QuestionID }) => QuestionID === v);
      return result;
    },
  },
};
</script>
