<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="selectedSoW"
          :items="sow"
          item-text="SoWName"
          item-value="SoWId"
          label="Select SoW..."
          outlined
          @change="getSoWTopics(selectedSoW)"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="selectedSubject"
          :items="subjects"
          item-text="SubjectName"
          item-value="SubjectID"
          label="Select Subject..."
          outlined
          @change="getTree(selectedSubject)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" v-if="(selectedSoW != null) & (selectedSubject != null)">
        <v-btn color="lime" @click="clearCurrent()"> Clear current </v-btn>
      </v-col>
      <v-col cols="3" v-if="(selectedSoW != null) & (selectedSubject != null)">
        <v-btn color="lime" @click="clearAll()"> Clear All </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn v-if="canSubmit" color="lime" @click="Submit()"> Save </v-btn>
        <v-alert v-if="Submitted == true" dense text type="success">
          Data uploaded successfully!
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="(selectedSoW != null) & (selectedSubject != null)">
      <v-col cols="6">
        <v-card height="700" class="overflow-auto">
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="sowTopic in sowTopics"
                :key="sowTopic.SoWTopicID"
                color="lime"
                @click="selectSoWTopic(sowTopic.SoWTopicID)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="sowTopic.SoWTopic"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-for="(mapped, id) in mappedTopics[sowTopic.SoWTopicID]"
                    :key="id"
                    >{{ topics[mapped] }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <div
                  v-if="
                    mappedTopics[sowTopic.SoWTopicID] &&
                    mappedTopics[sowTopic.SoWTopicID].length > 0
                  "
                >
                  <v-badge
                    color="red"
                    :content="mappedTopics[sowTopic.SoWTopicID].length"
                  >
                  </v-badge>
                </div>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card height="700" class="overflow-auto">
          <v-treeview
            v-if="selectedSoWTopic != null"
            color="lime"
            v-model="mappedTopics[selectedSoWTopic]"
            :items="tree"
            selectable
            selection-type="independent"
            @input="selectTopic()"
          ></v-treeview>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import natural from "natural";
import cosine from "calculate-cosine-similarity";
import keyword_extractor from "keyword-extractor";
export default {
  name: "MappingSow",
  components: {},
  data: () => ({
    tree: [],
    topics: {},
    selectedTopic2: [],
    selectedTopic: "",
    subjects: [],
    selectedSubject: null,
    selectedSoW: null,
    selectedSoWTopic: null,
    sow: null,
    sowTopics: [],
    mappedTopics: [],
    canSubmit: false,
    submitArray: [],
    deleteArray: [],
    existingArray: [],
    Submitted: false,
  }),
  mounted() {
    this.getSubjects();
    this.getSoW();
    //this.getExistingMaps()
  },
  props: {},
  methods: {
    getSoW() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/2",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.sow = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExistingMaps() {
      this.mappedTopics = [];
      this.existingArray = [];
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/18",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.existingArray = response.data;
          for (let i = 0; i < response.data.length; i++) {
            //this.selectedTopic2.push(response.data[i].TopicID)
            this.mappedTopics[response.data[i].SoWTopicID] = [];
          }
          for (let i = 0; i < response.data.length; i++) {
            this;
            //this.mappedTopics[response.data[i].SoWTopicID].push({id: response.data[i].TopicID, name: this.topics[response.data[i].TopicID], eid: response.data[i].NLPSoWID})
            if (this.topics[response.data[i].TopicID]) {
              this.mappedTopics[response.data[i].SoWTopicID].push(
                response.data[i].TopicID
              );
            }
          }
          //console.log(this.mappedTopics)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTree(sub) {
      this.Submitted = false;
      this.selectedSoWTopic = null;
      this.canSubmit = false;
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/topics/1",
        params: {
          data: sub,
        },
        responseType: "json",
      })
        .then((response) => {
          this.tree = response.data;
          this.getTopics(sub);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubjects() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/topics/3",
        params: {
          data: 1,
        },
        responseType: "json",
      })
        .then((response) => {
          this.subjects = response.data;
          //this.getExistingMaps()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTopics(sub) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/topics/2",
        params: {
          data: sub,
        },
        responseType: "json",
      })
        .then((response) => {
          this.topics = response.data;
          this.getExistingMaps();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSoWTopics(sow) {
      this.Submitted = false;
      this.selectedSoWTopic = null;
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/3",
        params: {
          SoWID: sow,
        },
        responseType: "json",
      })
        .then((response) => {
          this.sowTopics = response.data;
          //this.selectedSoWTopic = this.sowTopics[0].SoWTopicID
          //this.getExistingMaps()
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectTopic() {
      this.canSubmit = true;
    },
    selectSoWTopic(key) {
      this.Submitted = false;
      this.selectedSoWTopic = key;
    },
    clearCurrent() {
      this.mappedTopics[this.selectedSoWTopic] = [];
      this.canSubmit = true;
      //this.selectedSoWTopic = null
    },
    clearAll() {
      this.mappedTopics = [];
      //this.selectedSoWTopic = null

      this.canSubmit = true;
    },
    Submit() {
      //cycle through existing mappings and find those that dont exist, add to delete array
      //cycle through mappings and add to mapping array
      //in mapping array key is sowid and values are topics
      this.deleteArray = [];
      this.submitArray = [];
      //Deal with existing data...
      for (let k = 0; k < this.existingArray.length; k++) {
        let c = 0;
        for (let i = 0; i < this.mappedTopics.length; i++) {
          if (this.mappedTopics[i]) {
            for (let j = 0; j < this.mappedTopics[i].length; j++) {
              if (
                this.existingArray[k].SoWTopicID == i &&
                this.existingArray[k].TopicID == this.mappedTopics[i][j]
              ) {
                //console.log('found')
                this.submitArray.push({
                  keyval: {NLPSoWID: this.existingArray[k].NLPSoWID || null},
                  values: {SoWTopicID: i, TopicID: this.mappedTopics[i][j]},
                });
                c++;
              }
            }
          }
        }
        if (c == 0) {
          this.deleteArray.push({NLPSoWID: this.existingArray[k].NLPSoWID});
        }
        //Add new
      }
      for (let i = 0; i < this.mappedTopics.length; i++) {
        if (this.mappedTopics[i]) {
          for (let j = 0; j < this.mappedTopics[i].length; j++) {
            let c = 0;
            for (let k = 0; k < this.existingArray.length; k++) {
              if (
                this.existingArray[k].SoWTopicID == i &&
                this.existingArray[k].TopicID == this.mappedTopics[i][j]
              ) {
                //console.log('found')
                c++;
              }
            }
            if (c == 0) {
              this.submitArray.push({
                keyval: {NLPSoWID: null},
                values: {SoWTopicID: i, TopicID: this.mappedTopics[i][j]},
              });
            }
          }
        }
      }
      this.removeEntries();
      //console.log(this.existingArray)
      //console.log(this.mappedTopics)
    },
    removeEntries() {
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/1",
        data: {
          table: "NLPSoW",
          values: this.deleteArray
        },
        responseType: "json",
      })
        .then(() => {
          this.updateEntries();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateEntries() {
      this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/admin",
          data: {
            table: "NLPSoW",
            data: this.submitArray
          },
          responseType: "json",
        })
          .then((response) => {
            this.canSubmit = false;
          this.Submitted = true;
          //this.getExistingMaps()
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
};
</script>
