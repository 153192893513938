<template>
  <div>
    <v-select
    v-if="classes.length!=0"
      v-model="set"
      :items="classes"
      item-text="SetName"
      item-value="SetId"
      label="Select Class"
    ></v-select>
    <v-form v-if="set != null" lazy-validation v-model="valid">
      Enter the student total scores below. (You can use the enter key to move
      quickly to the next student)
      <div v-for="student in totals">
        <v-text-field
          v-model="student.Total"
          :label="student.LastFirst"
          :rules="[rules.isInt, rules.tooBig]"
          @keydown="clear()"
          @keydown.enter="focusNext"
        ></v-text-field>
      </div>
      <v-btn color="lime" dark v-if="valid" @click="removeAll()">Clear</v-btn>
      <v-btn color="lime" dark v-if="valid" @click="submit()">Submit</v-btn>
      <v-spacer></v-spacer>
      <v-alert v-if="submitted == true" dense text type="success">
        Done
      </v-alert>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "UploadTotalsManual",
  props: {
    test: Number,
    testName: String,
    total: Number,
    givenSet: Number,
  },
  mounted() {
    if (this.givenSet>-1){
            this.set=this.givenSet
          }
          else{
            this.getSets();
          }
    
  },
  data() {
    return {
      set: null,
      classes: [],
      totals: [],
      valid: true,
      submitted: false,
      rules: {
        tooBig: (value) =>
          value <= this.total || "Max mark available is " + this.total,
        isInt: (value) => {
          const pattern = /^null$|^[\d]*$/;
          return pattern.test(value) || "Value must be an integer";
        },
      },
    };
  },
  methods: {
    removeAll(){
      for (let i=0;i<this.totals.length;i++){
        this.totals[i].Total = null
      }
    },
    clear() {
      this.submitted = false;
    },
    getSets() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/3",
        params: {
          YeargroupID: this.$store.getters.getYeargroup,
        },
        responseType: "json",
      })
        .then((response) => {
          this.classes = response.data;
          if (this.givenSet>-1){
            this.set=this.givenSet
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTotals() {
      //this.submitted=false
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/3",
        params: {
          SetID: this.set,
          TestID: this.test,
        },
        responseType: "json",
      })
        .then((response) => {
          this.totals = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      let submitData = [];
      for (let i = 0; i < this.totals.length; i++) {
        submitData.push({
          keyval: { TestTotalsID: this.totals[i].TestTotalsID  || null},
          values: {
            TestID: this.totals[i].TestID,
            StudentID: this.totals[i].UniqueID,
            Total: parseInt(this.totals[i].Total),
          },
        });
      }

      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/teacher",
        data: {
          table: "TestTotals",
          data: submitData,
        },
        responseType: "json",
      })
        .then((response) => {
          this.submitted = true;
          this.getTotals();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="text"]')
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
  },
  computed: {},
  watch: {
    set: function () {
      this.getTotals();
    },
  },
};
</script>
