<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title> Terminal Exams </v-card-title>
          <v-card-text>
            Assign terminal exam endpoints such as KS2, GCSE, and A-Level. This
            is needed for two things:
            <ul>
              <li>
                Ranking the students to then fit a grade profile and make set
                change decisions
              </li>
              <li>
                Predicting students final grades from assessment scores based on
                the performance of their older peers
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title> Add new terminal exam endpoint: </v-card-title>
          <v-card-text>
            <v-form v-model="isFormValid">
              <v-text-field
                v-model="yearSat"
                label="School Year Exam Sat:"
                :rules="numberRules"
                hide-details="auto"
                @keyup="duplicateYears(yearSat)"
              ></v-text-field>
              <v-text-field
                v-model="endpointName"
                label="Exam Name:"
                :rules="rules"
                hide-details="auto"
              ></v-text-field>
            </v-form>
            <v-card-actions>
              <v-btn
                v-if="dupCheck == true"
                color="lime"
                align-right
                dark
                :disabled="!isFormValid"
                @click="addTerminal()"
              >
                Add
              </v-btn>
              <div v-if="dupCheck == false">
                <v-alert dense outlined type="error">
                  Warning: There is already a terminal exam allocated to this
                  school year
                </v-alert>
              </div>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item
        v-for="(Endpoint, i) in Endpoints"
        :key="i"
        color="black"
        fill-dot
      >
        <v-card color="black" dark>
          <v-img
            height="70"
            src="/images/slide_01.jpg"
            cover
            class="text-white"
          >
            <v-card-title class="text-h6">
              Year {{ Endpoint.YearSat }}
            </v-card-title>
          </v-img>
          <v-card-text class="white text--primary text-h6 text-left">
            {{ Endpoint.EndpointName }}
          </v-card-text>
          <v-card-actions class="white text--primary justify-end">
            <confirm-action
              btntext="Remove"
              Title="Confirm Delete?"
              Message="Warning: This will delete this Terminal Exam. Are you sure you wish to continue?"
              @confirm="removeEndpoint(Endpoint.EndpointID)"
            ></confirm-action>
          </v-card-actions>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
//import jwt from "jwt";

import ConfirmAction from "@/components/admin/ConfirmAction";
export default {
  name: "AdminTerminal",
  components: {
    ConfirmAction,
  },
  data: () => ({
    Endpoints: [],
    isFormValid: false,
    rules: [(value) => !!value || "Required."],
    numberRules: [
      //v => v.length > 0 || 'This field may not be empty',
      (v) =>
        Number.isInteger(Number(v)) || "The value must be an integer number",
      (v) => v > 0 || "The value must be greater than zero",
    ],
    yearSat: null,
    endpointName: "",
    dupCheck: true,
  }),
  mounted() {
    this.getTerminal();
  },
  props: {},
  methods: {
    getTerminal() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/11",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Endpoints = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addTerminal() {
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "EndpointNames",
          data: [
            {
              keyval: { EndpointID: null },
              values: {
                SubjectID: this.$store.state.Subject,
                EndpointName: this.endpointName,
                YearSat: this.yearSat,
              },
            },
          ],
        },
        responseType: "json",
      })
        .then(() => {
          this.endpointName = "";
          this.yearSat = null;
          this.getTerminal();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeEndpoint(i) {
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + i,
        data: {
          table: "EndpointNames",
        },
        responseType: "json",
      })
        .then(() => {
          this.getTerminal();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    duplicateYears(y) {
      this.dupCheck = true;
      for (let i = 0; i < this.Endpoints.length; i++) {
        if (this.Endpoints[i].YearSat == y) {
          this.dupCheck = false;
        }
      }
    },
  },
};
</script>
