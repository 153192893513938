<template>
    <div>
    <v-dialog v-model="dialog" width="90%" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="lime" text @click="dialog = !dialog">
          <v-icon>mdi-cloud-upload-outline</v-icon>
          QLA
        </v-btn>
      </template>

      <v-card>
        <ampil-card
          title="Question Level Analysis:"
          :items="menu"
          @tab="changeTab"
        ></ampil-card>
        <v-card-text>
            <v-select
            v-if="!givenSet"
      v-model="set"
      :items="classes"
      item-text="SetName"
      item-value="SetId"
      label="Select Class"
    ></v-select>
            <enter-qla-manual v-if="set!=null && tab==1" :key="componentKey" :test="test" :set="set"></enter-qla-manual>
        </v-card-text>
        <v-card-text>
          <enter-qla-csv :test="test" :set="set" v-if="set!=null && tab==2"></enter-qla-csv>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="lime" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EnterQlaManual from "@/components/teacher/EnterQlaManual"
import EnterQlaCsv from "@/components/teacher/EnterQlaCsv"
export default {
  name: "EnterQla",
  props: {
    test: Number,
    givenSet: Number,
    yeargroupID: Number
  },
  components: {
    EnterQlaManual,
    EnterQlaCsv,
  },
  mounted() {
    this.autoSet()
    this.getSets()
  },
  data() {
    return {
      menu: [
        { id: 1, name: "Manual Entry" },
        { id: 2, name: "Bulk Upload" },
      ],
      tab: 1,
        componentKey: 1,
        dialog: false,
        classes: [],
        set: null,
        dados: [{
                name: 'Teste',
                age: 13
            }],
            options: {
                columns: [{
                    title: 'Name',
                    field: 'name',
                    sorter: 'string',
                    width: 200,
                    editor: true,
                }, ],
            }
    };
  },
  methods: {
    autoSet(){
      if (this.givenSet>-1){
        this.set = this.givenSet
      }
      
    },
    changeTab(i) {
      this.tab = i;
    },
    getSets() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/30",
        params: {
          //YeargroupID: this.$store.getters.getYeargroup,
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
          this.classes = response.data;
          //this.getData()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    forceReload(){
      this.componentKey +=1
    }
  },
  computed: {},
  watch: {
    set: function () {
      this.forceReload();
    },
  },
};
</script>
