<template>
    <div>
        <v-alert
      prominent
      v-if="showBanner==true"
      close-text="Close Alert"
      dismissible
      shaped
      dense
      dark
      type="warning"
    >
      <v-row align="center">
        <v-col class="grow">
          You need to submit your feedback for the following assessments:
          <ul>
            <li v-for="text in bannerText">
              <div :class="textColor(text.expired)"><strong>{{ text.test }}</strong> due by {{ text.expiry }}</div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-alert>
    </div>
</template>

<script>
export default {
  name: "TaskBanner",
  props: {
    studentID: Number,
  },
  mounted() {
    this.getTasks()
  },
  data() {
    return {
        showBanner: false,
        data: []
    };
  },
  methods: {

    getTasks(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/38",
        params: {
          UniqueID: this.studentID
        },
        responseType: "json",
      })
        .then((response) => {
          this.data = response.data.filter(a=>a.Cnt==0)
          if (this.data.length>0){
            this.showBanner = true
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    textColor(t){
      if (t==true){
        return "red--text text--darken-4"
      }
      else{
        return "white--text"
      }
    },
    
  },
  computed: {

    bannerText(){
      let retval = []
      let item = {}
      for (let i=0;i<this.data.length;i++){
        item.test = this.data[i].TestName
        let date = new Date(this.data[i].Expiry)
        let now = new Date()
        item.expiry = date.toDateString()
        if (date<now){
          item.expired = true
          item.expiry = item.expiry+" (Overdue!)"
        }
        else{
          item.expired = false
        }
        
        retval.push(item)
      }
      return retval
    },
    
  },
  watch: {
    tasks: function () {
      
    },
  },
};
</script>