<template>
  <v-container>
    <v-alert v-if="submitMsg != ''" dense text type="success">
      {{ submitMsg }}
    </v-alert>
    <search
      :input="assessments"
      searchfield="TestName"
      @search="searchArray"
    ></search>
    <v-dialog v-model="editTrigger" persistent>
      <edit-assessment
        v-if="editTrigger"
        :testid="selectedtest.id"
        :testname="selectedtest.name"
        @cancel="editTrigger = false"
      ></edit-assessment>
    </v-dialog>
    <v-dialog v-model="CSVTrigger" persistent>
      <v-card>
        <v-card-title> File Upload: </v-card-title>
        <v-card-text>
          <v-file-input
            chips
            accept="text/csv"
            show-size
            label="Select CSV File for Import..."
            v-model="fileName"
          ></v-file-input>
          Download template file:
          <a
          :href="getLink+'assessment_upload.csv'"
            >Click Here</a
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="lime" dark @click="CSVTrigger = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="lime"
            dark
            :disabled="fileName == null"
            @click="upload()"
          >
            Upload
          </v-btn>

          <v-alert v-if="errorMsg != ''" dense outlined type="error">
            {{ errorMsg }}
          </v-alert>
          <v-alert v-if="submitMsg != ''" dense outlined type="success">
            {{ submitMsg }}
          </v-alert>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="CSVTrigger2" persistent>
      <v-card>
        <v-card-title> Check data before import </v-card-title>
        <v-card-text>
          Warning: This will overwrite all previously saved question data for
          this assessment.
          <v-data-table
            :headers="headers"
            :items="uploadData"
            :items-per-page="10"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="lime" dark @click="CSVTrigger2 = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="lime"
            dark
            :disabled="fileName == null"
            @click="saveUpload(selectedAssessment)"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col md="5">
      <v-card class="mx-auto my-2" width="374" elevation="1">
        <template v-if="this.newTest == false">
          <v-card-actions class="justify-center">
            <v-btn
              class="mx-auto my-auto"
              fab
              dark
              x-large
              color="lime"
              @click="newTest = true"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-title class="justify-center">Add New Assessment</v-card-title>
        </template>
        <template v-if="this.newTest">
          <v-text-field
            label="Add New Assessment"
            v-model="newTestName"
          ></v-text-field>
          <v-card-actions>
            <v-btn color="lime" dark @click="addTest(newTestName)"> Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="lime" dark @click="newTest = false"> Cancel</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-col>
    <v-expansion-panels mandatory>
      <v-expansion-panel
        v-for="assessment in assessments2"
        :key="assessment.TestID"
      >
        <v-expansion-panel-header>
          <template v-if="assessment.TestID != rename">
            <v-card-title>{{ assessment.TestName }}</v-card-title>

            <template v-if="assessment.Allowinput == 0">
              (Assessment hidden)</template
            >
          </template>

          <template v-if="assessment.TestID == rename">
            <v-text-field v-model="assessment.TestName"> </v-text-field>
            <v-spacer></v-spacer>

            <v-btn
              color="lime"
              dark
              @click="RenameSave(assessment.TestName, assessment.TestID)"
              >Save
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-actions>
            <v-btn
              color="lime"
              dark
              @click="editTest(assessment.TestID, assessment.TestName)"
            >
              Modify/View</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="lime" dark @click="CSVUpload(assessment.TestID)">
              CSV Upload</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="lime" dark @click="Rename(assessment.TestID)">
              Rename</v-btn
            >
          </v-card-actions>
          <v-switch
            color="lime"
            v-model="assessment.AllowinputBool"
            label="Visible?"
            @click="toggleVisible(assessment.TestID)"
          ></v-switch>

          <confirm-action
            btntext="Delete"
            Title="Confirm Delete?"
            Message="Warning: This assessment and all student results, and SoW mappings associated with it will be removed. Are you sure you wish to continue?"
            @confirm="deleteTest(assessment.TestID)"
          ></confirm-action>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ConfirmAction from "@/components/admin/ConfirmAction";
import EditAssessment from "@/components/admin/EditAssessment";
import Search from "@/components/admin/Search";
export default {
  name: "AdminAssessments",
  components: {
    ConfirmAction,
    EditAssessment,
    Search,
  },
  data: () => ({
    assessments: [],
    rename: null,
    newTest: false,
    newTestName: "",
    selectedtest: {},
    editTrigger: false,
    assessments2: [],
    CSVTrigger: false,
    CSVTrigger2: false,
    fileName: null,
    errorMsg: "",
    submitMsg: "",
    selectedAssessment: null,
    uploadData: [],
    headers: [
      { text: "Question Number", value: "Question No" },
      { text: "Topic", value: "Topic" },
      { text: "Max Mark", value: "Max Mark" },
    ],
  }),
  mounted() {
    this.getAssessments();
  },
  props: {},
  computed: {
    getLink(){
      return process.env.VUE_APP_CSV
    }
  },
  methods: {
    getAssessments() {
      this.assessments = [];
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/5",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.assessments = response.data;
          for (let i = 0; i < this.assessments.length; i++) {
            if (this.assessments[i].Allowinput == 1) {
              this.assessments[i].AllowinputBool = true;
            } else {
              this.assessments[i].AllowinputBool = false;
            }
          }
          this.assessments2 = this.assessments;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleVisible(e) {
      let i = this.getKey(e);
      let b = null;
      if (this.assessments[i].AllowinputBool == true) {
        b = 1;
        this.assessments[i].Allowinput = 1;
      } else {
        this.assessments[i].Allowinput = 0;
        b = 0;
      }
      //this.getAssessments()
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/admin/" + e,
        data: {
          table: "TestData",
          values: { Allowinput: b },
        },
        responseType: "json",
      }).catch((error) => {
        console.log(error);
      });
    },
    getKey(e) {
      let retval = null;
      for (let i = 0; i < this.assessments2.length; i++) {
        if (e === this.assessments2[i].TestID) {
          retval = i;
        }
      }
      return retval;
    },
    Rename(e) {
      this.rename = e;
    },
    RenameSave(name, id) {
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/admin/" + id,
        data: {
          table: "TestData",
          values: { TestName: name },
        },
        responseType: "json",
      })
        .then((response) => {
          //console.log(response.data);
          this.rename = null;
          this.getAssessments();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteTest(e) {
      //console.log(e)
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + e,
        data: {
          table: "TestData",
          //SchoolID: 1,
        },
        responseType: "json",
      })
        .then(() => {
          this.getAssessments();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addTest(name) {
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "TestData",
          data: [
            {
              keyval: { TestID: null },
              values: {
                SubjectID: this.$store.getters.getSubject,
                TestName: name,
              },
            },
          ],
        },
        responseType: "json",
      })
        .then(() => {
          //console.log(response.data)
          this.newTest = false;
          this.getAssessments();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTest(e, name) {
      this.selectedtest = { id: e, name: name };
      this.editTrigger = true;
    },
    searchArray(v) {
      this.assessments2 = v;
    },
    CSVUpload(assessment) {
      this.CSVTrigger = true;
      this.selectedAssessment = assessment;
    },
    upload() {
      this.uploadData = [];
      let self = this;
      this.$papa.parse(this.fileName, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: "greedy",
        complete: function (results) {
          //console.log(results.data)
          self.checkUpload(results.data);
          let chk = self.checkUpload(results.data);
          //console.log(chk)
          if (chk == true) {
            //console.log('here')
            self.uploadData = results.data;
            self.CSVTrigger = false;
            self.CSVTrigger2 = true;
          } else {
            self.fileName = null;
          }
          //use self to access functions
          //remove all entries for current assessment
          //check headers match requested format
          //check question numbers are unique, maxmark is an integer >0
        },
        error: function () {
          this.fileName = null;
          console.log("Parsing errors");
        },
      });
    },
    checkUpload(data) {
      let chk = true;
      if (data[0]["Question No"] && data[0]["Topic"] && data[0]["Max Mark"]) {
        chk = true;
      } else {
        chk = false;
        this.errorMsg = "The upload file does not meet the required format.";
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i]["Question No"] != i + 1) {
          chk = false;
          this.errorMsg =
            "Questions are not numbered sequentially. Please check your upload file.";
        }
        if (
          data[i]["Max Mark"] < 1 ||
          Number.isInteger(data[i]["Max Mark"]) == false
        ) {
          chk = false;
          this.errorMsg =
            "Max mark needs to be an integer greater than 0. Please check your upload file.";
        }
      }
      //console.log(data)
      return chk;
    },
    saveUpload(assess) {
      let uploadArray = [];
      for (let i = 0; i < this.uploadData.length; i++) {
        uploadArray.push({
          keyval: { QuestionID: null },
          values: {
            QuestionNumber: this.uploadData[i]["Question No"],
            TestTopic: this.uploadData[i]["Topic"],
            MarksAvailable: this.uploadData[i]["Max Mark"],
            TestID: assess,
          },
        });
      }
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + assess,
        data: {
          table: "QuestionData",
          altKey: "TestID",
          //SchoolID: 1,
        },
        responseType: "json",
      })
        .then(() => {
          this.$http({
            method: "post",
            url: process.env.VUE_APP_BACKEND + "/admin",
            data: {
              table: "QuestionData",
              data: uploadArray,
            },
            responseType: "json",
          })
            .then(() => {
              this.submitMsg = "Data Uploaded Successfully";
              this.CSVTrigger2 = false;
              //this.getSum();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
      //console.log(uploadArray)
    },
  },
};
</script>
