<template>
    <div>
        <v-card>
          <ampil-card title="Terminal Exams" @tab="changeTab" :items="menu"></ampil-card>
          <v-card-text>
            <v-select
              v-model="selectedEndpoint"
              :items="endpoints"
              item-text="EndpointName"
              item-value="EndpointID"
              label="Select Terminal Exam for entry..."
            ></v-select>
            <prior-manual v-if="tab==1 && selectedEndpoint!=null" :endpoint="selectedEndpoint" :year="$store.getters.getYeargroup"></prior-manual>
            <prior-csv v-if="tab==2 && selectedEndpoint!=null" :endpoint="selectedEndpoint" :year="$store.getters.getYeargroup"></prior-csv>
          </v-card-text>
        </v-card>
        
    </div>
</template>

<script>
import PriorManual from "@/components/leader/PriorManual";
import PriorCsv from "@/components/leader/PriorCsv";
export default {
  name: "LeaderPrior",
  components: {
    PriorManual,
    PriorCsv
  },
  props: {},
  mounted() {
    this.getPriorType()
  },
  data() {
    return {
      menu: [
        { id: 1, name: "Enter data manually" },
        { id: 2, name: "Upload CSV" },
      ],
      tab: 1,
      endpoints: [],
      selectedEndpoint: null,
    };
  },
  methods: {
    changeTab(i){
      this.tab = i;
    },
    getPriorType(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/5",
        params: {
          YeargroupID: this.$store.getters.getYeargroup,
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
            this.endpoints=response.data
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {
    getYear(){
      return this.$store.getters.getYeargroup
    }
  },
  watch: {
    getYear: function () {
      this.selectedEndpoint=null
      this.getPriorType()
    },
  },
};
</script>