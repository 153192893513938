import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueCookies from "vue-cookies";
import vuetify from "./plugins/vuetify";
import VuePapaParse from "vue-papa-parse";
import AmpilCard from "@/components/AmpilCard.vue";
import DialogModal from "@/components/DialogModal.vue";
//import ErrorHandler from "@/components/ErrorHandler.vue";
//import Tabulator from 'tabulator-tables'; 
import VueTabulator from 'vue-tabulator';
import * as Excel from "exceljs";
import streamSaver from 'streamsaver'
import Snotify from 'vue-snotify';
import 'vue-snotify/styles/material.css';
import LoadScript from 'vue-plugin-load-script';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from 'vue-gtag';
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID
  }
}, router);
Vue.use(VueReCaptcha, { siteKey: '6LcJ_BIaAAAAAC-iPfKj_2UR4kv8YQvUM4GTEZk6' });
Vue.use(VueCookies);
Vue.use(VuePapaParse);
Vue.use(VueTabulator);
Vue.use(streamSaver);
Vue.use(Snotify);
Vue.use(Excel);
Vue.use(LoadScript);
Vue.component("AmpilCard", AmpilCard);
Vue.component("DialogModal", DialogModal);
axios.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
//error reporting
axios.interceptors.response.use((response) => response, (error) => {
  // get user details if logged in
  let user = store.getters.getName||"unknown user"
  let school = store.getters.getSchool||"unknown school"
  let errorDetails = {user: user, school: school, status: error.response.status, statusText: error.response.statusText, file: error.response.data.file, line: error.response.data.line, message: error.response.data.message}
  if (error.response.status!=401){
    store.commit("setErrorMsg", errorDetails);
  }
  
});
Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
