<template>
    <v-container>
        <v-card>
            <v-alert
        v-if="displayMessage"
      dense
      text
      :type="message.type"
    >
      {{ message.message }}
    </v-alert>
            <v-card-title>
                Teacher final check
            </v-card-title>
            <v-card-text>

            
        
        <div v-for="task in tasks">
            <v-row>
                <v-col>
                    {{ task.SetName }}
                </v-col>
                <v-col v-if="task.Status>=5">
                     Submitted
                     <v-icon
                        color="lime"
                    >
                    mdi-checkbox-marked-circle
                    </v-icon>
                </v-col>
                <v-col v-else>
                     Not yet submitted
                </v-col>
                <v-col>
                    <v-btn color="lime" :disabled="task.Status>=5"  @click="email(task)">Email reminder</v-btn>
                </v-col>
            </v-row>
        </div>
    </v-card-text>
    <v-card-actions>
        <v-btn color="lime" text @click="refresh()">Refresh</v-btn>
      </v-card-actions>
    </v-card>

    </v-container>
</template>

<script>
export default {
  name: "FeedbackT1",
  props: {
    tasks: Array
  },
  mounted() {},
  data() {
    return {
        message: {},
        displayMessage: false,
    };
  },
  methods: {
    refresh(){
      this.$emit('changed')
    },
    email(task){
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/email",
        data: {
          type: 2,
          teacher: task.TeacherID,
          class: task.SetName
        },
        responseType: "json",
      })
        .then((response) => {
          //console.log(response.data)
          this.message.type="success"
          this.displayMessage=true
            this.message.message=response.data.message
        })
        .catch((error) => {
            this.message.type="error"
            this.message.message=error.response.data.message
            this.displayMessage=true
          //console.log(error.response.data.message);
        });
    },
  },
  computed: {},
};
</script>
