<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red" text v-bind="attrs" v-on="on">
          {{ btntext }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          {{ Title }}
        </v-card-title>
        <v-card-text>{{ Message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog = false"> Cancel </v-btn>
          <v-btn color="red" text @click="Confirm()"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ConfirmAction",
  props: {
    id: Number,
    btntext: String,
    Title: String,
    Message: String,
  },
  methods: {
    Confirm() {
      this.dialog = false;
      this.$emit("confirm");
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
