<template>
  <v-container>
    <div>Loading...</div>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  components: {},
  data: () => ({}),
};
</script>
