<template>
    <v-container>
        <v-dialog v-model="dialog" width="90%" persistent>
      <template v-slot:activator="{ on, attrs }">
            <v-card class="mx-auto my-2" width="374" elevation="1" v-if="taskID==null">
              <v-card-actions class="justify-center">
                <v-btn
                  class="mx-auto my-auto"
                  fab
                  dark
                  x-large
                  color="lime"
                  @click="dialog = !dialog"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-title class="justify-center"
                >Add New Feedback Task</v-card-title
              >
            </v-card>
            <v-card class="mx-auto my-2" width="374" elevation="1" v-else>
                <div v-if="tasksLoaded">
              <ampil-card :title="tasks[0].TaskName"></ampil-card>
              <v-card-text>
                <v-alert
                text
                dense
                color="teal"
                icon="mdi-list-status"
     
                    >Feedback task completion status:
                    <ul>
                        <li>
                            {{  getStatus.text }}
                        </li>
                    </ul>
                    </v-alert>
              </v-card-text>
              <v-card-actions>
                <confirm-action @confirm="deleteTask()" Title="Delete Task" Message="This will remove the feedback task. Are you sure you want to continue?" btntext="Delete"></confirm-action>
                <v-spacer></v-spacer>
                <v-btn color="lime" text @click="dialog = !dialog">Load</v-btn>
              </v-card-actions>
                </div>
            </v-card>

      </template>

      <v-card v-if="tasksLoaded">
        <ampil-card
          :title="tasks[0].TaskName"
        ></ampil-card>
        <v-stepper v-model="stepper" vertical>
            <v-stepper-step :complete="stepper > 1" step="1" color="lime">Assign assessments to classes:<small></small></v-stepper-step>
            <v-stepper-content step="1">
                <v-card>
                    <v-card-text>
                        <feedback-select @newTask="newTask" @newTaskID="newTID" @saved="stepper = 2" :taskID="taskID" :yeargroupID="yeargroupID" :tasks="tasks"></feedback-select>
                    </v-card-text>
                </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 2" step="2" color="lime">Upload assessment totals<small>To begin the feedback task, you need to upload the students total scores for this assessment. This will be used for the ranking, and to verify the student QLA input.</small></v-stepper-step>
            <v-stepper-content step="2">
                <v-card>
                    <v-card-text>
                        <feedback-totals @changed="getTasks(false)" v-if="stepper==2" :taskID="taskID"></feedback-totals>
                    </v-card-text>
                </v-card>
                <v-btn :disabled="getStatus.status<1" color="lime" dark @click="stepper = 3">Continue</v-btn>
                <v-btn color="lime" @click="stepper = 1" text>Back</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 3" step="3" color="lime">Initial ranking by department lead<small>To perform a ranking on a yeargroup, at least 90% of the students must have prior attainment data uploaded and the selected tests must have uploaded totals</small></v-stepper-step>
            <v-stepper-content step="3">
                <v-card>
                    <v-card-text>
                        <feedback-initial @changed="getTasks(false)"  v-if="stepper==3" :taskID="taskID" :yeargroupID="yeargroupID"></feedback-initial>
                    </v-card-text>
                </v-card>
                <v-btn :disabled="getStatus.status<2" color="lime" dark @click="stepper = tInput1()">Continue</v-btn>
                <v-btn color="lime" @click="stepper = 2" text>Back</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 4" step="4" color="lime"><div v-if="tInput1()==5" class="text-decoration-line-through">Teaching staff to check initial ranking</div><div v-else>Teaching staff to check initial ranking</div><small>Optional: before allocating a grade profile you can request teaching staff to rank the students in their own class</small></v-stepper-step>
            <v-stepper-content step="4">
                <v-card>
                    <v-card-text>
                        <feedback-t1 @changed="getTasks(false)"  v-if="stepper==4" :tasks="tasks"></feedback-t1>
                    </v-card-text>
                </v-card>
                <v-btn :disabled="getStatus.status<3" color="lime" dark @click="stepper = 5">Continue</v-btn>
                <v-btn color="lime" @click="stepper = 3" text>Back</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 5" step="5" color="lime">Allocate grade profile<small></small></v-stepper-step>
            <v-stepper-content step="5">
                <v-card>
                    <v-card-text>
                        <feedback-grades @changed="getTasks(false)"  v-if="stepper==5" :taskID="taskID" :yeargroupID="yeargroupID"></feedback-grades>
                    </v-card-text>
                </v-card>
                <v-btn :disabled="getStatus.status<4" color="lime" dark @click="stepper = tInput3()">Continue</v-btn>
                <v-btn color="lime" @click="stepper = tInput2()" text>Back</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 6" step="6" color="lime"><div v-if="tInput3()==7" class="text-decoration-line-through">Teaching staff to check grades</div><div v-else>Teaching staff to check grades</div><small>Optional: Teaching staff will check the grades that have been allocated to their class, and have the option to leave a comment for the department lead when checking the final grades</small></v-stepper-step>
            <v-stepper-content step="6">
                <v-card>
                    <v-card-text>
                        <feedback-t2 @changed="getTasks(false)"  v-if="stepper==6" :tasks="tasks"></feedback-t2>
                    </v-card-text>
                </v-card>
                <v-btn :disabled="getStatus.status<5" color="lime" dark @click="stepper = 7">Continue</v-btn>
                <v-btn color="lime" @click="stepper = 5" text>Back</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 7" step="7" color="lime">Final Check<small>Check and amend any of the ranking data. Here you can view any of the comments that the teaching staff have posted regarding individual students</small></v-stepper-step>
            <v-stepper-content step="7">
                <v-card>
                    <v-card-title>
                        Final Check and amendments
                    </v-card-title>
                    <v-card-text>
                        <feedback-check v-if="stepper==7" @done="closeDialog()" :taskID="taskID" :yeargroupID="yeargroupID"></feedback-check>
                    </v-card-text>
                </v-card>
                <v-btn color="lime" @click="stepper = tInput4()" text>Back</v-btn>
            </v-stepper-content>

        </v-stepper>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="lime" text @click="closeDialog()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
  </template>
  
  <script>
    import FeedbackSelect from "@/components/leader/FeedbackSelect.vue";
    import FeedbackTotals from "@/components/leader/FeedbackTotals.vue";
    import FeedbackInitial from "@/components/leader/FeedbackInitial.vue";
    import FeedbackT1 from "@/components/leader/FeedbackT1.vue";
    import FeedbackT2 from "@/components/leader/FeedbackT2.vue";
    import FeedbackGrades from "@/components/leader/FeedbackGrades.vue";
    import FeedbackCheck from "@/components/leader/FeedbackCheck.vue";
    import ConfirmAction from "@/components/admin/ConfirmAction.vue";
  export default {
    name: "Testing",
    components: {
      FeedbackSelect,
      FeedbackTotals,
      FeedbackInitial,
      FeedbackT1,
      FeedbackT2,
      FeedbackGrades,
      FeedbackCheck,
      ConfirmAction,
    },
    props: {
        taskID: Number,
        yeargroupID: Number,
    },
    data: () => ({
        //taskID: 26,
        stepper: 1,
        //yeargroupID: 4,
        tasksLoaded: false,
        dialog: false,
        datePicker: false,
        tasks: [{
            TaskName: "",
        }],
        submittable: true,
        rules: {
          name: [val => (val || '').length > 0 || 'This field is required'],
          date: [val => (val || '').length > 0 || 'This field is required'],
        },
        sets: [],
        tests: [],
        selectedTest: [],
    }),
    watch: {

    },
    mounted() {
        //this.$store.commit('setYeargroup', this.yeargroupID)
        if (this.taskID!=null){
            this.getTasks(true)
            
        }
        else{
            this.tasks[0].Expiry = this.dateRange().def
            this.tasksLoaded=true
            //this.getTests()
        }
        //this.getSets()
        
        
    },
    methods: {
        closeDialog(){
            this.dialog=false
            this.$emit('tasksChanged')
        },
        deleteTask(){
            this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/leader/"+this.taskID,
        data: {
          table: "FeedbackTasks",
        },
        responseType: "json",
      })
        .then((response) => {
            this.$emit('tasksChanged')
        })
        .catch((error) => {
          console.log(error);
        });
        },
        closeWindow(){
            this.dialog=false
        },
        tInput1(){
            if (this.tasks[0].TeacherInput ==1){
                return 4
            }
            else{
                return 5
            }
        },
        tInput2(){
            if (this.tasks[0].TeacherInput ==1){
                return 4
            }
            else{
                return 3
            }
        },
        tInput3(){
            if (this.tasks[0].TeacherInput ==1){
                return 6
            }
            else{
                return 7
            }
        },
        tInput4(){
            if (this.tasks[0].TeacherInput ==1){
                return 6
            }
            else{
                return 5
            }
        },
        newTID(s){
            this.taskID = s


        },
        newTask(s){
            this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/feedback",
        params: {
          name: s.name,
          task_id: s.task_id,
          expiry: s.expiry,
        },
        responseType: "json",
      })
        .then((response) => {
          
        })
        .catch((error) => {
          console.log(error);
        });
        },
        mapTests(){
            //console.log(this.tasks)
            for (let i=0;i<this.tasks.length;i++){
                this.selectedTest[this.tasks[i].SetID] = this.tasks[i].TestID
            }
        },
        selectTest(s){
            const result = this.tests.filter(({ SetID }) => SetID === s);
            return result
        },

        getTasks(flag){
            this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/23",
        params: {
          TaskID: this.taskID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.tasks = response.data
            this.tasksLoaded=true
            //this.getTests()
            if (flag==true){
                this.changeStatus()
            }
            
          
        })
        .catch((error) => {
          console.log(error);
        });
        },
        dateRange(){
            let min = new Date(Date.now())
            let max = new Date((Date.now() + 30 * 24 * 60 * 60 * 1000))
            let def = new Date((Date.now() + 14 * 24 * 60 * 60 * 1000))
            let retval = {}
            retval.min = min.toISOString().slice(0, 10).toString()
            retval.max = max.toISOString().slice(0, 10).toString()
            retval.def = def.toISOString().slice(0, 10).toString()
            return retval
        },
        mean(arr){
            return arr.reduce((acc, val) => acc + val, 0) / arr.length;
        },
        changeStatus(){
            if (this.getStatus.status>=0 && this.getStatus.status<1){
                this.stepper = 2
            }
            else if (this.getStatus.status==1){
                this.stepper = 3
            }
            else if (this.getStatus.status>=2 && this.getStatus.status<3){
                if (this.tasks[0].TeacherInput==1){
                    this.stepper = 4
                }
                else{
                    this.stepper = 5
                }
            }
            else if (this.getStatus.status==3){
                this.stepper = 5
            }
            else if (this.getStatus.status>=4 && this.getStatus.status<5){
                if (this.tasks[0].TeacherInput==1){
                    this.stepper = 6
                }
                else{
                    this.stepper = 7
                }
            }
            else if (this.getStatus.status>=5){
                this.stepper = 7
            }
        }
    },
    computed: {
        getYeargroup(){
            return this.$store.getters.getYeargroup
        },
        getSubject(){
            return this.$store.getters.getSubject
        },
        getStatus(){
            let arr = this.tasks.map(e=> e.Status).filter(f => f!=null);
            let st = this.mean(arr)
            let retval = {}
            if (st>=0 && st<1){
                retval.text = "Step 1) Awaiting Assessment total scores. Either upload these yourself, or ask teaching staff to do this for you"
            }
            else if (st==1){
                retval.text = "Step 2) All assessment totals uploaded. Awaiting initial ranking..."
            }
            else if (st>=2 && st<3){
                if (this.tasks[0].TeacherInput==1){
                    retval.text = "Step 3) Initial Ranking complete. Awaiting teaching staff checking their class rankings..."
                }
                else{
                    retval.text = "Step 3) Initial Ranking complete. Awaiting mapping of grades..."
                }
                
            }
            else if (st==3){
                retval.text = "Step 4) Initial Ranking complete and checked by all teaching staff. Awaiting mapping of grades..."
            }
            else if (st>=4 && st<5){
                if (this.tasks[0].TeacherInput==1){
                    retval.text = "Step 5) Grades allocated. Awaiting final check from teaching staff..."
                }
                else{
                    retval.text = "Step 5) Grades allocated. Awaiting final checks..."
                }
            }
            else if (st==5){
                retval.text = "Step 6) Grades allocated and checked by teaching staff. Awaiting final checks..."
            }
            else if (st==6){
                retval.text = "Feedback Task complete! You may now delete this task"
            }
            //status 0 totals not uploaded
            
            //status 1 totals uploaded
            //status 2 initial ranking complete
            //status 3 teacher checked
            //status 4 grades allocated
            //status 5 teacher checked
            //status 6 complete
            retval.status = st
            return  retval
        }
    },
    watch: {
        stepper: function () {
            this.getTasks(false)
        },
        dialog: function () {
            if (this.dialog==false){
                this.tasksLoaded = false
                //this.getTasks(true)
            }
        },
    },
  }
  </script>