<template>
  <v-container>
    <div
      class="home"
      v-if="this.loaded && this.value.length > 0 && this.total != null"
    >
      <v-card outlined>
        <v-toolbar
          src="/images/slide_01.jpg"
          dark
          color="grey darken-3"
          class="text-h5"
        >
          What {{ endname }} grade can I expect...
        </v-toolbar>
        <v-card-text>
          The graph below shows the final {{ endname }} grades that previous
          students who scored between {{ tolerance.min }} and
          {{ tolerance.max }} marks on this assessment achieved.
        </v-card-text>
        <v-sheet color="rgba(0, 0, 0, 0)">
          <v-sparkline
            :labels="label"
            :value="value"
            color="rgba(255, 255, 255, .7)"
            :gradient="['green', '#ffd200', '#f72047']"
            height="100"
            padding="24"
            stroke-linecap="round"
            smooth
            type="bar"
          >
          </v-sparkline>
        </v-sheet>
      </v-card>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
//mia baker
import axios from "axios";
axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_AUTH_KEY;
export default {
  name: "GradeGraph",
  components: {},
  data: () => ({
    loaded: false,
    endname: "",
    tolerance: {},
    value: [],
    label: [],
    total: null,
  }),
  mounted() {
    this.loaded = false;
  },
  watch: {
    getStudent() {
      if (this.getStudent.Year > 0) {
        this.getpredict();
      }
    },
  },
  methods: {
    getpredict() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/36",
        params: {
          SubjectID: this.getStudent.SubjectID,
          YearSat: this.getStudent.Year,
        },
        responseType: "json",
      })
        .then((response) => {
          let min = 0;
          let max = 0;
          for (let i = 0; i < this.getStudent.Tests.length; i++) {
            if (
              this.getStudent.Tests[i].ID == this.$store.getters.getSelectedTest
            ) {
              let tot = this.getStudent.Tests[i].Total;
              if (!isNaN(tot)) {
                this.total = tot;
                min = tot * 0.9;
                max = tot * 1.1;
                this.tolerance.min = parseInt(min);
                this.tolerance.max = parseInt(max);
              }

              //max=tot*1.1
            }
          }
          let e = response.data[0].EndpointID;
          //e=1
          this.endname = response.data[0].EndpointName;
          this.$http({
            method: "get",
            url: process.env.VUE_APP_BACKEND + "/student/37",
            params: {
              EndpointID: e,
              TestID: this.$store.state.selectedTest,
              Lower: min,
              Upper: max,
            },
            responseType: "json",
          })
            .then((response) => {
              //response.data.sort(function(a, b){return Number(a)-Number(b)});
              this.value = [];
              this.label = [];
              for (let i = 0; i < response.data.length; i++) {
                this.value.push(response.data[i].Freq);
                this.label.push(response.data[i].EndScore);
              }
              this.loaded = true;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    getSchool() {
      return this.$store.getters.getSchool;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
  },
};
</script>
