<template>
    <div>
      <v-card>
      <v-card-title> File Upload:</v-card-title>
      <v-card-text>
        
        <v-file-input
          chips
          accept="text/csv"
          show-size
          label="Select CSV File for Import..."
          v-model="fileName"
        ></v-file-input>
        Download template file:
        <a :href="getLink+'results_upload.csv'"
          >Click Here</a
        >
      </v-card-text>
      <v-card-actions>
        <v-btn color="lime" dark :disabled="fileName == null" @click="upload()">
          Upload
        </v-btn>
        <v-alert v-if="errorMsg != ''" dense outlined type="error">
          {{ errorMsg }}
        </v-alert>
        <v-alert v-if="submitMsg != ''" dense outlined type="success">
          {{ submitMsg }}
        </v-alert>
        <v-card-actions v-if="toUpload.length>0">
            <dialog-modal type="outline" buttonText="View Upload Data" title="Upload Data:" :dataTable="tableOutput" :sortBy="['SetName','LastFirst']"></dialog-modal>
            <v-spacer></v-spacer>
            <v-btn v-if="ready==true" color="lime" outlined text @click="submit()">Commit to DB</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn v-if="submitValid" color="lime" outlined text @click="sortData()">Confirm Assessments</v-btn>
        </v-card-actions>
      </v-card-actions>
      
      <v-alert
            v-if="submitted"
            dense
            text
            type="success"
        >
        Data uploaded successfully!
        </v-alert>
      <div v-if="unmatchedStudents.length>0">
            <v-alert
                dense
                outlined
                type="error"
                >
                {{ unmatchedStudents.length }} student(s) from the upload file cannot be found in the selected group, check the classes match
                <div class="text-right">
                    <dialog-modal type="error" buttonText="Show" title="The following students could not be found in the selected group:" :text="prepareUnmatched(unmatchedStudents)"></dialog-modal>
                </div>
            </v-alert>
            
        </div>
    </v-card>
    </div>
</template>

<script>
export default {
  name: "EnterQlaCsv",
  props: {
    test: Number,
    set: Number,
  },
  mounted() {
    this.getAllStudents()
    this.getAllResults()
  },
  data() {
    return {
      fileName: null,
      errorMsg: '',
      submitMsg: '',
      toUpload: [],
      tableOutput: {},
      submitValid: false,
      submitted: false,
      unmatchedStudents: [],
      allStudents: [],
      allResults: [],
      ready: false,
      checkFile: [
        "StudentID",
        "LastFirst",
        "Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7", "Q8", "Q9", "Q10", "Q11", "Q12", "Q13", "Q14", "Q15", "Q16", "Q17", "Q18", "Q19", "Q20", "Q21", "Q22", "Q23", "Q24", "Q25", "Q26", "Q27", "Q28", "Q29", "Q30", "Q31", "Q32", "Q33", "Q34", "Q35", "Q36", "Q37", "Q38", "Q39", "Q40", "Q41", "Q42", "Q43", "Q44", "Q45", "Q46", "Q47", "Q48", "Q49", "Q50",
      ],
      uploadData: [],

    };
  },
  methods: {
    submit(){
        this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/teacher",
          data: {
            table: "QuestionResults",
            data: this.toUpload
          },
          responseType: "json",
        })
          .then((response) => {
            this.ready = false
            this.submitted = true
          })
          .catch((error) => {
            console.log(error);
          });
    },
    prepareUnmatched(input){
        let out = ''
        for (let i=0;i<input.length;i++){
            out = out + input[i].LastFirst + "(" + input[i].StudentID + "), "
        }
        return out
    },
    getAllStudents() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/13",
        params: {
          SetID: this.set,
        },
        responseType: "json",
      })
        .then((response) => {
          this.allStudents = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllResults() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/12",
        params: {
          TestID: this.test,
          SetID: this.set
        },
        responseType: "json",
      })
        .then((response) => {
          this.allResults = response.data;
          //console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkFileValid(d) {
      let h = Object.keys(d.data[0]);
      let ret = true;
      let isInt
      for (let i = 0; i < h.length; i++) {
        if (h[i] != this.checkFile[i]) {
          this.errorMsg =
            "The upload file is invalid. Please check you have uploaded using the correct template.";
          ret = false;
        }
      }
      //check all data is integers
      for (let j=0;j<d.data.length;j++){
        for (let k=1;k<51;k++){
            isInt = Number.isInteger(d.data[j]['Q'+k])
            if (isInt==false && d.data[j]['Q'+k]!=null){
                this.errorMsg =
                "The upload file contains non numeric data. Please check before uploading again.";
                ret = false
            }
        }
      }
      return ret;
    },
    clearAll() {
      this.totals = [];
      this.sets = [];
      this.uploadData = []
      this.matchedStudents = []
      this.unmatchedStudents = []
      this.selectedTests = []
      this.ready2 = false
      this.submitted = false
    },
    upload() {
      let self = this;
      this.$papa.parse(this.fileName, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: "greedy",
        complete: function (results) {
          self.fileName = null;
          if (self.checkFileValid(results)) {
            self.clearAll();
            self.uploadData = results.data;
            //check students exist
            self.errorMsg = "";
            self.validateStudents()
            

            self.uploadCheck = true;
          } else {
            self.uploadCheck = false;
          }
        },
        error: function () {
          this.fileName = null;
          console.log("Parsing errors");
        },
        
      });
    },
    validateStudents(){
        let match
        for (let i=0;i<this.uploadData.length;i++){
            match = this.allStudents.find(({ StudentID }) => StudentID === this.uploadData[i].StudentID);
            if (match!=undefined){
                let toAdd = this.uploadData[i]
                toAdd.UniqueID = match.UniqueID
                toAdd.LastFirst = match.LastFirst
                this.matchedStudents.push(toAdd)
            }
            else {
                this.unmatchedStudents.push(this.uploadData[i])
            }
        }
        //console.log(this.matchedStudents, this.unmatchedStudents)
        this.matchData()
        
    },
    matchData(){
      this.ready = false
      let match
      let result
      let lastqn = this.allResults[(this.allResults.length-1)].QuestionNumber
      let qns = []
      let error = false
      let tableData = []
      let headers = [
            {text: "ID", value: "StudentID", groupable: false},
            {text: "Name", value: "LastFirst", groupable: false},
        ]
      for (let i=1;i<=lastqn;i++){
        qns.push("Q"+i)
        headers.push({text: "Q"+i, value: "Q"+i, groupable: false})
      }
      for (let j=0;j<this.matchedStudents.length;j++){
        let addStudent = {StudentID: this.matchedStudents[j].StudentID, LastFirst: this.matchedStudents[j].LastFirst}
        for (let k=0;k<qns.length;k++){
          if (this.matchedStudents[j][qns[k]]==null){
            result = 0
          }
          else{
            result = this.matchedStudents[j][qns[k]]
          }
          //match to existing records
          match = this.allResults.find(obj => obj.UniqueID == this.matchedStudents[j].UniqueID && obj.QuestionNumber == (k+1))
          //check if values are above the maximum
          if (result>match.MarksAvailable){
            error = true
            
            this.errorMsg =
                match.LastFirst+" has a submitted result of "+result+" for Q"+match.QuestionNumber+" which is higher than the maximum allowed mark of "+match.MarksAvailable;
          }
          //create toUpload
          this.toUpload.push({keyval: {ResultID: match.ResultID || null}, values: {MarkAwarded: result, QuestionID: match.QuestionID, StudentID: match.UniqueID, EnteredStatus: 2}})
          //create tableOutput
          addStudent[qns[k]] = result
          //console.log(match)
        }
        tableData.push(addStudent)
      }
      this.tableOutput.headers = headers
      this.tableOutput.values = tableData
      if (error == false){
        this.ready = true
      }
      
    }
  },
  computed: {
    getLink(){
      return process.env.VUE_APP_CSV
    }
  },
};
</script>
