<template>
    <v-container>
        <v-row>
            <v-col v-if="PAD.Ratio<90" class="text-center">
                Less than 90% of this yeargroup have prior attainment data uploaded
                <v-btn color="error" @click="$router.push('/leader/prior')">Upload {{ PAD.Type }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-autocomplete
            v-model="selectedTests"
            :items="availableTests"
            item-value="TestID"
            item-text="TestName"
            color="lime"
            outlined
            dense
            chips
            small-chips
            label="Select all assessments for ranking to be based on"
            multiple
          >
          <template v-slot:item="data">
                <template>
                  <v-list-item-content v-text="data.item.TestName+' ['+data.item.Cnt+' student(s) ]'"></v-list-item-content>
                </template>
          </template>
        </v-autocomplete>
        </v-row>
        <v-row>
            <v-col class="text-center">
                <v-btn color="lime" :disabled="PAD.Ratio<90 || selectedTests.length<1" @click="rank()">Perform initial ranking</v-btn>
            </v-col>
        </v-row>
        <v-alert
            v-if="done"
            dense
            text
            type="success"
        >
        Data uploaded successfully!
        </v-alert>
        <v-dialog v-model="showRank" persistent>
            <v-card>
                <ampil-card title="Initial Ranking"></ampil-card>
                <v-card-title>Drag and drop the students into the desired rank order</v-card-title>
                <v-card-text>
                    <v-simple-table class="table table-striped" fixed-header height="500px">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">Name</th>
                                <th scope="col">Set</th>
                                <th scope="col">Prior Data</th>
                                <th scope="col">Score</th>
                                <th v-for="test in markbook.tests" scope="col">{{test.TestName}}</th>
                            </tr>
                        </thead>
                    <draggable v-model="markbook.data" tag="tbody" group="UniqueID" @start="drag = true" @end="drag = false;numbering();">
                        <tr v-for="(student, index) in markbook.data" :key="index" :id="student.UniqueID">
                            <td scope="row">{{ student.Ranking }}</td>
                            <td>{{ student.LastFirst }}</td>
                            <td :bgcolor="setColors[student.SetID]">{{ student.SetName }}</td>
                            <td>{{ student.Prior }}</td>
                            <td><b>{{ Math.round(student.Score)}}</b></td>
                            <td v-for="test in markbook.tests">{{ student[test.TestID] }}</td>

                        </tr>
                    </draggable>
                    </v-simple-table>            
                </v-card-text>
                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn color="lime" text @click="submitRank()">Done</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import Markbook from '@/mixins/Markbook'
export default {
  name: "FeedbackInitial",
  mixins: [Markbook],
  props: {
    yeargroupID: Number,
    taskID: Number
  },
  components: {
    draggable
  },
  mounted() {
    this.checkPAD()
    this.getAvailableTests()
    this.getAllData()
    this.getStudents()
  },
  data() {
    return {
        availableTests: [],
        selectedTests: [],
        PAD: {},
        allData: [],
        testStats: [],
        PADStats: {},
        allPAD: [],
        allStudents: [],
        uploadData: [],
        markbook: [],
        showRank: false,
        setColors: [],
        done: false,
    };
  },
  methods: {
    randomColor(){
        return "#"+Math.floor(Math.random()*16777215).toString(16);
    },
    submitRank(){
        this.uploadData = []
        for (let i=0;i<this.markbook.data.length;i++){
            //this.markbook.data[i].Ranking = (i+1)
            this.uploadData.push({keyval: {RankID: null}, values: {YearID: this.yeargroupID, SetID: this.markbook.data[i].SetID, UniqueID: this.markbook.data[i].UniqueID, Ranking: this.markbook.data[i].Ranking, Score: this.markbook.data[i].Score, Tests: "["+this.selectedTests.toString()+"]", Tflag: 0}})
            //this.uploadData.push({keyval: {RankID: null}, values: {YearID: this.yeargroupID, SetID: this.markbook.data[i].SetID, UniqueID: this.markbook.data[i].UniqueID, Ranking: this.markbook.data[i].Ranking, Score: this.markbook.data[i].Score, TRank: this.markbook.data[i].Ranking, Tests: "["+this.selectedTests.toString()+"]", Tflag: 0}})
        }
        //console.log(this.uploadData)
        this.clearRanking()
    },
    rank(){
        //tolerance = 3 (between 0.1 and 4)
        let tol = 3
        //get all student results
        let result = this.allData.map(a => a.Total).filter(b => b!=null);

         //get mean and sd of all PAD
        let ap = this.allPAD.map(e=> e.Std).filter(f => f!=null && f!="0");
        this.PADStats.EndpointID = this.PAD.EndID
        this.PADStats.Mean = this.mean(ap)
        this.PADStats.StDev = this.stDev(ap)
        this.PADStats.Max = this.PADStats.Mean + (tol*this.PADStats.StDev)
        this.PADStats.Min = this.PADStats.Mean - (tol*this.PADStats.StDev)
        this.PADStats.Diff = this.PADStats.Max - this.PADStats.Min
        this.PADStats.Diff2 = this.PADStats.StDev*2*tol/(ap.length);
        //get mean and sd of each test
        for (let i=0;i<this.selectedTests.length;i++){
            let t = this.availableTests.find(a=> a.TestID== this.selectedTests[i])
            let f = this.allData.filter(b=> b.TestID==this.selectedTests[i]).map(c=> c.Total).filter(d => d!=null);
            let Max = (this.mean(f)+(tol*this.stDev(f)))
            //let Min = (this.mean(f)-(tol*this.stDev(f))) //THIS IS WHERE MY OLD FORMULA MIGHT NOT WORK!!!
            let Min = (0-(tol*this.stDev(f)))
            //Pad By Test
            let Students = this.allData.filter(h=> h.TestID==this.selectedTests[i]).map(j=> j.UniqueID)
            let PBT = this.allPAD.filter(f => Students.some(item => item === f.UniqueID));
            let PBT2 = PBT.map(e=> e.Std).filter(f => f!=null && f!="0");
            let meanPBT = this.mean(PBT2)
            let multiplier = (((meanPBT - this.PADStats.Mean)/this.PADStats.Mean)/this.PADStats.Diff2)+1
            this.testStats.push({TestID: t.TestID, TestName: t.TestName, Mean: this.mean(f), StDev: this.stDev(f), Max: Max, Min: Min, Diff: Max-Min, Multiplier: multiplier})
        }

        //for every student create standardised score
        let studentAvgs = []
        for (let i=0;i<this.selectedTests.length;i++){
            let byTest = this.allData.filter(b=> b.TestID==this.selectedTests[i]).filter(d => d.Total!=null);
            let stats = this.testStats.find(a=> a.TestID == this.selectedTests[i])
            for (let j=0;j<byTest.length;j++){
                byTest[j].Score = ((byTest[j].Total - stats.Min)/stats.Diff)*100*stats.Multiplier
                if (studentAvgs[byTest[j].UniqueID]==undefined){
                    studentAvgs[byTest[j].UniqueID] = []
                }
                if (byTest[j].Score!=NaN){
                    studentAvgs[byTest[j].UniqueID].push(byTest[j].Score)
                }
            }
        }
        
        for (let i=0;i<this.allStudents.length;i++){
            if (studentAvgs[this.allStudents[i].UniqueID]!=undefined){
                this.allStudents[i].Score = this.mean(studentAvgs[this.allStudents[i].UniqueID])
            }
            else{
                this.allStudents[i].Score = 0
            }
            //this.uploadData.push({keyval: {RankID: null}, values: {YearID: , SetID, UniqueID: , Ranking: , Score: , TRank: , Tests: }})
        }
        this.allStudents.sort((a, b) => b.Score - a.Score);
        for (let i=0;i<this.allStudents.length;i++){
            this.allStudents[i].Ranking = (i+1)
            //this.uploadData.push({keyval: {RankID: null}, values: {YearID: this.yeargroupID, SetID: this.allStudents[i].SetID, UniqueID: this.allStudents[i].UniqueID, Ranking: (i+1), Score: this.allStudents[i].Score, TRank: (i+1), Tests: "["+this.selectedTests.toString()+"]"}})
        }

        
        this.markbook = this.markbookSort(this.allStudents, this.selectedTests, this.allData, this.allStudents, this.allPAD)
        const uniqueSets = [...new Set(this.allStudents.map(item => item.SetID))]
        for (let i=0;i<uniqueSets.length;i++){
            this.setColors[uniqueSets[i]] = this.randomColor(uniqueSets[i])
        }
        this.showRank = true
    },
    updateTasks(){
        this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/leader/"+this.taskID,
        data: {
          table: "FeedbackMaps",
          key: "TaskID",
          values: {Status: 2}
        },
        responseType: "json",
      })
        .then((response) => {
          this.showRank=false
          this.done = true
          this.$emit('changed')
        })
        .catch((error) => {
          console.log(error);
        });
    },
    insertRanking(){
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/leader",
        data: {
          table: "ranking",
          data: this.uploadData,
        },
        responseType: "json",
      })
        .then((response) => {
          //this.showRank=false
          //this.done = true
          this.updateTasks()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearRanking(){
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/leader/" + this.yeargroupID,
        data: {
          table: "ranking",
          altKey: "YearID"
        },
        responseType: "json",
      })
      .then((response) =>{
        this.insertRanking()
      })
      .catch((error) => {
        console.log(error);
      });
    },
    mean(arr){
        return arr.reduce((acc, val) => acc + val, 0) / arr.length;
    },
    stDev (arr, usePopulation = false) {
        const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
        return Math.sqrt(
            arr
            .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
            .reduce((acc, val) => acc + val, 0) /
            (arr.length - (usePopulation ? 0 : 1))
        );
    },
    checkPAD(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/4",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.PAD = response.data
            this.getAllPAD()
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAvailableTests(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/11",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.availableTests = response.data
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllData(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/12",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allData = response.data
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStudents(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/14",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allStudents = response.data
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllPAD(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/13",
        params: {
          YeargroupID: this.yeargroupID,
          EndpointID: this.PAD.EndID
        },
        responseType: "json",
      })
        .then((response) => {
            this.allPAD = response.data
            for (let i=0;i<this.allPAD.length;i++){
                //if not a number
                if (isNaN(this.allPAD[i].EndScore) || this.allPAD[i].EndScore==""){
                    if (this.allPAD[i].EndScore.toUpperCase()=="A*"){
                        this.allPAD[i].Std = 56
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="A"){
                        this.allPAD[i].Std = 48
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="B"){
                        this.allPAD[i].Std = 40
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="C"){
                        this.allPAD[i].Std = 32
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="D"){
                        this.allPAD[i].Std = 24
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="E"){
                        this.allPAD[i].Std = 16
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="F"){
                        this.allPAD[i].Std = 8
                    }
                    else if (this.allPAD[i].EndScore.toUpperCase()=="U"){
                        this.allPAD[i].Std = 0
                    }
                    else{
                        this.allPAD[i].Std = 0
                    }
                }
                else{
                    this.allPAD[i].Std = parseInt(this.allPAD[i].EndScore)
                }
            }

          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sortTest(s,t){

    },
    numbering() {
      for (let i = 0; i < this.markbook.data.length; i++) {
        this.markbook.data[i].Ranking = i + 1;
      }
    },
  },
  computed: {},
};
</script>
