<template>
    <v-container>
      <v-btn color="black" width="100%" dark @click="$router.push('/leader')">Back to Dashboard</v-btn>
        <markbook-component :yeargroupID=getYear></markbook-component>
    </v-container>
</template>

<script>
import MarkbookComponent from "@/components/teacher/Markbook";
export default {
  name: "MarkbookLeader",
  components: {
    MarkbookComponent
  },
  props: {

  },
  mounted() {
  },
  data() {
    return {
      yeargroupID: null,
      loaded: false
    };
  },
  methods: {

  },
  computed: {
    getYear(){
      return this.$store.getters.getYeargroup
    },
  },
  watch: {

  }
};
</script>
