<template>
    <div>
        <v-form v-model="submittable" lazy-validation>
            <v-data-table
                :headers="headers"
                :items="grades"
                
            >
            <template v-slot:item.EndScore="{ item }">
                <v-text-field
                    v-model="item.EndScore"
                    :rules="[rules.grades]"
                    @change="submitted=false"
                >
                </v-text-field>
            </template>
            </v-data-table>
        </v-form>
        <v-card-actions>
            <v-select
                v-model="yearSat"
                :items="getYear"
                item-value="id"
                item-text="year"
                label="Select the year the exam was sat"
            ></v-select>
            <v-spacer></v-spacer>
            <v-alert v-if="submitted == true" dense text type="success">
                Done
            </v-alert>
            <v-spacer></v-spacer>
            <v-btn color="lime" dark v-if="submittable && yearSat!=null" @click="submit()">Submit</v-btn>
        </v-card-actions>
    </div>
</template>

<script>
export default {
  name: "PriorManual",
  props: {
    endpoint: Number,
    year: Number,
  },
  mounted() {
    this.getGrades()
  },
  data() {
    return {
        grades: [],
        yearSat: null,
        submittable: true,
        submitted: false,
        headers: [
          {
            text: 'Student Name',
            align: 'start',
            value: 'LastFirst',
          },
          { text: 'Group', value: 'SetName' },
          { text: 'Score (Tab key will move to next input)', value: 'EndScore' },
          { text: 'Year of assessment', value: 'DateCompleted' },
        ],
        rules: {
        grades: (value) => {
          const pattern = /^null$|^[\d]*$|^[ABCDEFGUabcdefgu]$|^A\*$/;
          return pattern.test(value) || "Value must be numeric or a grade from A*-G or U";
        },
      },
    };
  },
  methods: {
    submit(){
        let retArray = []
        for (let i=0;i<this.grades.length;i++){
            if (this.grades[i].EndScore!=null && this.grades[i].EndScore!=""){
                retArray.push({keyval: {EndpointScoresID: this.grades[i].EndScoreID || null}, values: {StudentID: this.grades[i].UniqueID, EndpointID: this.endpoint, EndScore: this.grades[i].EndScore, DateCompleted:this.idToYear(this.yearSat)}})
            }
            
        }
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/teacher",
        data: {
          table: "EndpointScores",
          data: retArray,
        },
        responseType: "json",
      })
        .then((response) => {
          this.submitted = true;
          this.getGrades();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGrades(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/6",
        params: {
          YeargroupID: this.year,
          EndpointID: this.endpoint,
        },
        responseType: "json",
      })
        .then((response) => {
            let check = response.data
            let data = []
            for (let i=0;i<(check.length-1);i++){
                if (check[i].UniqueID!=check[(i+1)].UniqueID){
                    data.push(check[i])
                }
            }
            data.push(check[(check.length-1)])
            this.grades=data
            
        })
        .catch((error) => {
          console.log(error);
        });
    },
    idToYear(i){
        const result = this.getYear.find(({ id }) => id === i);
        return result.year
    }
  },
  computed: {
    getYear(){
        let yr = new Date().getFullYear()
        let years = []
        for (let i=0;i<15;i++){
            years.push({id: i, year: (yr-i)})
        }
        return years
    },

  },
  watch: {
    endpoint: function () {
        this.submitted=false
      this.getGrades()
    },
    year: function () {
        this.submitted=false
      this.getGrades()
    },
  },
};
</script>