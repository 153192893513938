<template>
    <div>
        <v-alert
      prominent
      type="error"
      v-model="showBanner"
      close-text="Close Alert"
      dark
      dismissible
    >
      <v-row align="center">
        <v-col class="grow">
          {{ data.Ratio }}% of students in {{ data.Yeargroup }} have {{ data.Type }} scores uploaded. For amPIL to help rank students, you need at least 90% of the student data uploaded.
        </v-col>
        <v-col class="shrink">
          <v-btn color="error" @click="$router.push('/leader/prior')">Upload {{ data.Type }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    </div>
</template>

<script>
export default {
  name: "PriorBanner",
  props: {
    yearID: Number,
  },
  mounted() {

  },
  data() {
    return {
        showBanner: false,
        data: []
    };
  },
  methods: {
    checkPrior(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/4",
        params: {
          YeargroupID: this.yearID,
        },
        responseType: "json",
      })
        .then((response) => {
            if (response.data.Ratio<90){
                this.showBanner = true
                this.$emit('noprior')
                this.data = response.data
            }
            else{
                this.$emit('prior')
                this.showBanner = false
            }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {},
  watch: {
    yearID: function () {
      if (this.yearID>-1){
        this.checkPrior()
      }
    },
  },
};
</script>