<template>
    <div>
        <v-alert
      prominent
      v-if="showBanner.flag==true"
      close-text="Close Alert"
      dismissible
      shaped
      dense
      dark
      type="warning"
    >
      <v-row align="center">
        <v-col class="grow">
          Feedback task for: <strong>{{ showBanner.text }}</strong> needs to be checked and accepted for the task to proceed.
        </v-col>
      </v-row>
    </v-alert>
    </div>
</template>

<script>
export default {
  name: "TaskBanner",
  props: {
    tasks: Array,
  },
  mounted() {

  },
  data() {
    return {
        //showBanner: true,
        data: []
    };
  },
  methods: {

    
  },
  computed: {
    showBanner(){
      let retval = {}
      for (let i=0;i<this.tasks.length;i++){
        if (this.tasks[i].TeacherInput==1){
          if (this.tasks[i].Status==2 || this.tasks[i].Status==4){
            retval.flag = true
            retval.text = this.tasks[i].TestName
            return retval
          }
        }
      }
      retval.flag = false
      return retval
    }
  },
  watch: {
    tasks: function () {
      
    },
  },
};
</script>