<template>
    <vue-snotify></vue-snotify>
</template>

<script>
export default {
  name: "ErrorHandler",
  props: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {
    showBanner(newError, id){
        //remove any SQL from the displayed message
        let msgDisplay = newError.message
        if (msgDisplay.includes("SQL")){
            msgDisplay = "We are unable to show details of this specific error. Your unique error number is #"+id
        }
        const icon = `/images/ampil_red.png`;
        this.$snotify.error("Sorry, but you may have encountered a bug. The error has  been logged so the developers can work on a fix. You can find out more details by clicking on the button below", 'Oops...', {
            timeout: 30000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            titleMaxLength: 15,
            icon: icon,
            bodyMaxLength: 800,
            buttons: [
                {text: 'More details...', action: (toast) => {toast.body = msgDisplay; toast.title="Error details:"; toast.config.timeout=0; toast.config.buttons.splice(0,1)}, bold: false},
                {text: 'Dismiss', action: (toast) => {this.$snotify.remove(toast.id); }, bold: true},
            ]
        });
    }
  },
  computed: {
    errorMsg(){
        return this.$store.getters.getErrorMsg
    }
  },
  watch: {
    errorMsg (newError, oldError) {
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/report_error",
          data: {
            user: newError.user||"unavailable",
            school: newError.school||"unavailable",
            file: newError.file||"unavailable",
            line: newError.line||"unavailable",
            message: newError.message||"unavailable",
            status: newError.status||"unavailable",
            status_text: newError.status_text||"unavailable"
          },

        responseType: "json",
        })
        .then((response) => {
            //check if user is not a student
            let scope = this.$store.getters.getScope
            if (scope && scope.length>0 && scope!="student" && newError.message!="Invalid Request"){
                this.showBanner(newError, response.data.id)
            }
            
        })
        .catch((error) => {
          //console.log(error)
        });
    }
  }
};
</script>
