<template>
    <v-container>
        <v-alert
      v-if="codeWrong"
      dense
      outlined
      dismissable
      type="error"
    >
      {{ message }}
    </v-alert>
        <div class="text-center">
        <v-card max-width="50%" class="mx-auto my-12">
            <ampil-card title="Enter joining code"></ampil-card>
            <v-card-text>
                <p>Enter the joining code you have been given below. Please note, the code is case sensitive.</p>
                <v-otp-input
                    v-model="code"
                    :disabled="loading"
                    length="7"
                    type="text"
                    @finish="enteredCode()"
                ></v-otp-input>
            </v-card-text>
        </v-card>
    </div>
    <div class="text-center" v-if="codeValid">
        <v-card max-width="80%" class="mx-auto my-12">
            <ampil-card :title="data.school.name+' - '+data.subject"></ampil-card>
            <v-card-text>
                <v-row>
                    <v-col>
                        <!--
                        <p>Enter your date of birth...</p>
                        <v-date-picker color="lime" v-model="dob"></v-date-picker>-->
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            color="lime"
                            v-model="dob"
                            label="Select your date of birth..."
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="lime"
                            v-model="dob"
                            :active-picker.sync="activePicker"
                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                            min="1950-01-01"
                            @change="save"
                        ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <div v-if="dob!=null">
                            <p>Select your name</p>
                            <v-select
                                v-model="selected"
                                :items="names"
                                item-text="name"
                                item-value="id"
                                label="Select Name"
                                outlined
                                @change="checkStudent()"
                            ></v-select>
                            <div v-if="canAdd">
                                <v-form v-model="formValid">
                                    <v-row>
                        
                        <v-text-field
                            v-if="data.domain!=null"
                            :rules="rulesDomain"
                            name="input-10-1"
                            label="Enter Email"
                            :suffix="'@'+data.domain"
                            v-model="email"
                            class="input-group--focused"
                        ></v-text-field>
                        <v-text-field
                            v-else
                            :rules="rulesEmail"
                            name="input-10-1"
                            label="Enter Email"
                            v-model="email"
                            class="input-group--focused"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-2"
                            label="Enter Password"
                            hint="At least 8 characters"
                            v-model="password"
                            class="input-group--focused"
                            @click:append="show1 = !show1"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[matchingPasswords]"
                            :type="show2 ? 'text' : 'password'"
                            name="input-10-3"
                            label="Confirm Password"
                            v-model="passwordConfirm"
                            class="input-group--focused"
                            @click:append="show2 = !show2"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-btn color="lime" :disabled="formValid==false" @click="submit()">Submit</v-btn>
                    </v-row>
                                </v-form>
                            </div>
                        </div>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "JoinCode",
  props: {},
  mounted() {
    if (this.$route.query.code!=null){
        if (this.$route.query.code.length==7){
        this.code = this.$route.query.code
        this.enteredCode()
    }
    }

  },
  data() {
    return {
        activePicker: null,
        date: null,
        menu: false,
        loginFailed: false,
        code: null,
        loading: false,
        codeValid: false,
        codeWrong: false,
        data: [],
        dob: null,
        names: [],
        selected: null,
        message: "",
        canAdd: false,
        formValid: false,
        show1: false,
        show2: false,
        email: "",
        password: "",
        passwordConfirm: "",
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
        },
        rulesEmail: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        ],
        rulesDomain: [
        value => {
          const pattern = /^[A-Z0-9+_.-]+$/i
          return pattern.test(value) || 'Invalid username'
        },
        ],
        studentDetails: {},
    };
  },
  methods: {
    async login(email, password) {
      try {
        const response = await axios.post(
          process.env.VUE_APP_BACKEND + "/oauth/token",
          {
            grant_type: "password",
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            username: email,
            password: password,
            scope: "*",
          }
        );
        localStorage.setItem("access_token", response.data.access_token);
        this.$emit("authenticated");
        this.$store.dispatch("login");
        this.$router.push("/");
        //console.log("Logged In");
        //console.log(response.data);
      } catch (error) {
        console.error("Authentication failed:", error);
        this.loginFailed=true
      }
    },
    submit(){
        //if domain is pre-set add it
        if (this.data.domain!=null){
            this.email = this.email+"@"+this.data.domain
        }
        this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/verify-code/"+this.selected,
        params: {
          code: this.code,
          name: this.studentDetails.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirm,
        },
        responseType: "json",
      })
        .then((response) => {
            this.login(this.email, this.password)
            //this.$router.push('../login')
            //console.log(response.data)
        })
        .catch((error) => {
            this.loginFailed = true;
            console.log(error)
        });
    },
    checkStudent(){
        let stu = this.names.find(a=> a.id==this.selected)
        if (stu.valid==true){
            this.canAdd=true
            this.studentDetails = stu
        }
        else{
            this.codeWrong=true
            this.message="The data you entered does not match our records. Please try again"
            this.loading=false
            this.code=null
            this.codeValid=false
            this.data = []
            this.dob=null
            this.names=[]
            this.selected=null
            this.canAdd = false
            this.studentDetails = {}
        }
    },
    randomInt(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    },
    enteredCode(){
        this.loading=true
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/verify-code",
        data: {
          code: this.code,
        },
        responseType: "json",
      })
        .then((response) => {
            this.codeWrong = false
            this.codeValid=true
            this.data = response.data
        })
        .catch((error) => {
            this.message = 'The code you entered was not correct, please check and try again. Note, the code is case sensitive.'
            this.codeWrong = true
            this.loading=false
        });
    },
    save (date) {
        this.$refs.menu.save(date)
    },
  },
  computed: {
    matchingPasswords: function() {
            if (this.password === this.passwordConfirm) {
                return true;
            } else {
            return 'Passwords do not match.';
            }
            },
  },
  watch: {
    dob: function () {
        if (this.dob!=null){
            let validNames = this.data.students.filter(a=> a.DoB==this.dob)
            for (let i=0;i<validNames.length;i++){
                this.names.push({name: validNames[i].LastFirst, valid: true, id: validNames[i].UniqueID})
            }
            while (this.names.length<10){
                let j = this.randomInt(0,this.data.students.length)
                this.names.push({name: this.data.students[j].LastFirst, valid: false, id: this.data.students[j].UniqueID})
            }
            this.names.sort(() => (Math.random() > .5) ? 1 : -1);
        }
    },
    menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
  },
};
</script>
