<template>
  <div>
    <v-card v-if="ready==false">
      <v-card-title> File Upload: </v-card-title>
      <v-card-text>
        <v-file-input
          chips
          accept="text/csv"
          show-size
          label="Select CSV File for Import..."
          v-model="fileName"
        ></v-file-input>
        Download template file:
        <a :href="getLink+'totals_upload.csv'"
          >Click Here</a
        >
      </v-card-text>
      <v-card-actions>
        <v-btn color="lime" dark :disabled="fileName == null" @click="upload()">
          Upload
        </v-btn>
        <v-alert v-if="errorMsg != ''" dense outlined type="error">
          {{ errorMsg }}
        </v-alert>
        <v-alert v-if="submitMsg != ''" dense outlined type="success">
          {{ submitMsg }}
        </v-alert>
      </v-card-actions>
    </v-card>
    <v-card v-if="ready==true">
        <v-btn color="lime" dark @click="ready=false">Show Upload dialog</v-btn>
        <v-form v-model="submitValid">
        <div v-for="set in sets">
            <v-card-title>{{ set.SetName }}</v-card-title>
            <v-autocomplete
            v-model="selectedTests[set.SetID]"
            :items="allTests"
            item-text="TestName"
            item-value="TestID"
            outlined
            dense
            chips
            small-chips
            label="Select Tests (In the order they appear in upload)"
            multiple
            :rules="[rules.tooMany, rules.tooBig(set.SetID)]"
          >
            <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  small
                >
                Test {{ selectedTests[set.SetID].indexOf(data.item.TestID)+1 }}: {{ data.item.TestName }} 
                </v-chip>
            </template>
        </v-autocomplete>
          
    </div>
        
        
        <v-card-actions v-if="ready2">
            <dialog-modal type="outline" buttonText="View Upload Data" title="Upload Data:" :dataTable="tableOutput" :sortBy="['class', 'LastFirst']"></dialog-modal>
            <v-spacer></v-spacer>
            <v-btn v-if="submitValid" color="lime" outlined text @click="submit()">Commit to DB</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn v-if="submitValid" color="lime" outlined text @click="sortData()">Confirm Assessments</v-btn>
        </v-card-actions>
        <v-alert
            v-if="submitted"
            dense
            text
            type="success"
        >
        Data uploaded successfully!
        </v-alert>
    </v-form>
        <v-divider></v-divider>
        <div v-if="unmatchedStudents.length>0">
            <v-alert
                dense
                outlined
                type="error"
                >
                {{ unmatchedStudents.length }} student(s) from the upload file cannot be found in the database
                <div class="text-right">
                    <dialog-modal type="error" buttonText="Show" title="The following students could not be found:" :text="prepareUnmatched(unmatchedStudents)"></dialog-modal>
                </div>
            </v-alert>
            
        </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UploadTotalsCsv",
  props: {
    test: Number,
    testName: String,
    total: Number,
    setMappings: Array,
  },
  mounted() {
    this.getAllStudents();
    this.getAllTests();
  },
  data() {
    return {
      tableOutput: {},
      submitArray: [],
      fileName: null,
      errorMsg: "",
      submitMsg: "",
      totals: [],
      checkFile: [
        "StudentID",
        "LastFirst",
        "Test 1",
        "Test 2",
        "Test 3",
        "Test 4",
        "Test 5",
        "Test 6",
      ],
      uploadData: [],
      allStudents: [],
      matchedStudents: [],
      unmatchedStudents: [],
      sets: [],
      allTests: [],
      ready: false,
      ready2: false,
      selectedTests: [],
      submitted: false,
      submitValid: false,
      rules: {
        tooMany: (value) =>
            value.length<=6 || "You can only select a maximum of 6 tests",
        tooBig: (set) => (value) => {
            let tests
            let maxMarks = []
            let retval = true
            let max
            let badtests = ''
            //get max marks for tests
            for (let i=0;i<value.length;i++){
                tests = this.allTests.find(({ TestID }) => TestID === value[i]);
                if (tests!=undefined){
                    maxMarks.push({name: "Test "+(i+1), max: parseInt(tests.MaxMark)})
                }
            }
            //cycle through data and make array for given class
            let subSet = this.allStudents.filter((a) => a.SetID===set).map(a=>a.StudentID)
            let uploadData2 = this.uploadData.filter(a=>subSet.includes(a.StudentID))
            for (let i=0;i<maxMarks.length;i++){
                max = uploadData2.reduce(function(prev, current) {
                    return (prev[maxMarks[i].name] > current[maxMarks[i].name]) ? prev : current
                }) //returns object 
                if (max[maxMarks[i].name]>maxMarks[i].max){
                    retval=false
                    badtests = badtests + maxMarks[i].name + ","
                }
            }
            return retval || "The submitted total exceeds the maximum on "+badtests
        },
      },
    };
  },
  methods: {
    submit(){
        this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/teacher",
          data: {
            table: "TestTotals",
            data: this.submitArray
          },
          responseType: "json",
        })
          .then((response) => {
            this.ready2 = false
            this.submitted = true
          })
          .catch((error) => {
            console.log(error);
          });
          
    },
    async getExistingTotals(t,s){
        const res = await this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/6",
        params: {
          SetID: s,
          TestID: t
        },
        responseType: "json",
      })
      return res.data
    },
    async sortData(){
        Array.prototype.inArray = function(comparer) { 
            for(var i=0; i < this.length; i++) { 
                if(comparer(this[i])) return true; 
            }
            return false; 
        }; 
        Array.prototype.pushIfNotExist = function(element, comparer) { 
            if (!this.inArray(comparer)) {
                this.push(element);
            }
        }; 
        let existingData = []
        let headers = [
            {text: "ID", value: "StudentID", groupable: false},
            {text: "Name", value: "LastFirst", groupable: false},
            {text: "Class", value: "class"},
        ]
        let foundTest
        //get existing data
        //cycle through tests to create headers
        let keys = this.selectedTests.keys()
        //create array of tests for upload
        for (const key of keys) {
            if (Array.isArray(this.selectedTests[key])){
                
                for (let i=0;i<this.selectedTests[key].length;i++){
                    existingData.push(await this.getExistingTotals(this.selectedTests[key][i],key))
                    foundTest = this.allTests.find(({ TestID }) => TestID === this.selectedTests[key][i]);
                    foundTest = {text: foundTest.TestName, value: foundTest.TestID.toString(), groupable: false}
                    headers.pushIfNotExist(foundTest, function(e) { 
                        return e.value === foundTest.value && e.name === foundTest.name; 
                    });
                }
            }
        }
        existingData = existingData.flat()
        let foundStudent
        let output = []
        let tableData = []
        //cycle through upload file, match students and get tests from each set
        for (let i=0;i<this.uploadData.length;i++){
            //match student
            foundStudent = this.allStudents.find(({ StudentID }) => StudentID === this.uploadData[i].StudentID)
            if (foundStudent!=undefined){
                //begin create table
                let addStudent = {StudentID: foundStudent.StudentID, LastFirst: foundStudent.LastFirst, class: foundStudent.SetName}
                //get test details
                for (let j=0;j<6;j++){
                    let score = this.uploadData[i]['Test '+(j+1)]
                    let tid = this.selectedTests[foundStudent.SetID][j]
                    
                    //if test found
                    if (tid!=undefined){
                        addStudent[tid.toString()] = score
                        //try to find existing data
                        let foundExisting = existingData.find(obj => obj.StudentID == foundStudent.UniqueID && obj.TestID == tid)
                        if (foundExisting!=undefined){
                            //output.push({TestTotalsID: foundExisting.TestTotalsID, StudentID: foundStudent.UniqueID, TestID: tid, Total: score})
                            output.push({keyval: {TestTotalsID: foundExisting.TestTotalsID || null}, values: {StudentID: foundStudent.UniqueID, TestID: tid, Total: score}})
                        }
                        else{
                            output.push({keyval: {TestTotalsID: null}, values: {StudentID: foundStudent.UniqueID, TestID: tid, Total: score}})
                            //output.push({TestTotalsID: undefined, StudentID: foundStudent.UniqueID, TestID: tid, Total: score})
                        }
                        
                        //console.log(foundExisting)
                    }
                    //console.log(tid, score)
                }
                tableData.push(addStudent)
            }
            //find if existing data exists

        }
        this.tableOutput.headers = headers
        this.tableOutput.values = tableData
        this.submitArray = output
        this.ready2 = true
        //cycle through sets
        //StudentID, LastFirst, TestID, TestName, Total, TestTotalsID (for upload)
    },
    prepareUnmatched(input){
        let out = ''
        for (let i=0;i<input.length;i++){
            out = out + input[i].LastFirst + "(" + input[i].StudentID + "), "
        }
        return out
    },
    getAllStudents() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/4",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.allStudents = response.data;
          if (this.setMappings.length>0){
            this.selectedTests = this.setMappings
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllTests() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/5",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.allTests = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkFileValid(d) {
      let h = Object.keys(d.data[0]);
      let ret = true;
      let isInt
      for (let i = 0; i < h.length; i++) {
        if (h[i] != this.checkFile[i]) {
          this.errorMsg =
            "The upload file is invalid. Please check you have uploaded using the correct template.";
          ret = false;
        }
      }
      //check all data is integers
      for (let j=0;j<d.data.length;j++){
        for (let k=1;k<7;k++){
            isInt = Number.isInteger(d.data[j]['Test '+k])
            if (isInt==false && d.data[j]['Test '+k]!=null){
                this.errorMsg =
                "The upload file contains non numeric data. Please check before uploading again.";
                ret = false
            }
        }
      }
      return ret;
    },
    clearAll() {
      this.totals = [];
      this.sets = [];
      this.uploadData = []
      this.matchedStudents = []
      this.unmatchedStudents = []
      this.selectedTests = []
      if (this.setMappings.length>0){
            this.selectedTests = this.setMappings
          }
      this.ready2 = false
      this.submitted = false
    },
    upload() {
      let self = this;
      this.$papa.parse(this.fileName, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: "greedy",
        complete: function (results) {
          self.fileName = null;
          if (self.checkFileValid(results)) {
            self.clearAll();
            self.uploadData = results.data;
            //check students exist
            self.validateStudents()
            //create unique headers for each set
            //create dropdowns for each test (default to selected test)
            //list students not matched
            
            self.errorMsg = "";
            self.uploadCheck = true;
          } else {
            self.uploadCheck = false;
          }
        },
        error: function () {
          this.fileName = null;
          console.log("Parsing errors");
        },
        
      });
    },
    validateStudents(){
        let match
        let set
        //To check for unique sets in array
        Array.prototype.inArray = function(comparer) { 
            for(var i=0; i < this.length; i++) { 
                if(comparer(this[i])) return true; 
            }
            return false; 
        }; 
        Array.prototype.pushIfNotExist = function(element, comparer) { 
            if (!this.inArray(comparer)) {
                this.push(element);
            }
        }; 
        for (let i=0;i<this.uploadData.length;i++){
            match = this.allStudents.find(({ StudentID }) => StudentID === this.uploadData[i].StudentID);
            if (match!=undefined){
                this.matchedStudents.push(this.uploadData[i])
                set = {SetID: match.SetID, SetName: match.SetName}
                this.sets.pushIfNotExist(set, function(e) { 
                    return e.SetID === set.SetID && e.SetName === set.SetName; 
                });
            }
            else {
                this.unmatchedStudents.push(this.uploadData[i])
            }
        }
        this.setDefaultTests()
        
    },
    setDefaultTests(){
        //if clicked on single test for upload
        if (this.test>-1){
            for (let i=0;i<this.sets.length;i++){
                this.selectedTests[this.sets[i].SetID]=[]
                this.selectedTests[this.sets[i].SetID].push(this.test)
            }
        }
        //if from task
        this.ready=true
    },
  },
  computed: {
    getLink(){
      return process.env.VUE_APP_CSV
    }
  },
  watch: {
    selectedTests: function () {
      this.ready2=false
      this.submitted = false
    },
  },
};
</script>
