<template>
    <v-container>
        <v-skeleton-loader
        v-if="ready==false"
      class="mx-auto"
      type="table"
    ></v-skeleton-loader>
  <v-card>
    <v-card-text>
        <v-card-actions>

            <v-spacer></v-spacer>
            <v-btn color="lime" dark v-if="ready" @click="download()">Download to Excel</v-btn>
        </v-card-actions>
        <vue-tabulator ref="tabulator" v-if="ready" v-model="markbook.data" :options="options" :integration="{ updateStrategy: 'UPDATE' }" />
    </v-card-text>
  </v-card>

    </v-container>
  </template>
  
  <script>
  //above average, at and below for each test
  //residual for markbook column colour graded red to green
  //colour 
  import { saveAs } from 'file-saver';
import "tabulator-tables/dist/css/tabulator.min.css";
import Markbook from '@/mixins/Markbook'
  export default {
    name: "Markbook",
    mixins: [Markbook],
    components: {


    },
    props: {
        yeargroupID: Number,
    },
    data: () => ({
        //yeargroupID: 4,
        columnOffset: null,
        greenValues: [],
        amberValues: [],
        redValues: [],
        greenNames: [],
        redNames: [],
        ready: false,
        markbook: [],
        allData: [],
        options: {

                columns: [
                {
                    title: 'Name',
                    field: 'LastFirst',
                    editable: false,
                    headerSort:true,
                    headerFilter:"input", 

                },
                {
                    title: 'Set',
                    field: 'SetName',
                    editable: false,
                    headerSort:true,
                    headerFilter:"input", 
                },
                {
                    title: 'Prior',
                    field: 'Prior',
                    editable: false,
                    headerSort:true,
                    headerFilter:"input", 
                },
                {
                    title: 'Ranking',
                    field: 'Ranking',
                    editable: false,
                    headerSort:true,
                },
                {
                    title: 'Score',
                    field: 'Score',
                    editable: false,
                    headerSort:true,
                },
                {
                    title:"Residual", 
                    field:"Residual", 
                    formatter:"progress", 
                    formatterParams:{color:["rgb(255,0,0)", "orange", "#00dd00"], min: -200, max: 200, legend: true}, 
                    sorter:"number", 
                    width:100,
                    editable: false,
                    headerSort: true
                },
                {
                    title: 'Grade',
                    field: 'Grade',
                    editable: false,
                    headerSort:true,
                },

                
             ],
            },
    }),
    watch: {

    },
    mounted() {
        this.getData()
    },
    methods: {
        async download(){
            // polyfills required by exceljs
            require('core-js/modules/es.promise');
            require('core-js/modules/es.string.includes');
            require('core-js/modules/es.object.assign');
            require('core-js/modules/es.object.keys');
            require('core-js/modules/es.symbol');
            require('core-js/modules/es.symbol.async-iterator');
            require('regenerator-runtime/runtime');


            const ExcelJS = require('exceljs');
            const workbook = new ExcelJS.Workbook();
            workbook.creator = 'amPIL Tracker - D Wilson';
            workbook.lastModifiedBy = 'amPIL Tracker - D Wilson';
            var worksheet = workbook.addWorksheet('Markbook');
            let columns = [
                { header: 'Name', key: 'LastFirst', width: 30 },
                { header: 'Set', key: 'SetName', width: 10 },
                { header: 'Prior', key: 'Prior', width: 10 },
                { header: 'Ranking', key: 'Ranking', width: 10 },
                { header: 'Score', key: 'Score', width: 10 },
                { header: 'Residual', key: 'Residual', width: 10 },
                { header: 'Grade', key: 'Grade', width: 10 },
            ];
            //make column headings
            for (let i=0;i<this.markbook.tests.length;i++){
                columns.push({header: this.markbook.tests[i].TestName, key: this.markbook.tests[i].TestID.toString(), width: 5})
            }
            let coord
            worksheet.columns = columns
            //populate data
            let row = {}
            for (let i=0;i<this.markbook.data.length;i++){
                row = this.markbook.data[i]
                worksheet.addRow(row)
            }
            
            let rowVals = worksheet.getRow(1).values
            worksheet.getRow(1).font = { bold: true }
            coord = this.excelCoords(1,(columns.length))
            worksheet.autoFilter = 'A1:'+coord;
            //rotate headers
            for (let i=8;i<rowVals.length;i++){
                coord = this.excelCoords(1,i)
                worksheet.getCell(coord).alignment = { textRotation: 90 };             
            }
            for (let i=0;i<this.greenNames.length;i++){
                coord = this.greenNames[i].cellRef
                worksheet.getCell(coord).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'008000.'}
                    };
            }
            for (let i=0;i<this.redNames.length;i++){
                coord = this.redNames[i].cellRef
                worksheet.getCell(coord).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'FF0000.'}
                    };
            }
            for (let i=0;i<this.greenValues.length;i++){
                coord = this.greenValues[i].cellRef
                worksheet.getCell(coord).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'008000.'}
                    };
            }
            for (let i=0;i<this.amberValues.length;i++){
                coord = this.amberValues[i].cellRef
                worksheet.getCell(coord).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'FFBF00.'}
                    };
            }
            for (let i=0;i<this.redValues.length;i++){
                coord = this.redValues[i].cellRef
                worksheet.getCell(coord).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'FF0000.'}
                    };
            }

            workbook.xlsx.writeBuffer()
            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';

            const blob = new Blob([buffer], {type: fileType});

            saveAs(blob, 'Markbook' + fileExtension);
        },
        stats (arr) {
            const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
            const sd = Math.sqrt(
                arr
                .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
                .reduce((acc, val) => acc + val, 0) /arr.length
            );
            let upper = mean+sd
            let lower = mean-sd
            return {mean: mean, sd: sd, upper: upper, lower: lower}
         },
        getData(){
            this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/28",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            let d = response.data
            this.allData = d
            let tests = d.Tests.map(a=>a.TestID)
            this.markbook = this.markbookSort(d.allStudents, tests, d.allData, d.ranking, d.allPAD)
            //calculate residuals
            //let sortmb = this.markbook.data.slice()
            let sortmb = [].concat(this.markbook.data);
            for (let i=0;i<sortmb.length;i++){
                if (sortmb[i].Prior=="" || sortmb[i].Prior==null || sortmb[i].Prior==undefined || sortmb[i].Prior=="#N/A"){
                    sortmb[i].Prior=0
                }
            }
            sortmb.sort((a, b) => parseInt(b.Prior) - parseInt(a.Prior));
            for (let i=0;i<sortmb.length;i++){
                if(sortmb[i].Prior>0){
                    sortmb[i].Residual = (i+1) - sortmb[i].Ranking
                }
                else{
                    sortmb[i].Residual = 0
                }
                
                //let j = this.markbook.data.findIndex(a=> a.UniqueID==sortmb[i].UniqueID)
                //this.markbook.data[j].Residual = sortmb[i].Residual
                for (let j=0;j<this.markbook.data.length;j++){
                    if (this.markbook.data[j].UniqueID==sortmb[i].UniqueID){
                        this.markbook.data[j].Residual = sortmb[i].Residual
                    }
                }
            }
            //calculate residual
            const max = this.markbook.data.map(a=>a.Residual).reduce((a, b) => Math.max(a, b), -Infinity);
            const min = this.markbook.data.map(a=>a.Residual).reduce((a, b) => Math.min(a, b), Infinity);
            this.options.columns[5].formatterParams.min=min
            this.options.columns[5].formatterParams.max=max
            //colour assessment results
            var colorCell = (cell)=>{
                        var value = cell.getValue();
                        if (value!=undefined){
                            let test = cell.getColumn().getField()
                            let stats = this.columnStats.find(a=> a.TestID == test)
                            let index = this.columnStats.findIndex(a=> a.TestID == test)
                            let row = (cell.getRow().getPosition()+2)
                            let column = (index+1+this.columnOffset)
                            //return "<div style='background: red' class='circle'>"+value+"</div>"
                            if (value>stats.Stats.upper){
                                this.greenValues.push({row: row, field: cell.getColumn().getField(), col: column, value: value, cellRef: this.excelCoords(row, column)})
                                return "<div class='greenLight'>"+value+"</div>"
                            }
                            else if (value>stats.Stats.lower){
                                this.amberValues.push({row: row, field: cell.getColumn().getField(), col: column, value: value, cellRef: this.excelCoords(row, column)})
                                return "<div class='yellowLight'>"+value+"</div>"
                            }
                            else if (value>0){
                                this.redValues.push({row: row, field: cell.getColumn().getField(), col: column, value: value, cellRef: this.excelCoords(row, column)})
                                return "<div class='redLight'>"+value+"</div>"
                            }
                            
                        }
                        else{
                            return ""
                        }
                        
                    }
            var setFormat = (cell)=>{
                var sid = cell.getData().UniqueID
                var setid = cell.getData().SetID
                let setStats = this.allData.allSets.find(a=>a.SetId==setid)
                let setIndex = this.allData.allSets.findIndex(a=>a.SetId==setid)
                var value = cell.getValue();
                let score = cell.getData().Score
                let color = "black"
                let row = (cell.getRow().getPosition()+2)
                let column = 1
                if (setStats!=undefined){
                    
                    //if index isnt 0 and previous set has a higher average
                    //color green
                    if (setIndex!=0 && setStats.Stats.length!=0 && this.allData.allSets[(setIndex-1)].Stats!=undefined){
                        
                        if (this.allData.allSets[(setIndex-1)].Stats.mean>setStats.Stats.mean){
                            if (score>this.allData.allSets[(setIndex-1)].Stats.mean){
                                this.greenNames.push({row: row, field: cell.getColumn().getField(), col: column, value: value, cellRef: this.excelCoords(row, column)})
                                color = "#50900c"
                            }
                        }
                        
                    } 
                    //color red
                    else if (setIndex!=(setStats.length-1) && setStats.Stats.length!=0 && this.allData.allSets[(setIndex+1)].Stats!=undefined){
                        if (this.allData.allSets[(setIndex+1)].Stats.mean<setStats.Stats.mean){
                            if (score<this.allData.allSets[(setIndex+1)].Stats.mean){
                                this.redNames.push({row: row, field: cell.getColumn().getField(), col: column, value: value, cellRef: this.excelCoords(row, column)})
                                color = "red"
                            }
                        }
                        
                    } 
                }
                
                        if (value!=undefined){
                            return "<span style='color:"+color+"; font-weight:bold;'>"+value+"</span>"
                        }
                        else{
                            return ""
                        }
            }
            this.columnOffset = this.options.columns.length
            for (let i=0;i<this.markbook.tests.length;i++){
                this.options.columns.push({title: this.markbook.tests[i].TestName, field: this.markbook.tests[i].TestID.toString(), editable: false, headerSort: true, headerVertical: true, formatter: colorCell})
            }
            //generate set move recommendations
            for (let i=0;i<this.allData.allSets.length;i++){
                let setData = this.markbook.data.filter(a=>a.SetID==this.allData.allSets[i].SetId).map(b=>b.Score)
                if (setData.length>0){
                    let setStats = this.stats(setData)
                    this.allData.allSets[i].Stats = setStats
                }
                
            }
            this.options.columns[0].formatter = setFormat
            this.ready = true
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
        },

    excelCoords(row, col) {
            var colStr = '';

            while(col > 0) {
            colStr = this.toChar((col - 1) % 26) + colStr;
            col = Math.floor((col - 1) / 26);
            }

            return colStr + row;
        },

        toChar(n) {
            var CAPITAL_A = 65;
            return String.fromCharCode(CAPITAL_A + n);
        },

        // The inverse is also quite simple:

        cartesianCoords(excelCoords) {
            var row = parseInt(this.excelCoords.replace(/^[A-Z]+/, ''));
            var colChars = this.excelCoords.replace(/\d+$/, '').split('').reverse();
            var col = 0;
            var multiplier = 1;

            while(colChars.length) {
                col += toBase26Ish(colChars.shift()) * multiplier;
                multiplier *= 26;
            }

            return [row, col];
        },

        toBase26Ish(c) {
            var CAPITAL_A = 65;
            return c.charCodeAt(0) - CAPITAL_A + 1;
        },
    
    },
    computed: {
        columnStats(){
            let stats = []
            for (let i=0;i<this.markbook.tests.length;i++){
                let values = this.allData.allData.filter(a=>a.TestID == this.markbook.tests[i].TestID).map(b=>b.Total)
                stats.push({TestID: this.markbook.tests[i].TestID, TestName: this.markbook.tests[i].TestName, Stats: this.stats(values)})
                

            }
            return stats
        }
    },
    watch: {
       // tasks: function () {
            //this.getTasks()
        //},
        yeargroupID: function () {
            this.columnOffset = null
        this.greenValues = []
        this.amberValues= []
        this.redValues= []
        this.greenNames= []
        this.redNames= []
        this.markbook= []
        this.allData= []
            this.options = {

columns: [
{
    title: 'Name',
    field: 'LastFirst',
    editable: false,
    headerSort:true,
    headerFilter:"input", 

},
{
    title: 'Set',
    field: 'SetName',
    editable: false,
    headerSort:true,
    headerFilter:"input", 
},
{
    title: 'Prior',
    field: 'Prior',
    editable: false,
    headerSort:true,
    headerFilter:"input", 
},
{
    title: 'Ranking',
    field: 'Ranking',
    editable: false,
    headerSort:true,
},
{
    title: 'Score',
    field: 'Score',
    editable: false,
    headerSort:true,
},
{
    title:"Residual", 
    field:"Residual", 
    formatter:"progress", 
    formatterParams:{color:["rgb(255,0,0)", "orange", "#00dd00"], min: -200, max: 200, legend: true}, 
    sorter:"number", 
    width:100,
    editable: false,
    headerSort: true
},
{
    title: 'Grade',
    field: 'Grade',
    editable: false,
    headerSort:true,
},


],
}
            this.ready=false
        this.getData()
    },
    },
  }
  </script>

      <style>

      .redLight{
        background: brown;
        background-image: radial-gradient(red, transparent);
        border-radius: 50%;
        border: dotted 2px brown;
        box-shadow: 

            0 0 5px brown;
        font-weight: bold;
        color: #fff;
        text-align: center; 
    }

    .yellowLight{
  background: rgb(202, 151, 22);
  background-image: radial-gradient(orange, transparent);
  border-radius: 50%;
  border: dotted 2px rgb(202, 151, 22);
  box-shadow: 

    0 0 5px rgb(202, 151, 22);
    font-weight: bold;
        color: #fff;
        text-align: center; 
}
.greenLight{
  background:green;
  background-image: radial-gradient(lime, transparent);
  border-radius: 50%;
  border: dotted 2px green;
  box-shadow: 
    0 0 5px green;
    font-weight: bold;
        color: #fff;
        text-align: center; 
}
    </style>