<template>
    <v-container>
        <v-btn @click="generate()" color="lime" text>
            <v-icon>mdi-cloud-download-outline</v-icon>
            Class Report
        </v-btn>
    </v-container>
  </template>
  
  <script>
  import { saveAs } from 'file-saver';
  export default {
    name: "Testing",
    props: {
      testID: Number,
      setID: Number,
      strengths: Array,
      weaknesses: Array
    },
    components: {
      
    },
    data: () => ({
        //testID: 7,
        //setID: 56,
        studentNames: [],
        av: {},
    }),
    watch: {

    },
    mounted() {
        this.getClassData()
    },
    emits: ["loaded"],
    methods: {
        round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
      },
        getClassData(){
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/teacher/19",
          params: {
            SetID: this.setID,
            TestID: this.testID,
          },
          responseType: "json",
        })
          .then((response) => {
            this.studentNames=response.data
            if (this.studentNames.length>0){
              let j = 0;
            let sum = 0;
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].Total !== null) {
                sum = sum + parseInt(response.data[i].Total);
                j++;
              }
            }
  
            this.av.mark = this.round(sum / j, 1);
            this.av.perc = this.round(
              (this.av.mark / parseInt(response.data[0].MaxMark)) * 100,
              1
            );
            //console.log(this.studentNames)
            this.getAverages()
            //this.getTargets();
            }

          })
          .catch((error) => {
            console.log(error);
          });
        },
        getAverages(){
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/teacher/17",
          params: {
            TestID: this.testID,
          },
          responseType: "json",
        })
          .then((response) => {
            this.avgs = response.data
            this.getTargets()
          })
          .catch((error) => {
            console.log(error);
          });
      },
      orderQuestions(s){
        
        let q = s.data
        q.sort(function (a, b) {
              return a.QuestionNumber - b.QuestionNumber;
            });
        return q
      },
      getTargets() {
        let av
        let stu
        //this.strengths = [];
        //this.targets = [];
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/teacher/20",
          params: {
            SetID: this.setID,
            TestID: this.testID,
          },
          responseType: "json",
        })
          .then((response) => {
            this.allStudents = response.data
          })
          .catch((error) => {
            console.log(error);
          });

      },
        async generate(){
            // polyfills required by exceljs
            require('core-js/modules/es.promise');
            require('core-js/modules/es.string.includes');
            require('core-js/modules/es.object.assign');
            require('core-js/modules/es.object.keys');
            require('core-js/modules/es.symbol');
            require('core-js/modules/es.symbol.async-iterator');
            require('regenerator-runtime/runtime');


            const ExcelJS = require('exceljs');
            const workbook = new ExcelJS.Workbook();
            workbook.creator = 'amPIL Tracker - D Wilson';
            workbook.lastModifiedBy = 'amPIL Tracker - D Wilson';
            var worksheet = workbook.addWorksheet('Class Feedback');
            let columns = [
                { header: 'Qn', key: 'QuestionNumber', width: 10 },
                { header: 'Topic', key: 'TestTopic', width: 50 },
                { header: 'Max Mark', key: 'MaxMark', width: 10 },
            ];
            //make column headings
            for (let i=0;i<this.studentNames.length;i++){
                columns.push({header: this.studentNames[i].LastFirst, key: this.studentNames[i].UniqueID, width: 5})
            }
            worksheet.columns = columns
            //populate data
            let row = {}
            let qdata = []
            for (let i=0;i<this.avgs.length;i++){
                row = {QuestionNumber: this.avgs[i].QuestionNumber, TestTopic: this.avgs[i].TestTopic, MaxMark: this.avgs[i].MarksAvailable}
                qdata = this.allStudents.filter((s) => s.QuestionID === this.avgs[i].QuestionID);
                for (let j=0;j<qdata.length;j++){
                    row[qdata[j].UniqueID] = qdata[j].SResult
                }
                //console.log(qdata)
                worksheet.addRow(row)
            }
            //add totals
            row = {}
            for (let i=0;i<this.studentNames.length;i++){
                row.MaxMark = 'Total:'
                row[this.studentNames[i].UniqueID] = parseInt(this.studentNames[i].Total)
                
            }
            worksheet.addRow(row)
            //console.log(this.studentNames)

            //Strengths and targets [Comparison, avg, perc, qn, topic]
            //console.log(this.strengths)
            worksheet.addRow({QuestionNumber: ''})
            worksheet.addRow({QuestionNumber: ''})
            if (this.strengths.length>0){
              worksheet.addRow({QuestionNumber: 'Strengths:', TestTopic: "Q"+this.strengths[0].qn+") "+this.strengths[0].topic})
              for (let i=1;i<this.strengths.length;i++){
                worksheet.addRow({TestTopic: "Q"+this.strengths[i].qn+") "+this.strengths[i].topic})
              }
            }

            if (this.weaknesses.length>0){
              worksheet.addRow({QuestionNumber: ''})
              worksheet.addRow({QuestionNumber: 'Weaknesses:', TestTopic: "Q"+this.weaknesses[0].qn+") "+this.weaknesses[0].topic})
              for (let i=1;i<this.weaknesses.length;i++){
                worksheet.addRow({TestTopic: "Q"+this.weaknesses[i].qn+") "+this.weaknesses[i].topic})
              }
            }


            //worksheet.getCell('C1').alignment = { textRotation: 90 };
            let rowVals = worksheet.getRow(1).values
            let max = rowVals[3]
            let coord
            let red = this.studentNames[0].Red
            let amber = this.studentNames[0].Amber
            let green = this.studentNames[0].Green
            let colorPicked
            //rotate headers
            for (let i=4;i<rowVals.length;i++){
                coord = this.excelCoords(1,i)
                worksheet.getCell(coord).alignment = { textRotation: 90 };             
            }
            let k = worksheet.getColumn('QuestionNumber').values.length

            for (let j=2;j<k;j++){
                rowVals = worksheet.getRow(j).values
                let max = rowVals[3]
                for (let i=4;i<rowVals.length;i++){
                    let ratio = ((rowVals[i]/max)*100)
                    if (ratio>=red){
                        colorPicked = 'FF0000.'
                    }
                    if (ratio>amber){
                        colorPicked = 'FFBF00.'
                    }
                    if (ratio>green){
                        colorPicked = '008000.'
                    }
                    coord = this.excelCoords(j,i)
                    worksheet.getCell(coord).fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:colorPicked}
                    };
                
            }
            }
            
            

            workbook.xlsx.writeBuffer()
            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';

            const blob = new Blob([buffer], {type: fileType});

            saveAs(blob, 'ClassReport' + fileExtension);
        },
        excelCoords(row, col) {
            var colStr = '';

            while(col > 0) {
            colStr = this.toChar((col - 1) % 26) + colStr;
            col = Math.floor((col - 1) / 26);
            }

            return colStr + row;
        },

        toChar(n) {
            var CAPITAL_A = 65;
            return String.fromCharCode(CAPITAL_A + n);
        },

        // The inverse is also quite simple:

        cartesianCoords(excelCoords) {
            var row = parseInt(this.excelCoords.replace(/^[A-Z]+/, ''));
            var colChars = this.excelCoords.replace(/\d+$/, '').split('').reverse();
            var col = 0;
            var multiplier = 1;

            while(colChars.length) {
                col += toBase26Ish(colChars.shift()) * multiplier;
                multiplier *= 26;
            }

            return [row, col];
        },

        toBase26Ish(c) {
            var CAPITAL_A = 65;
            return c.charCodeAt(0) - CAPITAL_A + 1;
        },
    },
    computed: {

    },
    watch: {

    },
  }
  </script>