import { render, staticRenderFns } from "./FeedbackInitial.vue?vue&type=template&id=16c07b50&"
import script from "./FeedbackInitial.vue?vue&type=script&lang=js&"
export * from "./FeedbackInitial.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports