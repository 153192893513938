<template>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="text-caption text-center"
            color="pink lighten-5"
            text
            v-bind="attrs"
            v-on="on"
          >
            Privacy Policy / T&C's
          </v-btn>
        </template>
  
        <v-card>
          <ampil-card title="Privacy Policy"></ampil-card>
  
          <v-card-text>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Privacy Policy
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class=WordSection1>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:19.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>PRIVACY
POLICY&nbsp;&nbsp;&nbsp;</span></b><b><span style='font-size:11.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:black'>&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#7F7F7F'>Last
updated&nbsp;November 07, 2019&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#7F7F7F'>&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:0%'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:0%'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>Thank you for choosing to be part of our community at&nbsp;<span
class=SpellE>amPIL</span> Tracker&nbsp;(“<b>Company</b>”, “<b>we</b>”, “<b>us</b>”,
or “<b>our</b>”). We are committed to protecting your personal information and
your right to privacy. If you have any questions or concerns about our&nbsp;policy,
or our practices with regards to your personal information, please contact us
at&nbsp;admin@ampil.co.uk.&nbsp;</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>When you visit our&nbsp;website&nbsp;www.ampil.co.uk,&nbsp;and
use our services, you trust us with your personal information. We take your
privacy very seriously. In this&nbsp;privacy policy, we seek to explain to you
in the clearest way possible what information we collect, how we use it and
what rights you have in relation to it. We hope you take some time to read
through it carefully, as it is important. If there are any terms in this&nbsp;privacy
policy&nbsp;that you do not agree with, please discontinue use of our&nbsp;Sites&nbsp;and
our services.&nbsp;</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>This&nbsp;privacy policy&nbsp;applies to all information
collected through our&nbsp;website&nbsp;(such as&nbsp;www.ampil.co.uk),&nbsp;and/or
any related services, sales, marketing or events (we refer to them collectively
in this&nbsp;privacy policy&nbsp;as the &quot;<b>Services</b>&quot;).&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>Please read this&nbsp;privacy policy&nbsp;carefully as it will
help you make informed decisions about sharing your personal information with
us.&nbsp;&nbsp;</span></b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>&nbsp;</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'><br
style='mso-special-character:line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]></span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>TABLE OF
CONTENTS</span></b><b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'>&nbsp;</span></b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#infocollect"><span
style='color:#595959'>1. WHAT INFORMATION DO WE COLLECT?</span></a><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#infouse"><span
style='color:#595959'>2. HOW DO WE USE YOUR INFORMATION?</span></a><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'><a
href="#infoshare">3.
WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#cookies"><span
style='color:#595959'>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a></span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#inforetain"><span
style='color:#595959'>5. HOW LONG DO WE KEEP YOUR INFORMATION?&nbsp;</span></a></span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#infosafe"><span
style='color:#595959'>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a></span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#privacyrights"><span
style='color:#595959'>7. WHAT ARE YOUR PRIVACY RIGHTS?&nbsp;&nbsp;</span></a><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#databreach"><span
style='color:#595959'>8. DATA BREACH</span></a><a
href="#privacyrights"><span
style='color:#595959'>&nbsp;</span></a><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#DNT"><span
style='color:#595959'>9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#caresidents"><span
style='color:#595959'>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#policyupdates"><span
style='color:#595959'>11. DO WE MAKE UPDATES TO THIS POLICY?</span></a><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><a
href="#contact"><span
style='color:#595959'>12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></a><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'><br
style='mso-special-character:line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]></span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>1. WHAT
INFORMATION DO WE COLLECT?</span></b><b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;</span></b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'><br
style='mso-special-character:line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]></span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>Personal
information you disclose to us</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>In Short:</span></i></b><b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;</span></b><i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>We
collect personal information that you provide to us such as name, address,
contact information, passwords and security data,&nbsp;and payment information.</span></i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>We collect personal information that you voluntarily provide to
us when&nbsp;registering at the&nbsp;Services&nbsp;expressing an interest in
obtaining information about us or our products and services, when participating
in activities on the&nbsp;Services&nbsp;or otherwise contacting us.</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>The personal information that we collect depends on the context
of your interactions with us and the&nbsp;Services, the choices you make and
the products and features you use. The personal information we collect can
include the following:</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>Publicly
Available Personal Information.</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;We collect&nbsp;first
name, maiden name, last name, nickname,&nbsp;email addresses,&nbsp;and other
similar data.</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>Credentials.</span></b><span style='font-size:11.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#595959'>&nbsp;We collect passwords, password hints,
and similar security information used for authentication and account access.&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>Payment Data.</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;We collect data
necessary to process your payment if you make purchases, such as your payment
instrument number (such as a credit card number), and the security code
associated with your payment instrument. All payment data is stored by&nbsp;<span
class=SpellE>amPIL</span> Tracker. You may find their privacy policy link(s)
here:&nbsp;www.ampil.co.uk.&nbsp;&nbsp;</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>All personal information that you provide to us must be true,
complete and accurate, and you must notify us of any changes to such personal
information.&nbsp;&nbsp;&nbsp;</span><span style='font-size:11.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>Information
automatically collected</span></b><span style='font-size:11.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>In Short:</span></i></b><b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;</span></b><i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>Some
information – such as IP address and/or browser and device characteristics – is
collected automatically when you visit our&nbsp;Services.</span></i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>We automatically collect certain information when you visit, use
or navigate the&nbsp;Services. This information does not reveal your specific
identity (like your name or contact information) but may include device and
usage information, such as your IP address, browser and device characteristics,
operating system, language preferences, referring URLs, device name, country,
location, information about how and when you use our&nbsp;Services&nbsp;and
other technical information. This information is primarily needed to maintain
the security and operation of our&nbsp;Services, and for our internal analytics
and reporting purposes.</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>Like many businesses, we also collect information through
cookies and similar technologies.&nbsp;&nbsp;</span><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>Online
Identifiers.</span></b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>&nbsp;We collect&nbsp;tools and protocols, such as IP (Internet
Protocol) addresses,&nbsp;and other similar data.&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><span style='font-size:14.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'>2. HOW DO WE USE YOUR INFORMATION?</span></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;</span></b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>In Short:&nbsp;</span></i></b><b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;</span></b><i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>We
process your information for purposes based on legitimate business interests,
the <span class=SpellE>fulfillment</span> of our contract with you, compliance
with our legal obligations, and/or your consent.</span></i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>We use personal information collected via our&nbsp;Services&nbsp;for
a variety of business purposes described below. We process your personal information
for these purposes in reliance on our legitimate business interests, in order
to enter into or perform a contract with you, with your consent, and/or for
compliance with our legal obligations. We indicate the specific processing
grounds we rely on next to each purpose listed below.</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:black'>&nbsp;&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>We use
the information we collect or receive:</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<ul type=square>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>To
     facilitate account creation and logon process.</span></b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;If
     you choose to link your account with us to a <span class=GramE>third party</span>
     account (such as your Google or Facebook account), we use the information
     you allowed us to collect from those third parties to facilitate account
     creation and logon process for the performance of the contract.&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;<br
     style='mso-special-character:line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>To
     send administrative information to you.&nbsp;</span></b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>We
     may use your personal information to send you product, service and new
     feature information and/or information about changes to our terms,
     conditions, and policies.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br
     style='mso-special-character:line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>To
     protect our Services.</span></b><span style='font-size:11.5pt;font-family:
     "Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
     "Times New Roman";color:#595959'>&nbsp;We may use your information as part
     of our efforts to keep our&nbsp;Services&nbsp;safe and secure (for
     example, for fraud monitoring and prevention).&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'>&nbsp;&nbsp;<br style='mso-special-character:
     line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>To
     enforce our terms, conditions and policies for Business Purposes, Legal
     Reasons and Contractual.</span></b><span style='font-size:11.5pt;
     font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'><br style='mso-special-character:
     line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>To
     respond to legal requests and prevent harm.&nbsp;</span></b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>If
     we receive a subpoena or other legal request, we may need to inspect the
     data we hold to determine how to
     respond.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'><br style='mso-special-character:
     line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>To
     manage user accounts</span></b><span style='font-size:11.5pt;font-family:
     "Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
     "Times New Roman";color:#595959'>. We may use your information for the
     purposes of managing our account and keeping it in working order.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'><br>
     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l0 level1 lfo1;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>To
     deliver services to the user.</span></b><span style='font-size:11.5pt;
     font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;We may use
     your information to provide you with the requested service.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman"'>&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'><o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>3. WILL
YOUR INFORMATION BE SHARED WITH ANYONE?</span></b><b><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;</span></b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>In Short:&nbsp;</span></i></b><b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;</span></b><i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>We only
share information with your consent, to comply with laws, to provide you with
services, to protect your rights, or to <span class=SpellE>fulfill</span>
business obligations.&nbsp;</span></i><span style='font-size:11.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>We may process or share data based on the following legal basis:</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<ul type=square>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l1 level1 lfo2;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>Consent:</span></b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;We
     may process your data if you have given us specific consent to use your
     personal information in a specific purpose.&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'><br>
     <br style='mso-special-character:line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l1 level1 lfo2;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>Legitimate
     Interests:</span></b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
     color:#595959'>&nbsp;We may process your data when it is reasonably
     necessary to achieve our legitimate business interests.&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'><br>
     <br style='mso-special-character:line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l1 level1 lfo2;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>Performance
     of a Contract:&nbsp;</span></b><span style='font-size:11.5pt;font-family:
     "Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
     "Times New Roman";color:#595959'>Where we have entered into a contract
     with you, we may process your personal information to <span class=SpellE>fulfill</span>
     the terms of our contract.&nbsp;</span><span style='font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman"'><br>
     <br style='mso-special-character:line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l1 level1 lfo2;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>Legal
     Obligations:</span></b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
     color:#595959'>&nbsp;We may disclose your information where we are legally
     required to do so in order to comply with applicable law, governmental
     requests, a judicial proceeding, court order, or legal process, such as in
     response to a court order or a subpoena (including in response to public
     authorities to meet national security or law enforcement requirements).&nbsp;</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'><br>
     <br style='mso-special-character:line-break'>
     <![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
     <![endif]><o:p></o:p></span></li>
 <li class=MsoNormal style='color:black;mso-margin-top-alt:auto;mso-margin-bottom-alt:
     auto;mso-list:l1 level1 lfo2;tab-stops:list 36.0pt'><b><span
     style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
     "Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>Vital
     Interests:</span></b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
     mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
     color:#595959'>&nbsp;We may disclose your information where we believe it
     is necessary to investigate, prevent, or take action regarding potential
     violations of our policies, suspected fraud, situations involving
     potential threats to the safety of any person and illegal activities, or
     as evidence in litigation in which we are involved.</span><span
     style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
     mso-bidi-font-family:"Times New Roman"'><o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>More
specifically, we may need to process your data or share your personal
information in the following situations:</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;&nbsp;&nbsp;&nbsp;<br
style='mso-special-character:line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]><o:p></o:p></span></p>

<p class=MsoNormal><span class=GramE><span style='font-family:Symbol;
mso-ascii-font-family:Arial;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>·</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><span
style='mso-spacerun:yes'>  </span></span><b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#595959'>Business</span></b></span><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>
Transfers.</span></b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>&nbsp;We may share or transfer your information in connection
with, or during negotiations of, any merger, sale of company assets, financing,
or acquisition of all or a portion of our business to another company.</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><br>
<br style='mso-special-character:line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]><o:p></o:p></span></p>

<p class=MsoNormal><span class=GramE><span style='font-family:Symbol;
mso-ascii-font-family:Arial;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>·</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><span
style='mso-spacerun:yes'>  </span></span><b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:#595959'>Third</span></b></span><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>-Party
Advertisers.</span></b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:"Times New Roman";
color:#595959'>&nbsp;We may use third-party advertising companies to serve ads
when you visit the&nbsp;Services. These companies may use information about
your visits to our Website(s) and other websites that are contained in web
cookies and other tracking technologies in order to provide advertisements
about goods and services of interest to you.&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'>&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><b><span style='font-size:14.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'>4. DO WE USE COOKIES AND
OTHER TRACKING TECHNOLOGIES?</span></b><b><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'>&nbsp;</span></b><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>In Short:&nbsp;</span></i></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;</span></b><i><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>We may use cookies and other tracking technologies to collect
and store your information.</span></i><span style='font-size:11.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>We may use cookies and
similar tracking technologies (like web beacons and pixels) to access or store
information. Specific information about how we use such technologies and how
you can refuse certain cookies is set out in our Cookie Policy.</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;&nbsp;&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>In Short:&nbsp;</span></i></b><i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>We keep your information for as long as
necessary to <span class=SpellE>fulfill</span> the purposes outlined in this&nbsp;privacy
policy&nbsp;unless otherwise required by law.&nbsp;</span></i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>We will only keep your
personal information for as long as it is necessary for the purposes set out in
this&nbsp;privacy policy, unless a longer retention period is required or
permitted by law (such as tax, accounting or other legal requirements). No
purpose in this policy will require us keeping your personal information for
longer than&nbsp;the period of time in which users have an account with
us.&nbsp;</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>When we have no
ongoing legitimate business need to process your personal information, we will
either delete or anonymize it, or, if this is not possible (for example,
because your personal information has been stored in backup archives), then we
will securely store your personal information and isolate it from any further
processing until deletion is possible.&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>6. HOW DO WE KEEP YOUR INFORMATION SAFE?&nbsp;</span></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>In Short:&nbsp;</span></i></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;</span></b><i><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>We aim to protect your personal information through a system of
organizational and technical security measures.</span></i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>We have implemented
appropriate technical and organizational security measures designed to protect
the security of any personal information we process. However, please also
remember that we cannot guarantee that the internet itself is 100% secure.
Although we will do our best to protect your personal information, transmission
of personal information to and from our&nbsp;Services&nbsp;is at your own risk.
You should only access the services within a secure environment.&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>7. WHAT ARE YOUR PRIVACY RIGHTS?</span></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>In Short:&nbsp;</span></i></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;</span></b><i><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>In some regions, such as the European Economic Area, you have
rights that allow you greater access to and control over your personal information.&nbsp;You
may review, change, or terminate your account at any time.</span></i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>In some regions (like
the European Economic Area), you have certain rights under applicable data
protection laws. These may include the right (<span class=SpellE>i</span>) to
request access and obtain a copy of your personal information, (ii) to request
rectification or erasure; (iii) to restrict the processing of your personal
information; and (iv) if applicable, to data portability. In certain
circumstances, you may also have the right to object to the processing of your
personal information. To make such a request, please email admin@ampil.co.uk. We will consider and act upon any request
in accordance with applicable data protection laws.</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>If we are relying on
your consent to process your personal information, you have the right to
withdraw your consent at any time. Please note however that this will not
affect the lawfulness of the processing before its withdrawal.</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>If you are resident in
the European Economic Area and you believe we are unlawfully processing your
personal information, you also have the right to complain to your local data
protection supervisory authority. You can find their contact details here:</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#3030F1'><a
href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
target="_blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'><br>
</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>Account Information</span></b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>If you would at any
time like to review or change the information in your account or terminate your
account, you can:</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'>&nbsp; &nbsp; <span
class=GramE>■ &nbsp;<span style='color:#595959'>Contact</span></span></span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'> us using the contact information provided.&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>Upon your request to
terminate your account, we will deactivate or delete your account and
information from our active databases. However, some information may be
retained in our files to prevent fraud, troubleshoot problems, assist with any
investigations, enforce our Terms of Use and/or comply with legal
requirements.&nbsp;&nbsp;&nbsp;&nbsp;</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><u><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>Cookies and similar
technologies:</span></u></b><b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>&nbsp;</span></b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>Most Web browsers are set to accept cookies by
default. If you prefer, you can usually choose to set your browser to remove
cookies and to reject cookies. If you choose to remove cookies or reject
cookies, this could affect certain features or services of our&nbsp;Services.
To opt-out of interest-based advertising by advertisers on our&nbsp;Services</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>visit</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'>&nbsp;<a
href="http://www.aboutads.info/choices/" target="_blank"><span
style='color:#3030F1'>http://www.aboutads.info/choices/</span></a></span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><u><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>Opting out of email
marketing:</span></u></b><b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>&nbsp;</span></b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>You can unsubscribe from our marketing email
list at any time by clicking on the unsubscribe link in the emails that we send
or by contacting us using the details provided below. You will then be removed
from the marketing email list – however, we will still need to send you
service-related emails that are necessary for the administration and use of
your account. To otherwise opt-out, you may:&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp; &nbsp; <span class=GramE>■ &nbsp;<span
style='font-size:11.5pt'>Access</span></span></span><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'> your account settings and update preferences.</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>8. DATA BREACH</span></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>A privacy breach occurs when there is unauthorized access to or
collection, use, disclosure or disposal of personal information. You will be
notified about data breaches when&nbsp;<span class=SpellE>amPIL</span> Tracker&nbsp;believes
you are likely to be at risk of serious harm. For example, a data breach may be
likely to result in serious financial harm or harm to your mental or physical
well-being. In the event that&nbsp;<span class=SpellE>amPIL</span> Tracker&nbsp;becomes
aware of a security breach which has resulted or may result in unauthorized
access, use or disclosure of personal information&nbsp;<span class=SpellE>amPIL</span>
Tracker&nbsp;will promptly investigate the matter and notify the applicable
Supervisory Authority not later than 72 hours after having become aware of it,
unless the personal data breach is unlikely to result in a risk to the rights
and freedoms of natural persons.</span><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>Most web browsers and
some mobile operating systems and mobile applications include a Do-Not-Track
(“DNT”) feature or setting you can activate to signal your privacy preference
not to have data about your online browsing activities monitored and collected.
No uniform technology standard for recognizing and implementing DNT signals has
been finalized. As such, we do not currently respond to DNT browser signals or
any other mechanism that automatically communicates your choice not to be
tracked online. If a standard for online tracking is adopted that we must
follow in the future, we will inform you about that practice in a revised
version of this&nbsp;privacy policy.&nbsp;&nbsp;</span><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
PRIVACY RIGHTS?</span></b><b><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'>&nbsp;</span></b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>In Short:&nbsp;</span></i></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;</span></b><i><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>Yes, if you are a resident of California, you are granted
specific rights regarding access to your personal information.&nbsp;</span></i><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>California Civil Code
Section 1798.83, also known as the “Shine The Light” law, permits our users who
are California residents to request and obtain from us, once a year and free of
charge, information about categories of personal information (if any) we
disclosed to third parties for direct marketing purposes and the names and
addresses of all third parties with which we shared personal information in the
immediately preceding calendar year. If you are a California resident and would
like to make such a request, please submit your request in writing to us using
the contact information provided below.</span><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>If you are under 18
years of age, reside in California, and have a registered account with the&nbsp;Services,
you have the right to request removal of unwanted data that you publicly post
on the&nbsp;Services. To request removal of such data, please contact us using
the contact information provided below, and include the email address
associated with your account and a statement that you reside in California. We
will make sure the data is not publicly displayed on the&nbsp;Services, but
please be aware that the data may not be completely or comprehensively removed
from our systems.</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>11. DO WE MAKE UPDATES TO THIS POLICY?</span></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><b><i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>In Short:&nbsp;</span></i></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'>&nbsp;</span></b><i><span style='font-size:
11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>Yes, we will update this policy as necessary to stay compliant
with relevant laws.</span></i><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>We may update this&nbsp;privacy
policy&nbsp;from time to time. The updated version will be indicated by an
updated “Revised” date and the updated version will be effective as soon as it
is accessible. If we make material changes to this&nbsp;privacy policy, we may
notify you either by prominently posting a notice of such changes or by
directly sending you a notification. We encourage you to review this&nbsp;privacy
policy&nbsp;frequently to be informed of how we are protecting your
information.&nbsp;&nbsp;</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto'><b><span
style='font-size:14.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></b><b><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;</span></b><span style='font-size:11.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
text-align:justify'><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>If you have questions
or comments about this policy, you may&nbsp;email us at&nbsp;admin@ampil.co.uk&nbsp;or
by post to:</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span class=SpellE><span style='font-size:11.5pt;font-family:
"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";color:#595959'>amPIL</span></span><span
style='font-size:11.5pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:#595959'> Tracker&nbsp;&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>&nbsp;</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>Palmer Avenue</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>Cheadle&nbsp;SK8&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;</span><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>&nbsp;&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:#595959'>&nbsp;</span><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal><span style='font-size:11.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>United Kingdom</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'>&nbsp;&nbsp;</span><span style='font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>&nbsp;</span><span
style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><span style='font-family:"Arial",sans-serif;mso-fareast-font-family:
"Times New Roman";color:black'>&nbsp;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-top:8.05pt;margin-right:0cm;margin-bottom:
8.05pt;margin-left:0cm;mso-outline-level:1'><b><span style='font-size:14.5pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
color:black;text-transform:uppercase;mso-font-kerning:18.0pt'>HOW CAN YOU
REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?<o:p></o:p></span></b></p>

<p class=MsoNormal><span style='font-size:10.5pt;font-family:"Arial",sans-serif;
mso-fareast-font-family:"Times New Roman";color:#595959'>Based on the laws of
some countries, you may have the right to request access to the personal
information we collect from you, change that information, or delete it in some
circumstances. To request to review, update, or delete your personal
information, please submit a request by emailing admin@ampil.co.uk. We will respond to your request within 30 days.<o:p></o:p></span></p>

<p class=MsoNormal><o:p>&nbsp;</o:p></p></div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Terms and Conditions
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p>These terms and conditions outline the rules and regulations for the use of amPIL Tracker's Website.</p> <br />
	<span style="text-transform: capitalize;"> amPIL Tracker</span> is located at:<br />
	<address>Thirlmere, Macclesfield, Cheshire, , United Kingdom<br />
	</address>
	<p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use amPIL Tracker's website
	if you do not accept all of the terms and conditions stated on this page.</p>
	<p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
	and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website
	and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers
	to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client
	or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake
	the process of our assistance to the Client in the most appropriate manner, whether by formal meetings
	of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in respect
	of provision of the Company's stated services/products, in accordance with and subject to, prevailing law
	of United Kingdom. Any use of the above terminology or other words in the singular, plural,
	capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p><h2>Cookies</h2>
	<p>We employ the use of cookies. By using amPIL Tracker's website you consent to the use of cookies
	in accordance with amPIL Tracker's privacy policy.</p><p>Most of the modern day interactive web sites
	use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site
	to enable the functionality of this area and ease of use for those people visiting. Some of our
	affiliate / advertising partners may also use cookies.</p><h2>License</h2>
	<p>Unless otherwise stated, amPIL Tracker and/or it's licensors own the intellectual property rights for
	all material on amPIL Tracker. All intellectual property rights are reserved. You may view and/or print
	pages from http://www.ampil.co.uk for your own personal use subject to restrictions set in these terms and conditions.</p>
	<p>You must not:</p>
	<ol>
		<li>Republish material from http://www.ampil.co.uk</li>
		<li>Sell, rent or sub-license material from http://www.ampil.co.uk</li>
		<li>Reproduce, duplicate or copy material from http://www.ampil.co.uk</li>
	</ol>
	<p>Redistribute content from amPIL Tracker (unless content is specifically made for redistribution).</p>
<h2>Hyperlinking to our Content</h2>
	<ol>
		<li>The following organizations may link to our Web site without prior written approval:
			<ol>
			<li>Government agencies;</li>
			<li>Search engines;</li>
			<li>News organizations;</li>
			<li>Online directory distributors when they list us in the directory may link to our Web site in the same
				manner as they hyperlink to the Web sites of other listed businesses; and</li>
			<li>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls,
				and charity fundraising groups which may not hyperlink to our Web site.</li>
			</ol>
		</li>
	</ol>
	<ol start="2">
		<li>These organizations may link to our home page, to publications or to other Web site information so long
			as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or
			approval of the linking party and its products or services; and (c) fits within the context of the linking
			party's site.
		</li>
		<li>We may consider and approve in our sole discretion other link requests from the following types of organizations:
			<ol>
				<li>commonly-known consumer and/or business information sources such as Chambers of Commerce, American
					Automobile Association, AARP and Consumers Union;</li>
				<li>dot.com community sites;</li>
				<li>associations or other groups representing charities, including charity giving sites,</li>
				<li>online directory distributors;</li>
				<li>internet portals;</li>
				<li>accounting, law and consulting firms whose primary clients are businesses; and</li>
				<li>educational institutions and trade associations.</li>
			</ol>
		</li>
	</ol>
	<p>We will approve link requests from these organizations if we determine that: (a) the link would not reflect
	unfavorably on us or our accredited businesses (for example, trade associations or other organizations
	representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed
	to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from
	the visibility associated with the hyperlink outweighs the absence of ; and (d) where the
	link is in the context of general resource information or is otherwise consistent with editorial content
	in a newsletter or similar product furthering the mission of the organization.</p>

	<p>These organizations may link to our home page, to publications or to other Web site information so long as
	the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval
	of the linking party and it products or services; and (c) fits within the context of the linking party's
	site.</p>

	<p>If you are among the organizations listed in paragraph 2 above and are interested in linking to our website,
	you must notify us by sending an e-mail to <a href="mailto:admin@ampil.co.uk" title="send an email to admin@ampil.co.uk">admin@ampil.co.uk</a>.
	Please include your name, your organization name, contact information (such as a phone number and/or e-mail
	address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site,
	and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.</p>

	<p>Approved organizations may hyperlink to our Web site as follows:</p>

	<ol>
		<li>By use of our corporate name; or</li>
		<li>By use of the uniform resource locator (Web address) being linked to; or</li>
		<li>By use of any other description of our Web site or material being linked to that makes sense within the
			context and format of content on the linking party's site.</li>
	</ol>
	<p>No use of amPIL Tracker's logo or other artwork will be allowed for linking absent a trademark license
	agreement.</p>
<h2>Iframes</h2>
	<p>Without prior approval and express written permission, you may not create frames around our Web pages or
	use other techniques that alter in any way the visual presentation or appearance of our Web site.</p>
<h2>Reservation of Rights</h2>
	<p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular
	link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also
	reserve the right to amend these terms and conditions and its linking policy at any time. By continuing
	to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</p>
<h2>Removal of links from our website</h2>
	<p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact
	us about this. We will consider requests to remove links but will have no obligation to do so or to respond
	directly to you.</p>
	<p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness
	or accuracy; nor do we commit to ensuring that the website remains available or that the material on the
	website is kept up to date.</p>
<h2>Content Liability</h2>
	<p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify
	and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any
	page on your Web site or within any context containing content or materials that may be interpreted as
	libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
	other violation of, any third party rights.</p>
<h2>Disclaimer</h2>
	<p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
	<ol>
	<li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
	<li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
	<li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
	<li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
	</ol>
	<p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
	are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or
	in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
	(including negligence) and for breach of statutory duty.</p>
	<p>To the extent that the website and the information and services on the website are provided free of charge,
	we will not be liable for any loss or damage of any nature.</p>
<h2>Our content </h2>
<p>Unless otherwise indicated, the Site and Services including source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (Our Content) are owned or licensed to us, and are protected by copyright and trade mark laws. </p>
<p>Except as expressly provided in these Terms and Conditions, no part of the Site, Services or Our Content may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>
<p>Provided that you are eligible to use the Site, you are granted a limited licence to access and use the Site and Our Content and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use.   </p>
<p>You shall not (a) try to gain unauthorised access to the Site or any networks, servers or computer systems connected to the Site; and/or (b) make for any purpose including error correction, any modifications, adaptions, additions or enhancements to the Site or Our Content, including the modification of the paper or digital copies you may have downloaded. </p>
<p>We shall (a) prepare the Site and Our Content with reasonable skill and care; and (b) use industry standard virus detection software to try to block the uploading of content to the Site that contains viruses. </p>
<p>The content on the Site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from taking, any action on the basis of the content on the Site. </p>
<p>Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that Our Content on the Site is accurate, complete or up to date. </p>

<h2>Acceptable Use</h2>
<p>You may not access or use the Site for any purpose other than that for which we make the site and our services available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us. </p>

<h2>General</h2>
<p>These Terms and Conditions shall remain in full force and effect while you use the Site or Services or are otherwise a user of the Site, as applicable. You may terminate your use or participation at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us at admin@ampil.co.uk.</p>
<p>Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing.

    <br><b>You hereby agree to the use of electronic signatures, contracts, orders and other records and to electronic delivery of notices, policies and records of transactions initiated or completed by us or via the Site</b>. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by other than electronic means. </p>
<p>These Terms and Conditions and any policies or operating rules posted by us on the Site or in respect to the Services constitute the entire agreement and understanding between you and us.  </p>
<p>Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.  </p>
<p>We may assign any or all of our rights and obligations to others at any time.  </p>
<p> We shall not be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond our reasonable control. </p>
<p>If any provision or part of a provision of these Terms and Conditions is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining provisions. </p>
<p>There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or use of the Site or Services.  </p>
<p>The following are trade marks of www.ampil.co.uk. You are not permitted to use them without our approval, unless they are part of material our Site explicitly states you are permitted to use. </p>
<p>A person who is not a party to these Terms and Conditions shall have no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of these Terms and Conditions. </p>
<p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us by email at admin@ampil.co.uk</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="lime"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>

<script>
export default {
  name: "Privacy",
  props: {},
  mounted() {},
  data() {
    return {
        dialog: false,
    };
  },
  methods: {},
  computed: {},
};
</script>
