<template>
    <v-container>
        <v-dialog
      v-model="dialog"
      max-width="290"
      persistent
    >
      <v-card>
        <ampil-card title="Change subject"></ampil-card>

        <v-card-actions>
            <v-select
          :items="subMenu"
          item-value="studentid"
          item-text="subjectname"
          label="Subject"
          outlined
          v-model="selectedSubject"
        ></v-select>
          <v-spacer></v-spacer>

          <v-btn
            color="lime"
            text
            @click="submit()"
          >
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
</template>

<script>
export default {
  name: "StudentSubject",
  props: {},
  mounted() {
    this.getSubjects()
  },
  data() {
    return {
        dialog: true,
        subjects: [],
        selectedSubject: null
    };
  },
  methods: {
    submit(){
        let d = this.getAll(this.selectedSubject)
        this.$store.commit('setStudentID', d.student)
        this.$store.commit('setSubject', d.subject)
        this.$router.push('/student')
        //this.dialog = false
    },
    mapSubjects(s){
        let sub = this.subjects.find(a=>a.subjectid==s)
        if (sub!=undefined){
            return sub.subject_name
        }
        
    },
    getAll(s){
        let su = this.$store.getters.getStudentSubjects
        let found = su.find(a=>a.student==s)
        return found
    },
    getSubjects(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/39",
        params: {

        },
        responseType: "json",
      })
        .then((response) => {
          this.subjects = response.data
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    subMenu(){
        let su = this.$store.getters.getStudentSubjects
        let ret = []
        for (let i=0;i<su.length;i++){
            ret.push({subjectid: su[i].subject, studentid: su[i].student, subjectname: this.mapSubjects(su[i].subject)})
        }
        return ret
    },

   

  },
};
</script>
