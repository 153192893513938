<template>
  <v-container>
    <search :input="Subjects" searchfield="name" @search="searchArray"></search>
    <v-dialog v-model="Popup" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ PopupTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            label="Subject Name"
            hide-details="auto"
            v-model="SubjectName"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="lime" text @click="Popup = !Popup">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="lime" text @click="Submit()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col md="10">
        <v-row>
          <v-col md="5">
            <v-card class="mx-auto my-2" width="374" elevation="1">
              <v-card-actions class="justify-center">
                <v-btn
                  class="mx-auto my-auto"
                  fab
                  dark
                  x-large
                  color="lime"
                  @click="NewSubject()"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-title class="justify-center"
                >Add New Subject</v-card-title
              >
            </v-card>
          </v-col>

          <v-col md="5" v-for="Subject in Subjects2" :key="Subject.id">
            <card-view
              :Name="Subject.name"
              :id="Subject.id"
              :CanSelect="true"
              Img="/images/question.jpeg"
              @edit="edit(Subject.id)"
              @delete="del(Subject.id)"
            >
            </card-view>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardView from "@/components/admin/CardView";
import Search from "@/components/admin/Search";
export default {
  name: "AdminSubject",
  components: {
    CardView,
    Search,
  },
  data: () => ({
    Subjects: [],
    Subjects2: [],
    Popup: false,
    PopupTitle: "",
    SubjectName: "",
    SubjectID: null,
    submitted: "",
    SchoolID: null,
  }),
  mounted() {
    this.setScrollBar();
    this.getSubjects();
  },
  props: {
    CanSelect: Boolean,
  },
  methods: {
    setScrollBar() {
      this.$store.commit("setScrollBar", {
        slug: "admin_school",
        data: [
          { ID: 1, Name: "Add/Modify Subjects" },
          { ID: 2, Name: "Schemes of Work" },
          { ID: 3, Name: "Assessment Data" },
          { ID: 4, Name: "Classes" },
          { ID: 5, Name: "Topic Mappings" },
          { ID: 6, Name: "Terminal Exams" },
          { ID: 7, Name: "Assign teachers to classes"},
        ],
      });
    },
    edit(e) {
      this.SubjectName = this.Subjects[this.getName(e)].name;
      this.SubjectID = this.Subjects[this.getName(e)].id;
      this.SchoolID = 1;
      this.PopupTitle = "Edit Subject";
      this.Popup = !this.Popup;
    },
    del(e) {
      this.getName(e);
      this.deleteSubject(e);
    },
    deleteSubject(e) {
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + e,
        data: {
          table: "subject",
          //SchoolID: 1,
        },
        responseType: "json",
      })
        .then((response) => {
          this.submitted = response.data;
          this.getSubjects();
          //this.getSum();
          //this.reset();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubjects() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/1",
        params: {
          SchoolID: 1,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Subjects = [];
          for (let i = 0; i < response.data.length; i++) {
            //this.Subjects[i].name = response.data[i].subject_name
            //this.Subjects[i].id = response.data[i].subjectid

            this.Subjects.push({
              name: response.data[i].subject_name,
              id: response.data[i].subjectid,
            });
          }
          this.Subjects2 = this.Subjects;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    NewSubject() {
      this.PopupTitle = "Add New Subject";
      this.SubjectName = "";
      this.SubjectID = "";
      this.SchoolID = 1;
      this.Popup = !this.Popup;
    },
    getName(e) {
      for (let i = 0; i < this.Subjects2.length; i++) {
        if (e == this.Subjects2[i].id) {
          return i;
        }
      }
    },
    Submit() {
      this.Popup = !this.Popup;
      this.addSubject();
    },
    addSubject() {
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "subject",
          data: [
            {
              keyval: { subjectid: this.SubjectID  || null},
              values: {
                SchoolID: this.SchoolID,
                subject_name: this.SubjectName,
              },
            },
          ],
        },
        responseType: "json",
      })
        .then((response) => {
          this.submitted = response.data;
          this.getSubjects();
          //this.getSum();
          //this.reset();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchArray(v) {
      this.Subjects2 = v;
    },
  },
};
</script>
