<template>
  <select-student></select-student>
</template>

<script>
import SelectStudent from "@/components/student/SelectStudent.vue";

export default {
  name: "StudentSelect",

  components: {
    SelectStudent,
  },
  watch: {
    getStudentID() {
      this.$router.push("/student");
    },
  },
  computed: {
    getStudentID() {
      return this.$store.getters.getStudentID;
    },
  },
};
</script>
