import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import SecurePage from "../views/SecurePage.vue";
import LoginView from "../views/Login.vue";
import Testing from "../views/Testing.vue";
//Unauthenticated views
import ChangePassword from "../views/ChangePassword.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import JoinCode from "../views/JoinCode.vue";
import Register from "../views/Register.vue";
import Verify from "../views/Verify.vue";
//Profile
import Profile from "../views/Profile.vue";
//Student view
import StudentHome from "../views/student/Home.vue";
import StudentIndex from "../views/student/Index.vue";
import StudentSubmit from "../views/student/Submit.vue";
import StudentSelect from "../views/student/Select.vue";
import StudentSubject from "../views/student/Subject.vue";
//Admin view
import AdminHome from "../views/admin/Home.vue";
import AdminIndex from "../views/admin/Index.vue";
import AdminSubject from "../views/admin/NewSubject.vue";
import AdminSoW from "../views/admin/SoW.vue";
import AdminAssessments from "../views/admin/Assessments.vue";
import AdminClasses from "../views/admin/Classes.vue";
import AdminTeachers from "../views/admin/Teachers.vue";
//import AdminMappings from "../views/admin/Mapping.vue";
import AdminTerminal from "../views/admin/Terminal.vue";
import AdminAddStudents from "../views/admin/AddStudents.vue";
import AdminUploadStudents from "../views/admin/UploadStudents.vue";
import AdminMappings from "../views/admin/Mapping.vue";
import AdminExport from "../views/admin/ExportCSV.vue";
import AdminUsers from "../views/admin/Users.vue";
import AdminUsersStudents from "../views/admin/UsersStudents.vue";
//Teacher View
import TeacherIndex from "../views/teacher/TeacherIndex.vue";
import TeacherHome from "../views/teacher/TeacherHome.vue";
import Markbook from "../views/teacher/Markbook.vue";
//School View
import LeaderIndex from "../views/leader/LeaderIndex.vue";
import LeaderHome from "../views/leader/LeaderHome.vue";
import LeaderPrior from "../views/leader/LeaderPrior.vue";
import MarkbookLeader from "../views/leader/Markbook.vue";
import store from "../store";
import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      //requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/verify",
    name: "verify",
    component: Verify,
  },
  {
    path: "/secure",
    name: "secure",
    component: SecurePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auth/callback",
    component: SecurePage,
  },
  {
    path: "/testing",
    name: "testing",
    component: Testing
  },
  {
    path: "/email/change-password",
    name: "change-password",
    component: ChangePassword
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/code",
    name: "join-code",
    component: JoinCode
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/student",
    //name: "student",
    component: StudentIndex,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: StudentHome,
      },
      {
        path: "submit",
        component: StudentSubmit,
      },
      {
        path: "select",
        component: StudentSelect,
      },
      {
        path: "subject",
        component: StudentSubject,
      },
    ],
  },
  {
    path: "/admin",
    //name: "admin",
    component: AdminIndex,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: AdminHome,
      },
      {
        path: "subjects",
        component: AdminSubject,
      },
      {
        path: "sow",
        component: AdminSoW,
      },
      {
        path: "assessments",
        component: AdminAssessments,
      },
      {
        path: "classes",
        component: AdminClasses,
      },
      {
        path: "mappings",
        component: AdminMappings,
      },
      {
        path: "terminal",
        component: AdminTerminal,
      },
      {
        path: "add_students",
        component: AdminAddStudents,
      },
      {
        path: "upload_students",
        component: AdminUploadStudents,
      },
      {
        path: "export",
        component: AdminExport,
      },
      {
        path: "teachers",
        component: AdminTeachers,
      },
      {
        path: "users",
        component: AdminUsers,
      },
      {
        path: "users_students",
        component: AdminUsersStudents,
      },
    ],
  },
  {
    path: "/teacher",
    //name: "teacher",
    component: TeacherIndex,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: TeacherHome,
      },
      {
        path: "markbook",
        name: "Markbook",
        component: Markbook,
        props: true,
      },
    ],
    
  },
  {
    path: "/leader",
    //name: "leader",
    component: LeaderIndex,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: LeaderHome,
      },
      {
        path: "prior",
        component: LeaderPrior,
      },
      {
        path: "markbook",
        component: MarkbookLeader,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    $cookies.get("logged_in") == "false" ||
    !store.getters.isAuthenticated ||
    $cookies.isKey("logged_in") == false
  ) {
    await axios({
      method: "get",
      url: process.env.VUE_APP_BACKEND+"/checkauth",
      //url: "http://127.0.0.1:8000/api/checkauth",
      responseType: "json",
    })
      .then(function (response) {
        //console.log(response.data)
        store.dispatch("login");
        store.commit("setScope", response.data);
        $cookies.set("logged_in", true);
      })
      .catch(function () {
        store.dispatch("logout");
        $cookies.set("logged_in", false);
      });
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ name: "login" });
    } else {
      let path = to.matched[0].path
      path = path.slice(1);
      let scope = store.getters.getScope
      if (scope == "admin" && path!='profile'){
        store.commit("setViewAs", path);
        next();
      }
      else if (scope =="leader"){
        if (path=="admin"){
          next({ name: "home" });
        }
      }
      else if (scope =="teacher"){
        if (path=="admin" || path=="leader"){
          next({ name: "home" });
        }
      }
      else if (scope =="student"){
        if (path=="admin" || path=="leader" || path=="teacher"){
          next({ name: "home" });
        }
      }
      //if (to.matched[0].path != "/" + store.getters.getViewAs) {

        //if path doesn't match getter
        //console.log(to.matched)
        //next({ name: "login" });
      //}
      //authenticated
      if (path=="admin" || path=="leader" || path=='teacher' || path=='student'){
        store.commit("setViewAs", path);
      }
      
      next();
    }
  } else {
    //route doesn't require auth
    next();
  }
});

export default router;
