<template>
  <v-container>
    <div class="text-center">
        <v-card max-width="400" class="mx-auto my-12">
            <ampil-card title="Add student accounts"></ampil-card>
            <v-card-text>
              <p>To add student accounts, they simply need a joining code, where they will then be asked for their name and to confirm their date of birth</p>
              <p>Your school joining code is:</p>
              <p><div class="text-h3">{{ code.code }}</div></p>
              <p>Students will be able to either scan a QR code, or visit a link and type in the code</p>
              <p>You can print stickers with the QR code and joining details by using the button below</p>
              <p>Optional: if you want to limit student sign ups to use a fixed email domain (such as @myschool.sch.uk) you may enter the domain below</p>
              <strong v-if="code.domain!=null">Email domain currently restricted to '...@{{ code.domain }}'</strong>
              <v-form v-model="valid">
                <v-text-field
                v-model="domain"
                :rules="[rules.domain]"
                label="Domain (optional)"
              ></v-text-field>
              </v-form>

            </v-card-text>
            <v-card-actions>
              <confirm-action v-if="valid==true" Title="Generate new code" btntext="Regenerate code" Message="Warning: this will invalidate all codes used previously. Accounts that are already activated will not be affected." @confirm="newCode()"></confirm-action>
                <v-spacer></v-spacer>
                
                <v-btn color="lime" text @click="reportgen()">Print stickers</v-btn>
            </v-card-actions>
        </v-card>
    </div>
    <div class="text-center" v-if="ready">
      <v-data-table
    :headers="headers"
    :items="students.users"
    class="elevation-1"
  >
  <template
        v-slot:item.student="{ item }"
      >
        <ul v-for="stu in getDetails(item.student)">
          <li>{{ stu }}</li>
        </ul>
      </template>
      <template
        v-slot:item.pass="{ item }"
      >
      <confirm-action Title="Reset Password" btntext="Reset Password" Message="This will change the student password to the word 'password' and will email them a prompt to change their password" @confirm="reset(item.id)"></confirm-action>
      </template>
      <template
        v-slot:item.delete="{ item }"
      >
      <confirm-action Title="Remove Account" btntext="Remove account" Message="This will remove the student account completely. Are you sure you wish to continue?" @confirm="deleteAcc(item.id)"></confirm-action>
      </template>
</v-data-table>
    </div>
  </v-container>
</template>

<script>
import ConfirmAction from "@/components/admin/ConfirmAction";
export default {
  name: "UsersStudents",
  components: {
    ConfirmAction,
  },
  props: {},
  mounted() {
    this.setScrollBar()
    this.checkCode()
    this.getStudents()
  },
  data() {
    return {
      domain: "",
      valid: true,
      code: "",
      ready: false,
      students: [],
      headers: [
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'Subject accounts', value: 'student' },
          { text: 'Reset Password', value: 'pass' },
          { text: 'Delete Account', value: 'delete' }
        ],
      rules: {
          domain: value => {
            const pattern = /^$|^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
            return pattern.test(value) || 'Invalid domain.'
          },
      },
    }
  },
  methods: {
    reset(s){
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/users_students/"+s,
        params: {

        },
        responseType: "json",
      })
        .then((response) => {
          //console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteAcc(s){
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/users_students/"+s,
        params: {

        },
        responseType: "json",
      })
        .then((response) => {
          //console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
      this.getStudents()
    },
    getDetails(s){
      let text = []
      for (let i=0;i<s.length;i++){
        let stu = this.students.students.find(a=> a.UniqueID==s[i].student)
        if (stu!=undefined){
          text.push(stu.LastFirst+" - "+stu.subject_name+" ("+stu.SetName+")")
        }
      }
      return text
    },
    getStudents(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/users_students",
        params: {

        },
        responseType: "json",
      })
        .then((response) => {
          this.students = response.data
          this.ready=true
          console.log(this.students)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setScrollBar() {
      this.$store.commit("setScrollBar", {
        slug: "admin_users",
        data: [
          { ID: 1, Name: "Staff" },
          { ID: 2, Name: "Students" },
          //{ ID: 3, Name: "Parents" },
        ],
      });
    },
    checkCode(){
      //check if a code exists
      //make one if not
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/users_students/code",
        params: {
          subject: this.$store.getters.getSubject,
          code: this.makeid(7),
          domain: this.domain
        },
        responseType: "json",
      })
        .then((response) => {
          this.code = response.data
        })
        .catch((error) => {
          console.log(error);
        });
      //set variable code to it
    },
    newCode(){
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/users_students/code",
        params: {
          subject: this.$store.getters.getSubject,
          code: this.makeid(7),
          forcenew: 1,
          domain: this.domain
        },
        responseType: "json",
      })
        .then((response) => {
          console.log(response.data)
          this.code = response.data
        })
        .catch((error) => {
          console.log(error);
        });
      //check if code exists in db
      //if it does, regenerate
      //save to db
    },
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    reportgen: function () {
      var link = "https://www.ampil.co.uk/code"
      var code = this.code.code
        var pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require("pdfmake/build/vfs_fonts.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          content: [

{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},

],
styles: {
		header: {
			fontSize: 18,
			bold: true
		},
		bigger: {
			fontSize: 15,
			italics: true
		}
	},
          defaultStyle: {columnGap: 20},
        };
        //console.log(content)
        pdfMake.createPdf(docDefinition).download("Signup_sheet.pdf");
      },
  },
  computed: {},
};
</script>
