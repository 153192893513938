import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authenticated: false,
    scope: "",
    viewAs: "",
    name: "",
    school: "",
    studentDetails: [],
    selectedTest: null,
    scrollBar: {},
    studentID: null,
    Subject: 1,
    yeargroup: null,
    teacherID: null,
    class: null,
    studentSubjects: [],
    errorMsg: {},
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated;
    },
    getScope(state) {
      return state.scope;
    },
    getStudentSubjects(state) {
      return state.studentSubjects;
    },
    getName(state) {
      return state.name;
    },
    getSchool(state) {
      return state.school;
    },
    getViewAs(state) {
      return state.viewAs;
    },
    getStudentDetails(state) {
      return state.studentDetails;
    },
    getSelectedTest(state) {
      return state.selectedTest;
    },
    getScrollBar(state) {
      return state.scrollBar;
    },
    getStudentID(state) {
      return state.studentID;
    },
    getSubject(state) {
      return state.Subject;
    },
    getYeargroup(state) {
      return state.yeargroup;
    },
    getTeacherID(state) {
      return state.teacherID;
    },
    getClass(state){
      return state.class;
    },
    getErrorMsg(state){
      return state.errorMsg;
    }
  },
  mutations: {
    login(state) {
      state.authenticated = true;
    },
    logout(state) {
      state.scope = "";
      state.viewAs = "";
      state.name = "";
      state.school = "";
      state.authenticated = false;
    },
    setScope(state, data) {
      state.scope = data.scope;
      state.viewAs = data.scope;
      state.name = data.name;
      state.school = data.school;
    },
    setStudentSubjects(state, payload) {
      state.studentSubjects = payload;
    },
    setViewAs(state, data) {
      state.viewAs = data;
    },
    setStudent(state, payload) {
      state.studentDetails = payload;
    },
    setSelectedTest(state, payload) {
      state.selectedTest = payload;
    },
    setScrollBar(state, payload) {
      state.scrollBar = payload;
    },
    setStudentID(state, payload) {
      state.studentID = payload;
    },
    setTeacherID(state, payload) {
      state.teacherID = payload;
    },
    setSubject(state, payload) {
      state.Subject = payload;
    },
    setYeargroup(state, payload) {
      state.yeargroup = payload;
    },
    setClass(state, payload) {
      state.class = payload;
    },
    setErrorMsg(state, payload) {
      state.errorMsg = payload;
    },
  },
  actions: {
    login({ commit }) {
      commit("login");
    },
    logout({ commit }) {
      commit("logout");
    },
  },
  modules: {},
});
