<template>
  <div>
    <v-dialog v-model="dialog" width="90%" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item link v-if="side">
          <v-list-item-title @click="dialog = !dialog"
            >Upload Student Assessment Totals</v-list-item-title
          >
        </v-list-item>
        <v-btn v-else color="lime" text @click="dialog = !dialog">
          <v-icon>mdi-cloud-upload-outline</v-icon>
          Totals
        </v-btn>
      </template>

      <v-card>
        <ampil-card
          title="Student Assessment Scores:"
          :items="menu"
          @tab="changeTab"
        ></ampil-card>
        <v-card-text v-if="tab == 1">
          <upload-totals-manual
            :testName="testName"
            :test="test"
            :total="parseInt(total)"
          ></upload-totals-manual>
        </v-card-text>
        <v-card-text v-else-if="tab==2">
          <upload-totals-csv
            :testName="testName"
            :test="test"
            :total="parseInt(total)"
            :setMappings="[]"
          ></upload-totals-csv>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="lime" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UploadTotalsManual from "@/components/teacher/UploadTotalsManual.vue";
import UploadTotalsCsv from "@/components/teacher/UploadTotalsCsv.vue";
//import UploadTotalsRemove from "@/components/teacher/UploadTotalsRemove.vue";
export default {
  name: "UploadTotals",
  components: {
    UploadTotalsManual,
    UploadTotalsCsv,
    //UploadTotalsRemove,
  },
  props: {
    side: Boolean,
    testName: String,
    test: Number,
    total: Number,
  },
  data() {
    return {
      dialog: false,
      menu: [
        { id: 1, name: "Manual Entry" },
        { id: 2, name: "Bulk Upload" },
      ],
      tab: 1,
    };
  },
  methods: {
    changeTab(i) {
      this.tab = i;
    },
  },
};
</script>
