<template>
  <v-container>
    <v-card elevation="10">
      <v-toolbar
        src="/images/slide_01.jpg"
        dark
        color="grey darken-3"
        class="text-h5"
      >
        <v-avatar tile color="black">
          <v-icon dark> mdi-traffic-light </v-icon>
        </v-avatar>
        Progress predictors:
      </v-toolbar>
      <v-card-text>
        Scheme of Work:
        <v-menu offset-y v-if="trigger == true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="light-green darken-3"
              text
              dark
              v-bind="attrs"
              v-on="on"
              @click="changeSoW()"
            >
              {{ SoWID.Name }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in SoW"
              :key="index"
              @click="selSoW(item.SoWId, item.SoWName)"
            >
              <v-list-item-title>{{ item.SoWName }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-skeleton-loader
        v-if="trigger==false"
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
        <v-data-table
          v-else
          :headers="headers"
          :items="sowTopics"
          :items-per-page="5"
  
        >
          <template v-for="student in classDetails" v-slot:[`item.${student.UniqueID}`]="{ item }">
            <v-progress-circular
              @click="breakdown(item.SoWTopicID, item.SoWTopic, student.UniqueID, item[student.UniqueID])"
              :rotate="360"
              :size="30"
              :width="2"
              :value="item[student.UniqueID]"
              :color="ragcolour(item[student.UniqueID])"
            >
              {{ item[student.UniqueID] }}
            </v-progress-circular>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showbd" width="500">
      <v-card>
        <v-card-title
          class="text-h7 light-green darken-3 pink--text text--lighten-5"
        >
        {{ bd.StudentName }}
        
        </v-card-title>

        <v-card-text>
          <h3>{{ bd.Topic }}</h3>
          <p>
            The judgements for this rating come from the following questions:
          </p>
          <div v-for="(item, index) in bd.data" :key="index">
            <p class="text-overline font-weight-bold">
              {{ item.TestName }}
            </p>
            <p>
              Q{{ item.QuestionNumber }}) {{ item.TestTopic }} Score: {{ item.MarkAwarded }}/{{
                item.MarksAvailable
              }}
              ({{ Math.round((item.MarkAwarded/item.MarksAvailable)*100) }}%)
            </p>
            <v-divider></v-divider>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light-green darken-3" text @click="showbd = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import axios from "axios";
//axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_AUTH_KEY;
export default {
  name: "Home",
  components: {},
  props: {
    setID: Number
  },
  data: () => ({
    sowTopics: {},
    studentRAG: {},
    allRAG: [],
    SoW: [],
    SoWID: {},
    trigger: false,
    showbd: false,
    bd: {},
    classDetails: [],
    headers: [
      {
        text: "Topic",
        align: "start",
        sortable: false,
        value: "SoWTopic",
      },
    ],
  }),
  mounted() {
    this.getClass()
  },
  watch: {
    setID() {
      if (this.setID > 0) {
        this.getClass()
        
      }
    },
  },
  methods: {
    getClass(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/15",
        params: {
          SetID: this.setID,
        },
        responseType: "json",
      })
        .then((response) => {
          this.classDetails = response.data
          this.SoWID.ID = this.classDetails[0].SoWId;
          this.SoWID.Name = this.classDetails[0].SoWName;
          this.getRAG(this.SoWID.ID);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRAG(s) {
      this.trigger = false;
      this.sowTopics = {};
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/14",
        params: {
          SoWID: s,
          SetID: this.setID,
        },
        responseType: "json",
      })
        .then(async (response) => {
          //get unique students
          this.allRAG = response.data
          this.getSoW(0,0,s)
          
          //this.getQuestions(response.data, s);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSoW(rag, q, s) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/3",
        params: {
          SoWID: s,
        },
        responseType: "json",
      })
        .then((response) => {
          this.sowTopics = response.data;
          this.headers = [{text: "Topic",align: "start",sortable: false,value: "SoWTopic",},]
          for (let i=0;i<this.classDetails.length;i++){
            //add headers
            
            this.headers.push({text: this.classDetails[i].LastFirst, value: this.classDetails[i].UniqueID.toString()})
            let studentRag = this.allRAG.filter((s) => s.StudentID == this.classDetails[i].UniqueID);
            for (let k = 0; k < this.sowTopics.length; k++) {
              for (let j = 0; j < studentRag.length; j++) {
                if (this.sowTopics[k].SoWTopicID == studentRag[j].SoWTopicID) {
                  this.sowTopics[k][this.classDetails[i].UniqueID] = parseInt(studentRag[j].Rag * 100);
                }
              }

            }
          }
          this.trigger = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getQuestions(id, s, rag) {
      const res = await this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/32",
        params: {
          SoWID: s,
          UniqueID: id,
        },
        responseType: "json",
      })
      return res.data
    },
    changeSoW() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/2",
        params: {
          SubjectID: this.getStudent.SubjectID,
        },
        responseType: "json",
      })
        .then((response) => {
          this.SoW = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selSoW(s, n) {
      this.SoWID.ID = s;
      this.SoWID.Name = n;
      this.getRAG(s);
    },
    ragcolour(c) {
      let colour = "";
      if (c > this.classDetails[0].Red) {
        colour = "red";
      }
      if (c > this.classDetails[0].Amber) {
        colour = "amber";
      }
      if (c > this.classDetails[0].Green) {
        colour = "green";
      }
      return colour;
    },
    breakdown(topicid, name, sid, score) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/16",
        params: {
          SoWTopicID: topicid,
          StudentID: sid
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.bd.StudentName = response.data[0].LastFirst
            this.bd.Topic = response.data[0].SoWTopic;
            this.bd.data = response.data;
            this.showbd = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //if (c.length > 0) {
      //  this.showbd = true;
      //  this.bd.SoW = t;
      //  this.bd.data = c;
      //}
    },
  },
  computed: {
    getSchool() {
      return this.$store.getters.getSchool;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
  },
};
</script>
