<template>
    <div fluid color="#50900c" class="fill-height">
        <v-app-bar
      app
      color="#50900c"
      dark
      clipped-right
    >
      <div class="d-flex align-center">
        <v-img
          alt="amPIL Logo"
          class="shrink mr-2"
          contain
          src="/images/logo2.png"
          transition="scale-transition"
          width="40"
        />
        <v-toolbar-title
          class="pink--text text--lighten-5 font-weight-bold d-none d-sm-flex"
          >amPIL Tracker</v-toolbar-title
        >

      </div>
      <v-spacer></v-spacer>
      <v-toolbar-title
          class="pink--text text--lighten-5 d-sm-flex"
          >
          <v-btn class="pink--text text--lighten-5" text @click="$router.push('register')">Sign Up</v-btn>
          <v-btn class="pink--text text--lighten-5" text @click="$router.push('login')">Log In</v-btn>
          </v-toolbar-title
        >
    </v-app-bar>
        <v-carousel v-model="model" height="100vh" vertical-delimiters vertical dark cycle interval="15000">
    <v-carousel-item
      v-for="(page, i) in pages"
      :key="page.key"
    >
      <v-sheet
        :color="page.color"
        height="100%"
        tile
      >
        <v-row
          class="fill-height py-10 px-15 d-lg-block"
        >
            <div class="white--text">
            <h3 class="text-h4">{{ page.title }}</h3>
            <p>
                <v-row>
                    <v-col cols="12" sm="12" md="6">
                        <div class="text-body-2 px-5 pt-5">
                            <p>
                                {{ page.text }}
                            </p>
                            <p>
                                {{ page.text2 }}
                            </p>
                            <ul v-if="page.list">
                                <li v-for="item in page.list">
                                    {{ item.text }}
                                </li>
                            </ul>
                            <ol v-if="page.olist">
                                <li v-for="item in page.olist">
                                    {{ item.text }}
                                </li>
                            </ol>
                            
                        </div>
                    </v-col>
                    <v-col cols="12" sm="8" md="6">
                        <div>
                            <v-img class="d-none d-md-flex" style="border-top-right-radius: 50%; border-top-left-radius: 50%;" :src="page.img" max-width="800"></v-img>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-img class="d-block float-right d-md-none" style="border-top-right-radius: 50%; border-top-left-radius: 50%; justify: center;" :src="page.img" max-width="280"></v-img>
                  </v-col>
                  
                </v-row>
          </p>  
          </div> 

        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
  <object class="d-none d-md-block" style="position: fixed; bottom: 10%; right: 0%" data="images/ampil.svg" type="image/svg+xml"></object>
  <object class="d-none d-md-block" style="position: fixed; bottom: 10%; left: 25px" data="images/ampilred.svg" type="image/svg+xml"></object>
</div>
</template>

<script>
export default {
  name: "Splash",
  props: {},
  mounted() {},
  data() {
    return {
        model: 0,
      pages: [
        {
          key: 1,
          title: "Question level analysis made easy",
          text: "At its heart amPIL is a free QLA toolkit that links with a given scheme of work. We help free up your time by allowing students to enter their own data, and automatically drawing out insights essential to learning",
          text2: "Predict the topics students are likely to struggle with before you step foot in the classroom",
          list: [
            {text: "Topic prediction insights"},
            {text: "Revision lists"},
            {text: "Post assessment feedback sheets"},
          ],
          color: 'black',
          img: "/images/splash1.gif"
        },
        {
          key: 2,
          title: "Make the summative informative", 
          text: "We believe the best teaching happens when teachers understand when to push and when extra support is needed. By providing amPIL with the prerequisite knowledge necessary for a scheme of work, we will use QLA data to help teachers pre-empt this.", 
          color: 'grey darken-4', 
          img: "/images/splash2.gif"
        },
        {key: 3, 
          title: '"Adaptive teaching is about noticing when pupils are finding the work hard or easy, and then being bothered to do something about it."', 
          text: "Ben Newmark", 
          color: 'grey darken-3', 
          img: "/images/splash8.gif"
        },
        {
          key: 4, 
          title: "amPIL for teachers", 
          text: "Class teachers are only presented with the data they need to help their students. No additional clutter", 
          list: [
            {text: "Topic prediction insights"},
            {text: "Class strengths and weaknesses from each assessment"},
            {text: "QLA Feedback sheets (class and individual)"},
            {text: "Allocate feedback tasks to students"},
            
          ],
          color: 'grey darken-2', 
          img: "/images/splash3.gif"
        },
        {
          key: 5, 
          title: "amPIL for students", 
          text: "Our student dashboard provides personalised access to:", 
          list: [
            {text: "Revision lists for upcoming assessments"},
            {text: "QLA data entry validated by total scores"},
            {text: "Detailed post assessment feedback"},
            {text: "Topic prediction insights"},
            
          ],
          color: 'grey darken-1', 
          img: "/images/splash4.gif"
        },
        {
          key: 6, 
          title: "amPIL for leaders", 
          text: "Subject leaders are given additional features to help drive improvement", 
          list: [
            {text: "Help generate internal reporting grades"},
            {text: "Compare class performance, and historic performance"},
            {text: "Manage the post assessment feedback process"},
            {text: "Automatically generate revision lists"},
            
          ],
          color: 'black', 
          img: "/images/splash5.png"
        },
        {
          key: 7, 
          title: "Internal grade reporting", 
          text: "amPIL will help generate `working at` grades by ranking students and overlaying a grade profile, even if students have not all sat the same assessment", 
          text2: "Although amPIL's algorithm has proved to be quite reliable, and will provide an inital rank order, teaching staff have full control over the final output using a drag and drop interface", 
          color: 'grey darken-4', 
          img: "/images/splash6.gif"
        },
        {
          key: 8,
          title: "Workflow", 
          text: "", 
          olist: [
            {text: "Assessment templates uploaded to amPIL"}, 
            {text: "Revision lists generated and assigned to students"}, 
            {text: "Using our AI helper, topics are mapped to scheme of work prerequisites"}, 
            {text: "Students sit the assessment and total scores are uploaded to amPIL for verification"}, 
            {text: "Students prompted to submit their QLA and completion can be tracked in real time. Once completed, class and student reports are available for immediate download"}, 
            {text: "Department lead initiates ranking process"}, 
            {text: "Class teachers rank the students in their own class"}, 
            {text: "Grades generated and teaching staff given the opportunity for feedback"}
          ], 
          color: 'grey darken-3', 
          img: "/images/splash9.gif"
        },
        {
          key: 9, 
          title: "Additional features", 
          list: [
            {text: "You should be ready to to go for a yeargroup of students in under 15 minutes"}, 
            {text: "Assign feedback tasks to students on Google Classroom"}, 
            {text: "All QLA data can be exported to use with other services"}, 
            {text: "amPIL will help department leaders decide set movements and interventions"}, 
            {text: "QLA entry can be monitored in real time by teachers so you know who has done it"}, 
            {text: "Students sign up simply by scanning a QR code which are provided in class sets"}
          ], 
          color: 'grey darken-2', 
          img: "/images/splash7.gif"
        }
      ]
    };
  },
  methods: {},
  computed: {},
};
</script>

