<template>
  <div class="secure">
    <img alt="Vue logo" src="../assets/logo.png" />
    <v-btn @click="clk">Send message</v-btn>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "SecurePage",
  components: {},
  mounted: async function () {
    //const username = await this.$cookies.get("apikey");
    postMessage("success", "*");
    await top.close();
  },
  methods: {
    clk() {
      postMessage("success", "*");
    },
  },
};
</script>
