<template>
  <div v-if="this.loaded">
    <v-card class="mx-10 my-12" outlined elevation="20" :color="backColour">
      <v-toolbar
        src="/images/slide_01.jpg"
        dark
        color="grey darken-3"
        class="text-h5"
      >
        {{ studentData.testName }}
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="qla"
          :items-per-page="-1"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:item.MarkAwarded="{ item }">
            {{ item.MarkAwarded }}
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="red"
                  class="mr-2"
                  @click="subtract(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-arrow-down-circle-outline
                </v-icon>
              </template>
              <span>Subtract</span>
            </v-tooltip>

            <v-tooltip top color="green">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="green"
                  @click="add(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-arrow-up-circle-outline
                </v-icon>
              </template>
              <span>Add</span>
            </v-tooltip>

            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="red"
                  @click="min(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-chevron-triple-down
                </v-icon>
              </template>
              <span>Min</span>
            </v-tooltip>
            <v-tooltip top color="green">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="green"
                  @click="max(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-chevron-triple-up
                </v-icon>
              </template>
              <span>Max</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div
          v-if="showTotal"
          class="black lighten-3 rounded rounded-t-0 elevation-10 text-h6 text-right white--text"
        >
          <p class="mx-4">
            Your score: {{ studentData.smark }}/{{ studentData.max }} marks
          </p>
          <p class="mx-4">
            Teacher submitted score: {{ studentData.mark }}/{{
              studentData.max
            }}
            marks
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="grey darken-3" @click="cancel()"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-3" @click="submitDialog()">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title
          class="text-h5 light-green darken-3 pink--text text--lighten-5"
        >
          Submit assessment feedback
        </v-card-title>

        <v-card-text>
          <v-alert
            v-if="studentData.smark == studentData.mark"
            dense
            outlined
            type="success"
          >
            The score you are submitting matches the total your teacher has
            uploaded for you.
          </v-alert>
          <v-alert v-else dense outlined type="error">
            The score you are submitting does not match the total score of
            <strong>{{ studentData.mark }}</strong> your teacher has uploaded
            for you.
          </v-alert>
          Your score: {{ studentData.smark }}/{{ studentData.max }} marks
          <div class="text-subtitle-1 text-center">
            <strong>Are you sure you want to submit this feedback task?</strong>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="dialog = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="light-green darken-3" text @click="submit()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "StudentSubmit",

  data: () => ({
    av: {},
    dialog: false,
    totalsDontMatch: true,
    showTotal: false,
    backColour: "",
    studentData: {},
    loaded: false,
    avStatus: {},
    enteredStatus: null,
    targets: [],
    revision: [],
    strengths: [],
    qla: [],
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "QuestionNumber",
      },
      { text: "Topic", value: "TestTopic" },
      { text: "Out of", value: "MarksAvailable" },
      { text: "Mark", value: "MarkAwarded" },
    ],
  }),
  mounted() {
    this.getClassData();
  },
  methods: {
    cancel() {
      this.$router.push({
        path: "/student",
      });
    },
    submitDialog() {
      this.dialog = true;
    },
    submit() {
      let submitArray = [];
      for (let i = 0; i < this.qla.length; i++) {
        //submitArray.push({keyval: this.qla[i].ResultID, values: [this.qla[i].MarkAwarded, this.qla[i].QuestionID, this.$store.state.studentDetails.UniqueID, 2]})
        submitArray.push({
          unique: {
            QuestionID: this.qla[i].QuestionID,
            StudentID: this.$store.state.studentDetails.UniqueID,
          },
          values: { MarkAwarded: this.qla[i].MarkAwarded, EnteredStatus: 2 },
        });
      }
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/student",
        data: {
          submit: submitArray,
        },
        responseType: "json",
      })
        .then(() => {
          let c = this.getStudent;
          for (let i = 0; i < c.Tests.length; i++) {
            if (c.Tests[i].ID == this.$store.getters.getSelectedTest) {
              c.Tests[i].StudentTotal = this.studentData.smark;
            }
          }
          //this.$emit('submitted')
          this.$router.push({
            path: "/student",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.dialog = false;
    },
    round(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    },
    add(t) {
      if (t.MarkAwarded < t.MarksAvailable) {
        t.MarkAwarded++;
        this.getSmark();
      }
    },
    subtract(t) {
      if (t.MarkAwarded > 0) {
        t.MarkAwarded--;
        this.getSmark();
      }
    },
    min(t) {
      t.MarkAwarded = 0;
      this.getSmark();
    },
    max(t) {
      t.MarkAwarded = t.MarksAvailable;
      this.getSmark();
    },
    getClassData() {
      for (let i = 0; i < this.getStudent.Tests.length; i++) {
        if (
          this.$store.getters.getSelectedTest == this.getStudent.Tests[i].ID
        ) {
          this.studentData.mark = this.getStudent.Tests[i].Total;
          this.studentData.smark = this.getStudent.Tests[i].StudentTotal;
          this.studentData.testName = this.getStudent.Tests[i].Name;
        }
      }
      this.getResults();
    },
    getSmark() {
      this.showTotal = false;
      this.studentData.smark = 0;
      this.studentData.max = 0;
      for (let i = 0; i < this.qla.length; i++) {
        if (this.qla[i].MarkAwarded == null) {
          this.qla[i].MarkAwarded = 0;
        }
        this.studentData.smark =
          this.studentData.smark + this.qla[i].MarkAwarded;
        this.studentData.max =
          this.studentData.max + this.qla[i].MarksAvailable;
        if (this.studentData.smark == this.studentData.mark) {
          this.backColour = "lime lighten-2";
        } else {
          this.backColour = "red lighten-5";
        }
        this.showTotal = true;
      }
    },
    getResults() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/30",
        params: {
          UniqueID: this.getStudent.UniqueID,
          TestID: this.$store.getters.getSelectedTest,
        },
        responseType: "json",
      })
        .then((response) => {
          this.qla = response.data;
          this.getSmark();
          this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    getSchool() {
      return this.$store.getters.getSchool;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
  },
  watch: {
    "$store.state.selectedTest": function () {
      this.loaded = false;
      this.getClassData();
    },
  },
};
</script>
