<template>
  <div>
    <v-row>
      <v-col>
        <v-btn @click="showTopics = !showTopics" text color="lime"
          >Edit Topic Headers</v-btn
        >
      </v-col>
      <v-col v-if="showTopics">
        <v-data-table
          :headers="topicHeaders"
          :items="revData.headers"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Topic Headers</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="lime"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Header
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Edit</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="editedItem.RevHead"
                            label="Header"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize"> Reset </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="!showTopics">
      <v-col>
        <v-radio-group v-model="selectedTopic" mandatory>
          <v-radio
            v-for="item in revData.headers"
            :key="item.RevLinkID"
            :value="item.RevLinkID"
          >
            <template v-slot:label>
              <div>{{ item.RevHead }}</div>
              <div class="red--text text-caption">
                <ul>
                  <li v-for="ob in selected[item.RevLinkID]">
                    {{ ob.QuestionNumber }}. {{ altText(ob).text }}
                  </li>
                </ul>
                <hr />
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <div v-if="this.selected.length != 0">
          <v-btn @click="clear()" color="lime" dark> Clear </v-btn>
          <v-btn @click="create()" color="lime" dark> Save </v-btn>

          <div v-if="done == true">
            <v-alert dense text type="success"> Done </v-alert>
          </div>
        </div>
      </v-col>
      <v-col>
        <v-data-table
          v-model="selected[selectedTopic]"
          :headers="headers"
          :items="revData.rev"
          item-key="QuestionID"
          show-select
        >
          <template v-slot:item.TestTopic="{ item }">
            <v-text-field
              v-model="item.RevAlt"
              :placeholder="altText(item).text"
              :label="item.TestTopic"
            >
            </v-text-field>
          </template>
          <template v-slot:header.data-table-select="{ on, props }">
            Select
          </template>
          <template
            v-slot:item.data-table-select="{ isSelected, select, item }"
          >
            <v-simple-checkbox
              v-if="showTick(item.QuestionID)"
              color="lime"
              :value="isSelected"
              @input="select($event)"
              @click="done = false"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "RevisionCreate",
  props: {
    test: Number,
  },
  mounted() {
    this.revTopics(this.test);
  },
  data() {
    return {
      done: false,
      showTopics: false,
      revData: [],
      selected: [],
      topicHeaders: [
        {
          text: "Topic Header",
          align: "start",
          sortable: false,
          value: "RevHead",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headers: [
        { text: "Qn", value: "QuestionNumber" },
        { text: "Topic (Can amend displayed text)", value: "TestTopic" },
      ],
      editedItem: {},
      editedIndex: -1,
      dialog: false,
      dialogDelete: false,
      selectedTopic: 0,
    };
  },
  methods: {
    revTopics(test) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/2",
        params: {
          SubjectID: this.$store.getters.getSubject,
          TestID: test,
        },
        responseType: "json",
      })
        .then((response) => {
          this.revData = response.data;
          //get selected revision lists

          for (let i = 0; i < this.revData.rev.length; i++) {
            if (
              this.revData.rev[i].RevID != null &&
              this.revData.rev[i].RevID != ""
            ) {
              if (this.selected[this.revData.rev[i].RevID] == undefined) {
                this.selected[this.revData.rev[i].RevID] = [];
              }

              this.selected[this.revData.rev[i].RevID].push({
                QuestionID: this.revData.rev[i].QuestionID,
                QuestionNumber: this.revData.rev[i].QuestionNumber,
                TestTopic: this.revData.rev[i].TestTopic,
                MarksAvailable: this.revData.rev[i].MarksAvailable,
                TestID: this.revData.rev[i].TestID,
                RevID: this.revData.rev[i].RevID,
                RevAlt: this.revData.rev[i].RevAlt,
              });
            }
          }
          //console.log(this.revData)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editItem(item) {
      this.editedIndex = this.revData.headers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.revData.headers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let ind = this.revData.headers[this.editedIndex].RevLinkID;

      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + ind,
        data: {
          table: "RevLinks",
        },
        responseType: "json",
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });

      this.revData.headers.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let self = this;
      if (this.editedIndex > -1) {
        //update here
        Object.assign(this.revData.headers[this.editedIndex], this.editedItem);
        this.$http({
          method: "put",
          url:
            process.env.VUE_APP_BACKEND +
            "/leader/" +
            this.editedItem.RevLinkID,
          data: {
            table: "RevLinks",
            values: { RevHead: this.editedItem.RevHead },
          },
          responseType: "json",
        })
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
      } else {
        //this.revData.headers.push(this.editedItem)
        let rh = this.editedItem.RevHead;
        this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/admin",
          data: {
            table: "RevLinks",
            data: [
              {
                keyval: { RevLinkID: null },
                values: {
                  RevHead: this.editedItem.RevHead,
                  Num: 99,
                  SubjectID: this.$store.getters.getSubject,
                },
              },
            ],
          },
          responseType: "json",
        })
          .then((response) => {
            //self.revData.headers.push(self.editedItem)
            this.revData.headers.push({
              RevLinkID: response.data.lastid,
              RevHead: rh,
              Num: 99,
              SubjectID: this.$store.getters.getSubject,
            });
            //console.log(this.editedItem)
          })
          .catch((error) => {
            console.log(error);
          });
      }
      console.log(this.revData);
      this.close();
    },
    showTick(item) {
      //console.log(this.selected)
      //console.log(item)
      for (let i = 0; i < this.selected.length; i++) {
        //if a different revision list header
        if (i != this.selectedTopic) {
          let topic = this.selected[i];
          if (topic != null) {
            for (let j = 0; j < topic.length; j++) {
              if (topic[j].QuestionID == item) {
                return false;
              }
            }
          }
        }
      }
      return true;
    },
    altText(item) {
      if (item.RevAlt == "" || item.RevAlt == null) {
        return { text: item.TestTopic, type: 0 };
        //return {text: item.RevAlt, type: 1}
      } else {
        return { text: item.RevAlt, type: 1 };
        //return {text: item.TestTopic, type: 0}
      }
    },
    clear() {
      let ind;
      //let ret = []
      //console.log(this.selected)
      let self = this;
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/leader/" + this.test,
        data: {
          key: "TestID",
          table: "QuestionData",
          values: { RevID: 0, RevAlt: null },
        },
        responseType: "json",
      })
        .then((response) => {
          this.selected = [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    create() {
      let ind;
      //let ret = []
      //console.log(this.selected)
      let self = this;
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/leader/" + this.test,
        data: {
          key: "TestID",
          table: "QuestionData",
          values: { RevID: 0, RevAlt: null },
        },
        responseType: "json",
      })
        .then((response) => {
          for (let i = 0; i < this.revData.headers.length; i++) {
            ind = this.revData.headers[i].RevLinkID;

            if (self.selected[ind] !== undefined) {
              for (let j = 0; j < self.selected[ind].length; j++) {
                //ret.push({table: "QuestionData", values: {RevID: ind, RevAlt: this.selected[ind][j].RevAlt}})
                this.$http({
                  method: "put",
                  url:
                    process.env.VUE_APP_BACKEND +
                    "/leader/" +
                    self.selected[ind][j].QuestionID,
                  data: {
                    table: "QuestionData",
                    values: {
                      RevID: ind,
                      RevAlt: self.selected[ind][j].RevAlt,
                    },
                  },
                  responseType: "json",
                })
                  .then((response) => {
                    this.done = true;
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //console.log(ret)
    },
  },
  computed: {},
};
</script>
