<template>
    <v-container>
        <v-btn
              text
              color="lime"
              :disabled = "canDownload"
              @click="reportgen()"
            >
            <v-icon>mdi-cloud-download-outline</v-icon>
              Student Reports
            </v-btn>
    </v-container>
  </template>
  
  <script>

  export default {
    name: "StudentReports",
    props: {
      setID: Number,
      testID: Number
    },
    components: {

    },
    data: () => ({
      allStudents: [],
      studentNames: [],
      //setID: 56,
      //testID: 7, //7 or 98
      av: {},
      avgs: [],
      totalsDontMatch: true,
      studentData: {},
      loaded: false,
      avStatus: {},
      enteredStatus: null,
      targets: [],
      revision: [],
      strengths: [],
      qla: [],
      pdf: {
        header: {
          columns: [
            {
              image: "data:image/png;base64,",
              height: 30,
              width: 30,
              margin: [30, 10],
            },
            { text: "", style: "header", margin: [35, 10] },
          ],
        },
        footer: {
          columns: [
            { text: "Created on ", alignment: "left", margin: [30, -5] },
            { text: "Licensed to ", alignment: "center", margin: [30, -5] },
            {
              text: "amPIL Tracker by D Wilson",
              alignment: "right",
              margin: [30, -5],
            },
          ],
          margin: [0, 10],
          style: "footer",
        },
        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            color: "#50900c",
          },
          details: {
            color: "black",
          },
          footer: {
            fontSize: 8,
          },
        },
        content: [],
      },
    }),
    watch: {
      getStudent() {
        if (this.getStudent.SoWID > 0) {
          this.getClassData();
        }
      },
    },
    mounted() {
      let b = this;
      this.toDataURL("/images/logo2.png", function (dataUrl) {
        b.pdf.header.columns[0].image = dataUrl;
      });
      //if (this.getStudent.SoWID > 0) {
        this.getClassData();
      //}
    },
    emits: ["loaded"],
    methods: {
      round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
      },
      getClassData() {
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/teacher/19",
          params: {
            SetID: this.setID,
            TestID: this.testID,
          },
          responseType: "json",
        })
          .then((response) => {
            this.studentNames=response.data
            if (this.studentNames.length>0){
              let j = 0;
            let sum = 0;
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].Total !== null) {
                sum = sum + parseInt(response.data[i].Total);
                j++;
              }
            }
  
            this.av.mark = this.round(sum / j, 1);
            this.av.perc = this.round(
              (this.av.mark / parseInt(response.data[0].MaxMark)) * 100,
              1
            );
            this.getAverages()
            //this.getTargets();
            }

          })
          .catch((error) => {
            console.log(error);
          });
      },
      checkTotals(s){
        let enteredStatus
        let totalsDontMatch
        if (!isNaN(s.Total)) {
            if (isNaN(s.STotal)) {
                enteredStatus = 1;
            }
            else{
                if (s.STotal != s.Total){
                    totalsDontMatch = true
                }
                else{
                    totalsDontMatch = false
                }
                enteredStatus = 2
            }
        }
        else {
            enteredStatus = 0
        }
        let retval = {}
        retval.EnteredStatus = enteredStatus
        retval.totalsDontMatch = totalsDontMatch
        return retval
      },
      getAverages(){
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/teacher/17",
          params: {
            TestID: this.testID,
          },
          responseType: "json",
        })
          .then((response) => {
            this.avgs = response.data
            this.getTargets()
            //console.log(response.data)
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getTargets() {
        let av
        let stu
        this.strengths = [];
        this.targets = [];
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/teacher/18",
          params: {
            SetID: this.setID,
            TestID: this.testID,
          },
          responseType: "json",
        })
          .then((response) => {
            this.allStudents = response.data
            //console.log(this.allStudents)
            for (let i=0;i<this.allStudents.length;i++){
                av = this.avgs.find(({ QuestionID }) => QuestionID === this.allStudents[i].QuestionID);
                this.allStudents[i].Ratio = (this.allStudents[i].SResult-av.AvResult)/av.MarksAvailable
                this.allStudents[i].AvResult = av.AvResult
            }
            for (let i=0;i<this.studentNames.length;i++){
                this.studentNames[i].strengths = []
                this.studentNames[i].targets = []
                stu = this.allStudents.filter((s) => s.UniqueID === this.studentNames[i].UniqueID);
                //sort by ratio
                stu.sort(function (a, b) {
                    return b.Ratio - a.Ratio;
                })

                //get strengths and targets
                for (let j = 0; j < stu.length; j++) {
                    //console.log(stu[j].LastFirst, stu[j].Ratio)
                    if (stu[j].Ratio > 0) {
                        this.studentNames[i].strengths.push({
                            Qno: stu[j].QuestionNumber,
                            Topic: stu[j].TestTopic,
                        });
                    }
                    if (stu[stu.length - 1 - j].Ratio < 0) {
                        this.studentNames[i].targets.push({
                            Qno: stu[stu.length - 1 - j].QuestionNumber,
                            Topic: stu[stu.length - 1 - j].TestTopic,
                        });
                    }
                    this.studentNames[i].data = stu
                    this.studentNames[i].strengths = this.studentNames[i].strengths.slice(0, 5);
                    this.studentNames[i].targets = this.studentNames[i].targets.slice(0, 5);
                }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      orderQuestions(s){
        
        let q = s.data
        q.sort(function (a, b) {
              return a.QuestionNumber - b.QuestionNumber;
            });
        return q
      },
  
      toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      },
      addContent(s){
        let stu = this.studentNames[s]
        let content = []
        let details = {
          columns: [
            { text: "Name: " + stu.LastFirst },
            { text: "Set: " + stu.SetName },
            { text: "Assessment: " + stu.TestName },
          ],
          style: "details",
          margin: [10, 20],
        };
        content.push(details);
        
        //Add results table
        let rtable = {
          layout: "lightHorizontalLines", // optional
          table: {
            headerRows: 1,
            widths: [24, "*", 35, 35, 100],
            body: [["Qn", "Topic", "Mark", "Out of", "RAG"]],
          },
        };
        
        let tol = {
          red: stu.Red,
          amber: stu.Amber,
          green: stu.Green,
        };
        let stuData = this.orderQuestions(stu)
        let lg = [];
        for (let i = 0; i < stuData.length; i++) {
          let perc = (stuData[i].SResult / stuData[i].MarksAvailable) * 100;
          let avperc = (stuData[i].AvResult / stuData[i].MarksAvailable) * 100;
          if (perc >= tol.green) {
            lg = ["lightgreen", "darkgreen"];
          } else if (perc >= tol.amber) {
            lg = ["lightyellow", "darkorange"];
          } else if (perc >= tol.red) {
            lg = ["pink", "red"];
          } else {
            lg = ["pink", "red"];
          }
          
          let temp = [
            stuData[i].QuestionNumber,
            stuData[i].TestTopic,
            stuData[i].SResult,
            stuData[i].MarksAvailable,
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 2,
                  w: 100,
                  h: 8,
                  r: 5,
                  color: "lightgrey",
                  lineColor: "black",
                },
                {
                  type: "rect",
                  x: 0,
                  y: 2,
                  w: perc,
                  h: 8,
                  r: 5,
                  linearGradient: lg,
                },
                {
                  type: "line",
                  x1: avperc,
                  y1: 0,
                  x2: avperc,
                  y2: 12,
                  lineWidth: 3,
                },
              ],
            },
          ];
          rtable.table.body.push(temp);
        }
        content.push(rtable);
        
        content.push({ text: " " });
        //summary
        let perc = this.round(((stu.Total/stu.MaxMark)*100),1)
        let summary = {
          table: {
            headerRows: 1,
            margin: [100, 20],
            //widths: [24, '*', 35],
            body: [
              [
                "Total: " + stu.Total,
                "Out of: " + stu.MaxMark,
                "Percentage: " + perc + "%",
              ],
            ],
          },
        };
        let chktot = this.checkTotals(s)
        if (chktot.totalsDontMatch) {
          content.push({
            text: "*This submitted feedback does not match the total your teacher uploaded",
            bold: true,
            color: "black",
          });
        }
        
        content.push(summary);
        
        //QR code and targets
        let str = ["Strengths:"];
        for (let i = 0; i < stu.strengths.length; i++) {
          str.push(i + 1 + ") " + stu.strengths[i].Topic);
        }
        let tar = ["Targets:"];
        for (let i = 0; i < stu.targets.length; i++) {
          tar.push(i + 1 + ") " + stu.targets[i].Topic);
        }
        let qrt = {
          columns: [
            {
              stack: [str],
              fontSize: 10,
              width: "*",
              color: "darkgreen",
              italics: true,
              decoration: "underline",
              decorationStyle: "dotted",
            },
            {
              stack: [tar],
              fontSize: 10,
              width: "*",
              color: "red",
              italics: true,
              decoration: "underline",
              decorationStyle: "dotted",
            },
            { qr: "https://www.ampil.co.uk", fit: "50", width: 60 },
          ],
        };
        content.push({ text: " " });
        content.push(qrt);
        
       return content
      },
      reportgen: function () {
        this.pdf.content = [];
        var pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require("pdfmake/build/vfs_fonts.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
  
        today = "Created on " + dd + "/" + mm + "/" + yyyy;
        this.pdf.footer.columns[0].text = today;
        this.pdf.footer.columns[1].text = "Licensed to " + this.$store.getters.getSchool;
        this.pdf.header.columns[1].text = "Student Assessment Feedback Sheet";
        
        //content here
        let content = this.addContent(0)
        for (let i=1;i<this.studentNames.length;i++){
            content.push({text: "", pageBreak: 'after'})
            let newPage = this.addContent(i)
            content.push(newPage)
        }
        var docDefinition = {
          header: this.pdf.header,
          footer: this.pdf.footer,
          content: content,
          styles: this.pdf.styles,
          defaultStyle: { color: "gray", fontSize: 10 },
        };
        //console.log(content)
        pdfMake.createPdf(docDefinition).download("StudentReport.pdf");
      },
    },
    computed: {
      getSchool() {
        return this.$store.getters.getSchool;
      },
      getStudent() {
        return this.$store.getters.getStudentDetails;
      },
      canDownload(){
        if (this.studentNames.length>0){
          return false
        }
        else{
          return true
        }
      }
    },
    watch: {
      "$store.state.selectedTest": function () {
        this.loaded = false;
        this.getClassData();
      },
    },
  };
  </script>