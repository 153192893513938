<template>
  <v-container>
    <div v-if="this.loaded">
      <v-card outlined>
        <v-toolbar
          src="/images/slide_01.jpg"
          dark
          color="grey darken-3"
          class="text-h5"
        >
          {{ studentData.testName }}
        </v-toolbar>
        <v-card-text v-if="enteredStatus > 0">
          <v-row>
            <v-col>
              You scored {{ studentData.mark }}/{{ studentData.MaxMark }}
            </v-col>
            <v-col>
              <v-progress-linear
                :color="avStatus.barColour"
                height="25"
                :value="studentData.perc"
                striped
                rounded
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-row>
          <v-row>
            <v-col> Class average: </v-col>
            <v-col>
              <v-progress-linear
                color="light-blue lighten-3"
                height="25"
                :value="av.perc"
                striped
                rounded
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-row>
          <v-row justify="end">
            This result was {{ avStatus.text }} the average score for the other
            students in your group.
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="enteredStatus == 0">
          You have not yet sat this assessment. You can download a revision list
          for this assessment if your teacher has created one.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text v-if="enteredStatus == 1">
          You have not yet submitted your assessment feedback for this
          assessment.
        </v-card-text>
        <v-card-text v-else-if="enteredStatus == 2">
          <v-alert v-if="totalsDontMatch" dense outlined type="error">
            <strong>Alert: </strong>The QLA you submitted does not match the
            total your teacher uploaded. Your teacher believes you scored
            <strong>{{ studentData.mark }}</strong> marks and your QLA suggests
            <strong>{{ studentData.smark }}</strong> marks. You may wish to
            resubmit your feedback.
          </v-alert>
          <div class="text-h6">Topic feedback</div>

          <v-row>
            <v-col>
              <v-tooltip top color="green">
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dark
                    class="light-green"
                  >
                    <v-card-title class="text--subtitle-1">
                      What I am good at:<v-spacer></v-spacer>
                      <v-icon> mdi-check-circle </v-icon>
                    </v-card-title>
                    <v-card-text class="light-green lighten-5 black--text">
                      <div
                        v-for="(item, index) in strengths.slice(0, 5)"
                        :key="index"
                      >
                        Q{{ item.Qno }}. {{ item.Topic }}
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
                <span
                  >Questions you have performed best on, compared with all other
                  students that have sat this assessment</span
                >
              </v-tooltip>
            </v-col>
            <v-col>
              <v-tooltip top color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-bind="attrs" v-on="on" outlined dark class="pink">
                    <v-card-title class="text--subtitle-1">
                      What I need to work on:<v-spacer></v-spacer>
                      <v-icon> mdi-target </v-icon>
                    </v-card-title>
                    <v-card-text class="pink lighten-5 black--text">
                      <div
                        v-for="(item, index) in targets.slice(0, 5)"
                        :key="index"
                      >
                        Q{{ item.Qno }}. {{ item.Topic }}
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
                <span
                  >Questions you have performed worst on, compared with all
                  other students that have sat this assessment</span
                >
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <export-revision
            :selectedTest="$store.getters.getSelectedTest"
            :buttonText="'Revision List'"
            :testName="studentData.testName"
          ></export-revision>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="grey darken-3"
            :disabled="enteredStatus == 0"
            @click="
              $router.push({
                path: '/student/submit',
              })
            "
          >
            Submit Feedback
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="grey darken-3"
            :disabled="enteredStatus != 2"
            @click="reportgen()"
          >
            Download Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import ExportRevision from "@/components/student/ExportRevision.vue";
export default {
  name: "Dashboard",
  components: {
    ExportRevision,
  },
  data: () => ({
    av: {},
    totalsDontMatch: true,
    studentData: {},
    loaded: false,
    avStatus: {},
    enteredStatus: null,
    targets: [],
    revision: [],
    strengths: [],
    qla: [],
    pdf: {
      header: {
        columns: [
          {
            image: "data:image/png;base64,",
            height: 30,
            width: 30,
            margin: [30, 10],
          },
          { text: "", style: "header", margin: [35, 10] },
        ],
      },
      footer: {
        columns: [
          { text: "Created on ", alignment: "left", margin: [30, -5] },
          { text: "Licensed to ", alignment: "center", margin: [30, -5] },
          {
            text: "amPIL Tracker by D Wilson",
            alignment: "right",
            margin: [30, -5],
          },
        ],
        margin: [0, 10],
        style: "footer",
      },
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          italics: true,
          color: "#50900c",
        },
        details: {
          color: "black",
        },
        footer: {
          fontSize: 8,
        },
      },
      content: [],
    },
  }),
  watch: {
    getStudent() {
      if (this.getStudent.SoWID > 0) {
        this.getClassData();
      }
    },
  },
  mounted() {
    let b = this;
    this.toDataURL("/images/logo2.png", function (dataUrl) {
      b.pdf.header.columns[0].image = dataUrl;
    });
    if (this.getStudent.SoWID > 0) {
      this.getClassData();
    }
  },
  emits: ["loaded"],
  methods: {
    round(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    },
    getClassData() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/26",
        params: {
          SetID: this.getStudent.SetID,
          TestID: this.$store.getters.getSelectedTest,
        },
        responseType: "json",
      })
        .then((response) => {
          let j = 0;
          let sum = 0;
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].Total !== null) {
              sum = sum + parseInt(response.data[i].Total);
              j++;
            }
          }

          this.av.mark = this.round(sum / j, 1);
          this.av.perc = this.round(
            (this.av.mark / parseInt(response.data[0].MaxMark)) * 100,
            1
          );
          for (let i = 0; i < this.getStudent.Tests.length; i++) {
            if (this.$store.state.selectedTest == this.getStudent.Tests[i].ID) {
              this.studentData.mark = this.getStudent.Tests[i].Total;
              this.studentData.smark = this.getStudent.Tests[i].StudentTotal;
              this.studentData.testName = this.getStudent.Tests[i].Name;
              if (!isNaN(this.studentData.mark)) {
                if (isNaN(this.getStudent.Tests[i].StudentTotal)) {
                  this.enteredStatus = 1;
                } else {
                  if (
                    this.getStudent.Tests[i].StudentTotal !=
                    this.getStudent.Tests[i].Total
                  ) {
                    this.totalsDontMatch = true;
                  } else {
                    this.totalsDontMatch = false;
                  }
                  this.enteredStatus = 2;
                }
              } else {
                this.enteredStatus = 0;
              }
            }
          }
          this.getStudent.SetID;
          this.studentData.perc = this.round(
            (this.studentData.mark / parseInt(response.data[0].MaxMark)) * 100,
            1
          );
          this.studentData.MaxMark = response.data[0].MaxMark;
          if (this.studentData.perc > this.av.perc + 15) {
            this.avStatus.barColour = "light-green";
            this.avStatus.text = "higher than";
          } else if (this.studentData.perc < this.av.perc - 15) {
            this.avStatus.barColour = "pink";
            this.avStatus.text = "lower than";
          } else {
            this.avStatus.barColour = "amber";
            this.avStatus.text = "broadly in line with";
          }
          this.getTargets();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTargets() {
      this.strengths = [];
      this.targets = [];
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/27",
        params: {
          UniqueID: this.getStudent.UniqueID,
          TestID: this.$store.getters.getSelectedTest,
        },
        responseType: "json",
      })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].Ratio > 0) {
              this.strengths.push({
                Qno: response.data[i].QuestionNumber,
                Topic: response.data[i].TestTopic,
              });
            }
            if (response.data[response.data.length - 1 - i].Ratio < 0) {
              this.targets.push({
                Qno: response.data[response.data.length - 1 - i].QuestionNumber,
                Topic: response.data[response.data.length - 1 - i].TestTopic,
              });
            }
            this.strengths = this.strengths.slice(0, 5);
            this.targets = this.targets.slice(0, 5);
          }
          //sort by question number
          var sortbyq = response.data.slice(0);
          sortbyq.sort(function (a, b) {
            return a.QuestionNumber - b.QuestionNumber;
          });
          this.qla = sortbyq;
          //console.log(this.qla)
          //this.getRev()
          this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },

    reportgen: function () {
      this.pdf.content = [];
      var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = "Created on " + dd + "/" + mm + "/" + yyyy;
      this.pdf.footer.columns[0].text = today;
      this.pdf.footer.columns[1].text = "Licensed to " + this.getStudent.School;
      this.pdf.header.columns[1].text = "Student Assessment Feedback Sheet";
      //create student details
      let details = {
        columns: [
          { text: "Name: " + this.getStudent.Name },
          { text: "Set: " + this.getStudent.Set },
          { text: "Assessment: " + this.studentData.testName },
        ],
        style: "details",
        margin: [10, 20],
      };
      this.pdf.content.push(details);
      //Add results table
      let rtable = {
        layout: "lightHorizontalLines", // optional
        table: {
          headerRows: 1,
          widths: [24, "*", 35, 35, 100],
          body: [["Qn", "Topic", "Mark", "Out of", "RAG"]],
        },
      };
      let tol = {
        red: this.getStudent.Red,
        amber: this.getStudent.Amber,
        green: this.getStudent.Green,
      };
      let lg = [];
      for (let i = 0; i < this.qla.length; i++) {
        let perc = (this.qla[i].SResult / this.qla[i].MarksAvailable) * 100;
        let avperc = (this.qla[i].AvResult / this.qla[i].MarksAvailable) * 100;
        if (perc >= tol.green) {
          lg = ["lightgreen", "darkgreen"];
        } else if (perc >= tol.amber) {
          lg = ["lightyellow", "darkorange"];
        } else if (perc >= tol.red) {
          lg = ["pink", "red"];
        } else {
          lg = ["pink", "red"];
        }

        let temp = [
          this.qla[i].QuestionNumber,
          this.qla[i].TestTopic,
          this.qla[i].SResult,
          this.qla[i].MarksAvailable,
          {
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 2,
                w: 100,
                h: 8,
                r: 5,
                color: "lightgrey",
                lineColor: "black",
              },
              {
                type: "rect",
                x: 0,
                y: 2,
                w: perc,
                h: 8,
                r: 5,
                linearGradient: lg,
              },
              {
                type: "line",
                x1: avperc,
                y1: 0,
                x2: avperc,
                y2: 12,
                lineWidth: 3,
              },
            ],
          },
        ];
        rtable.table.body.push(temp);
      }
      this.pdf.content.push(rtable);
      this.pdf.content.push({ text: " " });
      //summary
      let summary = {
        table: {
          headerRows: 1,
          margin: [100, 20],
          //widths: [24, '*', 35],
          body: [
            [
              "Total: " + this.studentData.mark,
              "Out of: " + this.studentData.MaxMark,
              "Percentage: " + this.studentData.perc + "%",
            ],
          ],
        },
      };
      if (this.totalsDontMatch) {
        this.pdf.content.push({
          text: "*This submitted feedback does not match the total your teacher uploaded",
          bold: true,
          color: "black",
        });
      }
      this.pdf.content.push(summary);
      //QR code and targets
      let str = ["Strengths:"];
      for (let i = 0; i < this.strengths.length; i++) {
        //str=str+this.strengths[i].Qno+') '+this.strengths[i].Topic+','
        str.push(i + 1 + ") " + this.strengths[i].Topic);
      }
      let tar = ["Targets:"];
      for (let i = 0; i < this.targets.length; i++) {
        //str=str+this.strengths[i].Qno+') '+this.strengths[i].Topic+','
        tar.push(i + 1 + ") " + this.targets[i].Topic);
      }
      let qrt = {
        columns: [
          {
            stack: [str],
            fontSize: 10,
            width: "*",
            color: "darkgreen",
            italics: true,
            decoration: "underline",
            decorationStyle: "dotted",
          },
          {
            stack: [tar],
            fontSize: 10,
            width: "*",
            color: "red",
            italics: true,
            decoration: "underline",
            decorationStyle: "dotted",
          },
          { qr: "https://www.ampil.co.uk", fit: "50", width: 60 },
        ],
      };
      //let qr = { qr: 'https://www.ampil.co.uk/student', fit: '50'  }
      this.pdf.content.push({ text: " " });
      this.pdf.content.push(qrt);
      var docDefinition = {
        header: this.pdf.header,
        footer: this.pdf.footer,
        content: this.pdf.content,
        styles: this.pdf.styles,
        defaultStyle: { color: "gray", fontSize: 10 },
      };
      pdfMake.createPdf(docDefinition).download("StudentReport.pdf");
    },
  },
  computed: {
    getSchool() {
      return this.$store.getters.getSchool;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
  },
  watch: {
    "$store.state.selectedTest": function () {
      this.loaded = false;
      this.getClassData();
    },
  },
};
</script>
