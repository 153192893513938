<template>
  <div>
  <div v-if="(getScope == 'leader' || getScope == 'admin') && getScope && getTeacherID != null">
       <v-btn color="lime" text @click="changeTeacher()">{{ getTeacherID.Teacher }}</v-btn>
    </div>
    <div v-if="getTeacherID == null && getScope!='teacher'">
      <select-teacher></select-teacher>
    </div>
  <router-view v-if="getTeacherID!=null"></router-view>
</div>
</template>

<script>
import SelectTeacher from "@/components/teacher/SelectTeacher";
export default {
  name: "TeacherIndex",
  components: {
    SelectTeacher,
  },
  props: {},
  mounted() {
    if (this.getTeacherID==null){
      this.getData()
    }
  },
  data() {
    return {};
  },
  methods: {
    getData(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/user-load",
        params: {

        },
        responseType: "json",
      })
        .then((response) => {
          this.$store.commit("setTeacherID", {TeacherID: response.data.teacher})
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeTeacher(){
      this.$store.commit("setTeacherID", null)
    },
  },
  computed: {

    getScope() {
      return this.$store.getters.getScope;
    },
    getTeacherID() {
      return this.$store.getters.getTeacherID;
    },
  },
};
</script>
