<template>
  <div class="text-center">
    <v-menu offset-y v-if="showButton">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="light-green darken-4" dark v-bind="attrs" v-on="on">
          {{ viewAs }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in menuOptions" :key="index">
          <v-list-item-title @click="selectOption(item)">{{
            item.name
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ChangeScope",
  components: {},
  data: () => ({
    //
  }),
  methods: {
    selectOption(item) {
      this.$store.commit("setViewAs", item.slug);
      const scope = this.menuOptions.find(
        ({ slug }) => slug === this.$store.getters.getViewAs
      );
      //this.$store.commit('setScrollBar', {name: "Test", data: [{id: 1, name: 'blah'}, {id: 2, name: 'blah'}, {id: 3, name: 'blah'}, {id: 4, name: 'blah'}, {id: 5, name: 'blah'}, {id: 6, name: 'blah'},{id: 7, name: 'blah'},{id: 8, name: 'blah'},{id: 9, name: 'blah'},{id: 10, name: 'blah'},{id: 11, name: 'blah'}]})
      this.$router.push(scope.link).catch(() => {});
    },
  },
  computed: {
    viewAs() {
      return this.$store.getters.getViewAs;
    },
    showButton() {
      let scope = this.$store.getters.getScope;
      if (scope == "teacher" || scope == "leader" || scope == "admin") {
        return true;
      }
      return false;
    },
    menuOptions() {
      let options = [];
      switch (this.$store.getters.getScope) {
        case "admin":
          options = [
            { name: "Admin", slug: "admin", link: "/admin" },
            { name: "Subject Leader", slug: "leader", link: "/leader" },
            { name: "Teacher", slug: "teacher", link: "/teacher" },
            { name: "Student", slug: "student", link: "/student" },
          ];
          break;
        case "leader":
          options = [
            { name: "Subject Leader", slug: "leader", link: "/leader" },
            { name: "Teacher", slug: "teacher", link: "/teacher" },
            { name: "Student", slug: "student", link: "/student" },
          ];
          break;
        case "teacher":
          options = [
            { name: "Teacher", slug: "teacher", link: "/teacher" },
            { name: "Student", slug: "student", link: "/student" },
          ];
          break;
        default:
          return null;
      }
      return options;
    },
  },
};
</script>
