<template>
  <v-card>
    <v-card-title class="text-h5">
      {{ testname }}
    </v-card-title>
    <div style="text-align: center">
      You can re-order the questions in this assessment by dragging and dropping
    </div>
    <v-form v-model="isFormValid">
      <v-card-text>
        <draggable
          v-model="topics"
          group="questions"
          @start="drag = true"
          @end="
            drag = false;
            numbering();
          "
        >
          <div
            v-for="(question, index) in topics"
            :key="index"
            :id="question.QuestionID"
          >
            <v-container>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    :rules="rules"
                    v-model="question.TestTopic"
                    :label="question.QuestionNumber.toString()"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-text-field
                    :rules="numberRules"
                    v-model="question.MarksAvailable"
                    label="Marks"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-icon
                    slot="append"
                    color="red"
                    @click="removeQuestion(question.QuestionID, index)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </draggable>
        <v-container>
          <v-row>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  :label="topics.length + 1"
                  :key="topics.length"
                  v-model="NewQuestion"
                  ref="newqn"
                  @change="newRow()"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="NewMarks"
                  label="Marks"
                  @change="newRow()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="green darken-1" text @click="cancel()"> Cancel </v-btn>

      <v-btn
        color="green darken-1"
        text
        @click="save()"
        :disabled="!isFormValid"
      >
        Save
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="delflag" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete?
        </v-card-title>

        <v-card-text>
          Deleting this item will also remove all Topic to Scheme of Work
          mappings that are present.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="delflag = false">
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="removeQuestion3(delConfirm[0], delConfirm[1])"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  name: "EditAssessment",
  props: {
    testid: Number,
    testname: String,
  },
  mounted() {
    this.getQuestions();
  },
  methods: {
    getQuestions() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/6",
        params: {
          TestID: this.testid,
        },
        responseType: "json",
      })
        .then((response) => {
          this.topics = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.$emit("cancel");
    },
    numbering() {
      for (let i = 0; i < this.topics.length; i++) {
        this.topics[i].QuestionNumber = i + 1;
      }
    },
    newRow() {
      if (this.NewQuestion != "" && this.NewMarks != null) {
        this.topics.push({
          TestTopic: this.NewQuestion,
          MarksAvailable: this.NewMarks,
        });
        this.NewQuestion = "";
        this.NewMarks = null;
        this.numbering();
        this.$refs.newqn.focus();
      }
    },
    removeQuestion(id, index) {
      if (!id) {
        this.removeQuestion2(id, index);
      } else {
        //question exists, check for existing mappings
        //this.removeQuestion2(id,index)
        this.mapExists(id, index);
      }
    },
    removeQuestion2(id, index) {
      this.topics.splice(index, 1);
      this.numbering();
      //console.log(id)
    },
    removeQuestion3(id, index) {
      //console.log(id, index);
      this.delflag = false;
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + id,
        data: {
          table: "QuestionData",
          //SchoolID: 1,
        },
        responseType: "json",
      }).catch((error) => {
        console.log(error);
      });
      this.removeQuestion2(id, index);
    },
    mapExists(e, i) {
      //let rval = false
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/7",
        params: {
          QuestionID: e,
        },
        responseType: "json",
      })
        .then((response) => {
          //count = response.data[0].Cnt
          let cnt = 0;
          for (let p = 0; p < response.data.length; p++) {
            cnt = cnt + response.data[p].Cnt;
          }
          //this.TopicID[0]=e
          //this.TopicID[1]=i
          if (cnt > 0) {
            this.delflag = true;
            this.delConfirm[0] = e;
            this.delConfirm[1] = i;
          } else {
            this.$http({
              method: "delete",
              url: process.env.VUE_APP_BACKEND + "/admin/" + e,
              data: {
                table: "QuestionData",
                //SchoolID: 1,
              },
              responseType: "json",
            }).catch((error) => {
              console.log(error);
            });
            this.removeQuestion2(e, i);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      
      this.TopicsSubmit = [];
      for (let i = 0; i < this.topics.length; i++) {
        this.TopicsSubmit.push({
          keyval: { QuestionID: this.topics[i].QuestionID || null},
          values: {
            QuestionNumber: this.topics[i].QuestionNumber,
            TestTopic: this.topics[i].TestTopic,
            MarksAvailable: this.topics[i].MarksAvailable,
            TestID: this.testid,
          },
        });
      }
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "QuestionData",
          data: this.TopicsSubmit,
        },
        responseType: "json",
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$emit("cancel");
    },
  },
  data() {
    return {
      questions: [],
      topics: [],
      NewQuestion: "",
      NewMarks: null,
      rules: [(value) => !!value || "Required."],
      numberRules: [
        //v => v.length > 0 || 'This field may not be empty',
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        (v) => v > 0 || "The value must be greater than zero",
      ],
      isFormValid: false,
      delflag: false,
      TopicsSubmit: [],
      delConfirm: [],
    };
  },
};
</script>
