<template>
  <div>
    <task-banner :tasks="tasks"></task-banner>
    <v-row height="100%" class="ma-2" v-if="testsLoading==false">

      <v-btn color="black" width="100%" dark @click="$router.push({name: 'Markbook', params: {yearID: currentYeargroup }}).catch((err) => {})">Show Markbook</v-btn>
      <RAG :setID="getClass"></RAG>
    </v-row>
    <v-row height="100%" class="ma-2">
      <v-col cols="6">
        <div class="text-h4">Feedback Tasks:</div>
        <v-flex style="overflow: auto">
          <v-skeleton-loader
            v-if="feedbackLoading"
            type="card"
          ></v-skeleton-loader>
          <v-card height="90vh" v-else>
            <v-card class="my-1" v-for="task in tasks">
              <feedback :task="task"></feedback>    
            </v-card>
          </v-card>

        </v-flex>
      </v-col>
      <v-col cols="6">
        <div class="text-h4">Completed assessments:</div>
        <v-flex>
        <v-switch
        color="lime"
        v-model="targetsCompare"
        :label="getCaptions.switchText"
        ></v-switch>
      </v-flex>
        <v-flex style="overflow: auto">
          <v-skeleton-loader
            v-if="testsLoading"
            type="card"
          ></v-skeleton-loader>
          <v-card height="90vh" v-else>
            <v-card class="my-1" v-for="test in tests">
              <ampil-card :title="test.TestName"></ampil-card>
              <v-card-text>
                <v-row v-if="getTargets(test.TestID).weaknesses.length>0">
                  <v-col>
                    <v-tooltip top color="green">
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dark
                    class="light-green"
                  >
                    <v-card-title class="text--subtitle-1">
                      Strengths:<v-spacer></v-spacer>
                      <v-icon> mdi-check-circle </v-icon>
                    </v-card-title>
                    <v-card-text class="light-green lighten-5 black--text">
                      <ul>
                        <li v-for="strength in sortTargets(getTargets(test.TestID).strengths)">
                          Q{{ strength.qn}}. {{ strength.topic }} <div v-if="targetsCompare==false">({{ strength.perc }}%)</div>
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </template>
                <span>{{getCaptions.strengthText}}</span>
              </v-tooltip>
                  </v-col>
                  <v-col>
                    <v-tooltip top color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-bind="attrs" v-on="on" outlined dark class="pink">
                    <v-card-title class="text--subtitle-1">
                      Weaknesses:<v-spacer></v-spacer>
                      <v-icon> mdi-target </v-icon>
                    </v-card-title>
                    <v-card-text class="pink lighten-5 black--text">
                      <ul>
                        <li v-for="weakness in sortTargets(getTargets(test.TestID).weaknesses)">
                          Q{{ weakness.qn}}. {{ weakness.topic }} <div v-if="targetsCompare==false">({{ weakness.perc }}%)</div> 
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </template>
                <span>{{getCaptions.weaknessText}}</span>
              </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-else>
                  No data for this assessment...
                </v-row>
              </v-card-text>
              <v-card-actions>
                <enter-qla :yeargroupID="yeargroup" :test="test.TestID" :givenSet="getClass"></enter-qla>
                <v-spacer></v-spacer>
                <student-reports :setID="getClass" :testID="test.TestID"></student-reports>
                <v-spacer></v-spacer>
                <class-reports :setID="getClass" :testID="test.TestID" :strengths="sortTargets(getTargets(test.TestID).strengths)" :weaknesses="sortTargets(getTargets(test.TestID).weaknesses)"></class-reports>
              </v-card-actions>
            </v-card>
          </v-card>

        </v-flex>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import RAG from "@/components/teacher/RAG.vue";
import StudentReports from "@/components/teacher/StudentReports.vue";
import ClassReports from "@/components/teacher/ClassReports.vue";
import EnterQla from "@/components/teacher/EnterQla";
import Feedback from "@/components/teacher/Feedback.vue";
import TaskBanner from "@/components/teacher/TaskBanner.vue";
export default {
  name: "TeacherHome",
  components: {
    RAG,
    EnterQla,
    StudentReports,
    ClassReports,
    Feedback,
    TaskBanner,
  },
  props: {},
  mounted() {
    this.getClasses()
    
  },
  data() {
    return {
      classes: [],
      testsLoading: true,
      feedbackLoading: true,
      tests: [],
      tasks: [],
      setAvg: [],
      allAvg: [],
      targetsCompare: true,
      toggleText: [
        {
          value: true, 
          switchText: "Targets generated by comparing to other students performance on this assessment", 
          strengthText: "Questions the class has performed best on, compared with all other students that have sat this assessment",
          weaknessText: "Questions the class have performed worst on, compared with all other students that have sat this assessment"
        },
        {
          value: false, 
          switchText: "Targets generated from just this classes performance",
          strengthText: "Questions the class has performed best on, compared to the total marks available for the question",
          weaknessText: "Questions the class have performed worst on, compared to the total marks available for the question"
        }
      ]
    };
  },
  methods: {
    getTasks(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/22",
        params: {
          //SetID: this.$store.getters.getClass,
          SetID: this.getClass,
        },
        responseType: "json",
      })
        .then((response) => {
          this.tasks = response.data
          this.feedbackLoading = false
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getTestSummaryClass(t,s){
        const res = await this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/10",
        params: {
          SetID: s,
          TestID: t
        },
        responseType: "json",
      })
      return res.data
    },
    async getTestSummaryAll(t){
        const res = await this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/11",
        params: {
          TestID: t
        },
        responseType: "json",
      })
      return res.data
    },
    async getData(){
      this.testsLoading=true
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/9",
        params: {
          SetID: this.$store.getters.getClass,
        },
        responseType: "json",
      })
        .then(async (response) => {
          this.tests = response.data
          this.setAvg = []
          this.allAvg = []
          //for loop and create data
          for (let i=0;i<this.tests.length;i++){
            this.setAvg.push({test: this.tests[i].TestID, data: await this.getTestSummaryClass(this.tests[i].TestID, this.getClass)})
            this.allAvg.push({test: this.tests[i].TestID, data: await this.getTestSummaryAll(this.tests[i].TestID)})
          }
          this.testsLoading = false
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClasses(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/7",
        params: {
          SubjectID: this.$store.getters.getSubject,
          TeacherID: this.$store.getters.getTeacherID.TeacherID
        },
        responseType: "json",
      })
        .then((response) => {
          this.classes = response.data;
          this.$store.commit("setClass", this.classes[0].SetId);
          this.setScrollBar();
          this.getTasks()
          this.getData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setScrollBar() {
      let scroll = [];
      for (let i = 0; i < this.classes.length; i++) {
        scroll.push({
          ID: this.classes[i].SetId,
          Name: this.classes[i].SetName,
        });
      }
      this.$store.commit("setScrollBar", { slug: "classes", data: scroll });
    },
    getTargets(t){
      let retArray = {strengths: [], weaknesses: []}
      let j
      const result = this.setAvg.find(({ test }) => test === t);
      //if generating targets by comparing performance to other students...
        const result2 = this.allAvg.find(({ test }) => test === t);
        for (let n=0;n<result.data.Avgs.length;n++){
          let allMark = result2.data.find(({ QuestionID }) => QuestionID === result.data.Avgs[n].QuestionID);
          if (allMark!==undefined){
            result.data.Avgs[n].Comparison = (result.data.Avgs[n].Perc-allMark.Ratio)
          }
          else{
            result.data.Avgs[n].Comparison = 0
          }
        }
        if (result.data.Avgs.length>0){
          //result.data.Avgs.sort((a, b) => b.Comparison - a.Comparison);
        }
        
      if (result.data.Avgs.length>0){
        let k = (result.data.Avgs.length-1)
        if (result.data.Avgs.length>3){
          j = 4
        }
        else{
          j=k
        }
        for (let i=0;i<j;i++){
          let perc = Math.round(result.data.Avgs[i].Perc*100)
          if (result.data.Avgs[i].Perc>0){
            
            retArray.strengths.push({qn: result.data.Avgs[i].QuestionNumber, avg: result.data.Avgs[i].Avg, perc: perc, topic: result.data.Avgs[i].TestTopic, Comparison: result.data.Avgs[i].Comparison})
          }
          
          retArray.weaknesses.push({qn: result.data.Avgs[k-i].QuestionNumber, avg: result.data.Avgs[k-i].Avg, perc: Math.round(result.data.Avgs[k-i].Perc*100), topic: result.data.Avgs[k-i].TestTopic, Comparison: result.data.Avgs[k-i].Comparison})
        }
      }
      return retArray
    },
    sortTargets(targets){
      if (this.targetsCompare==true){
        targets.sort((a, b) => b.Comparison - a.Comparison);
      }
      else{
        targets.sort((a, b) => b.perc - a.perc);
      }
      
      return targets
    }
  },
  computed: {
    getCaptions(){
      let caption = this.toggleText.find(({ value }) => value === this.targetsCompare);
      return caption
    },
    getClass(){
      return this.$store.getters.getClass
    },
    yeargroup() {
      return this.classes[0].YeargroupID
    },
    currentYeargroup(){
      return this.classes.find(a=>a.SetId==this.$store.getters.getClass).YeargroupID
    }
    
  },
  watch: {
    getClass: function () {
        this.getData()
        this.feedbackLoading = true
        this.getTasks()
    },
  }
};
</script>
