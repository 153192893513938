<template>
  <div>
    <v-toolbar
      src="/images/slide_01.jpg"
      dark
      color="grey darken-3"
      class="text-h5"
    >
      {{ title }}
      <template v-slot:extension v-if="items">
        <v-tabs dark>
          <v-tab
            v-for="item in items"
            :key="item.id"
            @click="$emit('tab', item.id)"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "UploadTotals",
  props: {
    title: String,
    items: Array,
  },
  data() {
    return {
      //items: [{id:1, name:"fsfsff"}, {id:2, name:"ff"}]
    };
  },
};
</script>
