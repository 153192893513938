<template>
  <v-container>
    <v-card max-width="50%" class="mx-auto my-12">
            <ampil-card title="Forgotten Password"></ampil-card>
            <v-card-text>
              <v-form v-model="valid">
                <v-row>
                  <p>Enter your email address here, if it is in our database, you should receive an email to allow you to change your password</p>
                        <v-text-field
                            :rules="rulesEmail"
                            name="input-10-1"
                            label="Enter Email"
                            v-model="email"
                            class="input-group--focused"
                        ></v-text-field>
                    </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="lime" text @click="$router.push('../login')">Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn :disabled="valid==false" color="lime" text @click="submit()">Submit</v-btn>
            </v-card-actions>
        </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ForgotPassword",
  props: {},
  mounted() {},
  data() {
    return {
      valid: false,
      rulesEmail: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      ],
      email: null
    };
  },
  methods: {
    submit(){
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/forget-password",
        params: {
          email: this.email,
        },
        responseType: "json",
      })
        .then((response) => {
          this.$router.push("../login")
        })
        .catch((error) => {
          this.$router.push("../login")
        });
    }
  },
  computed: {},
};
</script>
