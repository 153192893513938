<template>
    <v-container v-if="loaded==true">
        
        <v-data-table
            
            :headers="headers"
            :items="ranking"
            :items-per-page="15"
    >
    <template v-slot:item.Grade="{ item }">
        <v-form v-model="submittable1">
        <v-text-field v-model="item.Grade" :rules="rules"
        ></v-text-field>
    </v-form>
    </template>
    <template v-slot:item.Ranking="{ item }">
        <v-form v-model="submittable2">
        <v-text-field v-model="item.Ranking" :rules="numberRules"
        ></v-text-field>
    </v-form>
    </template>
    <template v-slot:item.Score="{ item }">
        <v-form v-model="submittable3">
        <v-text-field v-model="item.Score" :rules="numberRules2"
        ></v-text-field>
        </v-form>
    </template>
    <template v-slot:item.Tflag="{ item }">
        <v-icon v-if="item.Tflag==true || item.Tflag==1" small color="red darken-3">mdi-flag</v-icon>
    </template>
    </v-data-table>
    <v-card-actions>
        <v-alert v-if="submitMsg != ''" dense outlined type="success">
          {{ submitMsg }}
        </v-alert>
        <v-spacer></v-spacer>
        
        <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="lime" :disabled="submittable1==false || submittable2==false || submittable3==false" v-bind="attrs" v-on="on">Submit</v-btn>
      </template>

      <v-card>
        <ampil-card title="Commit and finish feedback task"></ampil-card>
        <v-card-title>
          Are you sure you are done?
        </v-card-title>
        <v-card-text>
            This will end the feedback task.
        </v-card-text>
        

        <v-divider></v-divider>

        <v-card-actions>
            <v-btn
            color="lime"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="lime"
            text
            @click="submit()"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card-actions>
    
    </v-container>
</template>

<script>
export default {
  name: "FeedbackCheck",
  props: {
    yeargroupID: Number,
    taskID: Number,
  },
  mounted() {
    this.getRanking()
  },
  data() {
    return {
        ranking: [],
        headers: [
            {text: "ID", value: "StudentID"},
            {text: "Name", value: "LastFirst"},
            {text: "Set", value: "SetName"},
            {text: "Ranking", value: "Ranking"},
            {text: "Score", value: "Score"},
            {text: "Grade", value: "Grade"},
            {text: "Flag result", value: "Tflag"},
            {text: "Teacher Comment", value: "Comment"}
        ],
        loaded: false,
        submittable1: false,
        submittable2: false,
        submittable3: false,
        dialog: false,
        submitMsg: '',
        rules: [(value) => !!value || "Required."],
        numberRules: [
        //v => v.length > 0 || 'This field may not be empty',
        (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        (v) => v > 0 || "The value must be greater than zero",
        ],
        numberRules2: [
        //v => v.length > 0 || 'This field may not be empty',
        (v) => v > 0 || "The value must be greater than zero",
        ],
    };
  },
  methods: {
    submit(){
        let submit = []
        for (let i=0;i<this.ranking.length;i++){
            submit.push({keyval: {RankID: this.ranking[i].RankID || null}, values: {UniqueID: this.ranking[i].UniqueID, YearID: this.yeargroupID, SetID: this.ranking[i].SetId, Ranking: this.ranking[i].Ranking, Score: this.ranking[i].Score, Grade: this.ranking[i].Grade, Tflag: this.ranking[i].Tflag, Comment: this.ranking[i].Comment}})
        }
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/leader",
        data: {
          table: "ranking",
          data: submit
        },
        responseType: "json",
      })
        .then((response) => {
            //this.submitMsg="Grades submitted successfully"
            this.updateTasks()
            
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteTasks(){
        this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/leader/"+this.taskID,
        data: {
          table: "FeedbackTasks",
        },
        responseType: "json",
      })
        .then((response) => {
            this.submitMsg="Grades submitted successfully"
            this.dialog=false
            this.$emit('done')
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateTasks(){
        this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/leader/"+this.taskID,
        data: {
          table: "FeedbackMaps",
          key: "TaskID",
          values: {Status: 6}
        },
        responseType: "json",
      })
        .then((response) => {
            this.submitMsg="Grades submitted successfully"
            this.dialog=false
            this.$emit('done')
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRanking(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/16",
        params: {
          YeargroupID: this.yeargroupID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.ranking = response.data
            this.loaded=true
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {},
};
</script>
