<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card dark>
          <v-img
            height="70"
            src="/images/slide_01.jpg"
            cover
            class="text-white"
          >
            <v-card-title class="text-h6"> Upload Student Data </v-card-title>
          </v-img>
          <v-card-text class="white text--primary">
            <p>
              Bulk upload your student data via CSV file. In the upload file,
              all student ID's need to be unique. This is what will be used to
              match the students to those currently existing on amPIL.
            </p>
            <p>
              You must make sure each student ID has a name and a group. The
              date of birth is optional and will be used as a secondary log in
              check for students accessing their own student portal.
            </p>
            <p>
              If you do not supply a date of birth, students will simply log in
              with the student password and select their name.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title> File Upload: </v-card-title>
          <v-card-text>
            <v-file-input
              chips
              accept="text/csv"
              show-size
              label="Select CSV File for Import..."
              v-model="fileName"
            ></v-file-input>
            Download template file:
            <a
            :href="getLink+'names_upload.csv'"
              >Click Here</a
            >
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="lime"
              dark
              :disabled="fileName == null"
              @click="upload()"
            >
              Upload
            </v-btn>
            <v-alert v-if="errorMsg != ''" dense outlined type="error">
              {{ errorMsg }}
            </v-alert>
            <v-alert v-if="submitMsg != ''" dense outlined type="success">
              {{ submitMsg }}
            </v-alert>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="uploadCheck == true" cols="12">
        <v-card>
          <v-card-title> Upload Successful </v-card-title>
          <v-card-text>
            <div v-if="matchSets == true">
              Some teaching groups in the upload file do not have a match in
              your school structure. You can manually allocate these students to
              existing groups below. If you leave the select blank, the students
              in this class will not be uploaded.<br /><br />
              <div v-for="(ulSet, index) in ulSets" :key="index">
                <v-row v-if="ulSet.SetId == ''">
                  <v-col cols="4">
                    <b>{{ ulSet.SetName }}</b>
                  </v-col>
                  <v-col cols="4">
                    <v-switch v-model="ulSet.Included"></v-switch>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="ulSet.SetId"
                      v-if="ulSet.Included"
                      :items="dbSets"
                      item-value="SetId"
                      item-text="SetName"
                      label="Select Group"
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="4">
                    {{ ulSet.SetName }}
                  </v-col>
                  <v-col cols="4"> Matched! </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="ulSet.SetId"
                      :items="dbSets"
                      item-value="SetId"
                      item-text="SetName"
                      label="Select Group"
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-divider></v-divider>
            <div v-if="unmatched == true">
              Students exist on the database that are not included in the upload
              file. What do you want to do with these students?<br /><br />
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="unmatchedSelection"
                    :items="unmatchedOptions"
                    item-value="key"
                    item-text="name"
                    label="Select..."
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <div v-if="unmatchedSelection == 1">
                    <b
                      >This will keep all students not on the upload file in
                      their current set. Use this when uploading a partial set
                      of data such as a single yeargroup.</b
                    >
                  </div>
                  <div v-if="unmatchedSelection == 2">
                    <b
                      >Use this option when uploading students for the entire
                      cohort. Students not on the upload file are moved into an
                      'Off-Roll' group where it can be recovered and still used
                      for analysis.</b
                    >
                  </div>
                  <div style="color: red" v-if="unmatchedSelection == 3">
                    <b
                      >All students who are not included in the upload file will
                      be permanently deleted from the database along with any
                      saved results. If you select this option, the data for
                      these students can not be recovered.</b
                    >
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-dialog v-model="viewData">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="lime" dark v-bind="attrs" v-on="on">
                  View Import Data
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 lime"> Import Data </v-card-title>

                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="uploadData"
                    :items-per-page="50"
                    class="elevation-1"
                  ></v-data-table>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="lime" text @click="viewData = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-btn color="lime" dark @click="clearAll()"> Clear All </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="lime" dark @click="commit()">
              Commit to Database
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AdminUploadStudents",
  components: {},
  data: () => ({
    fileName: null,
    uploadData: [],
    uploadCheck: false,
    checkFile: ["StudentID", "Name", "Set", "DoB"],
    errorMsg: "",
    submitMsg: "",
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "StudentID",
      },
      {
        text: "Student Name",
        sortable: true,
        value: "Name",
      },
      {
        text: "Class",
        sortable: true,
        value: "Set",
      },
      {
        text: "Date of Birth",
        sortable: true,
        value: "DoB",
      },
    ],
    dbSets: [],
    ulSets: [],
    matchSets: false,
    viewData: false,
    selectRules: [(v) => v.length > 0 || "Group required"],
    existingStudents: [],
    unmatchedIDs: [],
    unmatched: false,
    unmatchedOptions: [
      {
        key: 1,
        name: "Keep students in current groups",
      },
      {
        key: 2,
        name: "Move students off roll",
      },
      {
        key: 3,
        name: "Permanently remove student data",
      },
    ],
    unmatchedSelection: 1,
    submitArray: [],
    offRollSet: [],
  }),
  mounted() {
    this.setScrollBar();
    this.createOffroll();
  },
  props: {},
  computed: {
    getLink(){
      return process.env.VUE_APP_CSV
    }
  },
  methods: {
    setScrollBar() {
      this.$store.commit("setScrollBar", {
        slug: "admin_students",
        data: [
          { ID: 1, Name: "Manually Allocate Students" },
          { ID: 2, Name: "Bulk Upload" },
        ],
      });
    },
    upload() {
      let self = this;
      this.$papa.parse(this.fileName, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: "greedy",
        complete: function (results) {
          self.fileName = null;
          if (self.checkFileValid(results)) {
            self.clearAll();
            self.uploadData = results.data;
            self.ulSets = [];
            self.errorMsg = "";
            self.uploadCheck = true;
            self.getSets();
            self.checkMissing();
          } else {
            self.uploadCheck = false;
          }
        },
        error: function () {
          this.fileName = null;
          console.log("Parsing errors");
        },
      });
    },
    clearAll() {
      this.ulSets = [];
      this.errorMsg = "";
      this.fileName = null;
      this.uploadCheck = false;
      this.dbSets = [];
      this.matchSets = false;
      this.viewData = false;
      this.existingStudents = [];
      this.unmatchedIDs = [];
      this.unmatched = false;
      this.submitMsg = "";
    },
    checkFileValid(d) {
      let h = Object.keys(d.data[0]);
      let ret = true;
      for (let i = 0; i < h.length; i++) {
        if (h[i] != this.checkFile[i]) {
          this.errorMsg =
            "The upload file is invalid. Please check you have uploaded using the correct template.";
          ret = false;
        }
      }
      if (this.checkUnique(d) == false) {
        ret = false;
        //return ret
      }
      if (this.checkDates(d) == false) {
        ret = false;
        //return ret
      }
      if (this.checkBlank(d) == false) {
        ret = false;
        //return ret
      }
      return ret;
    },
    checkUnique(d) {
      const unique = [...new Set(d.data.map((item) => item.StudentID))];
      if (unique.length == d.data.length) {
        return true;
      } else {
        this.errorMsg =
          "Duplicate Student IDs are present in your upload file. Each student must be assigned a unique ID.";
        return false;
      }
    },
    checkBlank(d) {
      let ret = true;
      let blanks = [];
      for (let i = 0; i < d.data.length; i++) {
        if (!d.data[i].StudentID) {
          blanks.push("StudentID");
          ret = false;
        }
        if (!d.data[i].Name) {
          blanks.push("Name");
          ret = false;
        }
        if (!d.data[i].Set) {
          blanks.push("Set");
          ret = false;
        }
      }
      if (ret == false) {
        this.errorMsg =
          "Blank rows were found in the following columns: " + blanks;
      }
      return ret;
    },
    checkDates(d) {
      let ret = true;
      let dateString = "";
      for (let i = 0; i < d.data.length; i++) {
        dateString = d.data[i].DoB;
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
          this.errorMsg =
            "Check date input column. Should be of the form DD/MM/YYYY";
          ret = false;
        }
        var parts = dateString.split("/");
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);
        // Check the ranges of month and year
        if (
          year < 1000 ||
          year > 3000 ||
          month < 1 ||
          month > 12 ||
          day < 1 ||
          day > 31
        ) {
          this.errorMsg =
            "Check date input column. Should be of the form DD/MM/YYYY";
          ret = false;
        }
      }
      return ret;
    },
    getSets() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/12",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.dbSets = response.data;
          let sets = [];
          for (let i = 0; i < this.uploadData.length; i++) {
            sets.push(this.uploadData[i].Set);
          }
          const unique = [...new Set(sets)];
          let chk = 0;
          let chk2 = false;
          for (let j = 0; j < unique.length; j++) {
            chk = 0;
            for (let i = 0; i < this.dbSets.length; i++) {
              if (this.dbSets[i].SetName == unique[j]) {
                this.ulSets.push({
                  SetId: this.dbSets[i].SetId,
                  SetName: this.dbSets[i].SetName,
                  Yeargroup: this.dbSets[i].Yeargroup,
                });
                chk = 1;
              }
            }
            if (chk == 0) {
              this.ulSets.push({
                SetId: "",
                SetName: unique[j],
                Yeargroup: "",
              });
              chk2 = true;
            }
          }
          if (chk2 == true) {
            this.matchSets = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    matchGroups() {
      this.matchSets = false;
    },
    checkMissing() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/13",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.existingStudents = response.data;
          let existingIds = [];
          for (let i = 0; i < this.existingStudents.length; i++) {
            existingIds.push(this.existingStudents[i].StudentID);
          }
          let uploadIds = [];
          for (let i = 0; i < this.uploadData.length; i++) {
            uploadIds.push(this.uploadData[i].StudentID.toString());
          }
          this.unmatchedIDs = existingIds.filter((x) => !uploadIds.includes(x));
          if (this.unmatchedIDs.length > 0) {
            this.unmatched = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dateFormat(d) {
      //console.log(new Date(d))
      //var parts = d.split("/");
      //var dt = new Date(parseInt(parts[2], 10),
      //            parseInt(parts[1], 10) - 1,
      //            parseInt(parts[0], 10));
      var parts = d.split("/");
      var day = "" + parseInt(parts[0], 10);
      var month = "" + parseInt(parts[1], 10);
      var year = parseInt(parts[2], 10);
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
      //console.log(dt.toString())
      //return dt.toString()
    },
    dateFormat2(d) {
      if (d==null){
        return "2001-01-01"
      }
      var parts = d.split("-");
      var year = parseInt(parts[0], 10);
      var month = "" + parseInt(parts[1], 10);
      var day = "" + parseInt(parts[2], 10);
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day;
    },
    commit() {
      let submitArray = [];
      let cnt = 0;
      let deleteArray = [];
      let x = {};
      //finds the students that are in the database already
      for (let i = 0; i < this.unmatchedIDs.length; i++) {
        x = this.find(this.existingStudents, this.unmatchedIDs[i]);
        console.log(x)
        if (this.unmatchedSelection == 1) {
          cnt++;
          submitArray.push({
            keyval: { UniqueID: x.UniqueID  || null},
            //keyval: {StudentID: x.StudentID, SubjectID: this.$store.getters.getSubject},
            values: {
              LastFirst: x.LastFirst,
              StudentID: x.StudentID,
              DoB: this.dateFormat2(x.DoB),
              SetID: x.SetID,
              SubjectID: this.$store.getters.getSubject,
            },
          });
        } else if (this.unmatchedSelection == 2) {
          cnt++;
          submitArray.push({
            keyval: { UniqueID: x.UniqueID  || null},
            //keyval: {StudentID: x.StudentID, SubjectID: this.$store.getters.getSubject},
            values: {
              LastFirst: x.LastFirst,
              StudentID: x.StudentID,
              DoB: this.dateFormat2(x.DoB),
              SetID: this.offRollSet[0].SetId,
              SubjectID: this.$store.getters.getSubject,
            },
          });
        } else if (this.unmatchedSelection == 3) {
          cnt++;
          deleteArray.push({ UniqueID: x.UniqueID });
        }
      }
      x = {};
      let y = {};
      for (let i = 0; i < this.uploadData.length; i++) {
        x = this.find(this.existingStudents, this.uploadData[i].StudentID);
        y = {};
        y = this.findSet(this.ulSets, this.uploadData[i].Set);
        if (!x && y.SetId) {
          submitArray.push({
            keyval: { UniqueID: null },
            //keyval: {StudentID: x.StudentID, SubjectID: this.$store.getters.getSubject},
            values: {
              LastFirst: this.uploadData[i].Name,
              StudentID: this.uploadData[i].StudentID,
              DoB: this.dateFormat(this.uploadData[i].DoB),
              SetID: y.SetId,
              SubjectID: this.$store.getters.getSubject,
            },
          });
        } else if (x && y.SetId) {
          submitArray.push({
            keyval: { UniqueID: x.UniqueID  || null},
            //keyval: {StudentID: x.StudentID, SubjectID: this.$store.getters.getSubject},
            values: {
              LastFirst: this.uploadData[i].Name,
              //StudentID: this.uploadData[i].StudentID,
              DoB: this.dateFormat(this.uploadData[i].DoB),
              SetID: y.SetId,
              //SubjectID: this.$store.state.Subject,
            },
          });
        }
      }
      //console.log(submitArray, deleteArray)
      this.commit2(deleteArray, submitArray, cnt);
    },
    commit2(deleteArray, submitArray, cnt) {
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "StudentNames",
          data: submitArray,
        },
        responseType: "json",
      })
        .then(() => {
          //console.log(response.data)
          if (deleteArray.length > 0) {
            this.$http({
              method: "delete",
              url: process.env.VUE_APP_BACKEND + "/admin/" + null,
              data: {
                table: "StudentNames",
                values: deleteArray,
              },
              responseType: "json",
            })
              .then(() => {
                //console.log(response.data)
                this.clearAll();
                this.submitMsg =
                  submitArray.length +
                  " student(s) on import file successfully uploaded to amPIL. ";
                if (cnt > 0) {
                  if (this.unmatchedSelection == 1) {
                    this.submitMsg =
                      this.submitMsg +
                      cnt +
                      " existing student(s) kept in current set";
                  }
                  if (this.unmatchedSelection == 2) {
                    this.submitMsg =
                      this.submitMsg +
                      cnt +
                      " existing student(s) moved Off-Roll";
                  }
                  if (this.unmatchedSelection == 3) {
                    this.submitMsg =
                      this.submitMsg +
                      cnt +
                      " Existing student(s) permanently removed";
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.clearAll();
            this.submitMsg =
              submitArray.length +
              " student(s) on import file successfully uploaded to amPIL. ";
            if (cnt > 0) {
              if (this.unmatchedSelection == 1) {
                this.submitMsg =
                  this.submitMsg +
                  cnt +
                  " existing student(s) kept in current set";
              }
              if (this.unmatchedSelection == 2) {
                this.submitMsg =
                  this.submitMsg + cnt + " existing student(s) moved Off-Roll";
              }
              if (this.unmatchedSelection == 3) {
                this.submitMsg =
                  this.submitMsg +
                  cnt +
                  " Existing student(s) permanently removed";
              }
            }
          }
          //this.clearAll()
          //this.submitMsg = submitArray.length+' students on import file successfully uploaded to amPIL'
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOffroll() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/14",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.offRollSet = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    find(s, v) {
      const result = s.find(
        ({ StudentID }) => StudentID.toString() === v.toString()
      );
      return result;
    },
    findSet(s, v) {
      const result = s.find(
        ({ SetName }) => SetName.toString() === v.toString()
      );
      return result;
    },
    createOffroll() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/15",
        params: {
          SubjectId: this.$store.getters.getSubject,
          Yeargroup: 99,
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.length == 0) {
            this.$http({
              method: "post",
              url: process.env.VUE_APP_BACKEND + "/admin",
              data: {
                table: "Yeargroups",
                data: [
                  {
                    keyval: { YeargroupID: null },
                    values: {
                      Yeargroup: 99,
                      SubjectId: this.$store.getters.getSubject,
                    },
                  },
                ],
              },
              responseType: "json",
            })
              .then((response2) => {
                this.$http({
                  method: "post",
                  url: process.env.VUE_APP_BACKEND + "/admin",
                  data: {
                    table: "MathsSets",
                    data: [
                      {
                        keyval: { SetId: null },
                        values: {
                          SetName: "Off-Roll",
                          YeargroupID: response2.data.lastid,
                        },
                      },
                    ],
                  },
                  responseType: "json",
                })
                  .then(() => {
                    this.getOffroll();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.getOffroll();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
