<template>
    <v-container>
        <v-card max-width="800" class="mx-auto my-12">
      <ampil-card title="Register"></ampil-card>
      <v-toolbar
      flat
      color="lime"
      dark
    >
      <v-toolbar-title>I am a...</v-toolbar-title>
    </v-toolbar>
    <v-tabs vertical color="lime">
      <v-tab>
        <v-icon left>
          mdi-account-school
        </v-icon>
        Student
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-account
        </v-icon>
        Teacher
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-town-hall
        </v-icon>
        New school
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              You will need to know your unique school access code or QR code. If you do not have this, you should ask your teacher to provide you with this.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="lime" text @click="$router.push('/code')">Enter your access code</v-btn>
        </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Individual teacher accounts will be set up by the department lead. 
            </p>
            <p>
              These, along with student accounts, are set up in the 'Admin' view under the 'Manage users' page.
            </p>
            <p>
              Once an account has been set up for you, you should receive an email which will allow you to activate your account.
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-form v-model="formValid">
        <v-card-text>
            <p>I wish to set up a free school account for my establishment and acknowledge I have the authority to do so</p>
            <v-text-field v-model="name" placeholder="Name" :rules="[rules.required]"></v-text-field>
          <v-text-field v-model="email" placeholder="Email" :rules="rulesEmail"></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            :rules="[rules.required, rules.min]"
            placeholder="Password"
          ></v-text-field>
          <v-text-field
            v-model="password_confirmation"
            type="password"
            :rules="[matchingPasswords]"
            placeholder="Password confirmation"
          ></v-text-field>
          <v-checkbox
             v-model="checkbox"
             :rules="[rules.check]"
            label="I agree to the terms and conditions set out by amPIL.co.uk"
            ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="formValid==false || submitted==true" color="lime" text @click="recaptcha">Sign Up</v-btn>
        </v-card-actions>
    </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs>


    <v-card-actions>
          <v-btn color="lime" text @click="$router.push('/login')">Log In</v-btn>
        </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="lime"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-container>
</template>

<script>
export default {
  name: "Register",
  props: {},
  components: {

  },
  mounted() {},
  data() {
    return {
        name: "",
        googleToken: null,
        formValid: false,
        submitted: false,
        snackbar: false,
        email: "",
        message: "",
        password: "",
        checkbox: false,
        password_confirmation: "",
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          check: v=> v==true || "You must agree to the T&C's"
        },
        rulesEmail: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      ],
    };
  },
  methods: {
    submit(){
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/auth/register",
        data: {
            name: this.name,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation
        },
        //responseType: "json",
      })
        .then((response) => {
          this.submitted=true
            this.snackbar=true
            this.message = "Success: Please check your email to continue setup"
        })
        .catch((error) => {
            this.snackbar=true
            this.message = "This operation failed. Please check your input and try again"
        });
    },
    async recaptcha() {

      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/recaptcha",
        params: {
            secret: process.env.VUE_APP_RECAPTCHA_SECRET,
            response: token,
        },
        //responseType: "json",
      })
        .then((response) => {
          if (response.data.success==true){
            this.submit()
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // Do stuff with the received token.
    },

  },
  computed: {
    matchingPasswords: function() {
            if (this.password === this.password_confirmation) {
                return true;
            } else {
            return 'Passwords do not match.';
            }
            },
  },
};
</script>
