<template>
  <v-container>
    <v-card elevation="10">
      <v-toolbar
        src="/images/slide_01.jpg"
        dark
        color="grey darken-3"
        class="text-h5"
      >
        <v-avatar tile color="black">
          <v-icon dark> mdi-traffic-light </v-icon>
        </v-avatar>
        Progress predictors:
      </v-toolbar>
      <v-card-text>
        Scheme of Work:
        <v-menu offset-y v-if="trigger == true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="light-green darken-3"
              text
              dark
              v-bind="attrs"
              v-on="on"
              @click="changeSoW()"
            >
              {{ SoWID.Name }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in SoW"
              :key="index"
              @click="selSoW(item.SoWId, item.SoWName)"
            >
              <v-list-item-title>{{ item.SoWName }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-data-table
          height="500"
          v-if="trigger"
          :headers="headers"
          :items="sowTopics"
          :items-per-page="-1"
          hide-default-footer
          class="elevation-1 overflow-auto"
        >
          <template v-slot:item.Rag="{ item }">
            <v-progress-circular
              @click="breakdown(item.Qdata, item.SoWTopic)"
              :rotate="360"
              :size="50"
              :width="5"
              :value="item.Rag"
              :color="ragcolour(item.Rag)"
            >
              {{ item.Rag }}
            </v-progress-circular>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showbd" width="500">
      <v-card>
        <v-card-title
          class="text-h5 light-green darken-3 pink--text text--lighten-5"
        >
          {{ bd.SoW }}
        </v-card-title>

        <v-card-text>
          <p>
            The judgements for this rating come from the following questions:
          </p>
          <div v-for="(item, index) in bd.data" :key="index">
            <p class="text-overline font-weight-bold">
              {{ item.Test }}
            </p>
            <p>
              Q{{ item.Qno }}) {{ item.Topic }} Score: {{ item.MarkAwarded }}/{{
                item.MarksAvailable
              }}
              ({{ item.Perc }}%)
            </p>
            <v-divider></v-divider>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light-green darken-3" text @click="showbd = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import axios from "axios";
//axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_AUTH_KEY;
export default {
  name: "Home",
  components: {},
  data: () => ({
    sowTopics: {},
    studentRAG: {},
    SoW: [],
    SoWID: {},
    trigger: false,
    showbd: false,
    bd: {},
    headers: [
      {
        text: "Topic",
        align: "start",
        sortable: false,
        value: "SoWTopic",
      },
      { text: "RAG", value: "Rag" },
    ],
  }),
  mounted() {},
  watch: {
    getStudent() {
      if (this.getStudent.SoWID > 0) {
        this.SoWID.ID = this.getStudent.SoWID;
        this.SoWID.Name = this.getStudent.SoW;
        this.getRAG(this.getStudent.SoWID);
      }
    },
  },
  methods: {
    getRAG(s) {
      this.trigger = false;
      this.sowTopics = {};
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/31",
        params: {
          SoWID: s,
          UniqueID: this.getStudent.UniqueID,
        },
        responseType: "json",
      })
        .then((response) => {
          this.getQuestions(response.data, s);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSoW(rag, q, s) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/3",
        params: {
          SoWID: s,
        },
        responseType: "json",
      })
        .then((response) => {
          this.sowTopics = response.data;
          for (let i = 0; i < this.sowTopics.length; i++) {
            this.sowTopics[i].Qdata = [];
            for (let j = 0; j < rag.length; j++) {
              if (this.sowTopics[i].SoWTopicID == rag[j].SoWTopicID) {
                this.sowTopics[i].Rag = parseInt(rag[j].Rag * 100);
              }
            }
            for (let k = 0; k < q.length; k++) {
              if (this.sowTopics[i].SoWTopicID == q[k].SoWTopicID) {
                this.sowTopics[i].Qdata.push({
                  MarkAwarded: q[k].MarkAwarded,
                  MarksAvailable: q[k].MarksAvailable,
                  Perc: q[k].Ratio * 100,
                  Qno: q[k].QuestionNumber,
                  Topic: q[k].TestTopic,
                  Test: q[k].TestName,
                });
              }
            }
          }
          this.trigger = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getQuestions(rag, s) {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/32",
        params: {
          SoWID: s,
          UniqueID: this.getStudent.UniqueID,
        },
        responseType: "json",
      })
        .then((response) => {
          let c = response.data;
          this.getSoW(rag, c, s);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeSoW() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/2",
        params: {
          SubjectID: this.getStudent.SubjectID,
        },
        responseType: "json",
      })
        .then((response) => {
          this.SoW = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selSoW(s, n) {
      this.SoWID.ID = s;
      this.SoWID.Name = n;
      this.getRAG(s);
    },
    ragcolour(c) {
      let colour = "";
      if (c > this.$store.state.studentDetails.Red) {
        colour = "red";
      }
      if (c > this.$store.state.studentDetails.Amber) {
        colour = "amber";
      }
      if (c > this.$store.state.studentDetails.Green) {
        colour = "green";
      }
      return colour;
    },
    breakdown(c, t) {
      if (c.length > 0) {
        this.showbd = true;
        this.bd.SoW = t;
        this.bd.data = c;
      }
    },
  },
  computed: {
    getSchool() {
      return this.$store.getters.getSchool;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
  },
};
</script>
