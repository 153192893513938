<template>
  <v-container>
    {{ menuArray[menuSelect - 1].text }}
    <v-stepper v-model="menuSelect" class="mt-12" non-linear value="3">
      <v-stepper-header>
        <v-stepper-step editable step="1" color="lime">
          SoW bindings
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable step="2" color="lime">
          Assessment bindings
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable step="3" color="lime">
          Manual allocations
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content step="1">
        <mapping-sow></mapping-sow>
      </v-stepper-content>
      <v-stepper-content step="2">
        <mapping-assessment></mapping-assessment>
      </v-stepper-content>
      <v-stepper-content step="3">
        <mapping-manual></mapping-manual>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import MappingSow from "@/components/admin/MappingSow";
import MappingAssessment from "@/components/admin/MappingAssessment";
import MappingManual from "@/components/admin/MappingManual";
export default {
  name: "AdminMappings",
  components: {
    MappingSow,
    MappingAssessment,
    MappingManual,
  },
  data: () => ({
    menuSelect: 1,
    menuArray: [
      { id: 1, text: "Allocate pre-requisite topics to Scheme of Work" },
      { id: 2, text: "Allocate topics to an assessment" },
      {
        id: 3,
        text: "Manually allocate prerequisites to Scheme of Work topics",
      },
    ],
  }),
  mounted() {},
  props: {},
  methods: {},
};
</script>
