<template>
    <v-container>
        <v-dialog  v-if="loaded" v-model="dialog" width="500" persistent>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="lime" text v-bind="attrs" v-on="on">Check initial ranking </v-btn>
                    </template>
                    <v-card>
                        <ampil-card :title="task.TaskName"></ampil-card>

                            

                        <v-card-text>
                            Drag and drop the students into the desired order, based on their ability. They are initially sorted by a 'Score' that amPIL calculates. When you are done, click save.
                            <v-simple-table class="table table-striped" fixed-header height="500px">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">Name</th>
                                <th scope="col">Set</th>
                                <th scope="col">Score</th>
                                <th v-for="test in markbook.tests" scope="col">{{test.TestName}}</th>
                            </tr>
                        </thead>
                    <draggable v-model="markbook.data" tag="tbody" group="UniqueID" @start="drag = true; numbering()" @end="drag = false;numbering();">
                        <tr v-for="(student, index) in markbook.data" :key="index" :id="student.UniqueID">
                            <td scope="row">{{ student.Ranking }}</td>
                            <td>{{ student.LastFirst }}</td>
                            <td>{{ student.SetName }}</td>
                            <td><b>{{ Math.round(student.Score)}}</b></td>
                            <td v-for="test in markbook.tests">{{ student[test.TestID] }}</td>

                        </tr>
                    </draggable>
                    </v-simple-table> 
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="lime" text @click="dialog=false">Close</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="lime" dark @click="save()">Save</v-btn>
                    </v-card-actions>

                    
                    </v-card>
                </v-dialog>
    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import Markbook from '@/mixins/Markbook'
export default {
  name: "FeedbackRankCheck",
  mixins: [Markbook],
  props: {
    status: Number,
    task: Object,

  },
  components: {
    draggable
  },
  mounted() {
    this.getAllData()
  },
  data() {
    return {
        dialog: false,
        allData: [],
        allStudents: [],
        allRanking: [],
        loaded: false,
        markbook: [],
        drag: false,
        yearRank: [],
    };
  },
  methods: {
    save(){
        let retarray = []
        //sort by teacher ranking (should be markbook.data)
        //apply original ranking (should be this.yearRank)
        for (let i=0;i<this.markbook.data.length;i++){
            this.markbook.data[i].Trank = this.markbook.data[i].Ranking
            this.markbook.data[i].Ranking = this.yearRank[i]
            let stu = this.allRanking.find(a=> a.UniqueID==this.markbook.data[i].UniqueID)
            stu.Trank = this.markbook.data[i].Trank
            stu.Ranking = this.markbook.data[i].Ranking
            let toAdd = {keyval: {RankID: stu.RankID || null}, values: {YearID: stu.YeargroupID, SetID: stu.SetId, UniqueID: stu.UniqueID, Ranking: stu.Ranking, Score: stu.Score, Trank: stu.Trank, Tests: stu.Tests, Tflag: 0}}
            retarray.push(toAdd)
        }
        
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/teacher",
        data: {
          table: "ranking",
          data: retarray,
        },
        responseType: "json",
      })
        .then((response) => {
            this.save2()
        })
        .catch((error) => {
          console.log(error);
        });
        
    },
    save2(){
        this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/teacher/"+this.task.FeedbackMapID,
        data: {
          table: "FeedbackMaps",
          key: "FeedbackMapID",
          values: {Status: 3}
        },
        responseType: "json",
      })
        .then((response) => {
          this.dialog=false
          this.$emit('submitted')
        })
        .catch((error) => {
          console.log(error);
        });
    },
    numbering() {
      for (let i = 0; i < this.markbook.data.length; i++) {
        this.markbook.data[i].Ranking = i+1;
      }
    },
    getAllData(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/25",
        params: {
          SetID: this.task.SetID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allData = response.data
            this.getStudents()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStudents(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/26",
        params: {
            SetID: this.task.SetID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allStudents = response.data
            this.getRanking()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRanking(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/27",
        params: {
            SetID: this.task.SetID,
        },
        responseType: "json",
      })
        .then((response) => {
            this.allRanking = response.data
            this.markbook = this.markbookSort(this.allStudents, [this.task.TestID], this.allData, this.allRanking)
            this.markbook.data.sort((a, b) => a.Ranking - b.Ranking)
            this.yearRank = this.markbook.data.map(a=> a.Ranking)
            //console.log(this.yearRank)
            for (let i=0;i<this.markbook.data.length;i++){
                this.markbook.data[i].YearRank = this.markbook.data[i].Ranking
                this.markbook.data[i].Ranking = (i+1)
            }
            this.loaded = true
          //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
};
</script>
