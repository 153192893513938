<template>
  <v-container>
    <div class="text-center">
        <v-card max-width="50%" class="mx-auto my-12">
            <ampil-card title="Add student accounts"></ampil-card>
            <v-card-text>
              <p>To add student accounts, they simply need a joining code, where they will then be asked for their name and to confirm their date of birth</p>
              <p>Your school joining code is:</p>
              <p><div class="text-h3">{{ code.code }}</div></p>
              <p>Students will be able to either scan a QR code, or visit a link and type in the code</p>
              <p>You can print stickers with the QR code and joining details by using the button below</p>
            </v-card-text>
            <v-card-actions>
              <confirm-action Title="Generate new code" btntext="Regenerate code" Message="Warning: this will invalidate all codes used previously. Accounts that are already activated will not be affected." @confirm="newCode()"></confirm-action>
                <v-spacer></v-spacer>
                
                <v-btn color="lime" text @click="reportgen()">Print stickers</v-btn>
            </v-card-actions>
        </v-card>
    </div>
  </v-container>
</template>

<script>
import ConfirmAction from "@/components/admin/ConfirmAction";
export default {
  name: "Testing",
  components: {
    ConfirmAction,
  },
  props: {},
  mounted() {
    this.checkCode()
  },
  data() {
    return {
      code: ""
    };
  },
  methods: {
    checkCode(){
      //check if a code exists
      //make one if not
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/users_students/code",
        params: {
          subject: this.$store.getters.getSubject,
          code: this.makeid(7)
        },
        responseType: "json",
      })
        .then((response) => {
          this.code = response.data
        })
        .catch((error) => {
          console.log(error);
        });
      //set variable code to it
    },
    newCode(){
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/users_students/code",
        params: {
          subject: this.$store.getters.getSubject,
          code: this.makeid(7),
          forcenew: 1,
        },
        responseType: "json",
      })
        .then((response) => {
          this.code = response.data
        })
        .catch((error) => {
          console.log(error);
        });
      //check if code exists in db
      //if it does, regenerate
      //save to db
    },
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    reportgen: function () {
      var link = "https://www.ampil.co.uk/code"
      var code = this.code.code
        var pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require("pdfmake/build/vfs_fonts.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          content: [

{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},
{text:"\n\n"},
{columns: [
    [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  [{text: ["Scan the QR code or visit "]},
      {columns: [{ qr: link+"?code="+code, fit: "70", width: 60 },[{text: link, bold:true},"and use the access code", {text: code, style: 'header'}]]}
      ],
  ],
},

],
styles: {
		header: {
			fontSize: 18,
			bold: true
		},
		bigger: {
			fontSize: 15,
			italics: true
		}
	},
          defaultStyle: {columnGap: 20},
        };
        //console.log(content)
        pdfMake.createPdf(docDefinition).download("Signup_sheet.pdf");
      },
  },
  computed: {},
};
</script>
