<template>
    <div>
      Please select a teacher to display as...
      <v-autocomplete
        class="my-10"
        v-model="teacherID"
        :items="teachers"
        item-text="Teacher"
        item-value="TeacherID"
        outlined
        dense
        label="Select Teacher Name"
      ></v-autocomplete>
    </div>
  </template>
  
  <script>
  export default {
    name: "SelectTeacher",
    components: {},
    data: () => ({
      teachers: [],
      teacherID: null,
    }),
    mounted() {
      this.getTeachers();
    },
    watch: {
      teacherID() {
        if (this.teacherID != null) {
            const result = this.teachers.find(({ TeacherID }) => TeacherID === this.teacherID);
          this.$store.commit("setTeacherID", result);
        }
      },
    },
    methods: {
      getTeachers() {
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/teacher/8",
          params: {
            SubjectID: 1,
          },
          responseType: "json",
        })
          .then((response) => {
            this.teachers = response.data;
            //return response.data
            //this.loaded=true
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    computed: {
      getSchool() {
        return this.$store.getters.getSchool;
      },
      getTeacher() {
        return this.$store.getters.getTeacherDetails;
      },
    },
  };
  </script>