<template>
  <div class="home">
    <task-banner :studentID="getStudentID"></task-banner>
    <v-row>
      <v-col>
        <Dashboard />
        <grade-graph></grade-graph>
      </v-col>
      <v-col>
        <Rag></Rag>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";
import Dashboard from "@/components/student/Dashboard.vue";
import Rag from "@/components/student/RAG.vue";
import GradeGraph from "@/components/student/GradeGraph.vue";
import TaskBanner from "@/components/student/TaskBanner.vue";
export default {
  name: "StudentHome",
  data: () => ({}),
  components: {
    Dashboard,
    Rag,
    GradeGraph,
    TaskBanner,
  },
  mounted() {
    this.getStudentDetails();
  },
  watch: {
    getStudentID() {
      this.getStudentDetails();
    },
  },
  methods: {
    getStudentDetails() {
      let studentDetails = {};
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/25",
        params: {
          UniqueID: this.$store.getters.getStudentID,
        },
        responseType: "json",
      })
        .then((response) => {
          studentDetails.UniqueID = response.data[0].UniqueID;
          studentDetails.StudentID = response.data[0].StudentID;
          studentDetails.Name = response.data[0].LastFirst;
          studentDetails.DoB = response.data[0].DoB;
          studentDetails.Set = response.data[0].SetName;
          studentDetails.SetID = response.data[0].SetID;
          studentDetails.Year = response.data[0].Yeargroup;
          studentDetails.Subject = response.data[0].subject_name;
          studentDetails.SubjectID = response.data[0].subjectid;
          studentDetails.Red = response.data[0].Red;
          studentDetails.Amber = response.data[0].Amber;
          studentDetails.Green = response.data[0].Green;
          studentDetails.Average = response.data[0].Average;
          studentDetails.SoW = response.data[0].SoWName;
          studentDetails.SoWID = response.data[0].SoWId;
          studentDetails.Tests = [];

          for (let i = 0; i < response.data.length; i++) {
            studentDetails.Tests.push({
              ID: response.data[i].TestID,
              Name: response.data[i].TestName,
              StudentTotal: parseInt(response.data[i].STotal),
              TeacherTotal: parseInt(response.data[i].TTotal),
              Total: parseInt(response.data[i].Total),
            });
          }
          studentDetails.School = this.getSchool;
          this.$store.commit("setStudent", studentDetails);
          this.$store.commit("setSelectedTest", this.getStudent.Tests[0].ID);
          this.setScrollBar();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setScrollBar() {
      this.$store.commit("setScrollBar", {
        slug: "assessment",
        data: this.getStudent.Tests,
      });
    },
  },
  computed: {
    getSchool() {
      return this.$store.getters.getSchool;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
    getStudentID() {
      return this.$store.getters.getStudentID;
    },
  },
};
</script>
