<template>
  <div>
    <div v-if="view == true">
      <div v-for="head in revision">
        <h1>{{ head.topic }}</h1>
        <ul v-for="top in head.questions">
          <li>
            {{ top.topic }}
          </li>
        </ul>
      </div>
    </div>
    <v-btn
      text
      color="grey darken-3"
      :disabled="revision.length == 0"
      @click="revgen()"
    >
      {{ buttonText }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ExportRevision",
  props: {
    selectedTest: Number,
    buttonText: String,
    testName: String,
    view: Boolean,
  },
  mounted() {
    let b = this;
    this.toDataURL("/images/logo2.png", function (dataUrl) {
      b.pdf.header.columns[0].image = dataUrl;
    });
    this.getRev();
  },
  data() {
    return {
      revision: [],
      pdf: {
        header: {
          columns: [
            {
              image: "data:image/png;base64,",
              height: 30,
              width: 30,
              margin: [30, 10],
            },
            { text: "", style: "header", margin: [35, 10] },
          ],
        },
        footer: {
          columns: [
            { text: "Created on ", alignment: "left", margin: [30, -5] },
            { text: "Licensed to ", alignment: "center", margin: [30, -5] },
            {
              text: "amPIL Tracker by D Wilson",
              alignment: "right",
              margin: [30, -5],
            },
          ],
          margin: [0, 10],
          style: "footer",
        },
        styles: {
          header: {
            fontSize: 22,
            bold: true,
            italics: true,
            color: "#50900c",
          },
          details: {
            color: "black",
          },
          footer: {
            fontSize: 8,
          },
        },
        content: [],
      },
    };
  },
  methods: {
    getRev() {
      this.revision = [];
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/student/29",
        params: {
          TestID: this.selectedTest,
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.length > 0) {
            let j = response.data[0].RevLinkID;
            this.revision.push({
              topic: response.data[0].RevHead,
              questions: [],
            });
            let k = 0;
            //sort revision array
            for (let i = 0; i < response.data.length; i++) {
              //this.revision[k].Question.push(response.data[i].TestTopic)

              if (response.data[i].RevLinkID != j) {
                this.revision.push({
                  topic: response.data[i].RevHead,
                  questions: [],
                });
                k++;
                j = response.data[i].RevLinkID;
              }
              this.revision[k].questions.push({
                topic: response.data[i].TestTopic,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    revgen: function () {
      this.pdf.content = [];
      var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = "Created on " + dd + "/" + mm + "/" + yyyy;
      this.pdf.footer.columns[0].text = today;
      this.pdf.footer.columns[1].text =
        "Licensed to " + this.$store.getters.getSchool;
      this.pdf.header.columns[1].text = "Revision Checklist: " + this.testName;
      let details = {
        columns: [
          {
            text: "Use this checklist to help with your revision. Topics do not appear in order of questions on the paper.",
          },
        ],
        style: "details",
        margin: [10, 20],
      };
      this.pdf.content.push(details);
      for (let i = 0; i < this.revision.length; i++) {
        let tab = {
          layout: "lightHorizontalLines", // optional

          table: {
            headerRows: 1,
            widths: ["*", 24],
            body: [
              [
                { text: this.revision[i].topic, style: "header", fontSize: 15 },
                { text: "Tick", style: "details" },
              ],
            ],
          },
        };
        let row = [];
        for (let j = 0; j < this.revision[i].questions.length; j++) {
          row = [
            this.revision[i].questions[j].topic,
            {
              canvas: [
                {
                  type: "rect",
                  x: 10,
                  y: 2,
                  w: 10,
                  h: 10,
                  r: 0,
                  color: "lightgrey",
                  lineColor: "black",
                },
              ],
            },
          ];
          tab.table.body.push(row);
        }

        this.pdf.content.push(tab);
        this.pdf.content.push({ text: " " });
      }

      var docDefinition = {
        header: this.pdf.header,
        footer: this.pdf.footer,
        content: this.pdf.content,
        styles: this.pdf.styles,
        defaultStyle: { color: "gray", fontSize: 10 },
      };
      pdfMake.createPdf(docDefinition).download("Revisionlist.pdf");
    },
  },
  computed: {},
};
</script>
