<template>
    <v-container>
        <div v-if="ready" v-for="(Teacher, index) in teachers" :key="index">
            <v-form v-model="isFormValid">
              <v-container>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      :rules="rules"
                      v-model="Teacher.Teacher"
                      label="Teacher:"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-icon
                      slot="append"
                      color="red"
                      @click="removeTeacher(Teacher.TeacherID, teachers, Teacher.Teacher)"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      v-model="Teacher.selectedClasses"
                      :items="classes"
                      item-text="SetName"
                      item-value="SetId"
                      outlined
                      dense
                      chips
                      small-chips
                      label="Classes"
                      multiple
                      deletable-chips
                    ></v-autocomplete>
                  </v-col>
                </v-row>

              </v-container>
            </v-form>
          </div>
          <div>
            <v-container>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            :key="teachers.length"
                            v-model="newTeacher"
                            label="Add new teacher:"
                            @keyup="keyset()"
                            @change="newRow()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <div v-if="showmsg">
                            Press enter, or click outside the box when done
                        </div>
                        <v-alert v-if="showalert" dense outlined type="error">
                            Teacher name needs to be unique
                        </v-alert>
                    </v-col>
                    
                   
                </v-row>
                <v-row>
                    <v-col>
                        <v-alert v-if="submitted" dense outlined type="success">
                            Submitted successfully!
                        </v-alert>
                    </v-col>
                    <v-col>
                        <v-btn v-if="isFormValid" color="lime" dark @click="submit">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-container>
          </div>
    </v-container>
</template>

<script>
//remember to change getSubject!!!
export default {
  name: "Testing",
  props: {},
  mounted() {
    this.getTeachers()
  },
  data() {
    return {
        teachers: [],
        mappings: [],
        classes: [],
        rules: [(value) => !!value || "Required."],
        isFormValid: false,
        ready: false,
        newTeacher: "",
        showmsg: false,
        showalert: false,
        submitTeachers: [],
        deleteTeachers: [],
        submitMap: [],
        deleteMap: [],
        submitted: false,

    };
  },
  methods: {
    deleteT(){
        this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/1",
        data: {
          table: "Teachers",
          values: this.deleteTeachers,
        },
        responseType: "json",
      })
        .then(() => {
          this.submitT()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitT(){
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "Teachers",
          data: this.submitTeachers,
        },
        responseType: "json",
      })
        .then((response) => {
          //this.getSum();
          //add insert ids
          let ids = response.data.insertids
          for (let i=0;i<ids.length;i++){
            if (this.submitTeachers[i].keyval.TeacherID!=ids[i]){
                this.submitTeachers[i].keyval.TeacherID=ids[i]
                this.teachers[i].TeacherID=ids[i]
            }
          }
          this.deleteM()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteM(){
        this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/1",
        data: {
          table: "TeachersSets",
          values: this.deleteMap,
        },
        responseType: "json",
      })
        .then(() => {
            for (let i=0;i<this.teachers.length;i++){
            //this.submitTeachers.push({keyval: {TeacherID: this.teachers[i].TeacherID}, values: {Teacher: this.teachers[i].Teacher, SubjectID: this.getSubject}})
            for (let j=0;j<this.teachers[i].selectedClasses.length;j++){
                let match = this.mappings.find(a=>a.TeacherID==this.teachers[i].TeacherID && a.SetID==this.teachers[i].selectedClasses[j])
                if (match!=undefined){
                    this.submitMap.push({keyval: {TeachersSetsID: match.TeachersSetsID || null}, values: {TeacherID: match.TeacherID, SetID: match.SetID}})
                }
                else{
                    this.submitMap.push({keyval: {TeachersSetsID: null}, values: {TeacherID: this.teachers[i].TeacherID, SetID: this.teachers[i].selectedClasses[j]}})
                }
            }
        }
        for (let i=0;i<this.mappings.length;i++){
            let match2 = this.submitMap.find(a=> a.keyval.TeachersSetsID==this.mappings[i].TeachersSetsID && this.mappings[i].TeachersSetsID!=null)
            if (match2==undefined){
                this.deleteMap.push({TeachersSetsID: this.mappings[i].TeachersSetsID})
            }
        }
          
          
          //console.log(this.submitMap)
          this.submitM()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitM(){
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "TeachersSets",
          data: this.submitMap,
        },
        responseType: "json",
      })
        .then(() => {
          //this.getSum();
          this.submitted=true
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeTeacher(id, array, val){
        let ind = array.findIndex(a=>a.TeacherID==id && a.Teacher==val)
        let t = array.find(a=>a.TeacherID==id && a.Teacher==val)
        array.splice(ind, 1);
        if (t.TeacherID!=null){
            this.deleteTeachers.push({TeacherID: t})
            //console.log(this.deleteTeachers)
        }
    },
    submit(){
        this.submitTeachers = []
        this.submitMap = []
        this.deleteMap = []
        for (let i=0;i<this.teachers.length;i++){
            this.submitTeachers.push({keyval: {TeacherID: this.teachers[i].TeacherID || null}, values: {Teacher: this.teachers[i].Teacher, SubjectID: this.getSubject}})
            
        }
        //this.submitT()
        this.deleteT()
        //console.log(this.deleteMap, this.submitMap)
    },
    keyset() {
      this.showalert = false;
      this.showmsg = true;
    },
    newRow() {
      let k = 0;
      //check if exists in db
      const result = this.teachers.find(
        ({ Teacher }) => Teacher === this.newTeacher
      );
      if (result) {
        k++;
      }
      if (k == 0) {
        this.teachers.push({
          TeacherID: null,
          Teacher: this.newTeacher,
          selectedClasses: [],
        });
      } else {
        this.showalert = true;
      }
      this.newTeacher = "";
      this.showmsg = false;
    },
    getTeachers(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/25",
        params: {
          SubjectID: this.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.teachers = response.data.teachers;
          this.mappings = response.data.mappings
          this.classes = response.data.allsets
          for (let i=0;i<this.teachers.length;i++){
            let c = this.mappings.filter(a=> a.TeacherID==this.teachers[i].TeacherID).map(b=>b.SetID)
            this.teachers[i].selectedClasses = c
          }
          this.ready = true
          //console.log(this.teachers)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {
    getSubject(){
        //return 1
        return this.$store.getters.getSubject
    }
  },
};
</script>
