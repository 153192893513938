<template>
    <v-container>
        <div class="text-center" v-if="loaded">
            <v-card max-width="50%">
            <v-card-title>
                Profile:
            </v-card-title>
            <v-card-text>
                <v-form v-model="formValid">
                    <v-text-field
                    v-model="profile.user.name"
                    :rules="[rules.required]"
                    label="Name"
                    :disabled="canSubmit==false"
                    ></v-text-field>
                    <v-text-field
                    v-model="profile.user.role.name"
                    label="Role"
                    disabled
                    ></v-text-field>
                    <v-text-field
                    v-model="profile.user.email"
                    :rules="[rules.required, rules.email]"
                    label="E-mail"
                    :disabled="canSubmit==false"
                    ></v-text-field>
                    <div v-if="canSubmit==false">
                        <p>Enter password to make changes:</p>
                    <v-row>
                        <v-col>
                            <v-text-field
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    v-model="password"
                    :rules="[rules.required, rules.counter]"
                    label="Password"
                    ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-btn color="lime" text @click="passCheck()">Submit Password</v-btn>
                            <v-alert
                                v-if="incorrectPassword"
                                dense
                                outlined
                                type="error"
                            >
                            The password you entered is incorrect
                            </v-alert>
                        </v-col>
                    </v-row>
                    </div>
                    <div v-if="canSubmit">
                        <v-text-field
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    v-model="password1"
                    :rules="[rules.pwd]"
                    label="Change Password"
                    ></v-text-field>
                    <v-text-field
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    @click:append="show3 = !show3"
                    v-model="password2"
                    :rules="[matchingPasswords]"
                    label="Confirm Password"
                    ></v-text-field>
                        <v-divider></v-divider>
                        <v-btn :disabled="formValid==false" color="lime" text @click="submit()">Submit Changes</v-btn>
                        <v-alert
                                v-if="couldNotChange"
                                dense
                                outlined
                                type="error"
                            >
                            The profile could not be updated
                            </v-alert>
                            <v-alert
                                v-if="success"
                                dense
                                outlined
                                type="success"
                            >
                            The profile was updated successfully
                            </v-alert>
                    </div>
                    

                </v-form>
            </v-card-text>
        </v-card>
        </div>
        
    </v-container>
</template>

<script>
export default {
  name: "Profile",
  props: {},
  mounted() {
    this.load()
  },
  data() {
    return {
        profile: [],
        success: false,
        password: "",
        password1: "",
        password2: "",
        show1: false,
        show2: false,
        show3: false,
        loaded: false,
        canSubmit: false,
        formValid: false,
        couldNotChange: false,
        incorrectPassword: false,
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length >= 8 || 'Min 8 characters',
          pwd: value=> {
            if (value.length==0 || value.length>=8) {
                return true
            }
            else{
                return 'Min 8 characters'
            }
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },

    };
  },
  methods: {
    submit(){
        this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/profile/"+this.profile.user.id,
        params: {
            name: this.profile.user.name,
            email: this.profile.user.email,
            password: this.password1,
            password_confirmation: this.password2,
        },
        responseType: "json",
      })
        .then((response) => {
            this.success=true
            console.log(response.data)
        })
        .catch((error) => {
          //console.log(error);
          this.couldNotChange = true
        });
    },
    passCheck(){
        this.incorrectPassword=false
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/profile",
        params: {
            password: this.password
        },
        responseType: "json",
      })
        .then((response) => {
            //console.log(response.data)
            this.canSubmit=true
        })
        .catch((error) => {
            this.incorrectPassword=true
          //console.log(error);
        });
    },
    load(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/profile",
        params: {

        },
        responseType: "json",
      })
        .then((response) => {
            this.profile=response.data
            this.loaded=true
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    matchingPasswords: function() {
            if (this.password1 === this.password2) {
                return true;
            } else {
            return 'Passwords do not match.';
            }
            },
  },
};
</script>
