<template>
  <div>
    <v-dialog v-model="dialog" width="90%" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item link v-if="side">
          <v-list-item-title @click="dialog = !dialog"
            >Revision Lists</v-list-item-title
          >
        </v-list-item>
        <v-btn v-else color="lime" text @click="dialog = !dialog">
          <v-icon>mdi-cloud-upload-outline</v-icon>
          Revision List
        </v-btn>
      </template>
      <v-card v-if="selectTest">
        <ampil-card
          title="Revision Lists: Select assessment..."
        ></ampil-card>
        <v-card-text>
          <v-autocomplete
            v-model="testManual"
            :items="tests"
            item-text="TestName"
            item-value="TestID"
            dense
            filled
            label="Select an assessment"
          ></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="lime" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        
        <ampil-card
          title="Revision Lists:"
          :items="menu"
          @tab="changeTab"
        ></ampil-card>
        <v-card-text v-if="tab == 1">
          <revision-create :test="testPicked"></revision-create>
        </v-card-text>
        <v-card-text v-else>
          <export-revision
            :selectedTest="testPicked"
            :buttonText="'Download Revision List'"
            :testName="testName"
            :view="true"
          ></export-revision>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="lime" text @click="selectTest = true" v-if="testManual!=null">Select a different test</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="lime" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RevisionCreate from "@/components/teacher/RevisionCreate.vue";
import ExportRevision from "@/components/student/ExportRevision.vue";
export default {
  name: "RevisionLists",
  components: {
    RevisionCreate,
    ExportRevision,
  },
  props: {
    side: Boolean,
    test: Number,
    testName: String,
  },
  mounted() {
    this.getTest()
  },
  data() {
    return {
      dialog: false,
      selectTest: true,
      menu: [
        { id: 1, name: "Create Revision List" },
        { id: 2, name: "View/Download" },
      ],
      tab: 1,
      tests: [],
      testManual: null
    };
  },
  methods: {
    changeTab(i) {
      this.tab = i;
    },
    getTest() {
      if (this.test>-1){
        this.selectTest = false
      }
      else{
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/5",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
            this.tests=response.data
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
  },
  computed: {
    testPicked(){
      if (this.test>-1){
        return this.test
      }
      else {
        return this.testManual
      }
    }
  },
  watch: {
    testPicked: function () {
      if (this.testPicked>-1){
        this.selectTest = false
      }
    },
  },
};
</script>
