<template>
    <v-container>
        <v-row>
            <v-col class="text-center">
                <v-dialog v-model="dialog">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="lime" dark v-bind="attrs" v-on="on">Submit all totals by CSV</v-btn>
                    </template>
                    <v-card>
                        <ampil-card title="CSV upload of student totals"></ampil-card>
                        <v-card-text>
                            <upload-totals-csv :setMappings="setArray()"></upload-totals-csv>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="lime" text @click="close2()">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                 </v-dialog>
            </v-col>
        </v-row>
        <v-row v-for="(task, index) in taskTotals">
            <v-col>
                {{ task.SetName }} - {{ task.TestName }} ({{ task.Totals }}/{{ task.AllStudents }}) Totals
            </v-col>
            <v-col>
                <v-dialog v-model="dialog2[index]">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="lime" text v-bind="attrs" v-on="on">Enter totals</v-btn>
                    </template>
                    <v-card>
                        <ampil-card :title="task.TestName"></ampil-card>
                        <v-card-text>
                            <upload-totals-manual :givenSet="task.SetID" :test="task.TestID" :total="task.MaxMark" :testName="task.TestName"></upload-totals-manual>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="lime" text @click="close(index)">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                 </v-dialog>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import UploadTotalsManual from "@/components/teacher/UploadTotalsManual.vue";
import UploadTotalsCsv from "@/components/teacher/UploadTotalsCsv.vue";
export default {
  name: "FeedbackTotals",
  props: {
    taskID: Number
  },
  components: {
    UploadTotalsManual,
    UploadTotalsCsv,
  },
  mounted() {
    this.getData()
    
  },
  data() {
    return {
        taskTotals: [],
        dialog2: [],
        dialog: false,
    };
  },
  methods: {
    setArray(){
        let retArray = []
        for (let i=0;i<this.taskTotals.length;i++){
            retArray[this.taskTotals[i].SetID] = [this.taskTotals[i].TestID]
        }
        return retArray
    },
    close(i, a){
        this.$set(this.dialog2, i, false)
        this.$emit('changed')
    },
    close2(){
        this.dialog=false
        this.$emit('changed')
    },
    getData(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/10",
        params: {
          TaskID: this.taskID,
        },
        responseType: "json",
      })
        .then((response) => {
            let submitted = []
            this.taskTotals = response.data.Counts
            for (let i=0;i<this.taskTotals.length;i++){
                //set status flags
                if (this.taskTotals[i].Status<2){
                    if (this.taskTotals[i].Totals>0){
                        submitted.push({keyval: {FeedbackMapID: this.taskTotals[i].FeedbackMapID || null}, values: {Status: 1}})
                    }
                    else{
                        submitted.push({keyval: {FeedbackMapID: this.taskTotals[i].FeedbackMapID || null}, values: {Status: 0}})
                    }
                }
                
                this.dialog2[i]=false
                let match = response.data.Totals.find(obj => obj.SetID == this.taskTotals[i].SetID && obj.TestID == this.taskTotals[i].TestID)
                if (match!=undefined){
                    this.taskTotals[i].MaxMark = parseInt(match.Total)
                }  
            }
            this.setStatus(submitted)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setStatus(s){
        this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/leader",
          data: {
            table: "FeedbackMaps",
            data: s
          },
          responseType: "json",
        })
          .then((response) => {
            this.$emit('changed')
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  computed: {},
};
</script>
