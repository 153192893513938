<template>
    <v-container>
        <v-card max-width="400" class="mx-auto my-12">
      <ampil-card title="Finalise registration..."></ampil-card>
      <v-form v-model="valid">
        <v-card-text>
          <v-text-field v-model="email" placeholder="Email" :rules="rulesEmail"></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            placeholder="Password"
            :rules="[rules.required, rules.min]"
          ></v-text-field>
          <v-text-field v-model="school" placeholder="School name" :rules="[rules.required, rules.min]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="valid==false" color="lime" text @click="submit()">Finish registration</v-btn>
        </v-card-actions>
    </v-form>
    </v-card>
    <v-snackbar
      v-model="snackbar"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="lime"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    </v-container>
</template>

<script>
export default {
  name: "Verify",
  props: {},
  mounted() {},
  data() {
    return {
        email: "",
        password: "",
        valid: false,
        school: "",
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          check: v=> v==true || "You must agree to the T&C's"
        },
        rulesEmail: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      ],
      message: "",
      snackbar: false,

    };
  },
  methods: {
    submit(){
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/new-db",
        data: {
            school: this.school,
            email: this.email,
            password: this.password,
        },
        //responseType: "json",
      })
        .then((response) => {
          
            this.snackbar=true
            this.message = "Success: You can now log into amPIL"
            this.$router.push('/login')
        })
        .catch((error) => {
            this.snackbar=true
            this.message = "This operation failed. Please check your input and try again"
        });
    },
  },
  computed: {},
};
</script>
