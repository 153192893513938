<template>
    <v-container>
        <v-dialog v-model="dialog" width="90%" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="lime" text @click="dialog = !dialog">
          Student Submission Tracker
        </v-btn>
      </template>

      <v-card>
        <ampil-card
          title="Submission Tracker:"
        ></ampil-card>
        <v-card-text>
            <v-card-title>Student completion tracker:</v-card-title>

            <v-row>Key:</v-row>
            <v-row>
                
                <v-col>
                    <v-progress-linear
                    color="deep-orange"
                    height="20"
                    value="20"
                    striped
                    rounded
                >
                <template v-slot:default="{ value }">
                    <strong>Incomplete</strong>
                </template>
            </v-progress-linear>
                </v-col>
                <v-col>
                    <v-progress-linear
                    color="light-green"
                    height="20"
                    value="20"
                    striped
                    rounded
                >
                <template v-slot:default="{ value }">
                    <strong>Submitted by student</strong>
                </template>
            </v-progress-linear>
                </v-col>
                <v-col>
                    <v-progress-linear
                    color="light-blue"
                    height="20"
                    value="20"
                    striped
                    rounded
                >
                <template v-slot:default="{ value }">
                    <strong>Submitted by teacher</strong>
                </template>
            </v-progress-linear>
                </v-col>
                
            </v-row>
            <v-row>
                <v-divider></v-divider>
            </v-row>
          <v-row v-for="student in data">
            <v-col>
                <v-progress-linear
                    :color="getColor(student)"
                    height="20"
                    :value="getPerc(student)"
                    striped
                    rounded
                >
                <template v-slot:default="{ value }">
                    <strong>{{ student.LastFirst }}</strong> <v-btn v-if="totalsDontMatch(student)" @click="submit(student)" text color="red">Teacher total: {{ student.Total }} Student total: {{ student.STotal }}. Accept student submission?</v-btn>
                </template>
            </v-progress-linear>
            </v-col>
            
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="lime" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
  </template>
  
  <script>

  export default {
    name: "Testing",
    components: {
      
    },
    props: {
        setID: Number,
        testID: Number,
    },
    data: () => ({
        dialog: false,
        //setID: 56,
        //testID: 7,
        data: [],
        loaded: false
    }),
    watch: {

    },
    mounted() {
        this.getData();
    },
    destroyed() {
        this.cancelAutoUpdate();
    },
    methods: {
        submit(s){
            this.$http({
            method: "post",
            url: process.env.VUE_APP_BACKEND + "/teacher",
            data: {
                table: "TestTotals",
                data: [{keyval: {TestTotalsID: s.TestTotalsID || null}, values: {Total: s.STotal}}],
            },
            responseType: "json",
            })
            .then((response) => {
                this.getData()
            })
            .catch((error) => {
                console.log(error);
            });
        },
        totalsDontMatch(s){
            if (s.STotal!=null && s.Total!=null && s.Status != (s.QCount*2)){
                if (s.Total!=s.STotal){
                    return true
                }
            }
            return false
        },
        cancelAutoUpdate () {
            clearInterval(this.timer);
        },
        refresh(){
            this.getData()
        },
        getColor(s){
            if (s.Cnt<s.QCount){
                return "deep-orange"
            }
            if (s.Status == (s.QCount*2)){
                return "light-blue"
            }
            if (s.Cnt == s.QCount){
                return "light-green"
            }
        },
        getPerc(s){
            let retval = (s.Cnt/s.QCount)*100
            return retval
        },
        getData(){
            this.$http({
            method: "get",
            url: process.env.VUE_APP_BACKEND + "/teacher/21",
            params: {
                TestID: this.testID,
                SetID: this.setID
            },
            responseType: "json",
            })
            .then((response) => {
                this.data = response.data;
                this.loaded = true
            })
            .catch((error) => {
                console.log(error);
            });
        },
    },
    computed: {

    },
    watch: {
        dialog() {
        if (this.dialog==true) {
            this.timer = setInterval(this.refresh, 10000);
        }
        else{
            this.cancelAutoUpdate();
        }
      },
    },
  }
  </script>