<template>
  <v-container>
    <p>Download existing data to CSV for import into other services</p>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="menu1sel"
          color="lime"
          :items="menu1"
          item-text="text"
          item-value="value"
          label="Data for download..."
          outlined
          @change="menu1func(menu1sel)"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-if="menu1sel"
          v-model="menu2sel"
          color="lime"
          :items="menu2"
          item-text="text"
          item-value="value"
          :label="menu2label"
          outlined
          @change="getData(menu1sel, menu2sel)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="menu1sel && menu2sel">
      <v-btn color="lime" @click="view()"> View Data </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="lime" @click="download()"> Download </v-btn>
    </v-row>
    <v-dialog v-model="showData">
      <v-card>
        <v-card-text>
          <v-data-table :headers="headers" :items="dlData" :items-per-page="15">
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="lime" @click="showData = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "AdminExport",
  components: {},
  data: () => ({
    menu1: [
      { text: "Scheme of Work", value: 1 },
      { text: "Assessment Topics", value: 2 },
      { text: "Student Details", value: 3 },
      { text: "Assessment Results", value: 4 },
    ],
    menu1sel: null,
    menu2sel: null,
    menu2: [],
    menu2label: null,
    dlData: [],
    headers: [],
    showData: false,
  }),
  mounted() {
    this.setScrollBar();
  },
  props: {},
  methods: {
    setScrollBar() {
      this.$store.commit("setScrollBar", {
        slug: "admin_export",
        data: [{ ID: 1, Name: "Export Data" }],
      });
    },
    menu1func(sel) {
      if (sel == 1) {
        this.menu2label = "Select Scheme of Work...";
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/admin/2",
          params: {
            SubjectId: this.$store.getters.getSubject,
          },
          responseType: "json",
        })
          .then((response) => {
            //console.log(response.data)
            this.menu2 = [];
            for (let i = 0; i < response.data.length; i++) {
              this.menu2.push({
                text: response.data[i].SoWName,
                value: response.data[i].SoWId,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (sel == 2) {
        this.menu2label = "Select Assessment...";
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/admin/5",
          params: {
            SubjectID: this.$store.getters.getSubject,
          },
          responseType: "json",
        })
          .then((response) => {
            //console.log(response.data)
            this.menu2 = [];
            for (let i = 0; i < response.data.length; i++) {
              this.menu2.push({
                text: response.data[i].TestName,
                value: response.data[i].TestID,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (sel == 3) {
        this.menu2label = "Select Yeargroup...";
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/admin/8",
          params: {
            SubjectId: this.$store.getters.getSubject,
          },
          responseType: "json",
        })
          .then((response) => {
            //console.log(response.data)
            this.menu2 = [];
            for (let i = 0; i < response.data.length; i++) {
              this.menu2.push({
                text: response.data[i].Yeargroup,
                value: response.data[i].YeargroupID,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (sel == 4) {
        this.menu2label = "Select Assessment...";
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/admin/5",
          params: {
            SubjectID: this.$store.getters.getSubject,
          },
          responseType: "json",
        })
          .then((response) => {
            //console.log(response.data)
            this.menu2 = [];
            for (let i = 0; i < response.data.length; i++) {
              this.menu2.push({
                text: response.data[i].TestName,
                value: response.data[i].TestID,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    view() {
      this.getData(this.menu1sel, this.menu2sel);
      this.showData = true;
    },
    download() {
      //this.getData(this.menu1sel, this.menu2sel)

      let fields = [];
      let heads = [];
      let values = [];
      for (let i = 0; i < this.headers.length; i++) {
        fields.push(this.headers[i].text);
        heads.push(this.headers[i].value);
      }
      for (let i = 0; i < this.dlData.length; i++) {
        let valtemp = [];
        for (let j = 0; j < heads.length; j++) {
          valtemp.push(this.dlData[i][heads[j]]);
        }
        values.push(valtemp);
      }
      let config = { download: true };
      //let self = this;
      let data = {
        fields: fields,
        data: values,
      };
      var csv = this.$papa.unparse(data, config);

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "data_download.csv";
      anchor.click();
    },
    getData(menu1, menu2) {
      if (menu1 == 1) {
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/admin/3",
          params: {
            SoWID: menu2,
          },
          responseType: "json",
        })
          .then((response) => {
            this.dlData = response.data;
            this.headers = [{ text: "Topic", value: "SoWTopic" }];
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (menu1 == 2) {
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/admin/6",
          params: {
            TestID: menu2,
          },
          responseType: "json",
        })
          .then((response) => {
            this.dlData = response.data;
            this.headers = [
              { text: "Question", value: "QuestionNumber" },
              { text: "Topic", value: "TestTopic" },
              { text: "Marks", value: "MarksAvailable" },
            ];
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (menu1 == 3) {
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/admin/22",
          params: {
            YeargroupID: menu2,
          },
          responseType: "json",
        })
          .then((response) => {
            this.dlData = response.data;
            this.headers = [
              { text: "Student ID", value: "StudentID" },
              { text: "Name", value: "LastFirst" },
              { text: "Date of Birth", value: "DoB" },
              { text: "Set", value: "SetName" },
            ];
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (menu1 == 4) {
        this.$http({
          method: "get",
          url: process.env.VUE_APP_BACKEND + "/admin/23",
          params: {
            TestID: menu2,
          },
          responseType: "json",
        })
          .then((response) => {
            this.dlData = response.data;
            this.headers = [
              { text: "Student ID", value: "StudentID" },
              { text: "Name", value: "LastFirst" },
              { text: "Assessment", value: "TestName" },
              { text: "Question", value: "QuestionNumber" },
              { text: "Topic", value: "TestTopic" },
              { text: "Marks", value: "MarkAwarded" },
            ];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
