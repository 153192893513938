<template>
  <div class="text-center">
    <v-card max-width="400" class="mx-auto my-12">
      <ampil-card title="Log In"></ampil-card>
      <v-form @submit.prevent="login">
        <v-card-text>
          <v-text-field v-model="email" placeholder="Email"></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            placeholder="Password"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="lime" text @click="$router.push('register')">Sign Up</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="lime" text @click="$router.push('forgot-password')">Forgot Password</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="lime" text type="submit">Login</v-btn>
        </v-card-actions>
    </v-form>
    </v-card>
    <v-snackbar
      v-model="failed"
    >
      Login failed

    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "LogIn",
  data() {
    return {
      email: "",
      password: "",
      test: process.env.VUE_APP_NAME,
      failed: false,
    };
  },
  mounted: async function () {},
  methods: {
    async login() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_BACKEND + "/oauth/token",
          {
            grant_type: "password",
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            username: this.email,
            password: this.password,
            scope: "*",
          }
        );
        localStorage.setItem("access_token", response.data.access_token);
        this.$emit("authenticated");
        this.$store.dispatch("login");
        this.$router.push("/");
        //console.log("Logged In");
        //console.log(response.data);
      } catch (error) {
        console.error("Authentication failed:", error);
        this.failed=true
      }
    },
    async AuthProvider(provider) {
      localStorage.setItem("access_token", "OAUTH");
      let win = window.open(
        process.env.VUE_APP_BACKEND + "/sociallogin/" + provider,
        "auth-popup",
        "width=500,height=500"
      );
      await win.addEventListener("message", (event) => {
        if (event.data == "success") {
          this.$store.dispatch("login");
          this.$router.push("/");
        }
      });
      win.focus();
      await this.$router.push("/");
    },
    async cookies() {
      const username = await this.$cookies.get("apikey");
      console.log(username);
    },
  },
};
</script>
