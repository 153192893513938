export default {
    methods: {
      markbookSort(s=[], t=[], d=[], r=[], p=[]) {
        let retval = {}
        retval.data = []
        retval.tests = []
        let sd = {}
        let stu
        
        //s students, t tests, d data, r ranking, p prior
        //cycle through all students
        for (let i=0;i<s.length;i++){
            sd = {UniqueID: s[i].UniqueID, StudentID: s[i].StudentID, LastFirst: s[i].LastFirst, SetID: s[i].SetID, SetName: s[i].SetName}
            //cycle through tests
            if (t.length>0){
                for (let j=0;j<t.length;j++){
                    stu = d.find(a=> a.TestID == t[j] && a.UniqueID == s[i].UniqueID)
                    if (stu!=undefined && stu.Total!=undefined){
                        sd[stu.TestID] = parseInt(stu.Total)
                    }
                }
            }
            //cycle through ranking
            stu = r.find(a=> a.UniqueID == s[i].UniqueID)
            if (stu!=undefined){
                sd.Score = parseInt(stu.Score)
                //sd.Score = stu.Score
                sd.Ranking = stu.Ranking
                sd.Grade = stu.Grade
                sd.RankID = stu.RankID
                sd.Tflag = stu.Tflag
                sd.Comment = stu.Comment
                sd.YearID = stu.YearID
            }
            //cycle through PAD
            stu = p.find(a=> a.UniqueID == s[i].UniqueID)
            if (stu!=undefined){
                sd.Prior = stu.EndScore
                
            }
            

            retval.data.push(sd)
        }
        let tests = []
        for (let j=0;j<t.length;j++){
            let test = d.find(a=> a.TestID == t[j])
            if (test!=undefined){
                tests.push({TestID: test.TestID, TestName: test.TestName})
            }
            
        }
            
            retval.tests = tests
            
        return retval
    }
    }
  };