<template>
    <div>
        <v-card>
            <v-card-text>
                <vue-tabulator ref="tabulator" v-if="ready && set!=null" v-model="dataSorted" :options="options" :integration="{ updateStrategy: 'UPDATE' }" />
            </v-card-text>
            <v-card-actions>
                <v-alert
            v-if="submitted"
            dense
            text
            type="success"
        >
        Data uploaded successfully!
        </v-alert>
                <v-spacer></v-spacer>
                <v-btn v-if="submitted==false" color="lime" dark @click="submit()">Submit</v-btn>
            </v-card-actions>
        </v-card>
        
        
    </div>
</template>

<script>
import "tabulator-tables/dist/css/tabulator.min.css";
export default {
  name: "EnterQlaManual",
  props: {
    test: Number,
    set: Number,
  },
  mounted(){
    this.getData()
  },
  data() {
    return {
        ready: false,
        submitted: false,
        data: [],
        classes: [],
        clearColumns: [
                {
                    title: 'Topic',
                    field: 'TestTopic',
                    width: 200,
                    editable: false,
                    headerSort:false,
                    frozen: true,

                    formatter: (cell)=>{
                        var value = cell.getValue();
                        if (value!=undefined){
                            return "<span style='color:#50900c; font-weight:bold;'>"+value+"</span>"
                        }
                        else{
                            return ""
                        }
                        
                    }


                },
                {
                    title: 'Qn',
                    field: 'QuestionNumber',
                    editable: false,
                    headerSort:false,
                    frozen: true,
                    formatter: (cell)=>{
                        var value = cell.getValue();
                        if (value!=undefined){
                            return "<span style='font-weight:bold;'>Q"+value+".</span>"
                        }
                        else{
                            return ""
                        }
                        
                    }

                },
             ],
        dataSorted: [],
            options: {
                keybindings:{
                    "navLeft" : "37",
                    "navRight" : "39",
                },
                columns: [
                {
                    title: 'Topic',
                    field: 'TestTopic',
                    width: 200,
                    editable: false,
                    headerSort:false,
                    frozen: true,

                    formatter: (cell)=>{
                        var value = cell.getValue();
                        if (value!=undefined){
                            return "<span style='color:#50900c; font-weight:bold;'>"+value+"</span>"
                        }
                        else{
                            return ""
                        }
                        
                    }


                },
                {
                    title: 'Qn',
                    field: 'QuestionNumber',
                    editable: false,
                    headerSort:false,
                    frozen: true,
                    formatter: (cell)=>{
                        var value = cell.getValue();
                        if (value!=undefined){
                            return "<span style='font-weight:bold;'>Q"+value+".</span>"
                        }
                        else{
                            return ""
                        }
                        
                    }

                },
             ],
            },
    };
  },
  methods: {
    submit(){
        //console.log(this.data)
        let submitArray = []
        let k = ["QuestionNumber", "MarksAvailable", "TestTopic", "MaxMark", "QuestionID"]
        let studentIDs = Object.keys(this.dataSorted[0])
        studentIDs = studentIDs.filter(item => !k.includes(item))
        for (let i=0;i<this.dataSorted.length;i++){
            for (let j=0;j<studentIDs.length;j++){
                //console.log(this.dataSorted[i][studentIDs[j]])
                //find resultID
                let foundExisting = this.data.find(obj => obj.UniqueID == studentIDs[j] && obj.QuestionID == this.dataSorted[i].QuestionID)
                if (this.dataSorted[i][studentIDs[j]]!=null && this.dataSorted[i][studentIDs[j]]!=undefined && this.dataSorted[i][studentIDs[j]]!=""){
                    submitArray.push({keyval: {ResultID: foundExisting.ResultID || null}, values: {StudentID: foundExisting.UniqueID, QuestionID: this.dataSorted[i].QuestionID, MarkAwarded: this.dataSorted[i][studentIDs[j]], EnteredStatus: 1}})
                }
                
            }
            //console.log(Object.keys(this.dataSorted[i]))
        }
        this.$http({
          method: "post",
          url: process.env.VUE_APP_BACKEND + "/teacher",
          data: {
            table: "QuestionResults",
            data: submitArray
          },
          responseType: "json",
        })
          .then((response) => {
            this.submitted=true

            this.getData()
          })
          .catch((error) => {
            console.log(error);
          });
        
    },
    getData(){
        //this.ready=false
        this.dataSorted = []
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/12",
        params: {
          TestID: this.test,
          SetID: this.set,
        },
        responseType: "json",
      })
        .then((response) => {
          this.data=response.data
          //console.log(this.data)
          this.sortData()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sortData(){
        var noDivide = function(cell, value){
            let max = cell._cell.row.data.MaxMark
            if (value<0){
                return false
            }
            if (value>max){
                return false
            }
            else{
                return true
            }
        }
        var format =  (cell)=>{
                        var value = cell.getValue();
                        if (value!=undefined){
                            return "<span style='color:red; font-weight:bold;'>"+value+"</span>"
                        }
                        else{
                            return ""
                        }
                        
                    }
        this.dataSorted = []
        this.options.columns = this.clearColumns
        let arr = {}
        let firstRun=true
        arr[this.data[0].UniqueID] = this.data[0].MarkAwarded
        arr.QuestionNumber = this.data[0].QuestionNumber
        arr.MarksAvailable = this.data[0].MarksAvailable
        arr.TestTopic = this.data[0].TestTopic
        arr.MaxMark = this.data[0].MarksAvailable
        arr.QuestionID = this.data[0].QuestionID
        this.options.columns.push({title: this.data[0].LastFirst, field: this.data[0].UniqueID.toString(), editor: 'number', headerVertical: true, validator:["integer", {type:noDivide}], bottomCalc:"sum", headerSort:false, editorParams: {verticalNavigation: "table"}})
        for (let i=1;i<this.data.length;i++){
            //if question id is the same as the one before or 0
            if (this.data[i].QuestionID==this.data[i-1].QuestionID){
                arr[this.data[i].UniqueID.toString()] = this.data[i].MarkAwarded
                if (firstRun==true){
                    this.options.columns.push({title: this.data[i].LastFirst, field: this.data[i].UniqueID.toString(), headerVertical: true, editor: 'number', validator:["integer", {type:noDivide}], bottomCalc:"sum", headerSort:false, editorParams: {verticalNavigation: "table"}})
                }
            }
            else {
                if (firstRun==true){
                    this.options.columns.push({title: "Max Mark", field: "MaxMark", headerVertical: true, editable: false, bottomCalc:"sum", headerSort:false, frozen: true, formatter: format, editorParams: {verticalNavigation: "table"}})
                }
                this.dataSorted.push(arr)
                arr = {}
                arr.QuestionNumber = this.data[i].QuestionNumber
                arr.MarksAvailable = this.data[i].MarksAvailable
                arr.TestTopic = this.data[i].TestTopic
                arr.MaxMark = this.data[i].MarksAvailable
                arr.QuestionID = this.data[i].QuestionID
                firstRun=false
                arr[this.data[i].UniqueID.toString()] = this.data[i].MarkAwarded
            }
        }
        //arr.QuestionNumber = this.data[(this.data.length-1)].QuestionNumber
        //arr.MarksAvailable = this.data[(this.data.length-1)].MarksAvailable
        //arr.TestTopic = this.data[(this.data.length-1)].TestTopic
        this.dataSorted.push(arr)
        if (this.submitted==true){
            //const tabulatorInstance = this.$refs.tabulator.getInstance();
            //console.log(tabulatorInstance)
            //tabulatorInstance.redraw(true)
            //tabulatorInstance.setColumns(this.options.columns);
            //tabulatorInstance.setData(this.dataSorted);
            
            //this.submitted=false
        }

        this.ready=true
    }
  },
  computed: {

  },
  watch: {
    set: function () {
        if (this.$refs.tabulator!=undefined){
            this.submitted=true
        }
      this.getData();
    },
  },
};
</script>
<style lang='scss'>

//@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

</style>
