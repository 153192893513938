<template>
    <v-container v-if="ready">
        <v-dialog
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="textColor"
          text
          lime
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-cloud-upload-outline</v-icon>
          Topic Mappings
        </v-btn>
      </template>

      <v-card>
        <ampil-card
          title="Topic Mappings"
        ></ampil-card>
        <v-card-text>
            <mapping-assessment :testID="testID"></mapping-assessment>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="lime"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
</template>

<script>
import MappingAssessment from "@/components/admin/MappingAssessment";
export default {
  name: "Testing",
  props: {
    testID: Number,
  },
  components: {
    MappingAssessment,
  },
  mounted() {
    this.checkMaps()
  },
  data() {
    return {
        //testID: 1,
        dialog: false,
        mappings: [],
        ready: false
    };
  },
  methods: {
    checkMaps(){
        this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/22",
        params: {
          TestID: this.testID,
        },
        responseType: "json",
      })
        .then((response) => {
          this.mappings = response.data;
          this.ready=true
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  computed: {
    textColor(){
        if (this.mappings.length>0){
            return "lime"
        }
        else{
            return "red"
        }
    }
  },
};
</script>
