<template>
  <div v-if="$store.getters.isAuthenticated==true">
    <hello-world />
  </div>
  <div v-else>
    <splash></splash>
  </div>

</template>

<script>
import HelloWorld from "../components/HelloWorld";
import Splash from "../components/Splash";

export default {
  name: "HomeView",
  data() {
    return {
      studentSelect: [],
      teacherSelect: [],
    }
  },
  components: {
    HelloWorld,
    Splash
  },
  mounted() {
    this.load()
  },
  methods: {
    load(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/user-load",
        params: {

        },
        responseType: "json",
      })
        .then((response) => {
          if (this.$store.getters.getScope=="student"){
            this.studentSelect = response.data
            this.$store.commit('setStudentSubjects', this.studentSelect)
          }
          else{
            this.teacherSelect = response.data
          }
          this.load2()
        })
        .catch((error) => {
          //console.log(error);
          this.teacherSelect.teacher=1;
          this.load2()
        });
    },
    load2(){
      if (this.scope=="admin"){
        if (this.teacherSelect.teacher){
          this.$store.commit('setTeacherID', {TeacherID: this.teacherSelect.teacher})
          this.$router.push('admin')
        }
        else{
          this.$router.push('admin')
        }
        
      }
      else if (this.scope=="leader"){
        if (this.teacherSelect.teacher){
          this.$store.commit('setTeacherID', {TeacherID: this.teacherSelect.teacher})
          this.$router.push('leader')
        }
        else{
          this.$router.push('leader')
        }
        
      }
      else if (this.scope=="teacher"){
        if (this.teacherSelect.teacher){
          this.$store.commit('setTeacherID', {TeacherID: this.teacherSelect.teacher})
          this.$router.push('teacher')
        }
        
      }
      else if (this.scope=="student"){
        let student = this.studentSelect[0].student
        this.$store.commit('setStudentID', student)
        this.$router.push('student')
      }
      //this.$router.push('leader')
    }
  },
  computed: {
    scope(){
      return this.$store.getters.getScope
    }
  }
};
</script>
