<template>
    <v-container>
        <v-alert
      v-if="failed"
      dense
      outlined
      dismissable
      type="error"
    >
      This operation could not be completed successfully. Please try again.
    </v-alert>
        <div class="text-center">
        <v-card v-if="token!=null" max-width="50%" class="mx-auto my-12">
            <ampil-card title="Set a new password"></ampil-card>
            <v-card-text>
                <v-form v-model="valid">
                    <v-row>
                        <v-text-field
                            :rules="rulesEmail"
                            name="input-10-1"
                            label="Enter Email"
                            v-model="email"
                            class="input-group--focused"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-2"
                            label="Enter Password"
                            hint="At least 8 characters"
                            v-model="password"
                            class="input-group--focused"
                            @click:append="show1 = !show1"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[matchingPasswords]"
                            :type="show2 ? 'text' : 'password'"
                            name="input-10-3"
                            label="Confirm Password"
                            v-model="passwordConfirm"
                            class="input-group--focused"
                            @click:append="show2 = !show2"
                        ></v-text-field>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="lime" :disabled="valid==false" @click="submit()">Submit</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else max-width="50%" class="mx-auto my-12">
            <ampil-card title="Unauthorised"></ampil-card>
            <v-card-text>
                You cannot view this page
            </v-card-text>
        </v-card>
    </div>
    </v-container>
</template>

<script>
export default {
  name: "ChangePassword",
  props: {},
  mounted() {
    this.token = this.$route.query.token
  },
  data() {
    return {
        failed: false,
        token: null,
        show1: false,
        show2: false,
        valid: false,
        email: "",
        password: "",
        passwordConfirm: "",
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
        },
        rulesEmail: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      ],
    };
  },
  methods: {
    submit(){
        
        this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/reset-password",
        params: {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirm,
          token: this.token
        },
        responseType: "json",
      })
        .then((response) => {
            this.failed=false
            this.$router.push('../login')
        })
        .catch((error) => {
            this.failed=true
          console.log(error);
        });
    }
  },
  computed: {
    matchingPasswords: function() {
            if (this.password === this.passwordConfirm) {
                return true;
            } else {
            return 'Passwords do not match.';
            }
            },
  },
};
</script>
