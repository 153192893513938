<template>
  <div>
    <v-row class="justify-end">
      <v-col cols="4">
        <v-text-field
          clearable
          label="Search..."
          prepend-inner-icon="mdi-magnify"
          v-model="searchString"
          @input="search()"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Search",
  props: {
    input: Array,
    searchfield: String,
  },
  mounted() {},
  methods: {
    search() {
      if (this.searchString) {
        const result = this.input.filter(
          (word) =>
            word[this.searchfield]
              .toLowerCase()
              .indexOf(this.searchString.toLowerCase()) != -1
        );
        this.$emit("search", result);
      } else {
        this.$emit("search", this.input);
      }
    },
  },
  data() {
    return {
      searchString: "",
    };
  },
};
</script>
