<template>
  <v-container>
    <v-col md="5">
      <v-card class="mx-auto my-2" width="374" elevation="1">
        <template v-if="this.newYear == false">
          <v-card-actions class="justify-center">
            <v-btn
              class="mx-auto my-auto"
              fab
              dark
              x-large
              color="lime"
              @click="newYear = true"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-title class="justify-center">Add Yeargroup</v-card-title>
        </template>
        <template v-if="this.newYear">
          <v-form v-model="isFormValid">
            <v-text-field
              :rules="numberRules"
              label="New Yeargroup"
              v-model="newYearName"
              @keyup="duplicateYears2(newYearName)"
            ></v-text-field>
          </v-form>
          <v-card-actions>
            <v-btn color="lime" dark @click="newYear = false"> Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="lime"
              dark
              :disabled="!isFormValid || dupYear == true"
              @click="addYear(newYearName)"
            >
              Save</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-col>
    <v-expansion-panels v-model="expanded" mandatory @change="getID(expanded)">
      <v-expansion-panel
        v-for="Yeargroup in Yeargroups2"
        :key="Yeargroup.YeargroupID"
      >
        <v-expansion-panel-header>
          <template v-if="Yeargroup.YeargroupID != rename">
            <v-card-title>Year {{ Yeargroup.Yeargroup }}</v-card-title>
          </template>

          <template v-if="Yeargroup.YeargroupID == rename">
            Year:
            <v-form v-model="isFormValid">
              <v-text-field
                ref="focushere"
                v-model="Yeargroup.Yeargroup"
                @keyup="duplicateYears(Yeargroup.Yeargroup)"
                :rules="numberRules"
              >
              </v-text-field>
            </v-form>
            <v-spacer></v-spacer>

            <v-btn
              color="lime"
              dark
              :disabled="!isFormValid || dupYear == true"
              @click="RenameSave(Yeargroup.Yeargroup, Yeargroup.YeargroupID)"
              >Save
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Note: If taught in sets, please list these in order with the highest set at the top in each band Eg X1, X2, X3, X4, Y1, Y2, Y3, Y4. Please make sure these set names match your upload file EXACTLY
          <div v-for="(Set, index) in Sets" :key="index">
            <v-form v-model="isFormValid">
              <v-container>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      :rules="rules"
                      v-model="Set.SetName"
                      label="Group:"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-icon
                      slot="append"
                      color="red"
                      @click="removeSet(Set.SetId, expanded)"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      :items="SoW"
                      item-text="SoWName"
                      item-value="SoWId"
                      v-model="Set.SoWID"
                      label="Following SoW..."
                      :rules="numberRules"
                      dense
                    ></v-select
                    >{{ SoWIndex }}
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                      v-if="Assessloaded"
                      v-model="Set.MappedAssessments"
                      :items="Assess"
                      item-text="TestName"
                      item-value="TestID"
                      outlined
                      dense
                      chips
                      small-chips
                      label="Assessments"
                      multiple
                      deletable-chips
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
          <v-row>
            <v-col cols="5">
              <v-text-field
                :key="Sets.length"
                v-model="NewSet"
                label="Add new group:"
                @keyup="keyset()"
                @change="newRow()"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <div v-if="showmsg">
                Press enter, or click outside the box when done
              </div>
              <v-alert v-if="showalert" dense outlined type="error">
                Set name needs to be unique
              </v-alert>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              v-if="Yeargroup.YeargroupID != rename"
              color="lime"
              dark
              @click="Rename(Yeargroup.YeargroupID)"
            >
              Rename</v-btn
            >
            <v-btn
              v-if="Yeargroup.YeargroupID == rename"
              color="lime"
              dark
              @click="RenameCancel()"
            >
              Cancel</v-btn
            >
            <v-spacer></v-spacer>
            <confirm-action
              btntext="Delete"
              Title="Confirm Delete?"
              Message="Warning: This will delete all stored data for this yeargroup. Are you sure you wish to continue?"
              @confirm="removeYeargroup()"
            ></confirm-action>
            <v-spacer></v-spacer>
            <v-btn :disabled="!isFormValid" color="lime" dark @click="Save()">
              Save</v-btn
            >
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="ConfirmDelete" persistent max-width="290">
      <v-card>
        <v-card-title>Confirm Delete?</v-card-title>
        <v-card-text
          >This will delete the current set. All student data will be preserved,
          but you will need to reupload your student details file.</v-card-text
        >
        <v-card-actions>
          <v-btn color="green darken-1" text @click="ConfirmDelete = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteConfirmed()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ConfirmAction from "@/components/admin/ConfirmAction";
export default {
  name: "AdminClasses",
  components: {
    ConfirmAction,
  },
  data: () => ({
    Yeargroups: [],
    Yeargroups2: [],
    newYear: false,
    newYearName: "",
    rename: null,
    rules: [(value) => !!value || "Required."],
    selectRules: [(v) => v.length > 0 || "Item is required in select 2"],
    numberRules: [
      //v => v.length > 0 || 'This field may not be empty',
      (v) =>
        Number.isInteger(Number(v)) || "The value must be an integer number",
      (v) => v > 0 || "The value must be greater than zero",
    ],
    isFormValid: false,
    expanded: null,
    Sets: [],
    SoW: [],
    SoWIDs: [],
    SoWIndex: null,
    NewSet: "",
    Assess: [],
    Assessloaded: false,
    Submit1: [],
    Submit2: [],
    Delete1: [],
    YearID: null,
    SetID: null,
    ConfirmDelete: false,
    dupYear: false,
    showmsg: false,
    allSets: [],
    showalert: false,
  }),
  mounted() {
    this.getYears();
    this.getSoW();
    this.getSets();
  },
  props: {},
  computed: {},
  methods: {
    keyset() {
      this.showalert = false;
      this.showmsg = true;
    },
    getSets() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/16",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.allSets = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchArray(v) {
      this.Yeargroups2 = v;
    },
    addYear(name) {
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "Yeargroups",
          data: [
            {
              keyval: { YeargroupID: null },
              values: {
                Yeargroup: name,
                SubjectId: this.$store.getters.getSubject,
              },
            },
          ],
        },
        responseType: "json",
      })
        .then(() => {
          this.newYear = false;
          this.newYearName = "";
          this.getYears();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getYears() {
      this.Yeargroups = [];
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/8",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Yeargroups = response.data;
          this.Yeargroups2 = this.Yeargroups;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Rename(e) {
      this.rename = e;
    },
    RenameSave(name, id) {
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/admin/" + id,
        data: {
          table: "Yeargroups",
          values: { Yeargroup: name },
        },
        responseType: "json",
      })
        .then(() => {
          this.rename = null;
          this.getYears();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    RenameCancel() {
      this.rename = null;
    },
    getID(id) {
      this.Assessloaded = false;
      //this.Sets = []
      let y = this.Yeargroups2[id].YeargroupID;
      this.YearID = y;
      this.getSetData(y);
    },
    getSetData(y) {
      this.getSets();
      this.Sets = [];
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/9",
        params: {
          YeargroupID: y,
        },
        responseType: "json",
      })
        .then((response) => {
          //console.log(response.data)
          this.Sets = response.data;
          this.Assessments(y);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSoW() {
      this.SoW = [];
      this.SoWIDs = [];
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/2",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          //for (let i=0; i<response.data.length;i++){
          //  this.SoW.push(response.data[i].SoWName)
          //  this.SoWIDs.push(response.data[i].SoWId)
          //}
          this.SoW = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Save() {
      //Check no duplicate set names here
      //when new Sets does not include SetId or YeargroupID
      this.Submit1 = [];
      this.Submit2 = [];
      this.Delete1 = [];
      for (let i = 0; i < this.Sets.length; i++) {
        this.Submit1.push({
          keyval: { SetId: this.Sets[i].SetId  || null},
          values: {
            SetName: this.Sets[i].SetName,
            YeargroupID: this.YearID,
            SoWID: this.Sets[i].SoWID,
          },
        });
        if (this.Sets[i].SetId != undefined) {
          this.Delete1.push({ SetID: this.Sets[i].SetId });
        }
      }
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "MathsSets",
          data: this.Submit1,
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.insertids) {
            let insertids = response.data.insertids;
            for (let z = 0; z < insertids.length; z++) {
              this.Sets[this.Sets.length - insertids.length + z].SetId =
                insertids[z];
            }
          }

          //let k=0
          for (let i = 0; i < this.Sets.length; i++) {
            for (let j = 0; j < this.Sets[i].MappedAssessments.length; j++) {
              this.Submit2.push({
                keyval: { TestSetsID: null },
                values: {
                  SetID: this.Sets[i].SetId,
                  TestID: this.Sets[i].MappedAssessments[j],
                },
              });
            }
          }

          this.deleteAssess();

          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
      //*/
    },
    deleteAssess() {
      if (this.Delete1.length > 0) {
        this.$http({
          method: "delete",
          url: process.env.VUE_APP_BACKEND + "/admin/" + null,
          data: {
            table: "TestSets",
            values: this.Delete1,
          },
          responseType: "json",
        })
          .then((response) => {
            this.addAssess();
            //this.getSum();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.addAssess();
      }
    },
    addAssess() {
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/admin",
        data: {
          table: "TestSets",
          data: this.Submit2,
        },
        responseType: "json",
      })
        .then(() => {
          //this.getSum();
          this.Assessloaded = false;
          this.getSetData(this.YearID);
          //this.deleteAssess()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newRow() {
      let k = 0;
      //check if exists in db
      const result = this.allSets.find(
        ({ SetName }) => SetName === this.NewSet
      );
      if (result) {
        k++;
      }
      //check if exists in array
      const result2 = this.Sets.find(({ SetName }) => SetName === this.NewSet);
      if (result2) {
        k++;
      }
      if (k == 0) {
        this.Sets.push({
          SetId: null,
          SetName: this.NewSet,
          MappedAssessments: [],
        });
      } else {
        this.showalert = true;
      }
      this.NewSet = "";
      this.showmsg = false;
    },
    Assessments(id) {
      //yeargroup then position in array
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/5",
        params: {
          SubjectID: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Assess = response.data;
          this.$http({
            method: "get",
            url: process.env.VUE_APP_BACKEND + "/admin/10",
            params: {
              YeargroupID: id,
            },
            responseType: "json",
          })
            .then((response2) => {
              //get all testsets for Yeargroup
              for (let i = 0; i < this.Sets.length; i++) {
                this.Sets[i].MappedAssessments = [];
                this.Sets[i].YeargroupID = id;
                for (let j = 0; j < response2.data.length; j++) {
                  if (this.Sets[i].SetId == response2.data[j].SetID) {
                    this.Sets[i].MappedAssessments.push(
                      response2.data[j].TestID
                    );
                  }
                }
              }
              this.Assessloaded = true;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeSet(id, yid) {
      this.SetID = id;
      this.YearID = yid;
      this.ConfirmDelete = true;
    },
    removeYeargroup() {
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + this.YearID,
        data: {
          table: "Yeargroups",
        },
        responseType: "json",
      })
        .then(() => {
          this.getYears();
          this.getSets();
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteConfirmed() {
      //this.getID(this.YearID)
      this.$http({
        method: "delete",
        url: process.env.VUE_APP_BACKEND + "/admin/" + this.SetID,
        data: {
          table: "MathsSets",
        },
        responseType: "json",
      })
        .then(() => {
          this.Sets = [];
          this.getID(this.YearID);
          this.ConfirmDelete = false;
          //this.getSum();
        })
        .catch((error) => {
          console.log(error);
        });
      this.ConfirmDelete = false;
    },
    duplicateYears(id) {
      let j = 0;
      for (let i = 0; i < this.Yeargroups.length; i++) {
        if (this.Yeargroups[i].Yeargroup == id) {
          j++;
        }
      }
      if (j > 1) {
        this.dupYear = true;
      } else {
        this.dupYear = false;
      }
    },
    duplicateYears2(id) {
      let j = 0;
      for (let i = 0; i < this.Yeargroups.length; i++) {
        if (this.Yeargroups[i].Yeargroup == id) {
          j++;
        }
      }
      if (j > 0) {
        this.dupYear = true;
      } else {
        this.dupYear = false;
      }
    },
  },
};
</script>
