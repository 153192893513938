<template>
    <v-container>
      <v-alert
      v-if="failed"
      dense
      outlined
      dismissable
      type="error"
    >
      Add teacher failed. Please check the email address is valid, and not already in use
    </v-alert>
    <v-alert
      v-if="message!=''"
      dense
      outlined
      dismissable
      type="success"
    >
      {{ message }}
    </v-alert>
      <v-data-table
      v-if="ready"
    :headers="headers"
    :items="teachers"
  >
  <template
        v-slot:item.role="{ item, index  }"
      >
      <v-form v-model="validRole[index]">
      <v-select
        v-model="item.role"
          :items="roles"
          :rules="rulesSelect"
          item-text="name"
          item-value="id"
          label="Role"
          width="10"
        ></v-select>
      </v-form>
      </template>
      <template
        v-slot:item.email="{ item, index }"
      >
      <v-form v-model="validEmail[index]">
      <v-text-field :rules="rules" v-model="item.email"></v-text-field>
    </v-form>
      </template>
      <template
        v-slot:item.active="{ item, index }"
      >
      <div v-if="item.active==1">
        <v-btn :disabled="validRole[index]==false || validEmail[index]==false" color="lime" text @click="updateUser(item)">Update</v-btn>
        <v-btn color="lime" text @click="sendConfirmation(item)">Re-send Activation</v-btn>
      </div>
      <div v-else>
        <v-btn :disabled="validRole[index]==false || validEmail[index]==false" color="lime" text @click="activate(item)">Activate</v-btn>
      </div>
      </template>
</v-data-table>
    </v-container>
</template>

<script>
export default {
  name: "Users",
  props: {},
  mounted() {
    this.setScrollBar()
    this.loadTeachers()
  },
  data() {
    return {
      teachers: [],
      message: "",
      subjects: [],
      users: [],
      roles: [],
      ready: false,
      validEmail: [],
      validRole: [],
      failed: false,
      rulesSelect: [
          value => !!value || 'Select a role',
          value => value>-1 || 'Select a role',
      ],
      rules: [
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      ],
      headers: [
      { text: 'Teacher', value: 'Teacher' },
      { text: 'Subject', value: 'subject_name' },
      { text: 'Role', value: 'role' },
      { text: 'Email', value: 'email' },
      { text: 'Actions', value: 'active'}
      ],
    };
  },
  methods: {
    updateUser(item){
      this.message=""
      this.$http({
        method: "put",
        url: process.env.VUE_APP_BACKEND + "/users_teachers/"+item.userID,
        params: {
          role: item.role,
          email: item.email,
        },
        responseType: "json",
      })
        .then((response) => {
          this.message = response.data.message
          //console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activate(item){
      this.message=""
      let i = this.teachers.findIndex(a=> a.TeacherID==item.TeacherID)
      
      this.$http({
        method: "post",
        url: process.env.VUE_APP_BACKEND + "/users_teachers",
        params: {
          name: item.Teacher,
          email: item.email,
          role: parseInt(item.role),
          teacher: item.TeacherID,
        },
        responseType: "json",
      })
        .then((response) => {
          this.failed=false
          this.teachers[i].active=1
          this.teachers[i].userID = response.data.user.id
          this.teachers[i].role = response.data.user.role_id
          this.teachers[i].email = response.data.user.email
          this.message="Account activated"
        })
        .catch((error) => {
          this.failed=true
          console.log(error);
        });
      
    },
    sendConfirmation(item){
      this.message=""
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/users_teachers/"+item.userID,
        params: {

        },
        responseType: "json",
      })
        .then((response) => {
          this.message = response.data.message
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(item.userID)
    },
    loadUsers(){
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/users_teachers",
        params: {
          
        },
        responseType: "json",
      })
        .then((response) => {
          this.users = response.data.users;
          this.roles = response.data.roles;
          let roleid = this.roles.findIndex(a=>a.name=="student")
          this.roles.splice(roleid,1)
          //get roles for all teachers
          for (let i=0;i<this.teachers.length;i++){
            this.teachers[i].active = 0
            let id = this.teachers[i].TeacherID
            for (let j=0;j<this.users.length;j++){
              if (this.users[j].teacher!=null){
                if (this.users[j].teacher.teacher==id){
                  this.teachers[i].role = this.users[j].role.id
                  this.teachers[i].email = this.users[j].email
                  this.teachers[i].userID = this.users[j].id
                  this.teachers[i].active = 1
                }
              }
            }
            //let user = this.users.find(a=> a.teacher.teacher==id)
            //if (user!=undefined){
            //  this.teachers[i].user = user
            //}
          }
          this.ready = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadTeachers(){
      //load all teachers of the school from own DB
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/admin/26",
        params: {
          
        },
        responseType: "json",
      })
        .then((response) => {
          this.teachers = response.data.teachers;
          this.subjects = response.data.subjects
          this.loadUsers()
        })
        .catch((error) => {
          console.log(error);
        });
      //load all accounts allocated to the school
    },
    setScrollBar() {
      this.$store.commit("setScrollBar", {
        slug: "admin_users",
        data: [
          { ID: 1, Name: "Staff" },
          { ID: 2, Name: "Students" },
          //{ ID: 3, Name: "Parents" },
        ],
      });
    },
  },
  computed: {

  },
};
</script>
