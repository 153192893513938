<template>
  <div>
    <prior-banner @noprior="taskSubmittable=false" @prior="taskSubmittable=true" :yearID="$store.getters.getYeargroup"></prior-banner>
    <router-view></router-view>
    <v-row height="100%" class="ma-2">
      <v-btn color="black" width="100%" dark @click="$router.push('/leader/markbook')">Show Markbook</v-btn>
      <v-col>
        <div class="text-h4">Feedback Tasks:</div>
        <v-flex style="overflow: auto">
        <v-skeleton-loader
                v-if="tasksLoaded==false"
                class="mx-auto"
                max-width="300"
                type="card"
                ></v-skeleton-loader>
                <div v-else>
                    <v-card height="90vh" v-if="taskSubmittable">
                      <feedback @tasksChanged="getTasks()" :taskID="null" :yeargroupID="yeargroup"></feedback>
                      <feedback @tasksChanged="getTasks()" v-for="task in tasks" :taskID="parseInt(task.taskID)" :yeargroupID="yeargroup"></feedback>
                    </v-card>
                    <div v-else>
                      <v-card>
                        <v-card-title>
                          You need to upload prior data to initiate a feedback task
                        </v-card-title>
                      </v-card>
                    </div>
                    
                </div>
              </v-flex>
      </v-col>
      <v-col>
        <div class="text-h4">Assessments:</div>
        <v-flex style="overflow: auto">
          <v-skeleton-loader
            v-if="testsLoading"
            type="card"
          ></v-skeleton-loader>
          <v-card height="90vh" v-else>
            <v-card class="my-1" v-for="test in testSummary">
              <ampil-card :title="test.TestName"></ampil-card>
              <v-row v-if="test.averages.length > 0">
                <v-col>
                  <v-card-text> Set averages </v-card-text>
                  <v-sparkline
                    type="bar"
                    :labels="graphLabels(test.averages)"
                    :value="graphData(test.averages)"
                    line-width="0"
                    padding="16"
                  ></v-sparkline>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-card-text>
                    No results found for current students...
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-if="test.historic.length > 0">
                <v-col>
                  <v-card-text>
                    Historic average scores:
                    <v-sparkline
                      :labels="graph2Labels(test.historic)"
                      :value="graph2Data(test.historic)"
                      line-width="0"
                      padding="16"
                      smooth="16"
                    ></v-sparkline>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-card-text>
                    No historic data found for this assessment...
                  </v-card-text>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <topic-maps :testID="test.TestID"></topic-maps>
              </v-card-actions>
              <v-card-actions>
                <revision-lists
                  :testName="test.TestName"
                  :test="test.TestID"
                ></revision-lists>
                <v-spacer></v-spacer>
                <upload-totals
                  :testName="test.TestName"
                  :test="test.TestID"
                  :total="parseInt(test.Max)"
                ></upload-totals>
                <v-spacer></v-spacer>
                <enter-qla :test="test.TestID" :yeargroupID="yeargroup"></enter-qla>
                
              </v-card-actions>
            </v-card>
          </v-card>
        </v-flex></v-col
      >
    </v-row>
  </div>
</template>

<script>
import Feedback from "@/components/leader/Feedback.vue";
import UploadTotals from "@/components/teacher/UploadTotals";
import RevisionLists from "@/components/teacher/RevisionLists";
import PriorBanner from "@/components/leader/PriorBanner";
import EnterQla from "@/components/teacher/EnterQla";
import TopicMaps from "@/components/leader/TopicMaps";
export default {
  name: "LeaderHome",
  components: {
    UploadTotals,
    RevisionLists,
    PriorBanner,
    EnterQla,
    Feedback,
    TopicMaps,
  },
  props: {},
  mounted() {
    this.getYeargroups();
    //this.getTasks()
  },
  watch: {
    yeargroup() {
      this.testsLoading = true;
      this.tasksLoaded = false
      this.getData();
      this.getTasks()
    },
  },
  data() {
    return {
      yeargroupID: null,
      taskSubmittable: true,
      yeargroups: [],
      testsLoading: true,
      testSummary: [],
      tasks: [],
        tasksLoaded: false,
    };
  },
  methods: {
    getTasks(){
      this.tasksLoaded=false

            this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/17",
        params: {
          YeargroupID: this.yeargroup,
        },
        responseType: "json",
      })
        .then((response) => {

            this.tasks = response.data
            this.tasksLoaded=true
            //this.getTests()
          
        })
        .catch((error) => {
          console.log(error);
        });
        },
    getData() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/leader/1",
        params: {
          SubjectID: this.$store.getters.getSubject,
          YeargroupID: this.$store.getters.getYeargroup,
        },
        responseType: "json",
      })
        .then((response) => {
          this.sortData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sortData(d) {
      let out = [];
      let Averages = [];
      let Historic = [];
      let h = [];
      for (let i = 0; i < d.tests.length; i++) {
        //Match averages
        Averages = [];
        Averages = d.averages.filter(
          ({ TestID, Avg }) => TestID == d.tests[i].TestID && Avg != null
        );
        h = [];
        for (let j = 0; j < d.historic.length; j++) {
          Historic = d.historic[j].data.filter(
            ({ TestID }) => TestID == d.tests[i].TestID
          );
          if (Historic.length > 0) {
            h.push({ Year: d.historic[j].year, Av: Historic[0].SetAvg });
          }
        }
        out.push({
          TestID: d.tests[i].TestID,
          TestName: d.tests[i].TestName,
          Max: d.tests[i].Max,
          averages: Averages,
          historic: h,
        });
      }
      this.testSummary = out;
      this.testsLoading = false;
    },
    getYeargroups() {
      this.$http({
        method: "get",
        url: process.env.VUE_APP_BACKEND + "/teacher/1",
        params: {
          SubjectId: this.$store.getters.getSubject,
        },
        responseType: "json",
      })
        .then((response) => {
          //console.log(response.data)
          this.yeargroups = response.data;
          this.$store.commit("setYeargroup", this.yeargroups[0].YeargroupID);
          this.setScrollBar();
          this.getData();
          this.getTasks()
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setScrollBar() {
      let scroll = [];
      for (let i = 0; i < this.yeargroups.length; i++) {
        scroll.push({
          ID: this.yeargroups[i].YeargroupID,
          Name: "Year " + this.yeargroups[i].Yeargroup,
        });
      }
      this.$store.commit("setScrollBar", { slug: "yeargroups", data: scroll });
    },
    graphData(data) {
      let r = [0];
      for (let i = 0; i < data.length; i++) {
        //console.log(data[i])
        r.push(parseInt(data[i].Avg));
      }
      return r;
    },
    graphLabels(data) {
      let r = [""];
      for (let i = 0; i < data.length; i++) {
        r.push(data[i].SetName + " (" + parseInt(data[i].Avg) + ")");
      }
      return r;
    },
    graph2Data(data) {
      let r = [0];
      for (let i = 0; i < data.length; i++) {
        r.push(parseInt(data[i].Av));
      }
      return r;
    },
    graph2Labels(data) {
      let r = [""];
      for (let i = 0; i < data.length; i++) {
        r.push(data[i].Year + " (" + parseInt(data[i].Av) + ")");
      }
      return r;
    },
  },
  computed: {
    yeargroup() {
      return this.$store.getters.getYeargroup;
    },
  },
};
</script>

<style>
.split {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.left {
  overflow-y: scroll; /* it works! */
}
.right {
  flex: 1;
  overflow-y: scroll; /* it works! */
}
</style>
