<template>
  <v-list nav dense>
    <v-list-item-group v-model="group" active-class="lime--text text--accent-4">
      <v-list-item inactive disabled>
        <v-list-item-title>Menu</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title @click="navigate('/profile')">Profile</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-for="menu_item in menu" :key="menu_item.name" link>
        <v-list-item-title @click="navigate(menu_item.link)">{{
          menu_item.name
        }}</v-list-item-title>
      </v-list-item>

    </v-list-item-group>

    <v-list-item-group v-if="scope == 'teacher' || scope == 'leader'">
      <v-divider></v-divider>
      <upload-totals :side="true"></upload-totals>
      <revision-lists :side="true"></revision-lists>
    </v-list-item-group>
  </v-list>
</template>

<script>
import UploadTotals from "@/components/teacher/UploadTotals";
import RevisionLists from "@/components/teacher/RevisionLists";
export default {
  name: "MainMenu",
  components: {
    UploadTotals,
    RevisionLists,
  },
  data: () => ({
    //
    group: null,
    menuAdmin: [
    { name: "Home", link: "/admin" },
      { name: "School Structure", link: "/admin/subjects" },
      { name: "Student Details", link: "/admin/add_students" },
      { name: "Manage Users", link: "/admin/users" },
      { name: "Export QLA Data", link: "/admin/export" },
    ],
    menuLeader: [
      { name: "Home", link: "/leader" },
      { name: "Markbook", link: "/leader/markbook" },
      { name: "Upload prior data", link: "/leader/prior" },
    ],
    menuTeacher: [
      { name: "Home", link: "/teacher" },
      { name: "Markbook", link: "/teacher/markbook" },
      
    ],
    menuStudent: [
      { name: "Home", link: "/student" },
      { name: "Select Student", link: "/student/select" },
    ],
    menuStudent2: [
      { name: "Home", link: "/student" },
    ],
    menuStudent3: [
      { name: "Home", link: "/student" },
      {name: "Change Subject", link: "/student/subject"},
    ],
  }),
  methods: {
    navigate(route) {
      this.$router.push(route).catch((err) => {});
    },
    clearstate() {},
  },
  computed: {
    scope() {
      return this.$store.getters.getViewAs;
    },
    menu() {
      let scope = this.$store.getters.getViewAs;
      switch (scope) {
        case "admin":
          return this.menuAdmin;
        case "leader":
          return this.menuLeader;
        case "teacher":
          return this.menuTeacher;
        case "student":
          if (this.$store.getters.getScope=="student"){
            //if more than one subject
            if (this.$store.getters.getStudentSubjects.length>1){
              return this.menuStudent3;
            }
            return this.menuStudent2;
          }
          else{
            return this.menuStudent;
          }
          
      }
    },
  },
};
</script>
