<template>
  <div>
    <div v-if="getScope != 'student' && getScope && getStudentID != null">
      {{ getStudent.Name }}: ({{ getStudent.Set }})
    </div>
    <div v-if="getStudentID == null && getScope != 'student'" >
      <select-student></select-student>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SelectStudent from "@/components/student/SelectStudent";

export default {
  name: "StudentIndex",
  data: () => ({}),
  components: {
    SelectStudent,
  },
  mounted() {
    if (this.getStudentID==null && this.getScope=="student"){
      this.$router.push('/')
    }
  },
  methods: {},
  computed: {
    getScope() {
      return this.$store.getters.getScope;
    },
    getStudent() {
      return this.$store.getters.getStudentDetails;
    },
    getStudentID() {
      return this.$store.getters.getStudentID;
    },
  },
};
</script>
