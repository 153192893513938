<template>
  <div>
    <LogIn></LogIn>
  </div>
</template>

<script>
import LogIn from "../components/LogIn";

export default {
  name: "LoginView",

  components: {
    LogIn,
  },
};
</script>
